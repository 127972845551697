import {
    USER_MANAGEMENT_DELETE_USER,
    USER_MANAGEMENT_DELETE_USER_ERROR,
    USER_MANAGEMENT_DELETE_USER_NONE,
    USER_MANAGEMENT_DELETE_USER_SUCCESS,
    USER_MANAGEMENT_DO_LOGIN,
    USER_MANAGEMENT_DO_LOGIN_ERROR,
    USER_MANAGEMENT_DO_LOGIN_NONE,
    USER_MANAGEMENT_DO_LOGIN_SUCCESS,
    USER_MANAGEMENT_DO_LOGOUT,
    USER_MANAGEMENT_DO_LOGOUT_ERROR,
    USER_MANAGEMENT_DO_LOGOUT_NONE,
    USER_MANAGEMENT_DO_LOGOUT_SUCCESS,
    USER_MANAGEMENT_GET_DETAILS,
    USER_MANAGEMENT_GET_DETAILS_ERROR,
    USER_MANAGEMENT_GET_DETAILS_NONE,
    USER_MANAGEMENT_GET_DETAILS_SUCCESS,
    USER_MANAGEMENT_GET_LOGINS,
    USER_MANAGEMENT_GET_LOGINS_ERROR,
    USER_MANAGEMENT_GET_LOGINS_NONE,
    USER_MANAGEMENT_GET_LOGINS_SUCCESS,
    USER_MANAGEMENT_GET_USER_ACTIVE_STATUS,
    USER_MANAGEMENT_GET_USER_ACTIVE_STATUS_ERROR,
    USER_MANAGEMENT_GET_USER_ACTIVE_STATUS_NONE,
    USER_MANAGEMENT_GET_USER_ACTIVE_STATUS_SUCCESS,
    USER_MANAGEMENT_REGISTER,
    USER_MANAGEMENT_REGISTER_ERROR,
    USER_MANAGEMENT_REGISTER_NONE,
    USER_MANAGEMENT_REGISTER_SUCCESS,
    USER_MANAGEMENT_UPDATE_DETAILS,
    USER_MANAGEMENT_UPDATE_DETAILS_ERROR,
    USER_MANAGEMENT_UPDATE_DETAILS_NONE,
    USER_MANAGEMENT_UPDATE_DETAILS_SUCCESS
} from "../../../constants/actions/chat/ChatActionTypes";

/** user management : register **/
export const userManagementUserRegister = (payload) => {
    return {
        type: USER_MANAGEMENT_REGISTER,
        payload: payload
    };
};

/** user management : register : success **/
export const userManagementUserRegisterSuccess = (payload) => {
    return {
        type: USER_MANAGEMENT_REGISTER_SUCCESS,
        payload: payload
    };
};

/** user management : register : error **/
export const userManagementUserRegisterError = (payload) => {
    return {
        type: USER_MANAGEMENT_REGISTER_ERROR,
        payload: payload
    };
};

/** user management : register : none **/
export const userManagementUserRegisterNone = (payload) => {
    return {
        type: USER_MANAGEMENT_REGISTER_NONE,
        payload: payload
    };
};


/** user management : to get user details **/
export const userManagementUserGetDetails = (payload) => {
    return {
        type: USER_MANAGEMENT_GET_DETAILS,
        payload: payload
    };
};

/** user management : to get user details : success **/
export const userManagementUserGetDetailsSuccess = (payload) => {
    return {
        type: USER_MANAGEMENT_GET_DETAILS_SUCCESS,
        payload: payload
    };
};

/** user management : to get user details : error **/
export const userManagementUserGetDetailsError = (payload) => {
    return {
        type: USER_MANAGEMENT_GET_DETAILS_ERROR,
        payload: payload
    };
};

/** user management : to get user details : none **/
export const userManagementUserGetDetailsNone = (payload) => {
    return {
        type: USER_MANAGEMENT_GET_DETAILS_NONE,
        payload: payload
    };
};


/** user management : to update user details **/
export const userManagementUserUpdateDetails = (payload) => {
    return {
        type: USER_MANAGEMENT_UPDATE_DETAILS,
        payload: payload
    };
};

/** user management : to update user details : success **/
export const userManagementUserUpdateDetailsSuccess = (payload) => {
    return {
        type: USER_MANAGEMENT_UPDATE_DETAILS_SUCCESS,
        payload: payload
    };
};

/** user management : to update user details : error **/
export const userManagementUserUpdateDetailsError = (payload) => {
    return {
        type: USER_MANAGEMENT_UPDATE_DETAILS_ERROR,
        payload: payload
    };
};

/** user management : to update user details : none **/
export const userManagementUserUpdateDetailsNone = (payload) => {
    return {
        type: USER_MANAGEMENT_UPDATE_DETAILS_NONE,
        payload: payload
    };
};


/** user management : to delete user details **/
export const userManagementUserDelete = (payload) => {
    return {
        type: USER_MANAGEMENT_DELETE_USER,
        payload: payload
    };
};

/** user management : to delete user details : success **/
export const userManagementUserDeleteSuccess = (payload) => {
    return {
        type: USER_MANAGEMENT_DELETE_USER_SUCCESS,
        payload: payload
    };
};

/** user management : to delete user details : error **/
export const userManagementUserDeleteError = (payload) => {
    return {
        type: USER_MANAGEMENT_DELETE_USER_ERROR,
        payload: payload
    };
};

/** user management : to delete user details : none **/
export const userManagementUserDeleteNone = (payload) => {
    return {
        type: USER_MANAGEMENT_DELETE_USER_NONE,
        payload: payload
    };
};


/** user management : to get logins **/
export const userManagementUserGetLogins = (payload) => {
    return {
        type: USER_MANAGEMENT_GET_LOGINS,
        payload: payload
    };
};

/** user management : to get logins : success **/
export const userManagementUserGetLoginsSuccess = (payload) => {
    return {
        type: USER_MANAGEMENT_GET_LOGINS_SUCCESS,
        payload: payload
    };
};

/** user management : to get logins : error **/
export const userManagementUserGetLoginsError = (payload) => {
    return {
        type: USER_MANAGEMENT_GET_LOGINS_ERROR,
        payload: payload
    };
};

/** user management : to get logins : none **/
export const userManagementUserGetLoginsNone = (payload) => {
    return {
        type: USER_MANAGEMENT_GET_LOGINS_NONE,
        payload: payload
    };
};


/** user management : login **/
export const userManagementUserLogin = (payload) => {
    return {
        type: USER_MANAGEMENT_DO_LOGIN,
        payload: payload
    };
};

/** user management : login : success **/
export const userManagementUserLoginSuccess = (payload) => {
    return {
        type: USER_MANAGEMENT_DO_LOGIN_SUCCESS,
        payload: payload
    };
};

/** user management : login : error **/
export const userManagementUserLoginError = (payload) => {
    return {
        type: USER_MANAGEMENT_DO_LOGIN_ERROR,
        payload: payload
    };
};

/** user management : login : none **/
export const userManagementUserLoginNone = (payload) => {
    return {
        type: USER_MANAGEMENT_DO_LOGIN_NONE,
        payload: payload
    };
};


/** user management : logout **/
export const userManagementUserLogOut = (payload) => {
    return {
        type: USER_MANAGEMENT_DO_LOGOUT,
        payload: payload
    };
};

/** user management : logout : success **/
export const userManagementUserLogOutSuccess = (payload) => {
    return {
        type: USER_MANAGEMENT_DO_LOGOUT_SUCCESS,
        payload: payload
    };
};

/** user management : logout : error **/
export const userManagementUserLogOutError = (payload) => {
    return {
        type: USER_MANAGEMENT_DO_LOGOUT_ERROR,
        payload: payload
    };
};

/** user management : logout : none **/
export const userManagementUserLogOutNone = (payload) => {
    return {
        type: USER_MANAGEMENT_DO_LOGOUT_NONE,
        payload: payload
    };
};

/** user management : to get user active status **/
export const userManagementGetUserActiveStatus = (payload) => {
    return {
        type: USER_MANAGEMENT_GET_USER_ACTIVE_STATUS,
        payload: payload
    };
};

/** user management : to get user active status : success **/
export const userManagementGetUserActiveStatusSuccess = (payload) => {
    return {
        type: USER_MANAGEMENT_GET_USER_ACTIVE_STATUS_SUCCESS,
        payload: payload
    };
};

/** user management : to get user active status : error **/
export const userManagementGetUserActiveStatusError = (payload) => {
    return {
        type: USER_MANAGEMENT_GET_USER_ACTIVE_STATUS_ERROR,
        payload: payload
    };
};

/** user management : to get user active status : none **/
export const userManagementGetUserActiveStatusNone = (payload) => {
    return {
        type: USER_MANAGEMENT_GET_USER_ACTIVE_STATUS_NONE,
        payload: payload
    };
};
