import {store} from "../../../NextApp";
import {
    DEFAULT_LANGUAGE_CODE,
    MESSAGE_ROUTER_CHANNEL_TYPES,
    MESSAGE_ROUTER_USER_STATUS_COLORS,
    MESSAGE_ROUTER_USER_STATUS_VALUES,
    TRIBE_CHAT_FILE_TYPES,
    UI_TEXT_CONFIG
} from "../constants/config";
import moment from "moment";
import {postSignalHubEvent} from "../../../appRedux/actions";

export const HTTP_TIMEOUT = 3000000;

const COLOR_CODES = {
    "red": {
        "50": "#ffebee",
        "100": "#ffcdd2",
        "200": "#ef9a9a",
        "300": "#e57373",
        "400": "#ef5350",
        "500": "#f44336",
        "600": "#e53935",
        "700": "#d32f2f",
        "800": "#c62828",
        "900": "#b71c1c",
        "hex": "#f44336",
        "a100": "#ff8a80",
        "a200": "#ff5252",
        "a400": "#ff1744",
        "a700": "#d50000"
    },
    "pink": {
        "50": "#fce4ec",
        "100": "#f8bbd0",
        "200": "#f48fb1",
        "300": "#f06292",
        "400": "#ec407a",
        "500": "#e91e63",
        "600": "#d81b60",
        "700": "#c2185b",
        "800": "#ad1457",
        "900": "#880e4f",
        "hex": "#e91e63",
        "a100": "#ff80ab",
        "a200": "#ff4081",
        "a400": "#f50057",
        "a700": "#c51162"
    },
    "purple": {
        "50": "#f3e5f5",
        "100": "#e1bee7",
        "200": "#ce93d8",
        "300": "#ba68c8",
        "400": "#ab47bc",
        "500": "#9c27b0",
        "600": "#8e24aa",
        "700": "#7b1fa2",
        "800": "#6a1b9a",
        "900": "#4a148c",
        "hex": "#9c27b0",
        "a100": "#ea80fc",
        "a200": "#e040fb",
        "a400": "#d500f9",
        "a700": "#aa00ff"
    },
    "deepPurple": {
        "50": "#ede7f6",
        "100": "#d1c4e9",
        "200": "#b39ddb",
        "300": "#9575cd",
        "400": "#7e57c2",
        "500": "#673ab7",
        "600": "#5e35b1",
        "700": "#512da8",
        "800": "#4527a0",
        "900": "#311b92",
        "hex": "#673ab7",
        "a100": "#b388ff",
        "a200": "#7c4dff",
        "a400": "#651fff",
        "a700": "#6200ea"
    },
    "indigo": {
        "50": "#e8eaf6",
        "100": "#c5cae9",
        "200": "#9fa8da",
        "300": "#7986cb",
        "400": "#5c6bc0",
        "500": "#3f51b5",
        "600": "#3949ab",
        "700": "#303f9f",
        "800": "#283593",
        "900": "#1a237e",
        "hex": "#3f51b5",
        "a100": "#8c9eff",
        "a200": "#536dfe",
        "a400": "#3d5afe",
        "a700": "#304ffe"
    },
    "blue": {
        "50": "#e3f2fd",
        "100": "#bbdefb",
        "200": "#90caf9",
        "300": "#64b5f6",
        "400": "#42a5f5",
        "500": "#2196f3",
        "600": "#1e88e5",
        "700": "#1976d2",
        "800": "#1565c0",
        "900": "#0d47a1",
        "hex": "#2196f3",
        "a100": "#82b1ff",
        "a200": "#448aff",
        "a400": "#2979ff",
        "a700": "#2962ff"
    },
    "lightBlue": {
        "50": "#e1f5fe",
        "100": "#b3e5fc",
        "200": "#81d4fa",
        "300": "#4fc3f7",
        "400": "#29b6f6",
        "500": "#03a9f4",
        "600": "#039be5",
        "700": "#0288d1",
        "800": "#0277bd",
        "900": "#01579b",
        "hex": "#03a9f4",
        "a100": "#80d8ff",
        "a200": "#40c4ff",
        "a400": "#00b0ff",
        "a700": "#0091ea"
    },
    "cyan": {
        "50": "#e0f7fa",
        "100": "#b2ebf2",
        "200": "#80deea",
        "300": "#4dd0e1",
        "400": "#26c6da",
        "500": "#00bcd4",
        "600": "#00acc1",
        "700": "#0097a7",
        "800": "#00838f",
        "900": "#006064",
        "hex": "#00bcd4",
        "a100": "#84ffff",
        "a200": "#18ffff",
        "a400": "#00e5ff",
        "a700": "#00b8d4"
    },
    "teal": {
        "50": "#e0f2f1",
        "100": "#b2dfdb",
        "200": "#80cbc4",
        "300": "#4db6ac",
        "400": "#26a69a",
        "500": "#009688",
        "600": "#00897b",
        "700": "#00796b",
        "800": "#00695c",
        "900": "#004d40",
        "hex": "#009688",
        "a100": "#a7ffeb",
        "a200": "#64ffda",
        "a400": "#1de9b6",
        "a700": "#00bfa5"
    },
    "green": {
        "50": "#e8f5e9",
        "100": "#c8e6c9",
        "200": "#a5d6a7",
        "300": "#81c784",
        "400": "#66bb6a",
        "500": "#4caf50",
        "600": "#43a047",
        "700": "#388e3c",
        "800": "#2e7d32",
        "900": "#1b5e20",
        "hex": "#4caf50",
        "a100": "#b9f6ca",
        "a200": "#69f0ae",
        "a400": "#00e676",
        "a700": "#00c853"
    },
    "lightGreen": {
        "50": "#f1f8e9",
        "100": "#dcedc8",
        "200": "#c5e1a5",
        "300": "#aed581",
        "400": "#9ccc65",
        "500": "#8bc34a",
        "600": "#7cb342",
        "700": "#689f38",
        "800": "#558b2f",
        "900": "#33691e",
        "hex": "#8bc34a",
        "a100": "#ccff90",
        "a200": "#b2ff59",
        "a400": "#76ff03",
        "a700": "#64dd17"
    },
    "lime": {
        "50": "#f9fbe7",
        "100": "#f0f4c3",
        "200": "#e6ee9c",
        "300": "#dce775",
        "400": "#d4e157",
        "500": "#cddc39",
        "600": "#c0ca33",
        "700": "#afb42b",
        "800": "#9e9d24",
        "900": "#827717",
        "hex": "#cddc39",
        "a100": "#f4ff81",
        "a200": "#eeff41",
        "a400": "#c6ff00",
        "a700": "#aeea00"
    },
    "yellow": {
        "50": "#fffde7",
        "100": "#fff9c4",
        "200": "#fff59d",
        "300": "#fff176",
        "400": "#ffee58",
        "500": "#ffeb3b",
        "600": "#fdd835",
        "700": "#fbc02d",
        "800": "#f9a825",
        "900": "#f57f17",
        "hex": "#ffeb3b",
        "a100": "#ffff8d",
        "a200": "#ffff00",
        "a400": "#ffea00",
        "a700": "#ffd600"
    },
    "amber": {
        "50": "#fff8e1",
        "100": "#ffecb3",
        "200": "#ffe082",
        "300": "#ffd54f",
        "400": "#ffca28",
        "500": "#ffc107",
        "600": "#ffb300",
        "700": "#ffa000",
        "800": "#ff8f00",
        "900": "#ff6f00",
        "hex": "#ffc107",
        "a100": "#ffe57f",
        "a200": "#ffd740",
        "a400": "#ffc400",
        "a700": "#ffab00"
    },
    "orange": {
        "50": "#fff3e0",
        "100": "#ffe0b2",
        "200": "#ffcc80",
        "300": "#ffb74d",
        "400": "#ffa726",
        "500": "#ff9800",
        "600": "#fb8c00",
        "700": "#f57c00",
        "800": "#ef6c00",
        "900": "#e65100",
        "hex": "#ff9800",
        "a100": "#ffd180",
        "a200": "#ffab40",
        "a400": "#ff9100",
        "a700": "#ff6d00"
    },
    "deepOrange": {
        "50": "#fbe9e7",
        "100": "#ffccbc",
        "200": "#ffab91",
        "300": "#ff8a65",
        "400": "#ff7043",
        "500": "#ff5722",
        "600": "#f4511e",
        "700": "#e64a19",
        "800": "#d84315",
        "900": "#bf360c",
        "hex": "#ff5722",
        "a100": "#ff9e80",
        "a200": "#ff6e40",
        "a400": "#ff3d00",
        "a700": "#dd2c00"
    },
    "brown": {
        "50": "#efebe9",
        "100": "#d7ccc8",
        "200": "#bcaaa4",
        "300": "#a1887f",
        "400": "#8d6e63",
        "500": "#795548",
        "600": "#6d4c41",
        "700": "#5d4037",
        "800": "#4e342e",
        "900": "#3e2723",
        "hex": "#795548"
    },
    "grey": {
        "50": "#fafafa",
        "100": "#f5f5f5",
        "200": "#eeeeee",
        "300": "#e0e0e0",
        "400": "#bdbdbd",
        "500": "#9e9e9e",
        "600": "#757575",
        "700": "#616161",
        "800": "#424242",
        "900": "#212121",
        "hex": "#9e9e9e"
    },
    "blueGrey": {
        "50": "#eceff1",
        "100": "#cfd8dc",
        "200": "#b0bec5",
        "300": "#90a4ae",
        "400": "#78909c",
        "500": "#607d8b",
        "600": "#546e7a",
        "700": "#455a64",
        "800": "#37474f",
        "900": "#263238",
        "hex": "#607d8b"
    },
    "black": {
        "hex": "#000000"
    },
    "white": {
        "hex": "#ffffff"
    }
};

const MONTH_NAMES = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

let AppUtils = {
    currentTranslations: {},
    defaultTranslations: {},
    currentUserId: "",
    notificationElement: null,
    incomingCallElement: null,
    mediaIFrame: null,
    isEventRegisteredForIframe: false,
    dispatch: null,

    setDispatch: function (dispatch) {
        try {
            AppUtils.dispatch = dispatch;
        } catch (ex) {
            console.error("setDispatch : error : ", ex);
        }
    },

    setCurrentUserId: function (currentUserId) {
        AppUtils.currentUserId = currentUserId;
    },

    getCurrentUserId: function () {
        return AppUtils.currentUserId;
    },

    setMediaIFrame: function (mediaIFrame) {
        AppUtils.mediaIFrame = mediaIFrame;
    },

    getHttpHeaders: function () {
        return {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': store.getState().auth.accessToken,
                //'Accept-Language': store.getState().settings.viewLanguage
            },
            timeout: HTTP_TIMEOUT
        }
    },

    getUUID: function () {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    },
    formatUTCTimeStamp: function (timestamp) {

        let date = new Date(timestamp);

        let day = date.getUTCDate();
        let month = date.getUTCMonth() + 1;
        let year = date.getUTCFullYear();
        let hours = date.getUTCHours();
        let minutes = date.getUTCMinutes();

        if (day < 10) {
            day = '0' + day;
        }
        if (month < 10) {
            month = '0' + month;
        }
        if (hours < 10) {
            hours = '0' + hours;
        }
        if (minutes < 10) {
            minutes = '0' + minutes;
        }

        return day + '-' + month + '-' + year + ' ' + hours + ':' + minutes;
    },

    formatAMPM: function (date) {
        let result = "";

        try {
            let hours = date.getHours();
            let minutes = date.getMinutes();
            let ampm = hours >= 12 ? 'pm' : 'am';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes < 10 ? '0' + minutes : minutes;
            result = hours + ':' + minutes + ' ' + ampm;

        } catch (ex) {
            console.error("formatAMPM : error : ", ex);
        }

        return result;
    },

    formatDateValue: function (dateVal) {
        let result = dateVal;
        try {

            let date = new Date(dateVal + (dateVal.indexOf("Z") != -1 ? "" : "Z"));
            let amPmValue = AppUtils.formatAMPM(date)
            result = (date.getUTCDate() + '-' + (date.getUTCMonth() + 1) + '-' + date.getUTCFullYear() + ' ' + amPmValue);
        } catch (ex) {
            console.error("formatDateValue : error : ", ex);
        }

        return result;

    },

    getChatCurrentValue: function (date) {
        let result = "";

        try {

            if (date && date.getTime) {
                const dateDifferenceInTime = new Date().getTime() - date.getTime();

                const dateDifferenceInDays = dateDifferenceInTime / (1000 * 60 * 60 * 24);


                if (dateDifferenceInDays < 1) {
                    return moment(date).format("LT");
                } else if (dateDifferenceInDays < 2) {
                    return "Yesterday";
                } else if (dateDifferenceInDays <= 7) {
                    return moment(date).format("dddd");
                } else {
                    return moment(date).format("l");
                }
            }
        } catch (ex) {
            console.error("getChatCurrentValue : error : ", ex);
        }

        return result;
    },

    getChatDividerValue: function (date) {
        let result = "";

        try {

            if (date && date.getTime) {
                const dateDifferenceInTime = new Date().getTime() - date.getTime();

                const dateDifferenceInDays = dateDifferenceInTime / (1000 * 60 * 60 * 24);


                if (dateDifferenceInDays < 1) {
                    return "Today";
                } else if (dateDifferenceInDays < 2) {
                    return "Yesterday";
                } else if (dateDifferenceInDays <= 7) {
                    return moment(date).format("dddd");
                } else {
                    return moment(date).format("l");
                }
            }

        } catch (ex) {
            console.error("getChatDividerValue : error : ", ex);
        }

        return result;
    },

    getMonthValueFromDate: function (date) {
        let result = "";

        try {

            if (date && date.getTime) {
                let currentDate = new Date();

                if (date.getFullYear() === currentDate.getFullYear()) {
                    if (date.getMonth() === currentDate.getMonth()) {
                        result = "This Month";
                    } else {
                        result = MONTH_NAMES[date.getMonth()];
                    }
                } else {
                    result = MONTH_NAMES[date.getMonth()] + ", " + date.getFullYear();
                }
            }

        } catch (ex) {
            console.error("getMonthValueFromDate : error : ", ex);
        }

        return result;
    },

    sortJSONArray: function (array, jsonKey, isAscending) {
        try {
            array.sort(function (a, b) {
                if (isAscending) {
                    return a[jsonKey] < b[jsonKey] ? -1 : 1;
                } else {
                    return a[jsonKey] > b[jsonKey] ? -1 : 1;
                }
            });
        } catch (ex) {
            console.error("sortJSONArray : error : ", ex);
        }
    },

    getJSONLength: function (jsonObj) {
        try {
            return Object.keys(jsonObj).length;
        } catch (ex) {
            console.error("getJSONLength : error : ", ex);
        }

        return 0;
    },

    getUserStatusColor: function (userId, userDetails) {

        try {
            if (typeof userDetails[userId] != "undefined" && typeof MESSAGE_ROUTER_USER_STATUS_COLORS[userDetails[userId]["status"]] != "undefined") {
                return MESSAGE_ROUTER_USER_STATUS_COLORS[userDetails[userId]["status"]];
            }

        } catch (ex) {
            console.error("getUserStatusColor : error : ", ex);
        }

        return MESSAGE_ROUTER_USER_STATUS_COLORS[MESSAGE_ROUTER_USER_STATUS_VALUES["APPEAR_OFFLINE"]];
    },

    getUTCTimeValue: function () {
        try {
            return new Date().getTime() + ((new Date().getTimezoneOffset()) * 60 * 1000);
        } catch (ex) {
            console.error("getUTCTimeValue : error : ", ex);
        }
        return 0;
    },

    getUTCTimeInMillis: function () {
        try {
            return new Date().getTime();
        } catch (ex) {
            console.error("getUTCTimeInMillis : error : ", ex);
        }
        return 0;
    },

    getHoursToMillis: function (hours) {
        try {
            return hours * 60 * 60 * 1000;
        } catch (ex) {
            console.error("getHoursToMillis : error : ", ex);
        }
        return 0;
    },

    isFilePreviewAvailable: function (fileType) {

        let result = false;

        try {

            if (fileType.indexOf("audio") !== -1 || fileType.indexOf("image") !== -1) {
                result = true;
            } else if (fileType.indexOf("video") !== -1 && TRIBE_CHAT_FILE_TYPES.VIDEO_FILE_TYPES.includes(fileType)) {
                result = true;
            }

        } catch (ex) {
            console.error("isFilePreviewAvailable : error : ", ex);
        }

        return result;

    },

    isFileContentType: function (fileType) {

        let result = TRIBE_CHAT_FILE_TYPES.OTHERS;

        try {

            if (fileType.indexOf("audio") !== -1) {
                result = TRIBE_CHAT_FILE_TYPES.AUDIO;
            } else if (fileType.indexOf("image") !== -1) {
                result = TRIBE_CHAT_FILE_TYPES.IMAGE;
            } else if (fileType.indexOf("video") !== -1 && TRIBE_CHAT_FILE_TYPES.VIDEO_FILE_TYPES.includes(fileType)) {
                result = TRIBE_CHAT_FILE_TYPES.VIDEO;
            }

        } catch (ex) {
            console.error("isFileContentType : error : ", ex);
        }

        return result;
    },

    getFileMediaType: function (fileType) {

        let result = TRIBE_CHAT_FILE_TYPES.MEDIA_TYPES.DOC;

        try {

            if (fileType.indexOf("image") !== -1 || fileType.indexOf("video") !== -1) {
                result = TRIBE_CHAT_FILE_TYPES.MEDIA_TYPES.MEDIA;
            }

        } catch (ex) {
            console.error("getFileMediaType : error : ", ex);
        }

        return result;
    },

    getBytesSize: function (bytes, decimals = 2) {
        try {
            if (!+bytes) return '0 Bytes'
            const k = 1024
            const dm = decimals < 0 ? 0 : decimals
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

            const i = Math.floor(Math.log(bytes) / Math.log(k))

            return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
        } catch (ex) {
            console.error("getBytesSize : error : ", ex);
        }

        return "";
    },

    openInNewTab: function (url) {
        try {
            let win = window.open(url, '_blank');
            win.focus();
        } catch (ex) {
            console.error("AppUtils : openInNewTab : ERROR : ", ex);
        }
    },

    downloadFile: function (url, fileName) {
        try {

            fetch(url)
            .then(response => response.blob())
            .then(blob => {
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = fileName;
                link.click();
                document.body.removeChild(link);
            })
            .catch(console.error);

            //AppUtils.openInNewTab(url);
        } catch (ex) {
            console.error("downloadFile : error : ", ex);
        }
    },
    getRandomColor: function () {

        try {
            let colorList = COLOR_CODES[AppUtils.pickRandomProperty(COLOR_CODES)];
            let newColorKey = AppUtils.pickRandomProperty(colorList);
            return colorList[newColorKey];
        } catch (ex) {
            console.error("getRandomColor : error : ", ex);
        }

        return "#000000";
    },
    pickRandomProperty: function (obj) {
        let result;
        let count = 0;
        for (let prop in obj) {
            if (Math.random() < 1 / ++count) {
                result = prop;
            }
        }

        return result;
    },

    setCurrentTranslations: function (translations, currentLanguage) {
        try {

            console.log("setCurrentTranslations : ", translations, currentLanguage)

            if (typeof translations[DEFAULT_LANGUAGE_CODE] != "undefined") {
                this.defaultTranslations = translations[DEFAULT_LANGUAGE_CODE].data;
            }

            if (typeof translations[currentLanguage] != "undefined") {
                this.currentTranslations = translations[currentLanguage].data;
            }

            console.log("this.currentTranslations : ", this.currentTranslations, translations)

            this.currentLanguage = currentLanguage;

        } catch (ex) {
            console.error("setCurrentTranslations : error : ", ex);
        }
    },

    getTranslationFromKey: function (translationKey) {
        let result = translationKey;

        try {

            if (typeof this.currentTranslations[translationKey] != "undefined") {
                result = this.currentTranslations[translationKey];
            } else if (typeof this.defaultTranslations[translationKey] != "undefined") {
                result = this.defaultTranslations[translationKey]
            }

        } catch (ex) {
            console.error("getTranslationFromKey : error : ", ex);
        }

        return result;
    },
    isValidPassword: function (password) {
        try {

            let minLength = 6;
            let maxLength = 15;

            let meetsLengthRequirements = password.length >= minLength;
            let hasUpperCaseChar = false;
            let hasLowerCaseChar = false;
            let hasDecimalDigit = false;

            if (meetsLengthRequirements) {
                for (let i = 0, len = password.length; i < len; i++) {
                    let char = password.charAt(i);
                    if (!isNaN(+char * 1)) {
                        hasDecimalDigit = true;
                    } else {
                        if (char === char.toUpperCase()) {
                            hasUpperCaseChar = true;
                        }
                        if (char === char.toLowerCase()) {
                            hasLowerCaseChar = true;
                        }
                    }
                }
            }

            return meetsLengthRequirements && hasUpperCaseChar && hasLowerCaseChar && hasDecimalDigit;

        } catch (ex) {
            console.error("isValidPassword : error : ", ex);
        }

        return false;
    },

    initIncomingCallRingtone: function () {
        try {

            this.incomingCallElement = document.createElement("audio");
            this.incomingCallElement.src = "./tribe/audio/audio_call.mp3";
            this.incomingCallElement.id = "incomingCallElement";
            this.incomingCallElement.autoplay = true;
            this.incomingCallElement.loop = true;
            this.incomingCallElement.height = 0;
            this.incomingCallElement.width = 0;
            this.incomingCallElement.opacity = 0;
            document.body.append(this.incomingCallElement);

        } catch (ex) {
            console.error("initIncomingCallRingtone : error : ", ex);
        }
    },

    playAudioCallRingtone: function () {
        try {

            if (this.incomingCallElement === null) {
                this.initIncomingCallRingtone();
            }

            this.incomingCallElement.play();

        } catch (ex) {
            console.error("playAudioCallRingtone : error : ", ex);
        }
    },

    stopAudioCallRingtone: function () {
        try {

            if (this.incomingCallElement === null) {
                this.initIncomingCallRingtone();
            }
            this.incomingCallElement.pause();
            this.incomingCallElement.currentTime = 0;


        } catch (ex) {
            console.error("stopAudioCallRingtone : error : ", ex);
        }
    },

    initNotification: function () {
        try {

            this.notificationElement = document.createElement("audio");
            this.notificationElement.src = "./tribe/audio/notification.mp3";
            this.notificationElement.autoplay = false;
            this.notificationElement.height = 0;
            this.notificationElement.width = 0;
            this.notificationElement.opacity = 0;
            document.body.append(this.notificationElement);


        } catch (ex) {
            console.error("initNotification : error : ", ex);
        }
    },

    playNotification: function () {
        try {

            if (this.notificationElement === null) {
                this.initNotification();
            }

            this.notificationElement.play();

        } catch (ex) {
            console.error("playNotification : error : ", ex);
        }
    },

    postDataToMediaIFrame: function (eventType, data) {
        try {

            if (!AppUtils.isEventRegisteredForIframe) {
                AppUtils.isEventRegisteredForIframe = true;
                AppUtils.registerEventsForIframe();
            }


            if (AppUtils.mediaIFrame && AppUtils.mediaIFrame.contentWindow) {

                console.log("postDataToMediaIFrame : ", eventType, data)

                AppUtils.mediaIFrame.contentWindow.postMessage({
                    "event": eventType,
                    "data": data
                }, '*');
            }

        } catch (ex) {
            console.error("postDataToMediaIFrame : error : ", ex);
        }
    },

    registerEventsForIframe: function () {
        try {
            window.addEventListener('message', function (event) {
                if (typeof event.data != "undefined") {
                    AppUtils.handleSignalHubEvents(event.data);
                }
            });

        } catch (ex) {
            console.error("registerEventsForIframe : error : ", ex);
        }
    },

    handleSignalHubEvents: function (eventData) {
        try {

            console.log("eventData : ", eventData)

            if (typeof eventData.event_type != "undefined" && AppUtils.dispatch != null) {
                AppUtils.dispatch(postSignalHubEvent(eventData.event_type, eventData.data));
            }

        } catch (ex) {
            console.error("handleSignalHubEvents : error : ", ex);
        }
    },


    getMeetingId: function (currentUserId, selectedSnapshotId, meetingType) {

        let result = "";

        try {

            if (meetingType === MESSAGE_ROUTER_CHANNEL_TYPES.GROUP) {
                result = selectedSnapshotId + "_0";
            } else {
                if (currentUserId < selectedSnapshotId) {
                    result = currentUserId + "_" + selectedSnapshotId + "_1";
                } else {
                    result = selectedSnapshotId + "_" + currentUserId + "_1";
                }
            }

        } catch (ex) {
            console.error("getMeetingId : error : ", ex);
        }

        return result;
    },

    getDateToString: function (dateVal) {
        let result = "";

        try {
            result = dateVal.getFullYear() + "-" + (dateVal.getMonth() + 1) + "-" + dateVal.getDate();
        } catch (ex) {
            console.error("getDateToString : error : ", ex);
        }

        return result;
    },

    validateEmail: function (text) {
        try {
            let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            return reg.test(text);
        } catch (ex) {
            console.error("validateEmail : error : ", ex);
        }

        return false;
    },

    getAvatarPlaceHolderFromName: function (label) {

        let result = label;

        try {

            let tempArr = label.split(" ");

            if (tempArr.length > 1) {
                result = tempArr[0].substr(0, 1) + tempArr[1].substr(0, 1);
            } else {
                result = label.substr(0, 2);
            }

            result = result.toUpperCase();


        } catch (ex) {
            console.error("getAvatarPlaceHolderFromName : error : ", ex);
        }

        return result;

    },

    requestMediaPermissions: function () {
        try {

            navigator.mediaDevices.getUserMedia({audio: true, video: true})
            .then(function (stream) {
                stream.getTracks().forEach(track => track.stop())
                console.log('You let me use your mic!')
            })
            .catch(function (err) {
                console.log('No mic for you!')
            });

        } catch (ex) {
            console.error("requestMediaPermissions : error : ", ex);
        }
    }


}


export default AppUtils;
