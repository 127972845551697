import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import axios from "axios";
import {
    URL_USER_PRIVATE_ROOM_DELETE_ROOM,
    URL_USER_PRIVATE_ROOM_GET_LIST,
    URL_USER_PRIVATE_ROOM_SAVE_ROOM
} from "../../../util/tribe/urls";

import AppUtils from "../../../util/tribe/utils/appUtils";

import {
    USER_PRIVATE_ROOM_DELETE,
    USER_PRIVATE_ROOM_GET_LIST,
    USER_PRIVATE_ROOM_SAVE
} from "../../../constants/actions/private_room/userPrivateRoomActionTypes";
import {
    userPrivateRoomGetListError,
    userPrivateRoomGetListSuccess,
    userPrivateRoomSaveRoomError,
    userPrivateRoomSaveRoomSuccess,
    userPrivateRoomDeleteRoomError,
    userPrivateRoomDeleteRoomSuccess
} from "../../actions";

/** http : user private room : to get list **/
const httpSagaUserPrivateRoomGetList = async (data) =>
    await axios.post(URL_USER_PRIVATE_ROOM_GET_LIST, data, AppUtils.getHttpHeaders())
    .then(authUser => authUser)
    .catch(error => {
        return error
    });

/** http : user private room : to save room **/
const httpSagaUserPrivateRoomSaveRoom = async (data) =>
    await axios.post(URL_USER_PRIVATE_ROOM_SAVE_ROOM, data, AppUtils.getHttpHeaders())
    .then(authUser => authUser)
    .catch(error => {
        return error
    });

/** http : user private room : delete **/
const httpSagaUserPrivateRoomDelete = async (data) =>
    await axios.post(URL_USER_PRIVATE_ROOM_DELETE_ROOM, data, AppUtils.getHttpHeaders())
    .then(authUser => authUser)
    .catch(error => {
        return error
    });

/**** ****/

/** handler : user private room : to get list **/
function* handleHttpSagaUserPrivateRoomGetList({payload}) {
    try {
        const result = yield call(httpSagaUserPrivateRoomGetList, payload);
        if (result.message) {
            yield put(userPrivateRoomGetListError(result.message));
        } else {
            yield put(userPrivateRoomGetListSuccess(result.data));
        }
    } catch (error) {
        yield put(userPrivateRoomGetListError(error));
    }
}

/** handler : user private room : to save room **/
function* handleHttpSagaUserPrivateRoomSaveRoom({payload}) {
    try {
        const result = yield call(httpSagaUserPrivateRoomSaveRoom, payload);
        if (result.message) {
            yield put(userPrivateRoomSaveRoomError(result.message));
        } else {
            yield put(userPrivateRoomSaveRoomSuccess(result.data));
        }
    } catch (error) {
        yield put(userPrivateRoomSaveRoomError(error));
    }
}

/** handler : user private room : to delete room **/
function* handleHttpSagaUserPrivateRoomDeleteRoom({payload}) {
    try {
        const result = yield call(httpSagaUserPrivateRoomDelete, payload);
        if (result.message) {
            yield put(userPrivateRoomDeleteRoomError(result.message));
        } else {
            yield put(userPrivateRoomDeleteRoomSuccess(result.data));
        }
    } catch (error) {
        yield put(userPrivateRoomDeleteRoomError(error));
    }
}

/** watcher : user private room : to get list **/
export function* sagaUserPrivateRoomGetList() {
    yield takeEvery(USER_PRIVATE_ROOM_GET_LIST, handleHttpSagaUserPrivateRoomGetList);
}

/** watcher : user private room : to save room **/
export function* sagaUserPrivateRoomSaveRoom() {
    yield takeEvery(USER_PRIVATE_ROOM_SAVE, handleHttpSagaUserPrivateRoomSaveRoom);
}

/** watcher : user private room : to save room **/
export function* sagaUserPrivateRoomDeleteRoom() {
    yield takeEvery(USER_PRIVATE_ROOM_DELETE, handleHttpSagaUserPrivateRoomDeleteRoom);
}

export default function* rootSaga() {
    yield all([
        fork(sagaUserPrivateRoomGetList),
        fork(sagaUserPrivateRoomSaveRoom),
        fork(sagaUserPrivateRoomDeleteRoom)
    ])
}
