import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import axios from "axios";
import {
    URL_CHAT_SUBSCRIPTION_SUBSCRIBE,
    URL_CHAT_SUBSCRIPTION_SUBSCRIBE_BY_ME,
    URL_CHAT_SUBSCRIPTION_SUBSCRIBE_TO_ME,
    URL_CHAT_SUBSCRIPTION_UNSUBSCRIBE
} from "../../../util/tribe/urls";
import {
    SUBSCRIPTION_MANAGEMENT_SUBSCRIBE,
    SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_BY_ME,
    SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_TO_ME,
    SUBSCRIPTION_MANAGEMENT_UNSUBSCRIBE
} from "../../../constants/actions/chat/ChatActionTypes";
import AppUtils from "../../../util/tribe/utils/appUtils";
import {
    subscribeManagementUserSubscribeByMeError,
    subscribeManagementUserSubscribeByMeSuccess,
    subscribeManagementUserSubscribeError,
    subscribeManagementUserSubscribeSuccess,
    subscribeManagementUserSubscribeToMeError,
    subscribeManagementUserSubscribeToMeSuccess,
    subscribeManagementUserUnsubscribeError,
    subscribeManagementUserUnsubscribeSuccess,
} from "../../actions";

/** http : subscription management : subscribe **/
const httpChatSubscriptionManagementSubscribe = async (data) =>
    await axios.post(URL_CHAT_SUBSCRIPTION_SUBSCRIBE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : subscription management : unsubscribe **/
const httpChatSubscriptionManagementUnsubscribe = async (data) =>
    await axios.post(URL_CHAT_SUBSCRIPTION_UNSUBSCRIBE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : subscription management : subscribe by me **/
const httpChatSubscriptionManagementSubscribeByMe = async (data) =>
    await axios.post(URL_CHAT_SUBSCRIPTION_SUBSCRIBE_BY_ME, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : subscription management : subscribe to me **/
const httpChatSubscriptionManagementSubscribeToMe = async (data) =>
    await axios.post(URL_CHAT_SUBSCRIPTION_SUBSCRIBE_TO_ME, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });


/** handler : subscription management : subscribe **/
function* handleHttpChatSubscriptionManagementSubscribe({payload}) {
    try {
        const result = yield call(httpChatSubscriptionManagementSubscribe, payload);
        if (result.message) {
            yield put(subscribeManagementUserSubscribeError(result.message));
        } else {
            yield put(subscribeManagementUserSubscribeSuccess(result.data));
        }
    } catch (error) {
        yield put(subscribeManagementUserSubscribeError(error));
    }
}

/** handler : subscription management : unsubscribe **/
function* handleHttpChatSubscriptionManagementUnsubscribe({payload}) {
    try {
        const result = yield call(httpChatSubscriptionManagementUnsubscribe, payload);
        if (result.message) {
            yield put(subscribeManagementUserUnsubscribeError(result.message));
        } else {
            yield put(subscribeManagementUserUnsubscribeSuccess(result.data));
        }
    } catch (error) {
        yield put(subscribeManagementUserUnsubscribeError(error));
    }
}

/** handler : subscription management : subscribe by me **/
function* handleHttpChatSubscriptionManagementSubscribeByMe({payload}) {
    try {
        const result = yield call(httpChatSubscriptionManagementSubscribeByMe, payload);
        if (result.message) {
            yield put(subscribeManagementUserSubscribeByMeError(result.message));
        } else {
            yield put(subscribeManagementUserSubscribeByMeSuccess(result.data));
        }
    } catch (error) {
        yield put(subscribeManagementUserSubscribeByMeError(error));
    }
}

/** handler : subscription management : subscribe to me **/
function* handleHttpChatSubscriptionManagementSubscribeToMe({payload}) {
    try {
        const result = yield call(httpChatSubscriptionManagementSubscribeToMe, payload);
        if (result.message) {
            yield put(subscribeManagementUserSubscribeToMeError(result.message));
        } else {
            yield put(subscribeManagementUserSubscribeToMeSuccess(result.data));
        }
    } catch (error) {
        yield put(subscribeManagementUserSubscribeToMeError(error));
    }
}


/** watcher : subscription management : subscribe **/
export function* chatSubscriptionManagementSubscribe() {
    yield takeEvery(SUBSCRIPTION_MANAGEMENT_SUBSCRIBE, handleHttpChatSubscriptionManagementSubscribe);
}

/** watcher : subscription management : unsubscribe **/
export function* chatSubscriptionManagementUnsubscribe() {
    yield takeEvery(SUBSCRIPTION_MANAGEMENT_UNSUBSCRIBE, handleHttpChatSubscriptionManagementUnsubscribe);
}

/** watcher : subscription management : subscribe by me **/
export function* chatSubscriptionManagementSubscribeByMe() {
    yield takeEvery(SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_BY_ME, handleHttpChatSubscriptionManagementSubscribeByMe);
}

/** watcher : subscription management : subscribe to me **/
export function* chatSubscriptionManagementSubscribeToMe() {
    yield takeEvery(SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_TO_ME, handleHttpChatSubscriptionManagementSubscribeToMe);
}


export default function* rootSaga() {
    yield all([
        fork(chatSubscriptionManagementSubscribe),
        fork(chatSubscriptionManagementUnsubscribe),
        fork(chatSubscriptionManagementSubscribeByMe),
        fork(chatSubscriptionManagementSubscribeToMe),
    ])
}
