import {chatBaseUrl} from "./keys";

/** signin **/
export const URL_AUTH_SIGN_IN = chatBaseUrl + '/login/do_login/';
export const URL_AUTH_SIGN_OUT = chatBaseUrl + '/login/do_logout/';

/** user settings **/
export const URL_USER_UPDATE_PASSWORD = chatBaseUrl + '/user/change_password/';
export const URL_USER_UPDATE_PROFILE_DETAILS = chatBaseUrl + '/user/update_profile/';

/** chat **/
export const URL_USERS_GET_ALL_OTHER_CONTACT_NAMES = chatBaseUrl + '/user/get_all_other_contacts/';
export const URL_USERS_REGISTER_DEVICE_ID = chatBaseUrl + '/messages/device/attach/';

/**** User management ****/
export const URL_CHAT_USER_REGISTER = chatBaseUrl + '/messages/user/register/';
export const URL_CHAT_USER_GET_DETAILS = chatBaseUrl + '/messages/user/details/';
export const URL_CHAT_USER_UPDATE = chatBaseUrl + '/messages/user/update/';
export const URL_CHAT_USER_DELETE = chatBaseUrl + '/messages/user/delete/';
export const URL_CHAT_USER_LOGINS = chatBaseUrl + '/messages/user/logins/';
export const URL_CHAT_USER_LOGIN = chatBaseUrl + '/messages/user/login/';
export const URL_CHAT_USER_LOGOUT = chatBaseUrl + '/messages/user/logout/';
export const URL_CHAT_USER_ACTIVE_STATUS = chatBaseUrl + '/messages/user/get_status/';

/**** Subscription management ****/
export const URL_CHAT_SUBSCRIPTION_SUBSCRIBE = chatBaseUrl + '/messages/subscribe/';
export const URL_CHAT_SUBSCRIPTION_UNSUBSCRIBE = chatBaseUrl + '/messages/unsubscribe/';
export const URL_CHAT_SUBSCRIPTION_SUBSCRIBE_BY_ME = chatBaseUrl + '/messages/subscribe/by_me/';
export const URL_CHAT_SUBSCRIPTION_SUBSCRIBE_TO_ME = chatBaseUrl + '/messages/subscribe/to_me/';

/**** Device management ****/
export const URL_CHAT_DEVICE_MANAGEMENT_ATTACH = chatBaseUrl + '/messages/device/attach/';
export const URL_CHAT_DEVICE_MANAGEMENT_UPDATE = chatBaseUrl + '/messages/device/update/';
export const URL_CHAT_DEVICE_MANAGEMENT_DETACH = chatBaseUrl + '/messages/device/detach/';
export const URL_CHAT_DEVICE_MANAGEMENT_LIST = chatBaseUrl + '/messages/device/list/';

/**** channel management ****/
export const URL_CHAT_CHANNEL_MANAGEMENT_CREATE = chatBaseUrl + '/messages/channel/create/';
export const URL_CHAT_CHANNEL_MANAGEMENT_UPDATE = chatBaseUrl + '/messages/channel/update/';
export const URL_CHAT_CHANNEL_MANAGEMENT_DELETE = chatBaseUrl + '/messages/channel/delete/';
export const URL_CHAT_CHANNEL_MANAGEMENT_ACTIVATE = chatBaseUrl + '/messages/channel/activate/';
export const URL_CHAT_CHANNEL_MANAGEMENT_DEACTIVATE = chatBaseUrl + '/messages/channel/deactivate/';
export const URL_CHAT_CHANNEL_MANAGEMENT_CHANGE_OWNERSHIP = chatBaseUrl + '/messages/channel/change_owner_ship/';
export const URL_CHAT_CHANNEL_MANAGEMENT_DETAILS = chatBaseUrl + '/messages/channel/details/';
export const URL_CHAT_CHANNEL_MANAGEMENT_LIST = chatBaseUrl + '/messages/channel/list/';
export const URL_CHAT_CHANNEL_MANAGEMENT_UPDATE_NAME = chatBaseUrl + '/messages/channel/update_name/';
export const URL_CHAT_CHANNEL_MANAGEMENT_UPDATE_DESCRIPTION = chatBaseUrl + '/messages/channel/update_description/';
export const URL_CHAT_CHANNEL_MANAGEMENT_UPDATE_IMAGE = chatBaseUrl + '/messages/channel/update_image/';
export const URL_CHAT_CHANNEL_MANAGEMENT_MUTE_UNMUTE_NOTIFICATIONS = chatBaseUrl + '/messages/group/notifications/mute_unmute/';


/**** Channel member management ****/
export const URL_CHAT_CHANNEL_MEMBER_MANAGEMENT_ADD = chatBaseUrl + '/messages/member/add/';
export const URL_CHAT_CHANNEL_MEMBER_MANAGEMENT_INVITE = chatBaseUrl + '/messages/member/invite/';
export const URL_CHAT_CHANNEL_MEMBER_MANAGEMENT_INVITE_DELETE = chatBaseUrl + '/messages/member/invite/delete/';
export const URL_CHAT_CHANNEL_MEMBER_MANAGEMENT_INVITE_ACCEPT = chatBaseUrl + '/messages/member/invite/accept/';
export const URL_CHAT_CHANNEL_MEMBER_MANAGEMENT_LEAVE = chatBaseUrl + '/messages/member/leave/';
export const URL_CHAT_CHANNEL_MEMBER_MANAGEMENT_BLOCK_NOTIFICATIONS = chatBaseUrl + '/messages/member/block_notifications/';
export const URL_CHAT_CHANNEL_MEMBER_MANAGEMENT_UNBLOCK_NOTIFICATIONS = chatBaseUrl + '/messages/member/unblock_notifications/';
export const URL_CHAT_CHANNEL_MEMBER_MANAGEMENT_MEMBER_BAN = chatBaseUrl + '/messages/member/ban/';
export const URL_CHAT_CHANNEL_MEMBER_MANAGEMENT_MEMBER_CHANGE_ROLE = chatBaseUrl + '/messages/member/change_role/';
export const URL_CHAT_CHANNEL_MEMBER_MANAGEMENT_MEMBER_LIST = chatBaseUrl + '/messages/member/list/';

/**** personal messages ****/
export const URL_CHAT_PERSONAL_MESSAGES_SEND = chatBaseUrl + '/messages/personal/message/send/';
export const URL_CHAT_PERSONAL_MESSAGES_EDIT = chatBaseUrl + '/messages/personal/message/edit/';
export const URL_CHAT_PERSONAL_MESSAGES_DELETE = chatBaseUrl + '/messages/personal/message/delete/';
export const URL_CHAT_PERSONAL_MESSAGES_READ = chatBaseUrl + '/messages/personal/message/read/';
export const URL_CHAT_PERSONAL_MESSAGES_READ_ALL = chatBaseUrl + '/messages/personal/message/read_all/';
export const URL_CHAT_PERSONAL_MESSAGES_LIST = chatBaseUrl + '/messages/personal/message/list/';
export const URL_CHAT_PERSONAL_MESSAGES_UNREAD = chatBaseUrl + '/messages/personal/message/unread/';
export const URL_CHAT_PERSONAL_MESSAGE_DELIVERED = chatBaseUrl + '/messages/personal/message/delivered/';
export const URL_CHAT_PERSONAL_MESSAGE_DELETE_MULTIPLE = chatBaseUrl + '/messages/personal/message/delete_multiple/';
export const URL_CHAT_PERSONAL_MESSAGES_CLEAR = chatBaseUrl + '/messages/personal/clear_messages/';
export const URL_CHAT_PERSONAL_MESSAGES_REACT_ON_MESSAGE = chatBaseUrl + '/messages/personal/message/react/';
export const URL_CHAT_PERSONAL_MESSAGES_PURGE = chatBaseUrl + '/messages/personal/purge_messages/';
export const URL_CHAT_PERSONAL_MESSAGES_GET_MESSAGE_HISTORY = chatBaseUrl + '/messages/personal/messages/history/';
export const URL_CHAT_PERSONAL_MESSAGES_FILTER_MESSAGES_BY_FIELD = chatBaseUrl + '/messages/personal/messages/filter_by_field/';
export const URL_CHAT_PERSONAL_MESSAGES_FORWARD = chatBaseUrl + '/messages/personal/forward_message/';
export const URL_CHAT_PERSONAL_MESSAGES_MUTE_UNMUTE_NOTIFICATIONS = chatBaseUrl + '/messages/personal/notifications/mute_unmute/';

/**** Channel or Group messages ****/
export const URL_CHAT_GROUP_MESSAGES_SEND = chatBaseUrl + '/messages/group/msg_send/';
export const URL_CHAT_GROUP_MESSAGES_DELETE = chatBaseUrl + '/messages/group/msg_delete/';
export const URL_CHAT_GROUP_MESSAGES_READ = chatBaseUrl + '/messages/group/msg_read/';
export const URL_CHAT_GROUP_MESSAGES_LIST = chatBaseUrl + '/messages/group/msg_list/';
export const URL_CHAT_GROUP_MESSAGES_READ_ALL = chatBaseUrl + '/messages/group/msg_read_all/';
export const URL_CHAT_GROUP_MESSAGES_FORWARD = chatBaseUrl + '/messages/group/forward_message/';

export const URL_CHAT_GROUP_MESSAGES_EDIT = chatBaseUrl + '/messages/group/msg_edit/';
export const URL_CHAT_GROUP_MESSAGES_UNREAD = chatBaseUrl + '/messages/group/msg_unread/';
export const URL_CHAT_GROUP_MESSAGES_DELIVERED = chatBaseUrl + '/messages/group/msg_deliver/';
export const URL_CHAT_GROUP_MESSAGES_DELETE_FOR_USER = chatBaseUrl + '/messages/group/msg_delete_for_user/';
export const URL_CHAT_GROUP_MESSAGES_CLEAR_FOR_USER = chatBaseUrl + '/messages/group/msg_clear_for_user/';
export const URL_CHAT_GROUP_MESSAGES_REACT_ON_MESSAGE = chatBaseUrl + '/messages/group/react_on_message/';
export const URL_CHAT_GROUP_MESSAGES_GET_MESSAGE_HISTORY = chatBaseUrl + '/messages/group/messages/history/';
export const URL_CHAT_GROUP_MESSAGES_FILTER_MESSAGES_BY_FIELD = chatBaseUrl + '/messages/group/messages/filter_by_field/';
export const URL_CHAT_GROUP_MESSAGES_GET_COMMON_CHANNELS_BETWEEN_USERS = chatBaseUrl + '/messages/group/belongs/';

/**** relay ****/
export const URL_CHAT_RELAY_IN_CHANNEL = chatBaseUrl + '/messages/relay/channel/';
export const URL_CHAT_RELAY_IN_DM = chatBaseUrl + '/messages/relay/dm/';

/**** misc ****/
export const URL_CHAT_MISC_GET_CHAT_SNAPSHOT = chatBaseUrl + '/messages/snapshot/';

/**** user status ****/
export const URL_CHAT_MISC_USER_STATUS_UPDATE_PROFILE_IMAGE = chatBaseUrl + '/messages/user/update_profile_image/';
export const URL_CHAT_MISC_USER_STATUS_UPDATE_DISPLAY_NAME = chatBaseUrl + '/messages/user/update_display_name/';
export const URL_CHAT_MISC_USER_STATUS_UPDATE_STATUS_MESSAGE = chatBaseUrl + '/messages/user/update_status_message/';


/**** ****/
export const URL_CHAT_UPDATE_SNAPSHOT_PIN = chatBaseUrl + '/messages/snapshot/update_pin_status/';

/**** chat tag urls ****/
export const URL_CHAT_TAG_CHANNEL = chatBaseUrl + '/messages/tag/channel/';
export const URL_CHAT_TAG_DM = chatBaseUrl + '/messages/tag/dm/';
export const URL_CHAT_TAG_CHANNEL_MESSAGE = chatBaseUrl + '/messages/tag/channel/message/';
export const URL_CHAT_TAG_DM_MESSAGE = chatBaseUrl + '/messages/tag/dm/message/';
export const URL_CHAT_TAG_GET_ALL = chatBaseUrl + '/messages/tag/all/';
export const URL_CHAT_TAG_GET_CHANNEL_MESSAGES = chatBaseUrl + '/messages/tag/channel/message/list/';
export const URL_CHAT_TAG_GET_DM_MESSAGES = chatBaseUrl + '/messages/tag/dm/message/list/';

/**** clients ****/
export const URL_CLIENTS_GET_ALL_CLIENTS = chatBaseUrl + '/clients/list/';
export const URL_CLIENTS_SAVE_CLIENT = chatBaseUrl + '/clients/save/';
export const URL_CLIENTS_DEACTIVATE_CLIENT = chatBaseUrl + '/clients/deactivate/';
export const URL_CLIENTS_GET_CLIENTS_FEATURES = chatBaseUrl + '/clients/get_features/';
export const URL_CLIENTS_SET_CLIENT_PRIVATE_ROOM_STATUS = chatBaseUrl + '/clients/features/private_room/status/';
export const URL_CLIENTS_UPDATE_VOIP_NOTIFICATION_CONFIG = chatBaseUrl + '/clients/voip_configuration/update/';
export const URL_CLIENTS_GET_VOIP_NOTIFICATION_CONFIG = chatBaseUrl + '/clients/voip_configuration/get/';
export const URL_CLIENTS_UPDATE_FCM_NOTIFICATION_CONFIG = chatBaseUrl + '/clients/fcm_configuration/update/';
export const URL_CLIENTS_GET_FCM_NOTIFICATION_CONFIG = chatBaseUrl + '/clients/fcm_configuration/get/';

/**** users ****/
export const URL_USERS_GET_ALL_USERS = chatBaseUrl + '/user/list/';
export const URL_USERS_SAVE_USER = chatBaseUrl + '/user/save/';
export const URL_USERS_DEACTIVATE_USER = chatBaseUrl + '/user/deactivate/';

/** user private room **/
export const URL_USER_PRIVATE_ROOM_GET_LIST = chatBaseUrl + '/messages/get_private_rooms/';
export const URL_USER_PRIVATE_ROOM_SAVE_ROOM = chatBaseUrl + '/messages/generate_private_room/';
export const URL_USER_PRIVATE_ROOM_DELETE_ROOM = chatBaseUrl + '/messages/delete_private_room/';

