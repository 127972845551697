import React, {useEffect} from "react";
import {Button, Checkbox, Form, Input, message} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";

import {
    hideMessage,
    showAuthLoader,
    userFacebookSignIn,
    userGithubSignIn,
    userGoogleSignIn,
    userSignIn,
    userTwitterSignIn
} from "../appRedux/actions";

import IntlMessages from "util/IntlMessages";
import CircularProgress from "../components/CircularProgress";
import TwitterOutlined from "@ant-design/icons/lib/icons/TwitterOutlined";
import GithubOutlined from "@ant-design/icons/lib/icons/GithubOutlined";
import FacebookOutlined from "@ant-design/icons/lib/icons/FacebookOutlined";
import GoogleOutlined from "@ant-design/icons/lib/icons/GoogleOutlined";
import AppUtils from "../util/tribe/utils/appUtils";

const SignIn = () => {
    const dispatch = useDispatch();
    const {loader, alertMessage, showMessage, authUser} = useSelector(({auth}) => auth);
    const history = useHistory();

    useEffect(() => {
        if (showMessage) {
            setTimeout(() => {
                dispatch(hideMessage());
            }, 100);
        }
        if (authUser !== null) {
            history.push('/');
        }
    });

    const onFinishFailed = errorInfo => {
    };

    const onFinish = values => {

        AppUtils.requestMediaPermissions();

        dispatch(showAuthLoader());
        dispatch(userSignIn(values));
    };

    return (
        <div className="gx-app-login-wrap">
            <div className="gx-app-login-container">
                <div className="gx-app-login-main-content">
                    <div className="gx-app-login-content">
                        <div className="gx-app-logo gx-pb-4">
                            <img alt="logo" className="login_logo" src="/assets/images/logo.png"/>
                        </div>
                        <Form
                            initialValues={{remember: true}}
                            name="basic"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            className="gx-signin-form gx-form-row0">

                            <Form.Item
                                initialValue=""
                                rules={[{required: true, message: 'The input is not valid E-mail!'}]} name="user_name">
                                <Input placeholder="Email"/>
                            </Form.Item>
                            <Form.Item
                                initialValue=""
                                rules={[{required: true, message: 'Please input your Password!'}]} name="password">
                                <Input type="password" placeholder="Password"/>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" className="gx-mb-0" htmlType="submit">
                                    <IntlMessages id="app.userAuth.signIn"/>
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>

                    {loader ? (<div className="gx-loader-view"><CircularProgress/></div>) : (null)}
                    {showMessage ? message.error(alertMessage.toString()) : null}

                </div>
            </div>
        </div>
    );
};

export default SignIn;
