import {
    HTTP_REQUEST_STATUS,
    MSG_ROUTER_EVENTS,
    USER_STATUS_DEFAULT_MESSAGE
} from "../../../util/tribe/constants/config";
import {
    CHAT_GET_ALL_OTHER_CONTACTS_SUCCESS,
    CHAT_USER_STATUS_UPDATE_DISPLAY_NAME,
    CHAT_USER_STATUS_UPDATE_DISPLAY_NAME_ERROR,
    CHAT_USER_STATUS_UPDATE_DISPLAY_NAME_NONE,
    CHAT_USER_STATUS_UPDATE_DISPLAY_NAME_SUCCESS,
    CHAT_USER_STATUS_UPDATE_PROFILE_IMAGE,
    CHAT_USER_STATUS_UPDATE_PROFILE_IMAGE_ERROR,
    CHAT_USER_STATUS_UPDATE_PROFILE_IMAGE_NONE,
    CHAT_USER_STATUS_UPDATE_PROFILE_IMAGE_SUCCESS,
    CHAT_USER_STATUS_UPDATE_STATUS_MESSAGE,
    CHAT_USER_STATUS_UPDATE_STATUS_MESSAGE_ERROR,
    CHAT_USER_STATUS_UPDATE_STATUS_MESSAGE_NONE,
    CHAT_USER_STATUS_UPDATE_STATUS_MESSAGE_SUCCESS
} from "../../../constants/actions/chat/ChatActionTypes";
import {SIGNIN_USER_SUCCESS} from "../../../constants/ActionTypes";
import {chatBaseDownloadUrl} from "../../../util/tribe/keys";

const INIT_STATE = {
    userStatusProfileImageStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    userStatusDisplayNameStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    userStatusStatusMessageStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    optionalData: {},
    currentUser: {},
    optionalDataMap: {}
}


const ReducerUserStatus = (state = INIT_STATE, action) => {
    
    switch (action.type) {
        
        case CHAT_GET_ALL_OTHER_CONTACTS_SUCCESS: {
            let optionalDataMap = state.optionalDataMap;
            
            if (typeof action.payload.data != "undefined") {
                let otherUsers = action.payload.data;
                
                for (let i = 0; i < otherUsers.length; i++) {
                    let optionalData = otherUsers[i]["optional_data"];
                    
                    if (typeof optionalData['profile_image'] == "undefined") {
                        optionalData = {
                            "status_message": USER_STATUS_DEFAULT_MESSAGE,
                            "profile_image": "",
                            "display_name": otherUsers[i]['first_name'] + " " + otherUsers[i]['last_name']
                        }
                    } else {
                        
                        if (optionalData["profile_image"] !== "") {
                            optionalData["profile_image"] = chatBaseDownloadUrl + optionalData["profile_image"];
                        }
                    }
                    
                    optionalDataMap[otherUsers[i]['id']] = optionalData;
                }
            }
            
            
            return {
                ...state,
                optionalDataMap: optionalDataMap
            }
        }
        
        case MSG_ROUTER_EVENTS.USERS.USER_DETAILS: {
            let currentUser = state.currentUser;
            let optionalData = state.optionalData;
            let optionalDataMap = state.optionalDataMap;
            
            if (typeof action.payload.data != "undefined" && typeof action.payload.data.uid != "undefined") {
                
                let userId = action.payload.data.uid;
                
                let optionalDataContent = {
                    "profile_image": ""
                };
                
                if (typeof action.payload.data.details != "undefined" && typeof action.payload.data.details.optional != "undefined") {
                    optionalDataContent = action.payload.data.details.optional;
                }
                
                if (typeof optionalDataContent["profile_image"] != "undefined" && optionalDataContent["profile_image"] !== "") {
                    optionalDataContent["profile_image"] = chatBaseDownloadUrl + optionalDataContent["profile_image"];
                }
                
                if (userId === currentUser.id) {
                    optionalData = optionalDataContent;
                }
                
                optionalDataMap[userId] = optionalDataContent;
            }
            
            return {
                ...state,
                currentUser: currentUser,
                optionalData: optionalData,
                optionalDataMap: optionalDataMap
            }
        }
        
        case SIGNIN_USER_SUCCESS: {
            let currentUser = {};
            let optionalData = {};
            
            if (typeof action.payload.data != "undefined" && typeof action.payload.data.user != "undefined") {
                currentUser = action.payload.data.user;
                
                if (typeof currentUser["optional_data"] != "undefined") {
                    optionalData = currentUser["optional_data"];
                    
                    if (typeof optionalData["profile_image"] != "undefined" && optionalData["profile_image"] !== "") {
                        optionalData["profile_image"] = chatBaseDownloadUrl + optionalData["profile_image"];
                    }
                }
            }
            
            return {
                ...state,
                currentUser: currentUser,
                optionalData: optionalData
            }
        }
        
        
        /** user status : to update profile image **/
        case CHAT_USER_STATUS_UPDATE_PROFILE_IMAGE: {
            
            return {
                ...state,
                userStatusProfileImageStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
            }
        }
        
        /** user status : to update profile image : success **/
        case CHAT_USER_STATUS_UPDATE_PROFILE_IMAGE_SUCCESS: {
            
            return {
                ...state,
                userStatusProfileImageStatus: HTTP_REQUEST_STATUS.SUCCESS
            }
        }
        
        /** user status : to update profile image : error **/
        case CHAT_USER_STATUS_UPDATE_PROFILE_IMAGE_ERROR: {
            
            return {
                ...state,
                userStatusProfileImageStatus: HTTP_REQUEST_STATUS.ERROR
            }
        }
        
        /** user status : to update profile image : none **/
        case CHAT_USER_STATUS_UPDATE_PROFILE_IMAGE_NONE: {
            
            return {
                ...state,
                userStatusProfileImageStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }
        
        /** user status : to update display name **/
        case CHAT_USER_STATUS_UPDATE_DISPLAY_NAME: {
            
            return {
                ...state,
                userStatusDisplayNameStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
            }
        }
        
        /** user status : to update display name : success **/
        case CHAT_USER_STATUS_UPDATE_DISPLAY_NAME_SUCCESS: {
            
            return {
                ...state,
                userStatusDisplayNameStatus: HTTP_REQUEST_STATUS.SUCCESS
            }
        }
        
        /** user status : to update display name : error **/
        case CHAT_USER_STATUS_UPDATE_DISPLAY_NAME_ERROR: {
            
            return {
                ...state,
                userStatusDisplayNameStatus: HTTP_REQUEST_STATUS.ERROR
            }
        }
        
        /** user status : to update display name : none **/
        case CHAT_USER_STATUS_UPDATE_DISPLAY_NAME_NONE: {
            
            return {
                ...state,
                userStatusDisplayNameStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }
        
        /** user status : to update status message **/
        case CHAT_USER_STATUS_UPDATE_STATUS_MESSAGE: {
            
            return {
                ...state,
                userStatusStatusMessageStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
            }
        }
        
        /** user status : to update status message : success **/
        case CHAT_USER_STATUS_UPDATE_STATUS_MESSAGE_SUCCESS: {
            
            return {
                ...state,
                userStatusStatusMessageStatus: HTTP_REQUEST_STATUS.SUCCESS
            }
        }
        
        /** user status : to update status message : error **/
        case CHAT_USER_STATUS_UPDATE_STATUS_MESSAGE_ERROR: {
            
            return {
                ...state,
                userStatusStatusMessageStatus: HTTP_REQUEST_STATUS.ERROR
            }
        }
        
        /** user status : to update status message : none **/
        case CHAT_USER_STATUS_UPDATE_STATUS_MESSAGE_NONE: {
            
            return {
                ...state,
                userStatusStatusMessageStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }
        
        default: {
            return {
                ...state
            }
        }
        
        
    }
    
}

export default ReducerUserStatus;
