import {chatBaseServerUrl, chatSocketPath} from "../keys";
import {io} from "socket.io-client";
import {
    chatMiscSocketConnectStatus,
    groupMessagesMarkAsDelivered,
    personalMessagesMarkMessageAsDelivered,
    socketEventTriggerReactEvent
} from "../../../appRedux/actions";
import AppUtils from "../utils/appUtils";

const socketEvents = {
    "connect": "connect",
    "join": "join",
    "message": "message",
    "disconnect": "disconnect"
};

export const TribeChatSocketIOHandler = {
    webSocketURL: chatBaseServerUrl,
    webSocketURLPath: chatSocketPath,
    isWebSocketInitDone: false,
    tokens: {},
    userId: "",
    dispatch: null,

    setDispatch: function (dispatch) {
        try {
            TribeChatSocketIOHandler.dispatch = dispatch;
            AppUtils.setDispatch(dispatch);
        } catch (ex) {
            console.error("setDispatch : error : ", ex);
        }
    },


    /****
     * to init socket io
     ****/
    initSocketIO: function (userId, tokens) {

        try {

            if (TribeChatSocketIOHandler.isWebSocketInitDone) {
                return;
            }

            TribeChatSocketIOHandler.userId = userId;
            TribeChatSocketIOHandler.tokens = tokens;

            TribeChatSocketIOHandler.isWebSocketInitDone = true;

            let query = {
                userId: userId,
                deviceId: tokens.deviceId,
                accessToken: tokens.accessToken,
                isDetachDisabled: false
            };

            let url = TribeChatSocketIOHandler.webSocketURL;
            let path = TribeChatSocketIOHandler.webSocketURLPath;

            TribeChatSocketIOHandler.socket = io.connect(url, {
                path: path,
                query: query,
                reconnectionDelay: 1000,
                reconnection: true,
                reconnectionAttempts: 10,
                transports: ['websocket'],
                agent: false,
                upgrade: false,
                rejectUnauthorized: false
            });

            TribeChatSocketIOHandler.socket.on(socketEvents['connect'], TribeChatSocketIOHandler.socketIOConnectCallback);
            TribeChatSocketIOHandler.socket.on(socketEvents['disconnect'], TribeChatSocketIOHandler.socketIODisconnectEvent);
            TribeChatSocketIOHandler.socket.on(socketEvents['message'], TribeChatSocketIOHandler.socketIOMessageCallback);


            TribeChatSocketIOHandler.socket.on("connect_error", (err) => {
                console.log(`connect_error due to ${err.message}`);
                console.log(err);
            });

        } catch (ex) {
            console.error("initSocketIO : error : ", ex);
        }
    },

    /****
     * to disconnect socket from events listening
     */
    disconnectSocket: function () {
        try {
            if (TribeChatSocketIOHandler.socket !== null) {
                TribeChatSocketIOHandler.socket.disconnect();
            }

            TribeChatSocketIOHandler.socket = null;
            TribeChatSocketIOHandler.isWebSocketInitDone = false;
        } catch (ex) {
            console.error("disconnectSocket : error : ", ex);
        }
    },

    /****
     * socket io : join
     ****/
    socketIOConnectCallback: function () {
        try {

            if (!TribeChatSocketIOHandler.dispatch) {
                return;
            }

            TribeChatSocketIOHandler.dispatch(chatMiscSocketConnectStatus(true));

            /*let params = {
                userId: TribeChatSocketIOHandler.userId
            };

            TribeChatSocketIOHandler.socket.emit(socketEvents['join'], {params}, TribeChatSocketIOHandler.socketIOJoinUserCallback);*/

        } catch (ex) {
            console.error("socketIOConnectCallback : error : ", ex);
        }
    },

    /****
     * socket io : disconnect event
     ****/
    socketIODisconnectEvent: function () {
        try {

            if (!TribeChatSocketIOHandler.dispatch) {
                return;
            }

            TribeChatSocketIOHandler.dispatch(chatMiscSocketConnectStatus(false));

        } catch (ex) {
            console.error("socketIODisconnectEvent : error : ", ex);
        }
    },

    /****
     * socket io : on message
     ****/
    socketIOMessageCallback: function (data) {
        try {
            TribeChatSocketIOHandler.handleWebSocketMessage(data)
        } catch (ex) {
            console.error("socketIOMessageCallback : error : ", ex);
        }
    },

    /****
     * socket io : join callback
     ****/
    socketIOJoinUserCallback: function (data, error) {
        try {
            console.info("socketIOJoinUserCallback : ", data);

            if (!TribeChatSocketIOHandler.dispatch) {
                return;
            }

            TribeChatSocketIOHandler.dispatch(chatMiscSocketConnectStatus(true));

        } catch (ex) {
            console.error("socketIOJoinUserCallback : error : ", ex);
        }
    },

    /****
     * to process socket io message
     ****/
    handleWebSocketMessage: function (data) {
        try {
            console.log("handleWebSocketMessage : data : ", JSON.stringify(data));
            let currentEvent = data["event"];

            if (!TribeChatSocketIOHandler.dispatch) {
                return;
            }

            TribeChatSocketIOHandler.dispatch(socketEventTriggerReactEvent(currentEvent, data));

        } catch (ex) {
            console.error("handleWebSocketMessage : error : ", ex);
        }
    },

    /****
     * to mark message as deliver
     ****/
    markMessageAsDeliver: function (messageId, userId, channelId) {
        try {

            if (!TribeChatSocketIOHandler.dispatch) {
                return;
            }

            let callback = function () {
                try {
                    let params = {
                        "txn": AppUtils.getUUID(),
                        "msgid": messageId,
                        "uid": userId
                    };

                    if (typeof channelId === "undefined" || channelId === "") {
                        TribeChatSocketIOHandler.dispatch(personalMessagesMarkMessageAsDelivered({params}));
                    } else {

                        params["chid"] = channelId;
                        TribeChatSocketIOHandler.dispatch(groupMessagesMarkAsDelivered({params}));
                    }

                } catch (ex) {
                    console.error("markMessageAsDeliver : inner : error : ", ex);
                }
            }

            window.setTimeout(callback, 1000);

        } catch (ex) {
            console.error("markMessageAsDeliver : error : ", ex);
        }
    }
};
