import {
    RELAY_NOTIFY_IN_CHANNEL,
    RELAY_NOTIFY_IN_CHANNEL_ERROR,
    RELAY_NOTIFY_IN_CHANNEL_NONE,
    RELAY_NOTIFY_IN_CHANNEL_SUCCESS,
    RELAY_NOTIFY_IN_DM,
    RELAY_NOTIFY_IN_DM_ERROR,
    RELAY_NOTIFY_IN_DM_NONE,
    RELAY_NOTIFY_IN_DM_SUCCESS
} from "../../../constants/actions/chat/ChatActionTypes";

/** relay : in channel **/
export const relayNotifyInChannel = (payload) => {
    return {
        type: RELAY_NOTIFY_IN_CHANNEL,
        payload: payload
    };
};

/** relay : in channel : success **/
export const relayNotifyInChannelSuccess = (payload) => {
    return {
        type: RELAY_NOTIFY_IN_CHANNEL_SUCCESS,
        payload: payload
    };
};

/** relay : in channel : error **/
export const relayNotifyInChannelError = (payload) => {
    return {
        type: RELAY_NOTIFY_IN_CHANNEL_ERROR,
        payload: payload
    };
};

/** relay : in channel : none **/
export const relayNotifyInChannelNone = (payload) => {
    return {
        type: RELAY_NOTIFY_IN_CHANNEL_NONE,
        payload: payload
    };
};

/** relay : in dm **/
export const relayNotifyDM = (payload) => {
    return {
        type: RELAY_NOTIFY_IN_DM,
        payload: payload
    };
};

/** relay : in dm : success **/
export const relayNotifyDMSuccess = (payload) => {
    return {
        type: RELAY_NOTIFY_IN_DM_SUCCESS,
        payload: payload
    };
};

/** relay : in dm : error **/
export const relayNotifyDMError = (payload) => {
    return {
        type: RELAY_NOTIFY_IN_DM_ERROR,
        payload: payload
    };
};

/** relay : in dm : none **/
export const relayNotifyDMNone = (payload) => {
    return {
        type: RELAY_NOTIFY_IN_DM_NONE,
        payload: payload
    };
};

/** signal hub : to post iframe event **/
export const postSignalHubEvent = (eventType, payload) => {
    return {
        type: eventType,
        payload: payload
    };
};
