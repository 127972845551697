import {combineReducers} from 'redux'
import {connectRouter} from 'connected-react-router'
import Settings from "./Settings";
import Auth from "./Auth";
import Notes from "./Notes";
import Contact from "./Contact";
import Common from "./Common";
import ReducerChat from "./chat/reducerChat";
import ReducerUserStatus from "./chat/reducerUserStatus";
import ReducerChatMessageHistory from "./chat/reducerChatMessageHistory";
import ReducerMessageMediaList from "./chat/reducerMessageMediaList";
import ReducerUserSettings from "./my_account/reducerUserSettings";
import ReducerTribeMedia from "./chat/reducerTribeMedia";
import ReducerClients from "./clients/reducerClients";
import ReducerUsers from "./users/reducerUsers";
import ReducerUserPrivateRoom from "./private_room/reducerPrivateRoom";

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    notes: Notes,
    contact: Contact,
    common: Common,
    ReducerChat: ReducerChat,
    ReducerUserStatus: ReducerUserStatus,
    ReducerChatMessageHistory: ReducerChatMessageHistory,
    ReducerMessageMediaList: ReducerMessageMediaList,
    ReducerUserSettings: ReducerUserSettings,
    ReducerTribeMedia: ReducerTribeMedia,
    ReducerClients: ReducerClients,
    ReducerUsers: ReducerUsers,
    ReducerUserPrivateRoom: ReducerUserPrivateRoom
});

export default createRootReducer
