import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {
    SIGNIN_USER,
    SIGNOUT_USER
} from "constants/ActionTypes";
import {
    showAuthMessage,
    userSignInSuccess,
    userSignOutSuccess,
    userSignUpSuccess
} from "../actions";
import {
    URL_AUTH_SIGN_IN,
    URL_AUTH_SIGN_OUT
} from "../../util/tribe/urls";
import axios from "axios";
import AppUtils from "../../util/tribe/utils/appUtils";
import StorageUtils from "../../util/tribe/storage_utils/storageUtils";

const signInUserWithEmailPasswordRequest = async (data) =>
    await axios.post(URL_AUTH_SIGN_IN, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

const signOutRequest = async () =>
    await axios.post(URL_AUTH_SIGN_OUT, {}, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

function* signInUserWithEmailPassword({payload}) {
    try {
        
        const signInUser = yield call(signInUserWithEmailPasswordRequest, payload);
        if (signInUser.message) {
            yield put(showAuthMessage(signInUser.message));
        } else {
            
            StorageUtils.setUserId(signInUser.data.data.user);
            
            //localStorage.setItem('user_id', signInUser.data.data.user.id);
            yield put(userSignInSuccess(signInUser.data));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

function* signOut() {
    try {
        const signOutUser = yield call(signOutRequest);
        if (signOutUser === undefined) {
            yield put(userSignOutSuccess(signOutUser));
        } else {
            yield put(showAuthMessage(signOutUser.message));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

export function* signInUser() {
    yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
    yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
    yield all([
        fork(signInUser),
        //fork(signOutUser)
    ]);
}
