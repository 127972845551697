import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import axios from "axios";
import {
    URL_CHAT_TAG_CHANNEL,
    URL_CHAT_TAG_CHANNEL_MESSAGE,
    URL_CHAT_TAG_DM,
    URL_CHAT_TAG_DM_MESSAGE,
    URL_CHAT_TAG_GET_ALL,
    URL_CHAT_TAG_GET_CHANNEL_MESSAGES,
    URL_CHAT_TAG_GET_DM_MESSAGES,
} from "../../../util/tribe/urls";
import {
    CHAT_TAG_CHANNEL,
    CHAT_TAG_CHANNEL_MESSAGE,
    CHAT_TAG_DM,
    CHAT_TAG_DM_MESSAGE,
    CHAT_TAG_GET_ALL_TAGS,
    CHAT_TAG_GET_CHANNEL_TAG_MESSAGES,
    CHAT_TAG_GET_DM_TAG_MESSAGES
} from "../../../constants/actions/chat/ChatActionTypes";
import AppUtils from "../../../util/tribe/utils/appUtils";
import {
    chatGetAllTagsError,
    chatGetAllTagsSuccess,
    getChatTagChannelMessagesError,
    getChatTagChannelMessagesSuccess,
    getChatTagDMMessageError,
    getChatTagDMMessageSuccess,
    chatTagChannelError,
    tagChatChannelMessageError,
    tagChatChannelMessageSuccess,
    chatTagChannelSuccess,
    chatTagDMError,
    tagChatDMMessageError,
    tagChatDMMessageSuccess,
    chatTagDMSuccess
} from "../../actions/chat/chatTagMessages";

/** http : tag : tag channel **/
const httpChatTagChannel = async (data) =>
    await axios.post(URL_CHAT_TAG_CHANNEL, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : tag : tag dm **/
const httpChatTagDM = async (data) =>
    await axios.post(URL_CHAT_TAG_DM, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : tag : tag channel message **/
const httpTagChatChannelMessage = async (data) =>
    await axios.post(URL_CHAT_TAG_CHANNEL_MESSAGE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : tag : tag dm message **/
const httptagChatDMMessage = async (data) =>
    await axios.post(URL_CHAT_TAG_DM_MESSAGE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : tag : get all tags **/
const httpChatTagGetAllTags = async (data) =>
    await axios.post(URL_CHAT_TAG_GET_ALL, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : tag : get tag channel messages **/
const httpGetChannelMessageTags = async (data) =>
    await axios.post(URL_CHAT_TAG_GET_CHANNEL_MESSAGES, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : tag : get tag dm messages **/
const httpChatGetDMMessages = async (data) =>
    await axios.post(URL_CHAT_TAG_GET_DM_MESSAGES, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/**** ****/

/** handler : tag : tag channel **/
function* handleHttpChatTagChannel({payload}) {
    try {
        const result = yield call(httpChatTagChannel, payload);
        if (result.message) {
            yield put(chatTagChannelError(result.message));
        } else {
            yield put(chatTagChannelSuccess(result.data));
        }
    } catch (error) {
        yield put(chatTagChannelError(error));
    }
}

/** handler : tag : tag dm **/
function* handleHttpChatTagDM({payload}) {
    try {
        const result = yield call(httpChatTagDM, payload);
        if (result.message) {
            yield put(chatTagDMError(result.message));
        } else {
            yield put(chatTagDMSuccess(result.data));
        }
    } catch (error) {
        yield put(chatTagDMError(error));
    }
}

/** handler : tag : tag channel message **/
function* handleHttpTagChatChannelMessage({payload}) {
    try {
        const result = yield call(httpTagChatChannelMessage, payload);
        if (result.message) {
            yield put(tagChatChannelMessageError(result.message));
        } else {
            yield put(tagChatChannelMessageSuccess(result.data));
        }
    } catch (error) {
        yield put(tagChatChannelMessageError(error));
    }
}

/** handler : tag : tag dm message **/
function* handleHttptagChatDMMessage({payload}) {
    try {
        const result = yield call(httptagChatDMMessage, payload);
        if (result.message) {
            yield put(tagChatDMMessageError(result.message));
        } else {
            yield put(tagChatDMMessageSuccess(result.data));
        }
    } catch (error) {
        yield put(tagChatDMMessageError(error));
    }
}

/** handler : tag : tag dm message **/
function* handleHttpChatTagGetAllTags({payload}) {
    try {
        const result = yield call(httpChatTagGetAllTags, payload);
        if (result.message) {
            yield put(chatGetAllTagsError(result.message));
        } else {
            yield put(chatGetAllTagsSuccess(result.data));
        }
    } catch (error) {
        yield put(chatGetAllTagsError(error));
    }
}

/** handler : tag : get channel message tags **/
function* handleHttpGetChannelMessageTags({payload}) {
    try {
        const result = yield call(httpGetChannelMessageTags, payload);
        if (result.message) {
            yield put(getChatTagChannelMessagesError(result.message));
        } else {
            yield put(getChatTagChannelMessagesSuccess(result.data));
        }
    } catch (error) {
        yield put(getChatTagChannelMessagesError(error));
    }
}

/** handler : tag : tag dm message **/
function* handleHttpChatTagGetDMTags({payload}) {
    try {
        const result = yield call(httpChatGetDMMessages, payload);
        if (result.message) {
            yield put(getChatTagDMMessageError(result.message));
        } else {
            yield put(getChatTagDMMessageSuccess(result.data));
        }
    } catch (error) {
        yield put(getChatTagDMMessageError(error));
    }
}


/** watcher : tag : tag channel **/
export function* chatTagChannel() {
    yield takeEvery(CHAT_TAG_CHANNEL, handleHttpChatTagChannel);
}

/** watcher : tag : tag dm **/
export function* chatTagDM() {
    yield takeEvery(CHAT_TAG_DM, handleHttpChatTagDM);
}

/** watcher : tag : tag channel message **/
export function* tagChatChannelMessage() {
    yield takeEvery(CHAT_TAG_CHANNEL_MESSAGE, handleHttpTagChatChannelMessage);
}

/** watcher : tag : tag dm message **/
export function* tagChatDMMessage() {
    yield takeEvery(CHAT_TAG_DM_MESSAGE, handleHttptagChatDMMessage);
}

/** watcher : tag : get all tags **/
export function* chatTagGetAllTags() {
    yield takeEvery(CHAT_TAG_GET_ALL_TAGS, handleHttpChatTagGetAllTags);
}

/** watcher : tag : get channel messages tags **/
export function* getChannelMessageTags() {
    yield takeEvery(CHAT_TAG_GET_CHANNEL_TAG_MESSAGES, handleHttpGetChannelMessageTags);
}

/** watcher : tag : get dm message tags **/
export function* chatTagGetDMTags() {
    yield takeEvery(CHAT_TAG_GET_DM_TAG_MESSAGES, handleHttpChatTagGetDMTags);
}

export default function* rootSaga() {
    yield all([
        fork(chatTagChannel),
        fork(chatTagDM),
        fork(tagChatChannelMessage),
        fork(tagChatDMMessage),
        fork(chatTagGetAllTags),
        fork(getChannelMessageTags),
        fork(chatTagGetDMTags)
    ])
}
