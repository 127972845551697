const StorageUtils = {
    keys: {
        "STORAGE_KEY_USER_DETAILS": "STORAGE_KEY_USER_DETAILS",
        "STORAGE_KEY_USER_ID": "STORAGE_KEY_USER_ID",
        "STORAGE_KEY_REFRESH_KEY": "STORAGE_KEY_REFRESH_KEY",
        "STORAGE_KEY_DEVICE_ID": "STORAGE_KEY_DEVICE_ID"
    },
    userId: "",
    
    setUserId: function (userObject) {
        try {
            
            if (typeof userObject.id != "undefined") {
                StorageUtils.userId = userObject.id;
            }
            
            if (typeof userObject.username != "undefined") {
                let username = userObject.username;
                
                let userData = StorageUtils.getJSONItem(StorageUtils.keys.STORAGE_KEY_USER_DETAILS);
                
                if (typeof userData == "undefined") {
                    userData[username] = StorageUtils.userId;
                }
                
                StorageUtils.saveJSONItem(StorageUtils.keys.STORAGE_KEY_USER_DETAILS, userData);
                
            }
            
        } catch (ex) {
            console.error("setUserId : error : ", ex);
        }
        
        //username
    },
    
    saveItem: function (key, value) {
        try {
            window.localStorage[key] = value;
        } catch (ex) {
            console.error("StorageUtils : saveItem : ERROR : ", ex);
        }
    },
    
    getItem: function (key) {
        let result = "";
        try {
            let temp = window.localStorage[key];
            
            if (typeof temp != "undefined") {
                result = temp;
            }
            
        } catch (ex) {
            console.error("StorageUtils : getItem : ERROR : ", ex);
        }
        
        return result;
    },
    
    saveJSONItem: function (key, jsonValue) {
        try {
            window.localStorage[key] = JSON.stringify(jsonValue);
        } catch (ex) {
            console.error("StorageUtils : saveJSONItem : ERROR : ", ex);
        }
    },
    
    getJSONItem: function (key) {
        let result = {};
        try {
            let temp = window.localStorage[key];
            
            if (typeof temp != "undefined") {
                result = JSON.parse(temp);
            }
            
        } catch (ex) {
            console.error("StorageUtils : getJSONItem : ERROR : ", ex);
        }
        
        return result;
    },
    
    getDeviceId: function () {
        let result = "";
        try {
            let deviceData = StorageUtils.getItem(StorageUtils.keys.STORAGE_KEY_DEVICE_ID);
            
            if (typeof deviceData == "undefined") {
                deviceData = {};
            }
            
            if (typeof deviceData[StorageUtils.userId] != "undefined") {
                result = deviceData[StorageUtils.userId];
            }
            
        } catch (ex) {
            console.error("getDeviceId : error : ", ex);
        }
        
        return result;
    },
    
    setDeviceId: function (deviceId) {
        try {
            let deviceData = StorageUtils.getJSONItem(StorageUtils.keys.STORAGE_KEY_DEVICE_ID);
            
            if (typeof deviceData == "undefined") {
                deviceData = {};
            }
            
            deviceData[StorageUtils.userId] = deviceId;
            StorageUtils.saveJSONItem(StorageUtils.keys.STORAGE_KEY_DEVICE_ID, deviceData);
            
        } catch (ex) {
            console.error("setDeviceId : error : ", ex);
        }
    },
    
    removeDeviceId: function () {
        try {
            let deviceData = StorageUtils.getJSONItem(StorageUtils.keys.STORAGE_KEY_DEVICE_ID);
            
            if (typeof deviceData == "undefined") {
                deviceData = {};
            }
            
            deviceData[StorageUtils.userId] = "";
            StorageUtils.saveJSONItem(StorageUtils.keys.STORAGE_KEY_DEVICE_ID, deviceData);
        } catch (ex) {
            console.error("removeDeviceId : error : ", ex);
        }
    },
    
    doLogout: function () {
        try {
            StorageUtils.removeDeviceId();
            StorageUtils.userId = "";
            
        } catch (ex) {
            console.error("doLogout : error : ", ex);
        }
    }
    
    
};

export default StorageUtils;
