import {
    DEVICE_MANAGEMENT_DEVICE_ATTACH,
    DEVICE_MANAGEMENT_DEVICE_ATTACH_ERROR,
    DEVICE_MANAGEMENT_DEVICE_ATTACH_NONE,
    DEVICE_MANAGEMENT_DEVICE_ATTACH_SUCCESS,
    DEVICE_MANAGEMENT_DEVICE_DETACH,
    DEVICE_MANAGEMENT_DEVICE_DETACH_ERROR,
    DEVICE_MANAGEMENT_DEVICE_DETACH_NONE,
    DEVICE_MANAGEMENT_DEVICE_DETACH_SUCCESS,
    DEVICE_MANAGEMENT_DEVICE_LIST,
    DEVICE_MANAGEMENT_DEVICE_LIST_ERROR,
    DEVICE_MANAGEMENT_DEVICE_LIST_NONE,
    DEVICE_MANAGEMENT_DEVICE_LIST_SUCCESS,
    DEVICE_MANAGEMENT_DEVICE_UPDATE,
    DEVICE_MANAGEMENT_DEVICE_UPDATE_ERROR,
    DEVICE_MANAGEMENT_DEVICE_UPDATE_NONE,
    DEVICE_MANAGEMENT_DEVICE_UPDATE_SUCCESS,
    
} from "../../../constants/actions/chat/ChatActionTypes";

/** device management : device attach **/
export const deviceManagementDeviceAttach = (payload) => {
    return {
        type: DEVICE_MANAGEMENT_DEVICE_ATTACH,
        payload: payload
    };
};

/** device management : device attach : success **/
export const deviceManagementDeviceAttachSuccess = (payload) => {
    return {
        type: DEVICE_MANAGEMENT_DEVICE_ATTACH_SUCCESS,
        payload: payload
    };
};

/** device management : device attach : error **/
export const deviceManagementDeviceAttachError = (payload) => {
    return {
        type: DEVICE_MANAGEMENT_DEVICE_ATTACH_ERROR,
        payload: payload
    };
};

/** device management : device attach : none **/
export const deviceManagementDeviceAttachNone = (payload) => {
    return {
        type: DEVICE_MANAGEMENT_DEVICE_ATTACH_NONE,
        payload: payload
    };
};


/** device management : device update **/
export const deviceManagementDeviceUpdate = (payload) => {
    return {
        type: DEVICE_MANAGEMENT_DEVICE_UPDATE,
        payload: payload
    };
};

/** device management : device update : success **/
export const deviceManagementDeviceUpdateSuccess = (payload) => {
    return {
        type: DEVICE_MANAGEMENT_DEVICE_UPDATE_SUCCESS,
        payload: payload
    };
};

/** device management : device update : error **/
export const deviceManagementDeviceUpdateError = (payload) => {
    return {
        type: DEVICE_MANAGEMENT_DEVICE_UPDATE_ERROR,
        payload: payload
    };
};

/** device management : device update : none **/
export const deviceManagementDeviceUpdateNone = (payload) => {
    return {
        type: DEVICE_MANAGEMENT_DEVICE_UPDATE_NONE,
        payload: payload
    };
};


/** device management : device detach **/
export const deviceManagementDeviceDetach = (payload) => {
    return {
        type: DEVICE_MANAGEMENT_DEVICE_DETACH,
        payload: payload
    };
};

/** device management : device detach : success **/
export const deviceManagementDeviceDetachSuccess = (payload) => {
    return {
        type: DEVICE_MANAGEMENT_DEVICE_DETACH_SUCCESS,
        payload: payload
    };
};

/** device management : device detach : error **/
export const deviceManagementDeviceDetachError = (payload) => {
    return {
        type: DEVICE_MANAGEMENT_DEVICE_DETACH_ERROR,
        payload: payload
    };
};

/** device management : device detach : none **/
export const deviceManagementDeviceDetachNone = (payload) => {
    return {
        type: DEVICE_MANAGEMENT_DEVICE_DETACH_NONE,
        payload: payload
    };
};


/** device management : device list **/
export const deviceManagementDeviceList = (payload) => {
    return {
        type: DEVICE_MANAGEMENT_DEVICE_LIST,
        payload: payload
    };
};

/** device management : device list : success **/
export const deviceManagementDeviceListSuccess = (payload) => {
    return {
        type: DEVICE_MANAGEMENT_DEVICE_LIST_SUCCESS,
        payload: payload
    };
};

/** device management : device list : error **/
export const deviceManagementDeviceListError = (payload) => {
    return {
        type: DEVICE_MANAGEMENT_DEVICE_LIST_ERROR,
        payload: payload
    };
};

/** device management : device list : none **/
export const deviceManagementDeviceListNone = (payload) => {
    return {
        type: DEVICE_MANAGEMENT_DEVICE_LIST_NONE,
        payload: payload
    };
};
