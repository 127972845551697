import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import axios from "axios";
import {
    URL_CHAT_DEVICE_MANAGEMENT_ATTACH,
    URL_CHAT_DEVICE_MANAGEMENT_DETACH,
    URL_CHAT_DEVICE_MANAGEMENT_LIST,
    URL_CHAT_DEVICE_MANAGEMENT_UPDATE,
} from "../../../util/tribe/urls";
import {
    DEVICE_MANAGEMENT_DEVICE_ATTACH,
    DEVICE_MANAGEMENT_DEVICE_DETACH,
    DEVICE_MANAGEMENT_DEVICE_LIST,
    DEVICE_MANAGEMENT_DEVICE_UPDATE
} from "../../../constants/actions/chat/ChatActionTypes";
import AppUtils from "../../../util/tribe/utils/appUtils";
import {
    deviceManagementDeviceAttachError,
    deviceManagementDeviceAttachSuccess,
    deviceManagementDeviceDetachError,
    deviceManagementDeviceDetachSuccess,
    deviceManagementDeviceListError,
    deviceManagementDeviceListSuccess,
    deviceManagementDeviceUpdateError,
    deviceManagementDeviceUpdateSuccess,
} from "../../actions";

/** http : device management : to attach device **/
const httpDeviceManagementAttachDevice = async (data) =>
    await axios.post(URL_CHAT_DEVICE_MANAGEMENT_ATTACH, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : device management : to update device **/
const httpDeviceManagementUpdateDevice = async (data) =>
    await axios.post(URL_CHAT_DEVICE_MANAGEMENT_UPDATE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : device management : to detach device **/
const httpDeviceManagementDetachDevice = async (data) =>
    await axios.post(URL_CHAT_DEVICE_MANAGEMENT_DETACH, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : device management : to get list devices **/
const httpDeviceManagementListDevices = async (data) =>
    await axios.post(URL_CHAT_DEVICE_MANAGEMENT_LIST, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** handler : device management : to attach device **/
function* handleHttpDeviceManagementAttachDevice({payload}) {
    try {
        const result = yield call(httpDeviceManagementAttachDevice, payload);
        if (result.message) {
            yield put(deviceManagementDeviceAttachError(result.message));
        } else {
            yield put(deviceManagementDeviceAttachSuccess(result.data));
        }
    } catch (error) {
        yield put(deviceManagementDeviceAttachError(error));
    }
}

/** handler : device management : to update device **/
function* handleHttpDeviceManagementUpdateDevice({payload}) {
    try {
        const result = yield call(httpDeviceManagementUpdateDevice, payload);
        if (result.message) {
            yield put(deviceManagementDeviceUpdateError(result.message));
        } else {
            yield put(deviceManagementDeviceUpdateSuccess(result.data));
        }
    } catch (error) {
        yield put(deviceManagementDeviceUpdateError(error));
    }
}

/** handler : device management : to detach device **/
function* handleHttpDeviceManagementDetachDevice({payload}) {
    try {
        const result = yield call(httpDeviceManagementDetachDevice, payload);
        if (result.message) {
            yield put(deviceManagementDeviceDetachError(result.message));
        } else {
            yield put(deviceManagementDeviceDetachSuccess(result.data));
        }
    } catch (error) {
        yield put(deviceManagementDeviceDetachError(error));
    }
}

/** handler : device management : to get list devices **/
function* handleHttpDeviceManagementListDevices({payload}) {
    try {
        const result = yield call(httpDeviceManagementListDevices, payload);
        if (result.message) {
            yield put(deviceManagementDeviceListError(result.message));
        } else {
            yield put(deviceManagementDeviceListSuccess(result.data));
        }
    } catch (error) {
        yield put(deviceManagementDeviceListError(error));
    }
}

/** watcher : device management : to attach device **/
export function* deviceManagementAttachDevice() {
    yield takeEvery(DEVICE_MANAGEMENT_DEVICE_ATTACH, handleHttpDeviceManagementAttachDevice);
}

/** watcher : device management : to update device **/
export function* deviceManagementUpdateDevice() {
    yield takeEvery(DEVICE_MANAGEMENT_DEVICE_UPDATE, handleHttpDeviceManagementUpdateDevice);
}

/** watcher : device management : to detach device **/
export function* deviceManagementDetachDevice() {
    yield takeEvery(DEVICE_MANAGEMENT_DEVICE_DETACH, handleHttpDeviceManagementDetachDevice);
}

/** watcher : device management : to get list devices **/
export function* deviceManagementListDevices() {
    yield takeEvery(DEVICE_MANAGEMENT_DEVICE_LIST, handleHttpDeviceManagementListDevices);
}


export default function* rootSaga() {
    yield all([
        fork(deviceManagementAttachDevice),
        fork(deviceManagementUpdateDevice),
        fork(deviceManagementDetachDevice),
        fork(deviceManagementListDevices)
    ])
}
