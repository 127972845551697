import {
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_ADD,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_ADD_ERROR,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_ADD_NONE,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_ADD_SUCCESS,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_BAN,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_BAN_ERROR,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_BAN_NONE,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_BAN_SUCCESS,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_BLOCK_NOTIFICATIONS,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_BLOCK_NOTIFICATIONS_ERROR,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_BLOCK_NOTIFICATIONS_NONE,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_BLOCK_NOTIFICATIONS_SUCCESS,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_CHANGE_ROLE,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_CHANGE_ROLE_ERROR,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_CHANGE_ROLE_NONE,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_CHANGE_ROLE_SUCCESS,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_ACCEPT,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_ACCEPT_ERROR,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_ACCEPT_NONE,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_ACCEPT_SUCCESS,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_DELETE,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_DELETE_ERROR,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_DELETE_NONE,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_DELETE_SUCCESS,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_ERROR,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_NONE,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_SUCCESS,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_LEAVE,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_LEAVE_ERROR,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_LEAVE_NONE,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_LEAVE_SUCCESS,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_LIST,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_LIST_ERROR,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_LIST_NONE,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_LIST_SUCCESS,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_UNBLOCK_NOTIFICATIONS,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_UNBLOCK_NOTIFICATIONS_ERROR,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_UNBLOCK_NOTIFICATIONS_NONE,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_UNBLOCK_NOTIFICATIONS_SUCCESS
} from "../../../constants/actions/chat/ChatActionTypes";

/** channel member management : member add **/
export const channelMemberManagementMemberAdd = (payload) => {
    return {
        type: CHANNEL_MEMBER_MANAGEMENT_MEMBER_ADD,
        payload: payload
    };
};

/** channel member management : member add : success **/
export const channelMemberManagementMemberAddSuccess = (payload) => {
    return {
        type: CHANNEL_MEMBER_MANAGEMENT_MEMBER_ADD_SUCCESS,
        payload: payload
    };
};

/** channel member management : member add : error **/
export const channelMemberManagementMemberAddError = (payload) => {
    return {
        type: CHANNEL_MEMBER_MANAGEMENT_MEMBER_ADD_ERROR,
        payload: payload
    };
};

/** channel member management : member add : none **/
export const channelMemberManagementMemberAddNone = (payload) => {
    return {
        type: CHANNEL_MEMBER_MANAGEMENT_MEMBER_ADD_NONE,
        payload: payload
    };
};


/** channel member management : member invite **/
export const channelMemberManagementMemberInvite = (payload) => {
    return {
        type: CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE,
        payload: payload
    };
};

/** channel member management : member invite : success **/
export const channelMemberManagementMemberInviteSuccess = (payload) => {
    return {
        type: CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_SUCCESS,
        payload: payload
    };
};

/** channel member management : member invite : error **/
export const channelMemberManagementMemberInviteError = (payload) => {
    return {
        type: CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_ERROR,
        payload: payload
    };
};

/** channel member management : member invite : none **/
export const channelMemberManagementMemberInviteNone = (payload) => {
    return {
        type: CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_NONE,
        payload: payload
    };
};


/** channel member management : member invite delete **/
export const channelMemberManagementMemberInviteDelete = (payload) => {
    return {
        type: CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_DELETE,
        payload: payload
    };
};

/** channel member management : member invite delete : success **/
export const channelMemberManagementMemberInviteDeleteSuccess = (payload) => {
    return {
        type: CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_DELETE_SUCCESS,
        payload: payload
    };
};

/** channel member management : member invite delete : error **/
export const channelMemberManagementMemberInviteDeleteError = (payload) => {
    return {
        type: CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_DELETE_ERROR,
        payload: payload
    };
};

/** channel member management : member invite delete : none **/
export const channelMemberManagementMemberInviteDeleteNone = (payload) => {
    return {
        type: CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_DELETE_NONE,
        payload: payload
    };
};


/** channel member management : member invite accept **/
export const channelMemberManagementMemberInviteAccept = (payload) => {
    return {
        type: CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_ACCEPT,
        payload: payload
    };
};

/** channel member management : member invite accept : success **/
export const channelMemberManagementMemberInviteAcceptSuccess = (payload) => {
    return {
        type: CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_ACCEPT_SUCCESS,
        payload: payload
    };
};

/** channel member management : member invite accept : error **/
export const channelMemberManagementMemberInviteAcceptError = (payload) => {
    return {
        type: CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_ACCEPT_ERROR,
        payload: payload
    };
};

/** channel member management : member invite accept : none **/
export const channelMemberManagementMemberInviteAcceptNone = (payload) => {
    return {
        type: CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_ACCEPT_NONE,
        payload: payload
    };
};


/** channel member management : member leave **/
export const channelMemberManagementMemberLeave = (payload) => {
    return {
        type: CHANNEL_MEMBER_MANAGEMENT_MEMBER_LEAVE,
        payload: payload
    };
};

/** channel member management : member leave : success **/
export const channelMemberManagementMemberLeaveSuccess = (payload) => {
    return {
        type: CHANNEL_MEMBER_MANAGEMENT_MEMBER_LEAVE_SUCCESS,
        payload: payload
    };
};

/** channel member management : member leave : error **/
export const channelMemberManagementMemberLeaveError = (payload) => {
    return {
        type: CHANNEL_MEMBER_MANAGEMENT_MEMBER_LEAVE_ERROR,
        payload: payload
    };
};

/** channel member management : member leave : none **/
export const channelMemberManagementMemberLeaveNone = (payload) => {
    return {
        type: CHANNEL_MEMBER_MANAGEMENT_MEMBER_LEAVE_NONE,
        payload: payload
    };
};


/** channel member management : member block notifications **/
export const channelMemberManagementMemberBlockNotifications = (payload) => {
    return {
        type: CHANNEL_MEMBER_MANAGEMENT_MEMBER_BLOCK_NOTIFICATIONS,
        payload: payload
    };
};

/** channel member management : member block notifications : success **/
export const channelMemberManagementMemberBlockNotificationsSuccess = (payload) => {
    return {
        type: CHANNEL_MEMBER_MANAGEMENT_MEMBER_BLOCK_NOTIFICATIONS_SUCCESS,
        payload: payload
    };
};

/** channel member management : member block notifications : error **/
export const channelMemberManagementMemberBlockNotificationsError = (payload) => {
    return {
        type: CHANNEL_MEMBER_MANAGEMENT_MEMBER_BLOCK_NOTIFICATIONS_ERROR,
        payload: payload
    };
};

/** channel member management : member block notifications : none **/
export const channelMemberManagementMemberBlockNotificationsNone = (payload) => {
    return {
        type: CHANNEL_MEMBER_MANAGEMENT_MEMBER_BLOCK_NOTIFICATIONS_NONE,
        payload: payload
    };
};


/** channel member management : member unblock notifications **/
export const channelMemberManagementMemberUnblockNotifications = (payload) => {
    return {
        type: CHANNEL_MEMBER_MANAGEMENT_MEMBER_UNBLOCK_NOTIFICATIONS,
        payload: payload
    };
};

/** channel member management : member unblock notifications : success **/
export const channelMemberManagementMemberUnblockNotificationsSuccess = (payload) => {
    return {
        type: CHANNEL_MEMBER_MANAGEMENT_MEMBER_UNBLOCK_NOTIFICATIONS_SUCCESS,
        payload: payload
    };
};

/** channel member management : member unblock notifications : error **/
export const channelMemberManagementMemberUnblockNotificationsError = (payload) => {
    return {
        type: CHANNEL_MEMBER_MANAGEMENT_MEMBER_UNBLOCK_NOTIFICATIONS_ERROR,
        payload: payload
    };
};

/** channel member management : member unblock notifications : none **/
export const channelMemberManagementMemberUnblockNotificationsNone = (payload) => {
    return {
        type: CHANNEL_MEMBER_MANAGEMENT_MEMBER_UNBLOCK_NOTIFICATIONS_NONE,
        payload: payload
    };
};


/** channel member management : member ban **/
export const channelMemberManagementMemberBan = (payload) => {
    return {
        type: CHANNEL_MEMBER_MANAGEMENT_MEMBER_BAN,
        payload: payload
    };
};

/** channel member management : member ban : success **/
export const channelMemberManagementMemberBanSuccess = (payload) => {
    return {
        type: CHANNEL_MEMBER_MANAGEMENT_MEMBER_BAN_SUCCESS,
        payload: payload
    };
};

/** channel member management : member ban : error **/
export const channelMemberManagementMemberBanError = (payload) => {
    return {
        type: CHANNEL_MEMBER_MANAGEMENT_MEMBER_BAN_ERROR,
        payload: payload
    };
};

/** channel member management : member ban : none **/
export const channelMemberManagementMemberBanNone = (payload) => {
    return {
        type: CHANNEL_MEMBER_MANAGEMENT_MEMBER_BAN_NONE,
        payload: payload
    };
};


/** channel member management : member change role **/
export const channelMemberManagementMemberChangeRole = (payload) => {
    return {
        type: CHANNEL_MEMBER_MANAGEMENT_MEMBER_CHANGE_ROLE,
        payload: payload
    };
};

/** channel member management : member change role : success **/
export const channelMemberManagementMemberChangeRoleSuccess = (payload) => {
    return {
        type: CHANNEL_MEMBER_MANAGEMENT_MEMBER_CHANGE_ROLE_SUCCESS,
        payload: payload
    };
};

/** channel member management : member change role : error **/
export const channelMemberManagementMemberChangeRoleError = (payload) => {
    return {
        type: CHANNEL_MEMBER_MANAGEMENT_MEMBER_CHANGE_ROLE_ERROR,
        payload: payload
    };
};

/** channel member management : member change role : none **/
export const channelMemberManagementMemberChangeRoleNone = (payload) => {
    return {
        type: CHANNEL_MEMBER_MANAGEMENT_MEMBER_CHANGE_ROLE_NONE,
        payload: payload
    };
};


/** channel member management : member list **/
export const channelMemberManagementMemberList = (payload) => {
    return {
        type: CHANNEL_MEMBER_MANAGEMENT_MEMBER_LIST,
        payload: payload
    };
};

/** channel member management : member list : success **/
export const channelMemberManagementMemberListSuccess = (payload) => {
    return {
        type: CHANNEL_MEMBER_MANAGEMENT_MEMBER_LIST_SUCCESS,
        payload: payload
    };
};

/** channel member management : member list : error **/
export const channelMemberManagementMemberListError = (payload) => {
    return {
        type: CHANNEL_MEMBER_MANAGEMENT_MEMBER_LIST_ERROR,
        payload: payload
    };
};

/** channel member management : member list : none **/
export const channelMemberManagementMemberListNone = (payload) => {
    return {
        type: CHANNEL_MEMBER_MANAGEMENT_MEMBER_LIST_NONE,
        payload: payload
    };
};
