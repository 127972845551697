import {
    HTTP_REQUEST_STATUS
} from "../../../util/tribe/constants/config";
import IntlMessages from "../../../util/IntlMessages";
import UIUtils from "../../../util/tribe/utils/uiUtils";
import {
    USER_PRIVATE_ROOM_DELETE,
    USER_PRIVATE_ROOM_DELETE_ERROR,
    USER_PRIVATE_ROOM_DELETE_NONE,
    USER_PRIVATE_ROOM_DELETE_SUCCESS,
    USER_PRIVATE_ROOM_GET_LIST,
    USER_PRIVATE_ROOM_GET_LIST_ERROR,
    USER_PRIVATE_ROOM_GET_LIST_NONE,
    USER_PRIVATE_ROOM_GET_LIST_SUCCESS,
    USER_PRIVATE_ROOM_SAVE,
    USER_PRIVATE_ROOM_SAVE_ERROR,
    USER_PRIVATE_ROOM_SAVE_NONE,
    USER_PRIVATE_ROOM_SAVE_SUCCESS,
    USER_PRIVATE_ROOM_SET_CURRENT_ROOM
} from "../../../constants/actions/private_room/userPrivateRoomActionTypes";
import AppUtils from "../../../util/tribe/utils/appUtils";
import {
    CLIENTS_GET_CLIENT_FEATURES,
    CLIENTS_GET_CLIENT_FEATURES_ERROR,
    CLIENTS_GET_CLIENT_FEATURES_NONE,
    CLIENTS_GET_CLIENT_FEATURES_SUCCESS,
    CLIENTS_GET_VOIP_NOTIFICATION_CONFIG,
    CLIENTS_GET_VOIP_NOTIFICATION_CONFIG_ERROR,
    CLIENTS_GET_VOIP_NOTIFICATION_CONFIG_NONE,
    CLIENTS_GET_VOIP_NOTIFICATION_CONFIG_SUCCESS,
    CLIENTS_SET_CLIENT_FEATURE_PRIVATE_ROOM_STATUS,
    CLIENTS_SET_CLIENT_FEATURE_PRIVATE_ROOM_STATUS_ERROR,
    CLIENTS_SET_CLIENT_FEATURE_PRIVATE_ROOM_STATUS_NONE,
    CLIENTS_SET_CLIENT_FEATURE_PRIVATE_ROOM_STATUS_SUCCESS,
    CLIENTS_SET_UPDATE_VOIP_NOTIFICATION_CONFIG,
    CLIENTS_SET_UPDATE_VOIP_NOTIFICATION_CONFIG_ERROR,
    CLIENTS_SET_UPDATE_VOIP_NOTIFICATION_CONFIG_NONE,
    CLIENTS_SET_UPDATE_VOIP_NOTIFICATION_CONFIG_SUCCESS
} from "../../../constants/actions/clients/actionTypesClients";

export const TABLE_PAGINATION_OPTIONS = [50, 100, 150, 200];

const privateRoomListFilterConfig = [
    {
        'key': 'private_room_search_input',
        'type': UIUtils.UI_COMPONENT_TYPES.SEARCH_INPUT,
        'localisation': "",
        'isStar': false,
        'isMandatory': false
    },
    {
        'key': 'private_room_status_dropdown',
        'type': UIUtils.UI_COMPONENT_TYPES.MENU_POPOVER,
        'isValueTitle': true,
        'localisation': "",
        'isStar': false,
        'isMandatory': false,
        'parent_class': "table_search_input_dropdown"
    }
];

const privateRoomTableHeaderConfig = [
    {
        id: 'meeting_title',
        label: <IntlMessages id="DIC_KEY_PRIVATE_ROOM_TABLE_HEADER_MEETING_TITLE"/>,
        visible: true,
        sortable: true,
        isClickable: true,
        className: "table_column_text_highlight gx-pl-4"
    },
    {
        id: 'created_date_str',
        label: <IntlMessages id="DIC_KEY_PRIVATE_ROOM_TABLE_HEADER_CREATED_DATE"/>,
        visible: true,
        sortable: true,
        isClickable: true,
        className: "table_column_text_normal"
    },
    {
        id: 'start_time_str',
        label: <IntlMessages id="DIC_KEY_PRIVATE_ROOM_TABLE_HEADER_START_TIME"/>,
        visible: true,
        sortable: true,
        isClickable: true,
        className: "table_column_text_normal"
    },
    {
        id: 'end_time_str',
        label: <IntlMessages id="DIC_KEY_PRIVATE_ROOM_TABLE_HEADER_END_TIME"/>,
        visible: true,
        sortable: true,
        isClickable: true,
        className: "table_column_text_normal"
    }
];

const privateRoomViewSubHeaderConfig = [
    {
        'key': 'private_room_view_action_save',
        'type': UIUtils.UI_COMPONENT_TYPES.BUTTON_STATIC_POSITIVE,
        'title': <IntlMessages id="DIC_KEY_PRIVATE_ROOM_VIEW_ACTION_LABEL_SAVE"/>,
        'parent_class': "float_right "
    },
    {
        'key': 'private_room_view_action_delete',
        'type': UIUtils.UI_COMPONENT_TYPES.BUTTON_STATIC_NEGATIVE,
        'title': <IntlMessages id="DIC_KEY_PRIVATE_ROOM_VIEW_ACTION_LABEL_DELETE"/>,
        'parent_class': "float_right gx-mr-2"
    },
    {
        'key': 'private_room_view_action_header',
        'type': UIUtils.UI_COMPONENT_TYPES.LABEL_CONTAINER,
        'title': <IntlMessages id="DIC_KEY_PRIVATE_ROOM_VIEW_ACTION_LABEL_DETAIL_INFORMATION"/>
    }
];

const privateRoomViewConfig = [
    {
        'key': 'meeting_name',
        'type': UIUtils.UI_COMPONENT_TYPES.HORIZONTAL_LABEL_INPUT,
        'style': {},
        'labelClass': "gx-pl-2",
        'localisation': "DIC_KEY_PRIVATE_ROOM_VIEW_MEETING_TITLE",
        'isStar': true,
        'isMandatory': true,
        'parent_class': "form_container_root form_container_root_next_dropdown",
        'placeholderKey': "DIC_KEY_PRIVATE_ROOM_VIEW_MEETING_TITLE_PLACEHOLDER",
        'variant': "outlined"
    },
    {
        'key': 'start_time',
        'type': UIUtils.UI_COMPONENT_TYPES.DATE_TIME_FIELD_HORIZONTAL,
        'style': {},
        'labelClass': "gx-pl-2",
        'localisation': "DIC_KEY_PRIVATE_ROOM_VIEW_START_TIME",
        'isStar': true,
        'isMandatory': false,
        'parent_class': "form_container_root",
        'placeholderKey': "DIC_KEY_PRIVATE_ROOM_VIEW_START_TIME_PLACEHOLDER",
        'variant': "outlined"
    },
    {
        'key': 'end_time',
        'type': UIUtils.UI_COMPONENT_TYPES.DATE_TIME_FIELD_HORIZONTAL,
        'style': {},
        'labelClass': "gx-pl-2",
        'localisation': "DIC_KEY_PRIVATE_ROOM_VIEW_END_TIME",
        'isStar': true,
        'isMandatory': false,
        'parent_class': "form_container_root",
        'placeholderKey': "DIC_KEY_PRIVATE_ROOM_VIEW_END_TIME_PLACEHOLDER",
        'variant': "outlined"
    },
    {
        "key": "is_video_call",
        "type": UIUtils.UI_COMPONENT_TYPES.SWITCH_LABEL_HORIZONTAL,
        'labelClass': "gx-pl-2",
        'localisation': "DIC_KEY_PRIVATE_ROOM_VIEW_VIDEO_CALL",
        'isStar': false,
        'isMandatory': false,
        'parent_class': "form_container_root",
        'placeholderKey': "DIC_KEY_PRIVATE_ROOM_VIEW_VIDEO_CALL",
        'variant': "outlined"
    },
    {
        "key": "is_knock_enabled",
        "type": UIUtils.UI_COMPONENT_TYPES.SWITCH_LABEL_HORIZONTAL,
        'labelClass': "gx-pl-2",
        'localisation': "DIC_KEY_PRIVATE_ROOM_VIEW_KNOCK",
        'isStar': false,
        'isMandatory': false,
        'parent_class': "form_container_root",
        'placeholderKey': "DIC_KEY_PRIVATE_ROOM_VIEW_KNOCK",
        'variant': "outlined"
    },
    {
        "key": "is_passcode_enabled",
        "type": UIUtils.UI_COMPONENT_TYPES.SWITCH_LABEL_HORIZONTAL,
        'labelClass': "gx-pl-2",
        'localisation': "DIC_KEY_PRIVATE_ROOM_VIEW_PASSCODE",
        'isStar': false,
        'isMandatory': false,
        'parent_class': "form_container_root",
        'placeholderKey': "DIC_KEY_PRIVATE_ROOM_VIEW_PASSCODE",
        'variant': "outlined"
    },

];


const INIT_STATE = {
    privateRoomListFilterConfig: privateRoomListFilterConfig,
    privateRoomTableHeaderConfig: privateRoomTableHeaderConfig,
    privateRoomViewSubHeaderConfig: privateRoomViewSubHeaderConfig,
    privateRoomViewConfig: privateRoomViewConfig,
    TABLE_PAGINATION_OPTIONS: TABLE_PAGINATION_OPTIONS,

    privateRoomList: [],
    currentRoom: {},
    currentClientFeatures: {},
    currentClientVOIPNotificationConfig: {},
    currentClientFCMNotificationConfig: {},

    alertMessage: "",
    privateRoomSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    privateRoomDeleteStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    privateRoomGetListStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    clientGetFeaturesStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    setClientFeaturesPrivateRoomStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    updateClientVOIPNotificationConfigStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    getClientVOIPNotificationConfigStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    updateClientFCMNotificationConfigStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    getClientFCMNotificationConfigStatus: HTTP_REQUEST_STATUS.NOT_STARTED,

}


const ReducerUserPrivateRoom = (state = INIT_STATE, action) => {

    switch (action.type) {

        /** client voip notification config update **/
        case CLIENTS_SET_UPDATE_VOIP_NOTIFICATION_CONFIG: {
            return {
                ...state,
                currentClientVOIPNotificationConfig: {},
                updateClientVOIPNotificationConfigStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
            }
        }

        /** client voip notification config update : success **/
        case CLIENTS_SET_UPDATE_VOIP_NOTIFICATION_CONFIG_SUCCESS: {
            let currentClientVOIPNotificationConfig = {};

            if (typeof action.payload != "undefined" && typeof action.payload.data != "undefined") {
                currentClientVOIPNotificationConfig = action.payload.data;
            }

            return {
                ...state,
                alertMessage: action.payload.message,
                currentClientVOIPNotificationConfig: currentClientVOIPNotificationConfig,
                updateClientVOIPNotificationConfigStatus: HTTP_REQUEST_STATUS.SUCCESS
            }
        }

        /** client voip notification config update : error **/
        case CLIENTS_SET_UPDATE_VOIP_NOTIFICATION_CONFIG_ERROR: {
            let alertMessage = "";

            if (typeof action.payload.error != "undefined") {
                alertMessage = action.payload.error;
            }

            return {
                ...state,
                alertMessage: alertMessage,
                currentClientVOIPNotificationConfig: {},
                updateClientVOIPNotificationConfigStatus: HTTP_REQUEST_STATUS.ERROR
            }
        }

        /** client voip notification config update : none **/
        case CLIENTS_SET_UPDATE_VOIP_NOTIFICATION_CONFIG_NONE: {
            return {
                ...state,
                updateClientVOIPNotificationConfigStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }

        /** client voip notification config get **/
        case CLIENTS_GET_VOIP_NOTIFICATION_CONFIG: {
            return {
                ...state,
                currentClientVOIPNotificationConfig: {},
                getClientVOIPNotificationConfigStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
            }
        }

        /** client voip notification config get : success **/
        case CLIENTS_GET_VOIP_NOTIFICATION_CONFIG_SUCCESS: {

            let currentClientVOIPNotificationConfig = {};

            if (typeof action.payload != "undefined" && typeof action.payload.data != "undefined") {
                currentClientVOIPNotificationConfig = action.payload.data;
            }

            return {
                ...state,
                currentClientVOIPNotificationConfig: currentClientVOIPNotificationConfig,
                getClientVOIPNotificationConfigStatus: HTTP_REQUEST_STATUS.SUCCESS
            }
        }

        /** client voip notification config get : error **/
        case CLIENTS_GET_VOIP_NOTIFICATION_CONFIG_ERROR: {
            let alertMessage = "";

            if (typeof action.payload.error != "undefined") {
                alertMessage = action.payload.error;
            }

            return {
                ...state,
                alertMessage: alertMessage,
                currentClientVOIPNotificationConfig: {},
                getClientVOIPNotificationConfigStatus: HTTP_REQUEST_STATUS.ERROR
            }
        }

        /** client voip notification config get : none **/
        case CLIENTS_GET_VOIP_NOTIFICATION_CONFIG_NONE: {
            return {
                ...state,
                getClientVOIPNotificationConfigStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }

        case CLIENTS_SET_CLIENT_FEATURE_PRIVATE_ROOM_STATUS: {

            return {
                ...state,
                alertMessage: "",
                setClientFeaturesPrivateRoomStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
            }
        }

        case CLIENTS_SET_CLIENT_FEATURE_PRIVATE_ROOM_STATUS_SUCCESS: {

            return {
                ...state,
                alertMessage: action.payload.message,
                setClientFeaturesPrivateRoomStatus: HTTP_REQUEST_STATUS.SUCCESS
            }
        }

        case CLIENTS_SET_CLIENT_FEATURE_PRIVATE_ROOM_STATUS_ERROR: {

            let alertMessage = "";

            if (typeof action.payload.error != "undefined") {
                alertMessage = action.payload.error;
            }

            return {
                ...state,
                alertMessage: alertMessage,
                setClientFeaturesPrivateRoomStatus: HTTP_REQUEST_STATUS.ERROR
            }
        }

        case CLIENTS_SET_CLIENT_FEATURE_PRIVATE_ROOM_STATUS_NONE: {

            return {
                ...state,
                alertMessage: "",
                setClientFeaturesPrivateRoomStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }

        case CLIENTS_GET_CLIENT_FEATURES: {
            return {
                ...state,
                alertMessage: "",
                currentClientFeatures: {},
                clientGetFeaturesStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
            }
        }

        case CLIENTS_GET_CLIENT_FEATURES_SUCCESS: {

            let currentClientFeatures = {};

            if (typeof action.payload != "undefined" && typeof action.payload.data != "undefined") {
                currentClientFeatures = action.payload.data;
            }

            return {
                ...state,
                alertMessage: "",
                currentClientFeatures: currentClientFeatures,
                clientGetFeaturesStatus: HTTP_REQUEST_STATUS.SUCCESS
            }
        }

        case CLIENTS_GET_CLIENT_FEATURES_ERROR: {
            let alertMessage = "";

            if (typeof action.payload.error != "undefined") {
                alertMessage = action.payload.error;
            }

            return {
                ...state,
                alertMessage: alertMessage,
                currentClientFeatures: {},
                clientGetFeaturesStatus: HTTP_REQUEST_STATUS.ERROR
            }
        }

        case CLIENTS_GET_CLIENT_FEATURES_NONE: {
            return {
                ...state,
                alertMessage: "",
                clientGetFeaturesStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }

        case USER_PRIVATE_ROOM_SET_CURRENT_ROOM: {
            return {
                ...state,
                currentRoom: action.payload
            }
        }

        case USER_PRIVATE_ROOM_SAVE: {

            return {
                ...state,
                privateRoomSaveStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                alertMessage: ""
            }
        }


        case USER_PRIVATE_ROOM_SAVE_SUCCESS: {

            let currentRoom = state.currentRoom;

            if (typeof action.payload != "undefined" && typeof action.payload.data != "undefined") {
                currentRoom = parsePrivateRoomDetails(action.payload.data);
            }

            return {
                ...state,
                privateRoomSaveStatus: HTTP_REQUEST_STATUS.SUCCESS,
                alertMessage: action.payload.message,
                currentRoom: currentRoom
            }
        }

        case USER_PRIVATE_ROOM_SAVE_ERROR: {
            let alertMessage = "";

            if (typeof action.payload.error != "undefined") {
                alertMessage = action.payload.error;
            }

            return {
                ...state,
                privateRoomSaveStatus: HTTP_REQUEST_STATUS.ERROR,
                alertMessage: alertMessage
            }
        }

        case USER_PRIVATE_ROOM_SAVE_NONE: {

            return {
                ...state,
                privateRoomSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                alertMessage: ""
            }
        }

        case USER_PRIVATE_ROOM_DELETE: {

            return {
                ...state,
                privateRoomDeleteStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                alertMessage: ""
            }
        }


        case USER_PRIVATE_ROOM_DELETE_SUCCESS: {

            return {
                ...state,
                privateRoomDeleteStatus: HTTP_REQUEST_STATUS.SUCCESS,
                alertMessage: action.payload.message,
                currentRoom: {}
            }
        }

        case USER_PRIVATE_ROOM_DELETE_ERROR: {
            let alertMessage = "";

            if (typeof action.payload.error != "undefined") {
                alertMessage = action.payload.error;
            }

            return {
                ...state,
                privateRoomDeleteStatus: HTTP_REQUEST_STATUS.ERROR,
                alertMessage: alertMessage
            }
        }

        case USER_PRIVATE_ROOM_DELETE_NONE: {

            return {
                ...state,
                privateRoomDeleteStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                alertMessage: ""
            }
        }

        /** private room list **/
        case USER_PRIVATE_ROOM_GET_LIST: {
            let privateRoomList = state.privateRoomList;

            if (typeof action.payload != "undefined" && typeof action.payload.params != "undefined" && (typeof action.payload.params.start == "undefined" || action.payload.params.start == 0)) {
                privateRoomList = [];
            }

            return {
                ...state,
                privateRoomGetListStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                alertMessage: "",
                privateRoomList: privateRoomList
            }
        }

        case USER_PRIVATE_ROOM_GET_LIST_SUCCESS: {

            let privateRoomList = state.privateRoomList;


            if (typeof action.payload.data != "undefined" && typeof action.payload.data.rooms != "undefined") {

                let rooms = action.payload.data.rooms;

                for (let i = 0; i < rooms.length; i++) {
                    rooms[i] = parsePrivateRoomDetails(rooms[i]);
                    privateRoomList.push(rooms[i]);
                }
            }

            return {
                ...state,
                privateRoomGetListStatus: HTTP_REQUEST_STATUS.SUCCESS,
                alertMessage: action.payload.message,
                privateRoomList: privateRoomList
            }
        }

        case USER_PRIVATE_ROOM_GET_LIST_ERROR: {

            let alertMessage = "";

            if (typeof action.payload.error != "undefined") {
                alertMessage = action.payload.error;
            }

            return {
                ...state,
                privateRoomGetListStatus: HTTP_REQUEST_STATUS.ERROR,
                alertMessage: alertMessage,
                privateRoomList: []
            }
        }

        case USER_PRIVATE_ROOM_GET_LIST_NONE: {

            return {
                ...state,
                privateRoomGetListStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                alertMessage: ""
            }
        }

        default: {
            return {
                ...state
            }
        }


    }

}

function parsePrivateRoomDetails(room) {
    try {
        room["created_date_str"] = AppUtils.formatDateValue(room["created_date"]);
        room["start_time_str"] = AppUtils.formatUTCTimeStamp(room["start_time"] * 1000);
        room["end_time_str"] = AppUtils.formatUTCTimeStamp(room["end_time"] * 1000);
    } catch (ex) {
        console.error("parsePrivateRoomDetails : error : ", ex);
    }

    return room;
}

export default ReducerUserPrivateRoom;
