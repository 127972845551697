/** user private room : to get list **/
import {
    USER_PRIVATE_ROOM_DELETE,
    USER_PRIVATE_ROOM_DELETE_ERROR,
    USER_PRIVATE_ROOM_DELETE_NONE,
    USER_PRIVATE_ROOM_DELETE_SUCCESS,
    USER_PRIVATE_ROOM_GET_LIST,
    USER_PRIVATE_ROOM_GET_LIST_ERROR,
    USER_PRIVATE_ROOM_GET_LIST_NONE,
    USER_PRIVATE_ROOM_GET_LIST_SUCCESS,
    USER_PRIVATE_ROOM_SAVE,
    USER_PRIVATE_ROOM_SAVE_ERROR,
    USER_PRIVATE_ROOM_SAVE_NONE,
    USER_PRIVATE_ROOM_SAVE_SUCCESS,
    USER_PRIVATE_ROOM_SET_CURRENT_ROOM
} from "../../../constants/actions/private_room/userPrivateRoomActionTypes";

/** user private room : to get list **/
export const userPrivateRoomGetList = (payload) => {
    return {
        type: USER_PRIVATE_ROOM_GET_LIST,
        payload: payload
    };
};

/** user private room : to get list : success **/
export const userPrivateRoomGetListSuccess = (payload) => {
    return {
        type: USER_PRIVATE_ROOM_GET_LIST_SUCCESS,
        payload: payload
    };
};

/** user private room : to get list : error **/
export const userPrivateRoomGetListError = (payload) => {
    return {
        type: USER_PRIVATE_ROOM_GET_LIST_ERROR,
        payload: payload
    };
};

/** user private room : to get list : none **/
export const userPrivateRoomGetListNone = (payload) => {
    return {
        type: USER_PRIVATE_ROOM_GET_LIST_NONE,
        payload: payload
    };
};

/** user private room : to save private room  **/
export const userPrivateRoomSaveRoom = (payload) => {
    return {
        type: USER_PRIVATE_ROOM_SAVE,
        payload: payload
    };
};

/** user private room : to save private room : success **/
export const userPrivateRoomSaveRoomSuccess = (payload) => {
    return {
        type: USER_PRIVATE_ROOM_SAVE_SUCCESS,
        payload: payload
    };
};

/** user private room : to save private room : error **/
export const userPrivateRoomSaveRoomError = (payload) => {
    return {
        type: USER_PRIVATE_ROOM_SAVE_ERROR,
        payload: payload
    };
};

/** user private room : to save private room : none **/
export const userPrivateRoomSaveRoomNone = (payload) => {
    return {
        type: USER_PRIVATE_ROOM_SAVE_NONE,
        payload: payload
    };
};

/** user private room : to delete private room  **/
export const userPrivateRoomDeleteRoom = (payload) => {
    return {
        type: USER_PRIVATE_ROOM_DELETE,
        payload: payload
    };
};

/** user private room : to delete private room : success **/
export const userPrivateRoomDeleteRoomSuccess = (payload) => {
    return {
        type: USER_PRIVATE_ROOM_DELETE_SUCCESS,
        payload: payload
    };
};

/** user private room : to delete private room : error **/
export const userPrivateRoomDeleteRoomError = (payload) => {
    return {
        type: USER_PRIVATE_ROOM_DELETE_ERROR,
        payload: payload
    };
};

/** user private room : to delete private room : none **/
export const userPrivateRoomDeleteRoomNone = (payload) => {
    return {
        type: USER_PRIVATE_ROOM_DELETE_NONE,
        payload: payload
    };
};

/** user private room : to set current room **/
export const setViewCurrentPrivateRoom = (payload) => {
    return {
        type: USER_PRIVATE_ROOM_SET_CURRENT_ROOM,
        payload: payload
    };
};