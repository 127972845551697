import {
    GROUP_MESSAGES_CLEAR_MESSAGES_FOR_USER,
    GROUP_MESSAGES_CLEAR_MESSAGES_FOR_USER_ERROR,
    GROUP_MESSAGES_CLEAR_MESSAGES_FOR_USER_NONE,
    GROUP_MESSAGES_CLEAR_MESSAGES_FOR_USER_SUCCESS,
    GROUP_MESSAGES_DELETE_MESSAGES_FOR_USER,
    GROUP_MESSAGES_DELETE_MESSAGES_FOR_USER_ERROR,
    GROUP_MESSAGES_DELETE_MESSAGES_FOR_USER_NONE,
    GROUP_MESSAGES_DELETE_MESSAGES_FOR_USER_SUCCESS,
    GROUP_MESSAGES_EDIT_MESSAGE,
    GROUP_MESSAGES_EDIT_MESSAGE_ERROR,
    GROUP_MESSAGES_EDIT_MESSAGE_NONE,
    GROUP_MESSAGES_EDIT_MESSAGE_SUCCESS,
    GROUP_MESSAGES_FILTER_MESSAGES_BY_FIELD,
    GROUP_MESSAGES_FILTER_MESSAGES_BY_FIELD_ERROR,
    GROUP_MESSAGES_FILTER_MESSAGES_BY_FIELD_NONE,
    GROUP_MESSAGES_FILTER_MESSAGES_BY_FIELD_SUCCESS,
    GROUP_MESSAGES_GET_COMMON_CHANNELS_BETWEEN_USERS,
    GROUP_MESSAGES_GET_COMMON_CHANNELS_BETWEEN_USERS_ERROR,
    GROUP_MESSAGES_GET_COMMON_CHANNELS_BETWEEN_USERS_NONE,
    GROUP_MESSAGES_GET_COMMON_CHANNELS_BETWEEN_USERS_SUCCESS,
    GROUP_MESSAGES_GET_MESSAGE_HISTORY,
    GROUP_MESSAGES_GET_MESSAGE_HISTORY_ERROR,
    GROUP_MESSAGES_GET_MESSAGE_HISTORY_NONE,
    GROUP_MESSAGES_GET_MESSAGE_HISTORY_SUCCESS,
    GROUP_MESSAGES_MARK_MESSAGE_AS_DELIVERED,
    GROUP_MESSAGES_MARK_MESSAGE_AS_DELIVERED_ERROR,
    GROUP_MESSAGES_MARK_MESSAGE_AS_DELIVERED_NONE,
    GROUP_MESSAGES_MARK_MESSAGE_AS_DELIVERED_SUCCESS,
    GROUP_MESSAGES_MARK_MESSAGE_AS_UNREAD,
    GROUP_MESSAGES_MARK_MESSAGE_AS_UNREAD_ERROR,
    GROUP_MESSAGES_MARK_MESSAGE_AS_UNREAD_NONE,
    GROUP_MESSAGES_MARK_MESSAGE_AS_UNREAD_SUCCESS,
    GROUP_MESSAGES_MESSAGE_DELETE,
    GROUP_MESSAGES_MESSAGE_DELETE_ERROR,
    GROUP_MESSAGES_MESSAGE_DELETE_NONE,
    GROUP_MESSAGES_MESSAGE_DELETE_SUCCESS,
    GROUP_MESSAGES_MESSAGE_FORWARD,
    GROUP_MESSAGES_MESSAGE_FORWARD_ERROR,
    GROUP_MESSAGES_MESSAGE_FORWARD_NONE,
    GROUP_MESSAGES_MESSAGE_FORWARD_SUCCESS,
    GROUP_MESSAGES_MESSAGE_LIST,
    GROUP_MESSAGES_MESSAGE_LIST_ERROR,
    GROUP_MESSAGES_MESSAGE_LIST_NONE,
    GROUP_MESSAGES_MESSAGE_LIST_SUCCESS,
    GROUP_MESSAGES_MESSAGE_READ,
    GROUP_MESSAGES_MESSAGE_READ_ALL,
    GROUP_MESSAGES_MESSAGE_READ_ALL_ERROR,
    GROUP_MESSAGES_MESSAGE_READ_ALL_NONE,
    GROUP_MESSAGES_MESSAGE_READ_ALL_SUCCESS,
    GROUP_MESSAGES_MESSAGE_READ_ERROR,
    GROUP_MESSAGES_MESSAGE_READ_NONE,
    GROUP_MESSAGES_MESSAGE_READ_SUCCESS,
    GROUP_MESSAGES_MESSAGE_SEND,
    GROUP_MESSAGES_MESSAGE_SEND_ERROR,
    GROUP_MESSAGES_MESSAGE_SEND_NONE,
    GROUP_MESSAGES_MESSAGE_SEND_SUCCESS,
    GROUP_MESSAGES_REACT_ON_MESSAGE,
    GROUP_MESSAGES_REACT_ON_MESSAGE_ERROR,
    GROUP_MESSAGES_REACT_ON_MESSAGE_NONE,
    GROUP_MESSAGES_REACT_ON_MESSAGE_SUCCESS,
} from "../../../constants/actions/chat/ChatActionTypes";

/** group messages : message send **/
export const groupMessagesMessageSend = (payload) => {
    return {
        type: GROUP_MESSAGES_MESSAGE_SEND,
        payload: payload
    };
};

/** group messages : message send : success **/
export const groupMessagesMessageSendSuccess = (payload) => {
    return {
        type: GROUP_MESSAGES_MESSAGE_SEND_SUCCESS,
        payload: payload
    };
};

/** group messages : message send : error **/
export const groupMessagesMessageSendError = (payload) => {
    return {
        type: GROUP_MESSAGES_MESSAGE_SEND_ERROR,
        payload: payload
    };
};

/** group messages : message send : none **/
export const groupMessagesMessageSendNone = (payload) => {
    return {
        type: GROUP_MESSAGES_MESSAGE_SEND_NONE,
        payload: payload
    };
};


/** group messages : message delete **/
export const groupMessagesMessageDelete = (payload) => {
    return {
        type: GROUP_MESSAGES_MESSAGE_DELETE,
        payload: payload
    };
};

/** group messages : message delete : success **/
export const groupMessagesMessageDeleteSuccess = (payload) => {
    return {
        type: GROUP_MESSAGES_MESSAGE_DELETE_SUCCESS,
        payload: payload
    };
};

/** group messages : message delete : error **/
export const groupMessagesMessageDeleteError = (payload) => {
    return {
        type: GROUP_MESSAGES_MESSAGE_DELETE_ERROR,
        payload: payload
    };
};

/** group messages : message delete : none **/
export const groupMessagesMessageDeleteNone = (payload) => {
    return {
        type: GROUP_MESSAGES_MESSAGE_DELETE_NONE,
        payload: payload
    };
};


/** group messages : message read **/
export const groupMessagesMessageRead = (payload) => {
    return {
        type: GROUP_MESSAGES_MESSAGE_READ,
        payload: payload
    };
};

/** group messages : message read : success **/
export const groupMessagesMessageReadSuccess = (payload) => {
    return {
        type: GROUP_MESSAGES_MESSAGE_READ_SUCCESS,
        payload: payload
    };
};

/** group messages : message read : error **/
export const groupMessagesMessageReadError = (payload) => {
    return {
        type: GROUP_MESSAGES_MESSAGE_READ_ERROR,
        payload: payload
    };
};

/** group messages : message read : none **/
export const groupMessagesMessageReadNone = (payload) => {
    return {
        type: GROUP_MESSAGES_MESSAGE_READ_NONE,
        payload: payload
    };
};


/** group messages : message list **/
export const groupMessagesMessageList = (payload) => {
    return {
        type: GROUP_MESSAGES_MESSAGE_LIST,
        payload: payload
    };
};

/** group messages : message list : success **/
export const groupMessagesMessageListSuccess = (payload) => {
    return {
        type: GROUP_MESSAGES_MESSAGE_LIST_SUCCESS,
        payload: payload
    };
};

/** group messages : message list : error **/
export const groupMessagesMessageListError = (payload) => {
    return {
        type: GROUP_MESSAGES_MESSAGE_LIST_ERROR,
        payload: payload
    };
};

/** group messages : message list : none **/
export const groupMessagesMessageListNone = (payload) => {
    return {
        type: GROUP_MESSAGES_MESSAGE_LIST_NONE,
        payload: payload
    };
};

/** group messages : message read all **/
export const groupMessagesMessageReadAll = (payload) => {
    return {
        type: GROUP_MESSAGES_MESSAGE_READ_ALL,
        payload: payload
    };
};

/** group messages : message read all : success **/
export const groupMessagesMessageReadAllSuccess = (payload) => {
    return {
        type: GROUP_MESSAGES_MESSAGE_READ_ALL_SUCCESS,
        payload: payload
    };
};

/** group messages : message read all : error **/
export const groupMessagesMessageReadAllError = (payload) => {
    return {
        type: GROUP_MESSAGES_MESSAGE_READ_ALL_ERROR,
        payload: payload
    };
};

/** group messages : message read all : none **/
export const groupMessagesMessageReadAllNone = (payload) => {
    return {
        type: GROUP_MESSAGES_MESSAGE_READ_ALL_NONE,
        payload: payload
    };
};

/** group messages : edit message **/
export const groupMessagesEditMessage = (payload) => {
    return {
        type: GROUP_MESSAGES_EDIT_MESSAGE,
        payload: payload
    };
};

/** group messages : edit message  : success **/
export const groupMessagesEditMessageSuccess = (payload) => {
    return {
        type: GROUP_MESSAGES_EDIT_MESSAGE_SUCCESS,
        payload: payload
    };
};

/** group messages : edit message  : error **/
export const groupMessagesEditMessageError = (payload) => {
    return {
        type: GROUP_MESSAGES_EDIT_MESSAGE_ERROR,
        payload: payload
    };
};

/** group messages : edit message  : none **/
export const groupMessagesEditMessageNone = (payload) => {
    return {
        type: GROUP_MESSAGES_EDIT_MESSAGE_NONE,
        payload: payload
    };
};


/** group messages : mark message as unread **/
export const groupMessagesMarkAsUnread = (payload) => {
    return {
        type: GROUP_MESSAGES_MARK_MESSAGE_AS_UNREAD,
        payload: payload
    };
};

/** group messages : mark message as unread  : success **/
export const groupMessagesMarkAsUnreadSuccess = (payload) => {
    return {
        type: GROUP_MESSAGES_MARK_MESSAGE_AS_UNREAD_SUCCESS,
        payload: payload
    };
};

/** group messages : mark message as unread  : error **/
export const groupMessagesMarkAsUnreadError = (payload) => {
    return {
        type: GROUP_MESSAGES_MARK_MESSAGE_AS_UNREAD_ERROR,
        payload: payload
    };
};

/** group messages : mark message as unread  : none **/
export const groupMessagesMarkAsUnreadNone = (payload) => {
    return {
        type: GROUP_MESSAGES_MARK_MESSAGE_AS_UNREAD_NONE,
        payload: payload
    };
};

/** group messages : mark message as delivered **/
export const groupMessagesMarkAsDelivered = (payload) => {
    return {
        type: GROUP_MESSAGES_MARK_MESSAGE_AS_DELIVERED,
        payload: payload
    };
};

/** group messages : mark message as delivered  : success **/
export const groupMessagesMarkAsDeliveredSuccess = (payload) => {
    return {
        type: GROUP_MESSAGES_MARK_MESSAGE_AS_DELIVERED_SUCCESS,
        payload: payload
    };
};

/** group messages : mark message as delivered  : error **/
export const groupMessagesMarkAsDeliveredError = (payload) => {
    return {
        type: GROUP_MESSAGES_MARK_MESSAGE_AS_DELIVERED_ERROR,
        payload: payload
    };
};

/** group messages : mark message as delivered  : none **/
export const groupMessagesMarkAsDeliveredNone = (payload) => {
    return {
        type: GROUP_MESSAGES_MARK_MESSAGE_AS_DELIVERED_NONE,
        payload: payload
    };
};

/** channel or group messages : delete messages for user **/
export const groupMessagesDeleteMessagesForUser = (payload) => {
    return {
        type: GROUP_MESSAGES_DELETE_MESSAGES_FOR_USER,
        payload: payload
    };
};

/** channel or group messages : delete messages for user  : success **/
export const groupMessagesDeleteMessagesForUserSuccess = (payload) => {
    return {
        type: GROUP_MESSAGES_DELETE_MESSAGES_FOR_USER_SUCCESS,
        payload: payload
    };
};

/** channel or group messages : delete messages for user  : error **/
export const groupMessagesDeleteMessagesForUserError = (payload) => {
    return {
        type: GROUP_MESSAGES_DELETE_MESSAGES_FOR_USER_ERROR,
        payload: payload
    };
};

/** channel or group messages : delete messages for user  : none **/
export const groupMessagesDeleteMessagesForUserNone = (payload) => {
    return {
        type: GROUP_MESSAGES_DELETE_MESSAGES_FOR_USER_NONE,
        payload: payload
    };
};

/** channel or group messages : clear messages for user **/
export const groupMessagesClearMessagesForUser = (payload) => {
    return {
        type: GROUP_MESSAGES_CLEAR_MESSAGES_FOR_USER,
        payload: payload
    };
};

/** channel or group messages : clear messages for user  : success **/
export const groupMessagesClearMessagesForUserSuccess = (payload) => {
    return {
        type: GROUP_MESSAGES_CLEAR_MESSAGES_FOR_USER_SUCCESS,
        payload: payload
    };
};

/** channel or group messages : clear messages for user  : error **/
export const groupMessagesClearMessagesForUserError = (payload) => {
    return {
        type: GROUP_MESSAGES_CLEAR_MESSAGES_FOR_USER_ERROR,
        payload: payload
    };
};

/** channel or group messages : clear messages for user  : none **/
export const groupMessagesClearMessagesForUserNone = (payload) => {
    return {
        type: GROUP_MESSAGES_CLEAR_MESSAGES_FOR_USER_NONE,
        payload: payload
    };
};

/** channel or group messages : react on message **/
export const groupMessagesReactOnMessage = (payload) => {
    return {
        type: GROUP_MESSAGES_REACT_ON_MESSAGE,
        payload: payload
    };
};

/** channel or group messages : react on message  : success **/
export const groupMessagesReactOnMessageSuccess = (payload) => {
    return {
        type: GROUP_MESSAGES_REACT_ON_MESSAGE_SUCCESS,
        payload: payload
    };
};

/** channel or group messages : react on message  : error **/
export const groupMessagesReactOnMessageError = (payload) => {
    return {
        type: GROUP_MESSAGES_REACT_ON_MESSAGE_ERROR,
        payload: payload
    };
};

/** channel or group messages : react on message  : none **/
export const groupMessagesReactOnMessageNone = (payload) => {
    return {
        type: GROUP_MESSAGES_REACT_ON_MESSAGE_NONE,
        payload: payload
    };
};

/** channel or group messages : to get the reply message history **/
export const groupMessagesGetMessageHistory = (payload) => {
    return {
        type: GROUP_MESSAGES_GET_MESSAGE_HISTORY,
        payload: payload
    };
};

/** channel or group messages : to get the reply message history  : success **/
export const groupMessagesGetMessageHistorySuccess = (payload) => {
    return {
        type: GROUP_MESSAGES_GET_MESSAGE_HISTORY_SUCCESS,
        payload: payload
    };
};

/** channel or group messages : to get the reply message history  : error **/
export const groupMessagesGetMessageHistoryError = (payload) => {
    return {
        type: GROUP_MESSAGES_GET_MESSAGE_HISTORY_ERROR,
        payload: payload
    };
};

/** channel or group messages : to get the reply message history  : none **/
export const groupMessagesGetMessageHistoryNone = (payload) => {
    return {
        type: GROUP_MESSAGES_GET_MESSAGE_HISTORY_NONE,
        payload: payload
    };
};


/** channel or group messages : to filter messages by message field **/
export const groupMessagesFilterMessageByField = (payload) => {
    return {
        type: GROUP_MESSAGES_FILTER_MESSAGES_BY_FIELD,
        payload: payload
    };
};

/** channel or group messages : to filter messages by message field  : success **/
export const groupMessagesFilterMessageByFieldSuccess = (payload) => {
    return {
        type: GROUP_MESSAGES_FILTER_MESSAGES_BY_FIELD_SUCCESS,
        payload: payload
    };
};

/** channel or group messages : to filter messages by message field  : error **/
export const groupMessagesFilterMessageByFieldError = (payload) => {
    return {
        type: GROUP_MESSAGES_FILTER_MESSAGES_BY_FIELD_ERROR,
        payload: payload
    };
};

/** channel or group messages : to filter messages by message field  : none **/
export const groupMessagesFilterMessageByFieldNone = (payload) => {
    return {
        type: GROUP_MESSAGES_FILTER_MESSAGES_BY_FIELD_NONE,
        payload: payload
    };
};

/** channel or group messages : get common channels between users **/
export const groupMessagesGetCommonChannelsBetweenUsers = (payload) => {
    return {
        type: GROUP_MESSAGES_GET_COMMON_CHANNELS_BETWEEN_USERS,
        payload: payload
    };
};

/** channel or group messages : get common channels between users  : success **/
export const groupMessagesGetCommonChannelsBetweenUsersSuccess = (payload) => {
    return {
        type: GROUP_MESSAGES_GET_COMMON_CHANNELS_BETWEEN_USERS_SUCCESS,
        payload: payload
    };
};

/** channel or group messages : get common channels between users  : error **/
export const groupMessagesGetCommonChannelsBetweenUsersError = (payload) => {
    return {
        type: GROUP_MESSAGES_GET_COMMON_CHANNELS_BETWEEN_USERS_ERROR,
        payload: payload
    };
};

/** channel or group messages : get common channels between users  : none **/
export const groupMessagesGetCommonChannelsBetweenUsersNone = (payload) => {
    return {
        type: GROUP_MESSAGES_GET_COMMON_CHANNELS_BETWEEN_USERS_NONE,
        payload: payload
    };
};

/** group messages : messages forward **/
export const groupMessagesMessageForward = (payload) => {
    return {
        type: GROUP_MESSAGES_MESSAGE_FORWARD,
        payload: payload
    };
};

/** group messages : messages forward : success **/
export const groupMessagesMessageForwardSuccess = (payload) => {
    return {
        type: GROUP_MESSAGES_MESSAGE_FORWARD_SUCCESS,
        payload: payload
    };
};

/** group messages : messages forward : error **/
export const groupMessagesMessageForwardError = (payload) => {
    return {
        type: GROUP_MESSAGES_MESSAGE_FORWARD_ERROR,
        payload: payload
    };
};

/** group messages : messages forward : none **/
export const groupMessagesMessageForwardNone = (payload) => {
    return {
        type: GROUP_MESSAGES_MESSAGE_FORWARD_NONE,
        payload: payload
    };
};
