export const notifications = [
    
    {
        image: "https://via.placeholder.com/150",
        title: "Stella Johnson has recently posted an album",
        time: "4:10 PM",
        icon: "thumb-up gx-text-blue",
    }, {
        image: "https://via.placeholder.com/150",
        title: "Alex Brown has shared Martin Guptil's post",
        time: "5:18 PM",
        icon: "chat gx-text-grey",
    }, {
        image: "https://via.placeholder.com/150",
        title: "Domnic Brown has sent you a group invitation for Global Health",
        time: "5:36 PM",
        icon: "birthday text-info",
    }, {
        image: "https://via.placeholder.com/150",
        title: "John Smith has birthday today",
        time: "5:54 PM",
        icon: "birthday gx-text-warning",
    }, {
        image: "https://via.placeholder.com/150",
        title: "Chris has updated his profile picture",
        time: "5:25 PM",
        icon: "profile gx-text-grey",
    }
];
