import {
    CHAT_GET_ALL_OTHER_CONTACTS,
    CHAT_GET_ALL_OTHER_CONTACTS_ERROR,
    CHAT_GET_ALL_OTHER_CONTACTS_NONE,
    CHAT_GET_ALL_OTHER_CONTACTS_SUCCESS
} from "../../../constants/actions/chat/ChatActionTypes";

/** to get all other user names **/
export const getChatOtherUsers = (payload) => {
    return {
        type: CHAT_GET_ALL_OTHER_CONTACTS,
        payload: payload
    };
};

/** to get all other user names : success **/
export const getChatOtherUsersSuccess = (payload) => {
    return {
        type: CHAT_GET_ALL_OTHER_CONTACTS_SUCCESS,
        payload: payload
    };
};

/** to get all other user names : error **/
export const getChatOtherUsersError = (payload) => {
    return {
        type: CHAT_GET_ALL_OTHER_CONTACTS_ERROR,
        payload: payload
    };
};

/** to get all other user names : none **/
export const getChatOtherUsersNone = (payload) => {
    return {
        type: CHAT_GET_ALL_OTHER_CONTACTS_NONE,
        payload: payload
    };
};

