import {
    USERS_DEACTIVATE_USER, USERS_DEACTIVATE_USER_ERROR, USERS_DEACTIVATE_USER_NONE, USERS_DEACTIVATE_USER_SUCCESS,
    USERS_FILTER_USERS,
    USERS_GET_ALL_USERS,
    USERS_GET_ALL_USERS_ERROR,
    USERS_GET_ALL_USERS_NONE,
    USERS_GET_ALL_USERS_SUCCESS, USERS_SAVE_USER, USERS_SAVE_USER_ERROR, USERS_SAVE_USER_NONE, USERS_SAVE_USER_SUCCESS,
    USERS_SET_CURRENT_USER
} from "../../../constants/actions/users/actionTypesUsers";

/** users : to get all user list **/
export const usersGetAllUsers = (payload) => {
    return {
        type: USERS_GET_ALL_USERS,
        payload: payload
    };
};

/** users : to get all user list : success **/
export const usersGetAllUsersSuccess = (payload) => {
    return {
        type: USERS_GET_ALL_USERS_SUCCESS,
        payload: payload
    };
};

/** users : to get all user list : error **/
export const usersGetAllUsersError = (payload) => {
    return {
        type: USERS_GET_ALL_USERS_ERROR,
        payload: payload
    };
};

/** users : to get all user list : none **/
export const usersGetAllUsersNone = (payload) => {
    return {
        type: USERS_GET_ALL_USERS_NONE,
        payload: payload
    };
};

/** users : to save user **/
export const usersSaveUser = (payload) => {
    return {
        type: USERS_SAVE_USER,
        payload: payload
    };
};

/** users : to save user : success **/
export const usersSaveUserSuccess = (payload) => {
    return {
        type: USERS_SAVE_USER_SUCCESS,
        payload: payload
    };
};

/** users : to save user : error **/
export const usersSaveUserError = (payload) => {
    return {
        type: USERS_SAVE_USER_ERROR,
        payload: payload
    };
};

/** users : to save user : none **/
export const usersSaveUserNone = (payload) => {
    return {
        type: USERS_SAVE_USER_NONE,
        payload: payload
    };
};

/** users : deactivate user **/
export const usersDeactivateUser = (payload) => {
    return {
        type: USERS_DEACTIVATE_USER,
        payload: payload
    };
};

/** users : deactivate user : success **/
export const usersDeactivateUserSuccess = (payload) => {
    return {
        type: USERS_DEACTIVATE_USER_SUCCESS,
        payload: payload
    };
};

/** users : deactivate user : error **/
export const usersDeactivateUserError = (payload) => {
    return {
        type: USERS_DEACTIVATE_USER_ERROR,
        payload: payload
    };
};

/** users : deactivate user : none **/
export const usersDeactivateUserNone = (payload) => {
    return {
        type: USERS_DEACTIVATE_USER_NONE,
        payload: payload
    };
};


/** users : to set current user for view page **/
export const setViewCurrentUser = (payload) => {
    return {
        type: USERS_SET_CURRENT_USER,
        payload: payload
    };
};

/** users : to filter users **/
export const filterUsersList = (payload) => {
    return {
        type: USERS_FILTER_USERS,
        payload: payload
    };
};
