import {
    SUBSCRIPTION_MANAGEMENT_SUBSCRIBE,
    SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_BY_ME,
    SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_BY_ME_ERROR,
    SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_BY_ME_NONE,
    SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_BY_ME_SUCCESS,
    SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_ERROR,
    SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_NONE,
    SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_SUCCESS,
    SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_TO_ME,
    SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_TO_ME_ERROR,
    SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_TO_ME_NONE,
    SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_TO_ME_SUCCESS,
    SUBSCRIPTION_MANAGEMENT_UNSUBSCRIBE,
    SUBSCRIPTION_MANAGEMENT_UNSUBSCRIBE_ERROR,
    SUBSCRIPTION_MANAGEMENT_UNSUBSCRIBE_NONE,
    SUBSCRIPTION_MANAGEMENT_UNSUBSCRIBE_SUCCESS,
} from "../../../constants/actions/chat/ChatActionTypes";

/** subscribe management : subscribe **/
export const subscribeManagementUserSubscribe = (payload) => {
    return {
        type: SUBSCRIPTION_MANAGEMENT_SUBSCRIBE,
        payload: payload
    };
};

/** subscribe management : subscribe : success **/
export const subscribeManagementUserSubscribeSuccess = (payload) => {
    return {
        type: SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_SUCCESS,
        payload: payload
    };
};

/** subscribe management : subscribe : error **/
export const subscribeManagementUserSubscribeError = (payload) => {
    return {
        type: SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_ERROR,
        payload: payload
    };
};

/** subscribe management : subscribe : none **/
export const subscribeManagementUserSubscribeNone = (payload) => {
    return {
        type: SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_NONE,
        payload: payload
    };
};


/** subscribe management : unsubscribe **/
export const subscribeManagementUserUnsubscribe = (payload) => {
    return {
        type: SUBSCRIPTION_MANAGEMENT_UNSUBSCRIBE,
        payload: payload
    };
};

/** subscribe management : unsubscribe : success **/
export const subscribeManagementUserUnsubscribeSuccess = (payload) => {
    return {
        type: SUBSCRIPTION_MANAGEMENT_UNSUBSCRIBE_SUCCESS,
        payload: payload
    };
};

/** subscribe management : unsubscribe : error **/
export const subscribeManagementUserUnsubscribeError = (payload) => {
    return {
        type: SUBSCRIPTION_MANAGEMENT_UNSUBSCRIBE_ERROR,
        payload: payload
    };
};

/** subscribe management : unsubscribe : none **/
export const subscribeManagementUserUnsubscribeNone = (payload) => {
    return {
        type: SUBSCRIPTION_MANAGEMENT_UNSUBSCRIBE_NONE,
        payload: payload
    };
};


/** subscribe management : subscribe by me **/
export const subscribeManagementUserSubscribeByMe = (payload) => {
    return {
        type: SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_BY_ME,
        payload: payload
    };
};

/** subscribe management : subscribe by me : success **/
export const subscribeManagementUserSubscribeByMeSuccess = (payload) => {
    return {
        type: SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_BY_ME_SUCCESS,
        payload: payload
    };
};

/** subscribe management : subscribe by me : error **/
export const subscribeManagementUserSubscribeByMeError = (payload) => {
    return {
        type: SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_BY_ME_ERROR,
        payload: payload
    };
};

/** subscribe management : subscribe by me : none **/
export const subscribeManagementUserSubscribeByMeNone = (payload) => {
    return {
        type: SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_BY_ME_NONE,
        payload: payload
    };
};


/** subscribe management : subscribe to me **/
export const subscribeManagementUserSubscribeToMe = (payload) => {
    return {
        type: SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_TO_ME,
        payload: payload
    };
};

/** subscribe management : subscribe to me : success **/
export const subscribeManagementUserSubscribeToMeSuccess = (payload) => {
    return {
        type: SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_TO_ME_SUCCESS,
        payload: payload
    };
};

/** subscribe management : subscribe to me : error **/
export const subscribeManagementUserSubscribeToMeError = (payload) => {
    return {
        type: SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_TO_ME_ERROR,
        payload: payload
    };
};

/** subscribe management : subscribe to me : none **/
export const subscribeManagementUserSubscribeToMeNone = (payload) => {
    return {
        type: SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_TO_ME_NONE,
        payload: payload
    };
};
