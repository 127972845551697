import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import axios from "axios";
import {
    URL_USERS_GET_ALL_OTHER_CONTACT_NAMES
} from "../../../util/tribe/urls";
import {
    CHAT_GET_ALL_OTHER_CONTACTS
} from "../../../constants/actions/chat/ChatActionTypes";
import AppUtils from "../../../util/tribe/utils/appUtils";
import {
    getChatOtherUsersError,
    getChatOtherUsersSuccess,
} from "../../actions";

/** to get all other user details **/
const httpGetChatOtherUserNames = async (data) =>
    await axios.post(URL_USERS_GET_ALL_OTHER_CONTACT_NAMES, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });


function* handleHttpGetChatOtherUserNames({payload}) {
    try {
        const result = yield call(httpGetChatOtherUserNames, payload);
        if (result.message) {
            yield put(getChatOtherUsersError(result.message));
        } else {
            yield put(getChatOtherUsersSuccess(result.data));
        }
    } catch (error) {
        yield put(getChatOtherUsersError(error));
    }
}


export function* getChatOtherUserNames() {
    yield takeEvery(CHAT_GET_ALL_OTHER_CONTACTS, handleHttpGetChatOtherUserNames);
}


export default function* rootSaga() {
    yield all([
        fork(getChatOtherUserNames),
    ])
}
