import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import axios from "axios";
import {
  URL_CHAT_PERSONAL_MESSAGE_DELETE_MULTIPLE,
  URL_CHAT_PERSONAL_MESSAGE_DELIVERED,
  URL_CHAT_PERSONAL_MESSAGES_CLEAR,
  URL_CHAT_PERSONAL_MESSAGES_DELETE,
  URL_CHAT_PERSONAL_MESSAGES_EDIT,
  URL_CHAT_PERSONAL_MESSAGES_FILTER_MESSAGES_BY_FIELD,
  URL_CHAT_PERSONAL_MESSAGES_FORWARD,
  URL_CHAT_PERSONAL_MESSAGES_GET_MESSAGE_HISTORY,
  URL_CHAT_PERSONAL_MESSAGES_LIST, URL_CHAT_PERSONAL_MESSAGES_MUTE_UNMUTE_NOTIFICATIONS,
  URL_CHAT_PERSONAL_MESSAGES_PURGE,
  URL_CHAT_PERSONAL_MESSAGES_REACT_ON_MESSAGE,
  URL_CHAT_PERSONAL_MESSAGES_READ,
  URL_CHAT_PERSONAL_MESSAGES_READ_ALL,
  URL_CHAT_PERSONAL_MESSAGES_SEND,
  URL_CHAT_PERSONAL_MESSAGES_UNREAD
} from "../../../util/tribe/urls";
import {
  CHAT_DM_MESSAGE_CLEAR_MESSAGES,
  CHAT_DM_MESSAGE_DELETE_MULTIPLE_MESSAGES,
  CHAT_DM_MESSAGE_FILTER_MESSAGES_BY_FIELD,
  CHAT_DM_MESSAGE_GET_MESSAGE_HISTORY,
  CHAT_DM_MESSAGE_MARK_AS_DELIVERED,
  CHAT_DM_MESSAGE_MARK_AS_UNREAD,
  CHAT_DM_MESSAGE_PURGE_MESSAGES,
  CHAT_DM_MESSAGE_REACT_ON_MESSAGE,
  PERSONAL_MESSAGES_MESSAGE_DELETE,
  PERSONAL_MESSAGES_MESSAGE_EDIT,
  PERSONAL_MESSAGES_MESSAGE_FORWARD,
  PERSONAL_MESSAGES_MESSAGE_LIST,
  PERSONAL_MESSAGES_MESSAGE_READ,
  PERSONAL_MESSAGES_MESSAGE_READ_ALL,
  PERSONAL_MESSAGES_MESSAGE_SEND, PERSONAL_MESSAGES_MUTE_UNMUTE_NOTIFICATIONS
} from "../../../constants/actions/chat/ChatActionTypes";
import AppUtils from "../../../util/tribe/utils/appUtils";
import {
  personalMessagesClearMessagesError,
  personalMessagesClearMessagesSuccess,
  personalMessagesDeleteMultipleMessagesError,
  personalMessagesDeleteMultipleMessagesSuccess,
  personalMessagesFilterMessagesByFieldError,
  personalMessagesFilterMessagesByFieldSuccess,
  personalMessagesGetMessageHistoryError,
  personalMessagesGetMessageHistorySuccess,
  personalMessagesMarkMessageAsDeliveredError,
  personalMessagesMarkMessageAsDeliveredSuccess,
  personalMessagesMarkMessageAsUnreadError,
  personalMessagesMarkMessageAsUnreadSuccess,
  personalMessagesMessageDeleteError,
  personalMessagesMessageDeleteSuccess,
  personalMessagesMessageEditError,
  personalMessagesMessageEditSuccess,
  personalMessagesMessageListError,
  personalMessagesMessageListSuccess,
  personalMessagesMessageReadAllError,
  personalMessagesMessageReadAllSuccess,
  personalMessagesMessageReadError,
  personalMessagesMessageReadSuccess,
  personalMessagesMessageSendError,
  personalMessagesMessageSendSuccess,
  personalMessagesMessagesForwardError,
  personalMessagesMessagesForwardSuccess, personalMessagesMuteUnmuteNotificationsError, personalMessagesMuteUnmuteNotificationsSuccess,
  personalMessagesPurgeMessagesError,
  personalMessagesPurgeMessagesSuccess,
  personalMessagesReactOnMessageError,
  personalMessagesReactOnMessageSuccess,
} from "../../actions";

/** http : personal messages : message send **/
const httpChatPersonalMessagesMessageSend = async (data) =>
    await axios.post(URL_CHAT_PERSONAL_MESSAGES_SEND, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : personal messages : message edit **/
const httpChatPersonalMessagesMessageEdit = async (data) =>
    await axios.post(URL_CHAT_PERSONAL_MESSAGES_EDIT, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : personal messages : message delete **/
const httpChatPersonalMessagesMessageDelete = async (data) =>
    await axios.post(URL_CHAT_PERSONAL_MESSAGES_DELETE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : personal messages : message read **/
const httpChatPersonalMessagesMessageRead = async (data) =>
    await axios.post(URL_CHAT_PERSONAL_MESSAGES_READ, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });


/** http : personal messages : message read all **/
const httpChatPersonalMessagesMessageReadAll = async (data) =>
    await axios.post(URL_CHAT_PERSONAL_MESSAGES_READ_ALL, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });


/** http : personal messages : message list **/
const httpChatPersonalMessagesMessageList = async (data) =>
    await axios.post(URL_CHAT_PERSONAL_MESSAGES_LIST, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });


/** http : personal messages : mark message as unread **/
const httpChatPersonalMessagesMarkMessageAsUnread = async (data) =>
    await axios.post(URL_CHAT_PERSONAL_MESSAGES_UNREAD, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : personal messages : mark message as delivered **/
const httpChatPersonalMessagesMarkMessageAsDelivered = async (data) =>
    await axios.post(URL_CHAT_PERSONAL_MESSAGE_DELIVERED, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : personal messages : delete multiple messages **/
const httpChatPersonalMessagesDeleteMultipleMessages = async (data) =>
    await axios.post(URL_CHAT_PERSONAL_MESSAGE_DELETE_MULTIPLE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : personal messages : clear messages **/
const httpChatPersonalMessagesClearMessages = async (data) =>
    await axios.post(URL_CHAT_PERSONAL_MESSAGES_CLEAR, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : personal messages : to react on message **/
const httpChatPersonalMessagesReactOnMessage = async (data) =>
    await axios.post(URL_CHAT_PERSONAL_MESSAGES_REACT_ON_MESSAGE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : personal messages : purge messages **/
const httpChatPersonalMessagesPurgeMessages = async (data) =>
    await axios.post(URL_CHAT_PERSONAL_MESSAGES_PURGE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : personal messages : to get reply message history  **/
const httpChatPersonalMessagesGetMessageHistory = async (data) =>
    await axios.post(URL_CHAT_PERSONAL_MESSAGES_GET_MESSAGE_HISTORY, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : personal messages : to filter the messages by message field  **/
const httpChatPersonalMessagesFilterMessagesByField = async (data) =>
    await axios.post(URL_CHAT_PERSONAL_MESSAGES_FILTER_MESSAGES_BY_FIELD, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : personal messages : messages forward **/
const httpChatPersonalMessagesForwardMessages = async (data) =>
    await axios.post(URL_CHAT_PERSONAL_MESSAGES_FORWARD, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : personal messages : messages forward **/
const httpChatPersonalMessagesMuteUnmuteNotifications = async (data) =>
    await axios.post(URL_CHAT_PERSONAL_MESSAGES_MUTE_UNMUTE_NOTIFICATIONS, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
          return error
        });

/** handler : personal messages : message send **/
function* handleHttpChatPersonalMessagesMessageSend({payload}) {
    try {
        const result = yield call(httpChatPersonalMessagesMessageSend, payload);
        if (result.message) {
            yield put(personalMessagesMessageSendError(result.message));
        } else {
            yield put(personalMessagesMessageSendSuccess(result.data));
        }
    } catch (error) {
        yield put(personalMessagesMessageSendError(error));
    }
}

/** handler : personal messages : message edit **/
function* handleHttpChatPersonalMessagesMessageEdit({payload}) {
    try {
        const result = yield call(httpChatPersonalMessagesMessageEdit, payload);
        if (result.message) {
            yield put(personalMessagesMessageEditError(result.message));
        } else {
            yield put(personalMessagesMessageEditSuccess(result.data));
        }
    } catch (error) {
        yield put(personalMessagesMessageEditError(error));
    }
}

/** handler : personal messages : message delete **/
function* handleHttpChatPersonalMessagesMessageDelete({payload}) {
    try {
        const result = yield call(httpChatPersonalMessagesMessageDelete, payload);
        if (result.message) {
            yield put(personalMessagesMessageDeleteError(result.message));
        } else {
            yield put(personalMessagesMessageDeleteSuccess(result.data));
        }
    } catch (error) {
        yield put(personalMessagesMessageDeleteError(error));
    }
}

/** handler : personal messages : message read **/
function* handleHttpChatPersonalMessagesMessageRead({payload}) {
    try {
        const result = yield call(httpChatPersonalMessagesMessageRead, payload);
        if (result.message) {
            yield put(personalMessagesMessageReadError(result.message));
        } else {
            yield put(personalMessagesMessageReadSuccess(result.data));
        }
    } catch (error) {
        yield put(personalMessagesMessageReadError(error));
    }
}

/** handler : personal messages : message read all **/
function* handleHttpChatPersonalMessagesMessageReadAll({payload}) {
    try {
        const result = yield call(httpChatPersonalMessagesMessageReadAll, payload);
        if (result.message) {
            yield put(personalMessagesMessageReadAllError(result.message));
        } else {
            yield put(personalMessagesMessageReadAllSuccess(result.data));
        }
    } catch (error) {
        yield put(personalMessagesMessageReadAllError(error));
    }
}


/** handler : personal messages : message list **/
function* handleHttpChatPersonalMessagesMessageList({payload}) {
    try {
        const result = yield call(httpChatPersonalMessagesMessageList, payload);
        if (result.message) {
            yield put(personalMessagesMessageListError(result.message));
        } else {
            yield put(personalMessagesMessageListSuccess(result.data));
        }
    } catch (error) {
        yield put(personalMessagesMessageListError(error));
    }
}

/** handler : personal messages : mark message as unread **/
function* handleHttpChatPersonalMessagesMarkMessageAsUnread({payload}) {
    try {
        const result = yield call(httpChatPersonalMessagesMarkMessageAsUnread, payload);
        if (result.message) {
            yield put(personalMessagesMarkMessageAsUnreadError(result.message));
        } else {
            yield put(personalMessagesMarkMessageAsUnreadSuccess(result.data));
        }
    } catch (error) {
        yield put(personalMessagesMarkMessageAsUnreadError(error));
    }
}

/** handler : personal messages : mark message as delivered **/
function* handleHttpChatPersonalMessagesMarkMessageAsDelivered({payload}) {
    try {
        const result = yield call(httpChatPersonalMessagesMarkMessageAsDelivered, payload);
        if (result.message) {
            yield put(personalMessagesMarkMessageAsDeliveredError(result.message));
        } else {
            yield put(personalMessagesMarkMessageAsDeliveredSuccess(result.data));
        }
    } catch (error) {
        yield put(personalMessagesMarkMessageAsDeliveredError(error));
    }
}

/** handler : personal messages : mark message as delivered **/
function* handleHttpChatPersonalMessagesDeleteMultipleMessages({payload}) {
    try {
        const result = yield call(httpChatPersonalMessagesDeleteMultipleMessages, payload);
        if (result.message) {
            yield put(personalMessagesDeleteMultipleMessagesError(result.message));
        } else {
            yield put(personalMessagesDeleteMultipleMessagesSuccess(result.data));
        }
    } catch (error) {
        yield put(personalMessagesDeleteMultipleMessagesError(error));
    }
}

/** handler : personal messages : mark message as delivered **/
function* handleHttpChatPersonalMessagesClearMessages({payload}) {
    try {
        const result = yield call(httpChatPersonalMessagesClearMessages, payload);
        if (result.message) {
            yield put(personalMessagesClearMessagesError(result.message));
        } else {
            yield put(personalMessagesClearMessagesSuccess(result.data));
        }
    } catch (error) {
        yield put(personalMessagesClearMessagesError(error));
    }
}

/** handler : personal messages : to react on message **/
function* handleHttpChatPersonalMessagesReactOnMessage({payload}) {
    try {
        const result = yield call(httpChatPersonalMessagesReactOnMessage, payload);
        if (result.message) {
            yield put(personalMessagesReactOnMessageError(result.message));
        } else {
            yield put(personalMessagesReactOnMessageSuccess(result.data));
        }
    } catch (error) {
        yield put(personalMessagesReactOnMessageError(error));
    }
}

/** handler : personal messages : mark message as delivered **/
function* handleHttpChatPersonalMessagesPurgeMessages({payload}) {
    try {
        const result = yield call(httpChatPersonalMessagesPurgeMessages, payload);
        if (result.message) {
            yield put(personalMessagesPurgeMessagesError(result.message));
        } else {
            yield put(personalMessagesPurgeMessagesSuccess(result.data));
        }
    } catch (error) {
        yield put(personalMessagesPurgeMessagesError(error));
    }
}

/** handler : personal messages : to get reply message history **/
function* handleHttpChatPersonalMessagesGetMessageHistory({payload}) {
    try {
        const result = yield call(httpChatPersonalMessagesGetMessageHistory, payload);
        if (result.message) {
            yield put(personalMessagesGetMessageHistoryError(result.message));
        } else {
            yield put(personalMessagesGetMessageHistorySuccess(result.data));
        }
    } catch (error) {
        yield put(personalMessagesGetMessageHistoryError(error));
    }
}

/** handler : personal messages : to filter messages by message filed **/
function* handleHttpChatPersonalMessagesFilterMessagesByField({payload}) {
    try {
        const result = yield call(httpChatPersonalMessagesFilterMessagesByField, payload);
        if (result.message) {
            yield put(personalMessagesFilterMessagesByFieldError(result.message));
        } else {
            yield put(personalMessagesFilterMessagesByFieldSuccess(result.data));
        }
    } catch (error) {
        yield put(personalMessagesFilterMessagesByFieldError(error));
    }
}

/** handler : personal messages : messages forward **/
function* handleHttpChatPersonalMessagesForwardMessages({payload}) {
    try {
        const result = yield call(httpChatPersonalMessagesForwardMessages, payload);
        if (result.message) {
            yield put(personalMessagesMessagesForwardError(result.message));
        } else {
            yield put(personalMessagesMessagesForwardSuccess(result.data));
        }
    } catch (error) {
        yield put(personalMessagesMessagesForwardError(error));
    }
}

/** handler : personal messages : mute unmute notifications **/
function* handleHttpChatPersonalMessagesMuteUnmuteNotifications({payload}) {
    try {
        const result = yield call(httpChatPersonalMessagesMuteUnmuteNotifications, payload);
        if (result.message) {
          yield put(personalMessagesMuteUnmuteNotificationsError(result.message));
        } else {
          yield put(personalMessagesMuteUnmuteNotificationsSuccess(result.data));
        }
    } catch (error) {
        yield put(personalMessagesMuteUnmuteNotificationsError(error));
    }
}


/** watcher : personal messages : message send **/
export function* chatPersonalMessagesMessageSend() {
    yield takeEvery(PERSONAL_MESSAGES_MESSAGE_SEND, handleHttpChatPersonalMessagesMessageSend);
}

/** watcher : personal messages : message edit **/
export function* chatPersonalMessagesMessageEdit() {
    yield takeEvery(PERSONAL_MESSAGES_MESSAGE_EDIT, handleHttpChatPersonalMessagesMessageEdit);
}

/** watcher : personal messages : message delete **/
export function* chatPersonalMessagesMessageDelete() {
    yield takeEvery(PERSONAL_MESSAGES_MESSAGE_DELETE, handleHttpChatPersonalMessagesMessageDelete);
}

/** watcher : personal messages : message read **/
export function* chatPersonalMessagesMessageRead() {
    yield takeEvery(PERSONAL_MESSAGES_MESSAGE_READ, handleHttpChatPersonalMessagesMessageRead);
}

/** watcher : personal messages : message read all**/
export function* chatPersonalMessagesMessageReadAll() {
    yield takeEvery(PERSONAL_MESSAGES_MESSAGE_READ_ALL, handleHttpChatPersonalMessagesMessageReadAll);
}

/** watcher : personal messages : message list **/
export function* chatPersonalMessagesMessageList() {
    yield takeEvery(PERSONAL_MESSAGES_MESSAGE_LIST, handleHttpChatPersonalMessagesMessageList);
}

/** watcher : personal messages : mark message as unread **/
export function* chatPersonalMessagesMarkMessageAsUnread() {
    yield takeEvery(CHAT_DM_MESSAGE_MARK_AS_UNREAD, handleHttpChatPersonalMessagesMarkMessageAsUnread);
}

/** watcher : personal messages : mark message as delivered **/
export function* chatPersonalMessagesMarkMessageAsDelivered() {
    yield takeEvery(CHAT_DM_MESSAGE_MARK_AS_DELIVERED, handleHttpChatPersonalMessagesMarkMessageAsDelivered);
}

/** watcher : personal messages : delete multiple messages **/
export function* chatPersonalMessagesDeleteMultipleMessages() {
    yield takeEvery(CHAT_DM_MESSAGE_DELETE_MULTIPLE_MESSAGES, handleHttpChatPersonalMessagesDeleteMultipleMessages);
}

/** watcher : personal messages : clear messages **/
export function* chatPersonalMessagesClearMessages() {
    yield takeEvery(CHAT_DM_MESSAGE_CLEAR_MESSAGES, handleHttpChatPersonalMessagesClearMessages);
}

/** watcher : personal messages : clear messages **/
export function* chatPersonalMessagesReactOnMessage() {
    yield takeEvery(CHAT_DM_MESSAGE_REACT_ON_MESSAGE, handleHttpChatPersonalMessagesReactOnMessage);
}

/** watcher : personal messages : purge messages **/
export function* chatPersonalMessagesPurgeMessages() {
    yield takeEvery(CHAT_DM_MESSAGE_PURGE_MESSAGES, handleHttpChatPersonalMessagesPurgeMessages);
}

/** watcher : personal messages : to get reply message history **/
export function* chatPersonalMessagesGetMessageHistory() {
    yield takeEvery(CHAT_DM_MESSAGE_GET_MESSAGE_HISTORY, handleHttpChatPersonalMessagesGetMessageHistory);
}

/** watcher : personal messages : to filter messages by message field **/
export function* chatPersonalMessagesFilterMessagesByField() {
    yield takeEvery(CHAT_DM_MESSAGE_FILTER_MESSAGES_BY_FIELD, handleHttpChatPersonalMessagesFilterMessagesByField);
}

/** watcher : personal messages : messages forward **/
export function* chatPersonalMessagesForwardMessages() {
    yield takeEvery(PERSONAL_MESSAGES_MESSAGE_FORWARD, handleHttpChatPersonalMessagesForwardMessages);
}

/** watcher : personal messages : mute unmute notifications **/
export function* chatPersonalMessagesMuteUnmuteNotifications() {
  yield takeEvery(PERSONAL_MESSAGES_MUTE_UNMUTE_NOTIFICATIONS, handleHttpChatPersonalMessagesMuteUnmuteNotifications);
}


export default function* rootSaga() {
    yield all([
        fork(chatPersonalMessagesMessageSend),
        fork(chatPersonalMessagesMessageEdit),
        fork(chatPersonalMessagesMessageDelete),
        fork(chatPersonalMessagesMessageRead),
        fork(chatPersonalMessagesMessageList),
        fork(chatPersonalMessagesMessageReadAll),
        fork(chatPersonalMessagesMarkMessageAsUnread),
        fork(chatPersonalMessagesMarkMessageAsDelivered),
        fork(chatPersonalMessagesDeleteMultipleMessages),
        fork(chatPersonalMessagesClearMessages),
        fork(chatPersonalMessagesReactOnMessage),
        fork(chatPersonalMessagesPurgeMessages),
        fork(chatPersonalMessagesGetMessageHistory),
        fork(chatPersonalMessagesFilterMessagesByField),
        fork(chatPersonalMessagesForwardMessages),
        fork(chatPersonalMessagesMuteUnmuteNotifications)
    ])
}
