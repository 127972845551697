import React from "react";
import {Avatar} from "antd";
import {UserOutlined} from "@ant-design/icons";

const UserCell = ({onSelectUser, selectedSectionId, user}) => {
    
    return (
        <div className={`gx-chat-user-item ${selectedSectionId === user.id ? 'active' : ''}`} onClick={() => {
            onSelectUser(user);
        }}>
            <div className="gx-chat-user-row">
                <div className="gx-chat-avatar">
                    <div className="gx-status-pos">
                        {user.thumb && user.thumb !== "" ? (<Avatar className="gx-mr-2 gx-ml-2 gx-size-50" src={user.thumb} />) : (<Avatar className="gx-size-50" icon={<UserOutlined/>}/>)}
                        {/*<Avatar src={user.thumb} className="gx-size-50" alt="Abbott"/>
                        <span className={`gx-status ${user.status}`}/>*/}
                    </div>
                </div>
                
                <div className="gx-chat-contact-col">
                    <div className="h4 gx-name">{user.name}</div>
                    <div className="gx-chat-info-des gx-text-truncate">{user.mood.substring(0, 40)}</div>
                </div>
            </div>
        </div>
    )
};

export default UserCell;
