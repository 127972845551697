import React from "react";
import {Route, Switch} from "react-router-dom";
import {NotificationContainer} from "react-notifications";
import asyncComponent from "util/asyncComponent";
import {APP_NAVIGATION_PATHS} from "../util/tribe/constants/config";

const App = ({match}) => (
    <div className="gx-main-content-wrapper">
        <Switch>
            <Route path={`${match.url + APP_NAVIGATION_PATHS.CHAT_HOME}`} component={asyncComponent(() => import('./chat'))}/>
            <Route path={`${match.url + APP_NAVIGATION_PATHS.CALENDER_HOME}`} component={asyncComponent(() => import('./calender'))}/>
            <Route path={`${match.url + APP_NAVIGATION_PATHS.CALLS_HOME}`} component={asyncComponent(() => import('./calls'))}/>
            <Route path={`${match.url + APP_NAVIGATION_PATHS.MY_ACCOUNT}`} component={asyncComponent(() => import('./profile/UserSettings'))}/>

            /** private room **/
            <Route path={`${match.url + APP_NAVIGATION_PATHS.PRIVATE_ROOM}`} component={asyncComponent(() => import('./private_room/UserPrivateRoom'))}/>
            <Route path={`${match.url + APP_NAVIGATION_PATHS.VIEW_PRIVATE_ROOM}`} component={asyncComponent(() => import('./private_room/userPrivateRoomView'))}/>

            /** clients **/
            <Route path={`${match.url + APP_NAVIGATION_PATHS.CLIENTS_HOME}`} component={asyncComponent(() => import('./clients/index'))}/>
            <Route path={`${match.url + APP_NAVIGATION_PATHS.VIEW_CLIENT}`} component={asyncComponent(() => import('./clients/clientsView'))}/>

            /** users **/
            <Route path={`${match.url + APP_NAVIGATION_PATHS.USERS_HOME}`} component={asyncComponent(() => import('./users/index'))}/>
            <Route path={`${match.url + APP_NAVIGATION_PATHS.VIEW_USER}`} component={asyncComponent(() => import('./users/usersView'))}/>

        </Switch>
        <NotificationContainer/>
    </div>
);

export default App;
