import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import axios from "axios";
import AppUtils from "../../../util/tribe/utils/appUtils";

import {
    URL_USERS_DEACTIVATE_USER,
    URL_USERS_GET_ALL_USERS,
    URL_USERS_SAVE_USER
} from "../../../util/tribe/urls";
import {
    usersDeactivateUserError,
    usersDeactivateUserSuccess,
    usersGetAllUsersError,
    usersGetAllUsersSuccess,
    usersSaveUserError,
    usersSaveUserSuccess
} from "../../actions";

import {
    USERS_DEACTIVATE_USER,
    USERS_GET_ALL_USERS,
    USERS_SAVE_USER
} from "../../../constants/actions/users/actionTypesUsers";

/** http : user : get all users **/
const httpGetAllUsersList = async (data) =>
    await axios.post(URL_USERS_GET_ALL_USERS, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : users : save user **/
const httpSaveUser = async (data) =>
    await axios.post(URL_USERS_SAVE_USER, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : users : deactivate user **/
const httpDeactivateUser = async (data) =>
    await axios.post(URL_USERS_DEACTIVATE_USER, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });


/** handler : users : get all users **/
function* handleHttpGetAllUsersList({payload}) {
    try {
        const result = yield call(httpGetAllUsersList, payload);
        if (result.message) {
            yield put(usersGetAllUsersError(result.message));
        } else {
            yield put(usersGetAllUsersSuccess(result.data));
        }
    } catch (error) {
        yield put(usersGetAllUsersError(error));
    }
}

/** handler : users : save user **/
function* handleHttpSaveUser({payload}) {
    try {
        const result = yield call(httpSaveUser, payload);
        if (result.message) {
            yield put(usersSaveUserError(result.message));
        } else {
            yield put(usersSaveUserSuccess(result.data));
        }
    } catch (error) {
        yield put(usersSaveUserError(error));
    }
}

/** handler : users : deactivate user **/
function* handleHttpDeactivateUser({payload}) {
    try {
        const result = yield call(httpDeactivateUser, payload);
        if (result.message) {
            yield put(usersDeactivateUserError(result.message));
        } else {
            yield put(usersDeactivateUserSuccess(result.data));
        }
    } catch (error) {
        yield put(usersDeactivateUserError(error));
    }
}


/** watcher : users : get all users **/
export function* getAllUsersList() {
    yield takeEvery(USERS_GET_ALL_USERS, handleHttpGetAllUsersList);
}

/** watcher : users : save user **/
export function* saveUser() {
    yield takeEvery(USERS_SAVE_USER, handleHttpSaveUser);
}

/** watcher : users : deactivate user **/
export function* deactivateUser() {
    yield takeEvery(USERS_DEACTIVATE_USER, handleHttpDeactivateUser);
}


export default function* rootSaga() {
    yield all([
        fork(getAllUsersList),
        fork(saveUser),
        fork(deactivateUser)
    ])
}
