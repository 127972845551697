import {MEDIA_EVENTS} from "../../../constants/actions/media/mediaActionTypes";
import {MSG_ROUTER_EVENTS, MSG_ROUTER_RELAY_TYPES} from "../../../util/tribe/constants/config";
import AppUtils from "../../../util/tribe/utils/appUtils";
import {CALL_EVENT_JOIN_CALL, CALL_EVENT_RESET_CALL, CALL_EVENT_START_CALL} from "../../../constants/actions/chat/ChatActionTypes";

const INIT_STATE = {
    isMediaMinimised: false,
    isMediaTerminated: false,
    currentMeetingId: "",
    isMeetingVideoEnabled: false,
    currentMeetingType: ""
};


const ReducerTribeMedia = (state = INIT_STATE, action) => {
    
    switch (action.type) {
        
        case CALL_EVENT_RESET_CALL: {
            return {
                ...state,
                currentMeetingId: "",
                isMeetingVideoEnabled: false,
                currentMeetingType: ""
            }
        }
        
        case CALL_EVENT_JOIN_CALL: {
            return {
                ...state,
                currentMeetingId: action.payload.meeting_id,
                isMeetingVideoEnabled: action.payload.is_video,
                currentMeetingType: action.payload.meeting_type
            }
        }
        
        case CALL_EVENT_START_CALL: {
            return {
                ...state,
                currentMeetingId: action.payload.meeting_id,
                isMeetingVideoEnabled: action.payload.is_video,
                currentMeetingType: action.payload.meeting_type
            }
        }
        
        case MEDIA_EVENTS.MEDIA_EVENT_MEDIA_MINIMISE: {
            return {
                ...state,
                isMediaMinimised: true
            }
        }
        
        case MEDIA_EVENTS.MEDIA_EVENT_MEDIA_MAXIMISE: {
            return {
                ...state,
                isMediaMinimised: false
            }
        }
        
        
        case MEDIA_EVENTS.MEDIA_EVENT_MEDIA_TERMINATE: {
            return {
                ...state,
                isMediaTerminated: true
            }
        }
        
        case MEDIA_EVENTS.MEDIA_EVENT_MEDIA_TERMINATE_RESET: {
            return {
                ...state,
                isMediaTerminated: false
            }
        }
        
        
        /** dm and channel : relay events  **/
        case MSG_ROUTER_EVENTS.RELAY.DM_RELAY:
        case MSG_ROUTER_EVENTS.RELAY.CHANNEL_RELAY: {
            
            let isMediaTerminated = state.isMediaTerminated;
            
            if (typeof action.payload.data != "undefined" && typeof action.payload.data.to != "undefined" && typeof action.payload.data.to.uid != "undefined" && action.payload.data.to.uid === AppUtils.getCurrentUserId() &&
                (action.payload.data.user_id !== AppUtils.getCurrentUserId() && action.payload.data.from_user !== AppUtils.getCurrentUserId())) {
                if (typeof action.payload.data.relay != "undefined" && typeof action.payload.data.relay.data != "undefined") {
                    
                    let relayData = action.payload.data.relay.data;
                    
                    let relayType = relayData.type;
                    
                    switch (relayType) {
                        
                        /** terminate calle **/
                        case MSG_ROUTER_RELAY_TYPES.TERMINATE_DM_CALL: {
                            
                            if (relayData.meeting_id === state.currentMeetingId) {
                                isMediaTerminated = true;
                            }
                            break;
                        }
                        
                        default: {
                            break;
                        }
                    }
                }
            }
            
            return {
                ...state,
                isMediaTerminated: isMediaTerminated
            }
        }
        
        default: {
            return {
                ...state
            }
        }
        
    }
    
}

export default ReducerTribeMedia;
