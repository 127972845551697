/** to get other contact names **/
export const CHAT_GET_ALL_OTHER_CONTACTS = 'CHAT_GET_ALL_OTHER_CONTACTS';
export const CHAT_GET_ALL_OTHER_CONTACTS_SUCCESS = 'CHAT_GET_ALL_OTHER_CONTACTS_SUCCESS';
export const CHAT_GET_ALL_OTHER_CONTACTS_ERROR = 'CHAT_GET_ALL_OTHER_CONTACTS_ERROR';
export const CHAT_GET_ALL_OTHER_CONTACTS_NONE = 'CHAT_GET_ALL_OTHER_CONTACTS_NONE';

/**** User management ****/
/** to register user **/
export const USER_MANAGEMENT_REGISTER = 'USER_MANAGEMENT_REGISTER';
export const USER_MANAGEMENT_REGISTER_SUCCESS = 'USER_MANAGEMENT_REGISTER_SUCCESS';
export const USER_MANAGEMENT_REGISTER_ERROR = 'USER_MANAGEMENT_REGISTER_ERROR';
export const USER_MANAGEMENT_REGISTER_NONE = 'USER_MANAGEMENT_REGISTER_NONE';

/** user : to get details **/
export const USER_MANAGEMENT_GET_DETAILS = 'USER_MANAGEMENT_GET_DETAILS';
export const USER_MANAGEMENT_GET_DETAILS_SUCCESS = 'USER_MANAGEMENT_GET_DETAILS_SUCCESS';
export const USER_MANAGEMENT_GET_DETAILS_ERROR = 'USER_MANAGEMENT_GET_DETAILS_ERROR';
export const USER_MANAGEMENT_GET_DETAILS_NONE = 'USER_MANAGEMENT_GET_DETAILS_NONE';

/** user : to update details  **/
export const USER_MANAGEMENT_UPDATE_DETAILS = 'USER_MANAGEMENT_UPDATE_DETAILS';
export const USER_MANAGEMENT_UPDATE_DETAILS_SUCCESS = 'USER_MANAGEMENT_UPDATE_DETAILS_SUCCESS';
export const USER_MANAGEMENT_UPDATE_DETAILS_ERROR = 'USER_MANAGEMENT_UPDATE_DETAILS_ERROR';
export const USER_MANAGEMENT_UPDATE_DETAILS_NONE = 'USER_MANAGEMENT_UPDATE_DETAILS_NONE';

/** user : to delete details  **/
export const USER_MANAGEMENT_DELETE_USER = 'USER_MANAGEMENT_DELETE_USER';
export const USER_MANAGEMENT_DELETE_USER_SUCCESS = 'USER_MANAGEMENT_DELETE_USER_SUCCESS';
export const USER_MANAGEMENT_DELETE_USER_ERROR = 'USER_MANAGEMENT_DELETE_USER_ERROR';
export const USER_MANAGEMENT_DELETE_USER_NONE = 'USER_MANAGEMENT_DELETE_USER_NONE';

/** user : to get logins  **/
export const USER_MANAGEMENT_GET_LOGINS = 'USER_MANAGEMENT_GET_LOGINS';
export const USER_MANAGEMENT_GET_LOGINS_SUCCESS = 'USER_MANAGEMENT_GET_LOGINS_SUCCESS';
export const USER_MANAGEMENT_GET_LOGINS_ERROR = 'USER_MANAGEMENT_GET_LOGINS_ERROR';
export const USER_MANAGEMENT_GET_LOGINS_NONE = 'USER_MANAGEMENT_GET_LOGINS_NONE';

/** user : to get user active status details  **/
export const USER_MANAGEMENT_GET_USER_ACTIVE_STATUS = 'USER_MANAGEMENT_GET_USER_ACTIVE_STATUS';
export const USER_MANAGEMENT_GET_USER_ACTIVE_STATUS_SUCCESS = 'USER_MANAGEMENT_GET_USER_ACTIVE_STATUS_SUCCESS';
export const USER_MANAGEMENT_GET_USER_ACTIVE_STATUS_ERROR = 'USER_MANAGEMENT_GET_USER_ACTIVE_STATUS_ERROR';
export const USER_MANAGEMENT_GET_USER_ACTIVE_STATUS_NONE = 'USER_MANAGEMENT_GET_USER_ACTIVE_STATUS_NONE';

/** user : login  **/
export const USER_MANAGEMENT_DO_LOGIN = 'USER_MANAGEMENT_DO_LOGIN';
export const USER_MANAGEMENT_DO_LOGIN_SUCCESS = 'USER_MANAGEMENT_DO_LOGIN_SUCCESS';
export const USER_MANAGEMENT_DO_LOGIN_ERROR = 'USER_MANAGEMENT_DO_LOGIN_ERROR';
export const USER_MANAGEMENT_DO_LOGIN_NONE = 'USER_MANAGEMENT_DO_LOGIN_NONE';

/** user : logout  **/
export const USER_MANAGEMENT_DO_LOGOUT = 'USER_MANAGEMENT_DO_LOGOUT';
export const USER_MANAGEMENT_DO_LOGOUT_SUCCESS = 'USER_MANAGEMENT_DO_LOGOUT_SUCCESS';
export const USER_MANAGEMENT_DO_LOGOUT_ERROR = 'USER_MANAGEMENT_DO_LOGOUT_ERROR';
export const USER_MANAGEMENT_DO_LOGOUT_NONE = 'USER_MANAGEMENT_DO_LOGOUT_NONE';

/**** subscription management ****/
/** subscription : to subscribe **/
export const SUBSCRIPTION_MANAGEMENT_SUBSCRIBE = 'SUBSCRIPTION_MANAGEMENT_SUBSCRIBE';
export const SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_SUCCESS = 'SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_SUCCESS';
export const SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_ERROR = 'SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_ERROR';
export const SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_NONE = 'SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_NONE';

/** subscription : to unsubscribe **/
export const SUBSCRIPTION_MANAGEMENT_UNSUBSCRIBE = 'SUBSCRIPTION_MANAGEMENT_UNSUBSCRIBE';
export const SUBSCRIPTION_MANAGEMENT_UNSUBSCRIBE_SUCCESS = 'SUBSCRIPTION_MANAGEMENT_UNSUBSCRIBE_SUCCESS';
export const SUBSCRIPTION_MANAGEMENT_UNSUBSCRIBE_ERROR = 'SUBSCRIPTION_MANAGEMENT_UNSUBSCRIBE_ERROR';
export const SUBSCRIPTION_MANAGEMENT_UNSUBSCRIBE_NONE = 'SUBSCRIPTION_MANAGEMENT_UNSUBSCRIBE_NONE';

/** subscription : subscribe by me **/
export const SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_BY_ME = 'SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_BY_ME';
export const SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_BY_ME_SUCCESS = 'SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_BY_ME_SUCCESS';
export const SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_BY_ME_ERROR = 'SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_BY_ME_ERROR';
export const SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_BY_ME_NONE = 'SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_BY_ME_NONE';

/** subscription : subscribe to me **/
export const SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_TO_ME = 'SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_TO_ME';
export const SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_TO_ME_SUCCESS = 'SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_TO_ME_SUCCESS';
export const SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_TO_ME_ERROR = 'SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_TO_ME_ERROR';
export const SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_TO_ME_NONE = 'SUBSCRIPTION_MANAGEMENT_SUBSCRIBE_TO_ME_NONE';

/**** device management ****/
/** device management : attach device **/
export const DEVICE_MANAGEMENT_DEVICE_ATTACH = 'DEVICE_MANAGEMENT_DEVICE_ATTACH';
export const DEVICE_MANAGEMENT_DEVICE_ATTACH_SUCCESS = 'DEVICE_MANAGEMENT_DEVICE_ATTACH_SUCCESS';
export const DEVICE_MANAGEMENT_DEVICE_ATTACH_ERROR = 'DEVICE_MANAGEMENT_DEVICE_ATTACH_ERROR';
export const DEVICE_MANAGEMENT_DEVICE_ATTACH_NONE = 'DEVICE_MANAGEMENT_DEVICE_ATTACH_NONE';

/** device management : update device **/
export const DEVICE_MANAGEMENT_DEVICE_UPDATE = 'DEVICE_MANAGEMENT_DEVICE_UPDATE';
export const DEVICE_MANAGEMENT_DEVICE_UPDATE_SUCCESS = 'DEVICE_MANAGEMENT_DEVICE_UPDATE_SUCCESS';
export const DEVICE_MANAGEMENT_DEVICE_UPDATE_ERROR = 'DEVICE_MANAGEMENT_DEVICE_UPDATE_ERROR';
export const DEVICE_MANAGEMENT_DEVICE_UPDATE_NONE = 'DEVICE_MANAGEMENT_DEVICE_UPDATE_NONE';

/** device management : detach device **/
export const DEVICE_MANAGEMENT_DEVICE_DETACH = 'DEVICE_MANAGEMENT_DEVICE_DETACH';
export const DEVICE_MANAGEMENT_DEVICE_DETACH_SUCCESS = 'DEVICE_MANAGEMENT_DEVICE_DETACH_SUCCESS';
export const DEVICE_MANAGEMENT_DEVICE_DETACH_ERROR = 'DEVICE_MANAGEMENT_DEVICE_DETACH_ERROR';
export const DEVICE_MANAGEMENT_DEVICE_DETACH_NONE = 'DEVICE_MANAGEMENT_DEVICE_DETACH_NONE';

/** device management : device list **/
export const DEVICE_MANAGEMENT_DEVICE_LIST = 'DEVICE_MANAGEMENT_DEVICE_LIST';
export const DEVICE_MANAGEMENT_DEVICE_LIST_SUCCESS = 'DEVICE_MANAGEMENT_DEVICE_LIST_SUCCESS';
export const DEVICE_MANAGEMENT_DEVICE_LIST_ERROR = 'DEVICE_MANAGEMENT_DEVICE_LIST_ERROR';
export const DEVICE_MANAGEMENT_DEVICE_LIST_NONE = 'DEVICE_MANAGEMENT_DEVICE_LIST_NONE';

/**** channel management ****/
/** channel management : create channel **/
export const CHANNEL_MANAGEMENT_CHANNEL_CREATE = 'CHANNEL_MANAGEMENT_CHANNEL_CREATE';
export const CHANNEL_MANAGEMENT_CHANNEL_CREATE_SUCCESS = 'CHANNEL_MANAGEMENT_CHANNEL_CREATE_SUCCESS';
export const CHANNEL_MANAGEMENT_CHANNEL_CREATE_ERROR = 'CHANNEL_MANAGEMENT_CHANNEL_CREATE_ERROR';
export const CHANNEL_MANAGEMENT_CHANNEL_CREATE_NONE = 'CHANNEL_MANAGEMENT_CHANNEL_CREATE_NONE';

/** channel management : update channel **/
export const CHANNEL_MANAGEMENT_CHANNEL_UPDATE = 'CHANNEL_MANAGEMENT_CHANNEL_UPDATE';
export const CHANNEL_MANAGEMENT_CHANNEL_UPDATE_SUCCESS = 'CHANNEL_MANAGEMENT_CHANNEL_UPDATE_SUCCESS';
export const CHANNEL_MANAGEMENT_CHANNEL_UPDATE_ERROR = 'CHANNEL_MANAGEMENT_CHANNEL_UPDATE_ERROR';
export const CHANNEL_MANAGEMENT_CHANNEL_UPDATE_NONE = 'CHANNEL_MANAGEMENT_CHANNEL_UPDATE_NONE';

/** channel management : update channel name **/
export const CHANNEL_MANAGEMENT_CHANNEL_UPDATE_NAME = 'CHANNEL_MANAGEMENT_CHANNEL_UPDATE_NAME';
export const CHANNEL_MANAGEMENT_CHANNEL_UPDATE_NAME_SUCCESS = 'CHANNEL_MANAGEMENT_CHANNEL_UPDATE_NAME_SUCCESS';
export const CHANNEL_MANAGEMENT_CHANNEL_UPDATE_NAME_ERROR = 'CHANNEL_MANAGEMENT_CHANNEL_UPDATE_NAME_ERROR';
export const CHANNEL_MANAGEMENT_CHANNEL_UPDATE_NAME_NONE = 'CHANNEL_MANAGEMENT_CHANNEL_UPDATE_NAME_NONE';

/** channel management : update channel description **/
export const CHANNEL_MANAGEMENT_CHANNEL_UPDATE_DESCRIPTION = 'CHANNEL_MANAGEMENT_CHANNEL_UPDATE_DESCRIPTION';
export const CHANNEL_MANAGEMENT_CHANNEL_UPDATE_DESCRIPTION_SUCCESS = 'CHANNEL_MANAGEMENT_CHANNEL_UPDATE_DESCRIPTION_SUCCESS';
export const CHANNEL_MANAGEMENT_CHANNEL_UPDATE_DESCRIPTION_ERROR = 'CHANNEL_MANAGEMENT_CHANNEL_UPDATE_DESCRIPTION_ERROR';
export const CHANNEL_MANAGEMENT_CHANNEL_UPDATE_DESCRIPTION_NONE = 'CHANNEL_MANAGEMENT_CHANNEL_UPDATE_DESCRIPTION_NONE';

/** channel management : update channel image **/
export const CHANNEL_MANAGEMENT_CHANNEL_UPDATE_IMAGE = 'CHANNEL_MANAGEMENT_CHANNEL_UPDATE_IMAGE';
export const CHANNEL_MANAGEMENT_CHANNEL_UPDATE_IMAGE_SUCCESS = 'CHANNEL_MANAGEMENT_CHANNEL_UPDATE_IMAGE_SUCCESS';
export const CHANNEL_MANAGEMENT_CHANNEL_UPDATE_IMAGE_ERROR = 'CHANNEL_MANAGEMENT_CHANNEL_UPDATE_IMAGE_ERROR';
export const CHANNEL_MANAGEMENT_CHANNEL_UPDATE_IMAGE_NONE = 'CHANNEL_MANAGEMENT_CHANNEL_UPDATE_IMAGE_NONE';

/** channel management : delete channel **/
export const CHANNEL_MANAGEMENT_CHANNEL_DELETE = 'CHANNEL_MANAGEMENT_CHANNEL_DELETE';
export const CHANNEL_MANAGEMENT_CHANNEL_DELETE_SUCCESS = 'CHANNEL_MANAGEMENT_CHANNEL_DELETE_SUCCESS';
export const CHANNEL_MANAGEMENT_CHANNEL_DELETE_ERROR = 'CHANNEL_MANAGEMENT_CHANNEL_DELETE_ERROR';
export const CHANNEL_MANAGEMENT_CHANNEL_DELETE_NONE = 'CHANNEL_MANAGEMENT_CHANNEL_DELETE_NONE';

/** channel management : activate channel **/
export const CHANNEL_MANAGEMENT_CHANNEL_ACTIVATE = 'CHANNEL_MANAGEMENT_CHANNEL_ACTIVATE';
export const CHANNEL_MANAGEMENT_CHANNEL_ACTIVATE_SUCCESS = 'CHANNEL_MANAGEMENT_CHANNEL_ACTIVATE_SUCCESS';
export const CHANNEL_MANAGEMENT_CHANNEL_ACTIVATE_ERROR = 'CHANNEL_MANAGEMENT_CHANNEL_ACTIVATE_ERROR';
export const CHANNEL_MANAGEMENT_CHANNEL_ACTIVATE_NONE = 'CHANNEL_MANAGEMENT_CHANNEL_ACTIVATE_NONE';

/** channel management : deactivate channel **/
export const CHANNEL_MANAGEMENT_CHANNEL_DEACTIVATE = 'CHANNEL_MANAGEMENT_CHANNEL_DEACTIVATE';
export const CHANNEL_MANAGEMENT_CHANNEL_DEACTIVATE_SUCCESS = 'CHANNEL_MANAGEMENT_CHANNEL_DEACTIVATE_SUCCESS';
export const CHANNEL_MANAGEMENT_CHANNEL_DEACTIVATE_ERROR = 'CHANNEL_MANAGEMENT_CHANNEL_DEACTIVATE_ERROR';
export const CHANNEL_MANAGEMENT_CHANNEL_DEACTIVATE_NONE = 'CHANNEL_MANAGEMENT_CHANNEL_DEACTIVATE_NONE';

/** channel management : change ownership channel **/
export const CHANNEL_MANAGEMENT_CHANNEL_CHANGE_OWNERSHIP = 'CHANNEL_MANAGEMENT_CHANNEL_CHANGE_OWNERSHIP';
export const CHANNEL_MANAGEMENT_CHANNEL_CHANGE_OWNERSHIP_SUCCESS = 'CHANNEL_MANAGEMENT_CHANNEL_CHANGE_OWNERSHIP_SUCCESS';
export const CHANNEL_MANAGEMENT_CHANNEL_CHANGE_OWNERSHIP_ERROR = 'CHANNEL_MANAGEMENT_CHANNEL_CHANGE_OWNERSHIP_ERROR';
export const CHANNEL_MANAGEMENT_CHANNEL_CHANGE_OWNERSHIP_NONE = 'CHANNEL_MANAGEMENT_CHANNEL_CHANGE_OWNERSHIP_NONE';

/** channel management : details channel **/
export const CHANNEL_MANAGEMENT_CHANNEL_DETAILS = 'CHANNEL_MANAGEMENT_CHANNEL_DETAILS';
export const CHANNEL_MANAGEMENT_CHANNEL_DETAILS_SUCCESS = 'CHANNEL_MANAGEMENT_CHANNEL_DETAILS_SUCCESS';
export const CHANNEL_MANAGEMENT_CHANNEL_DETAILS_ERROR = 'CHANNEL_MANAGEMENT_CHANNEL_DETAILS_ERROR';
export const CHANNEL_MANAGEMENT_CHANNEL_DETAILS_NONE = 'CHANNEL_MANAGEMENT_CHANNEL_DETAILS_NONE';

/** channel management : list channel **/
export const CHANNEL_MANAGEMENT_CHANNEL_LIST = 'CHANNEL_MANAGEMENT_CHANNEL_LIST';
export const CHANNEL_MANAGEMENT_CHANNEL_LIST_SUCCESS = 'CHANNEL_MANAGEMENT_CHANNEL_LIST_SUCCESS';
export const CHANNEL_MANAGEMENT_CHANNEL_LIST_ERROR = 'CHANNEL_MANAGEMENT_CHANNEL_LIST_ERROR';
export const CHANNEL_MANAGEMENT_CHANNEL_LIST_NONE = 'CHANNEL_MANAGEMENT_CHANNEL_LIST_NONE';

/** channel management : mute unmute notifications **/
export const CHANNEL_MANAGEMENT_CHANNEL_MUTE_UNMUTE_NOTIFICATIONS = 'CHANNEL_MANAGEMENT_CHANNEL_MUTE_UNMUTE_NOTIFICATIONS';
export const CHANNEL_MANAGEMENT_CHANNEL_MUTE_UNMUTE_NOTIFICATIONS_SUCCESS = 'CHANNEL_MANAGEMENT_CHANNEL_MUTE_UNMUTE_NOTIFICATIONS_SUCCESS';
export const CHANNEL_MANAGEMENT_CHANNEL_MUTE_UNMUTE_NOTIFICATIONS_ERROR = 'CHANNEL_MANAGEMENT_CHANNEL_MUTE_UNMUTE_NOTIFICATIONS_ERROR';
export const CHANNEL_MANAGEMENT_CHANNEL_MUTE_UNMUTE_NOTIFICATIONS_NONE = 'CHANNEL_MANAGEMENT_CHANNEL_MUTE_UNMUTE_NOTIFICATIONS_NONE';

/**** channel member management ****/
/** channel member management : member add **/
export const CHANNEL_MEMBER_MANAGEMENT_MEMBER_ADD = 'CHANNEL_MEMBER_MANAGEMENT_MEMBER_ADD';
export const CHANNEL_MEMBER_MANAGEMENT_MEMBER_ADD_SUCCESS = 'CHANNEL_MEMBER_MANAGEMENT_MEMBER_ADD_SUCCESS';
export const CHANNEL_MEMBER_MANAGEMENT_MEMBER_ADD_ERROR = 'CHANNEL_MEMBER_MANAGEMENT_MEMBER_ADD_ERROR';
export const CHANNEL_MEMBER_MANAGEMENT_MEMBER_ADD_NONE = 'CHANNEL_MEMBER_MANAGEMENT_MEMBER_ADD_NONE';

/** channel member management : member invite **/
export const CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE = 'CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE';
export const CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_SUCCESS = 'CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_SUCCESS';
export const CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_ERROR = 'CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_ERROR';
export const CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_NONE = 'CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_NONE';

/** channel member management : member invite delete **/
export const CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_DELETE = 'CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_DELETE';
export const CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_DELETE_SUCCESS = 'CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_DELETE_SUCCESS';
export const CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_DELETE_ERROR = 'CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_DELETE_ERROR';
export const CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_DELETE_NONE = 'CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_DELETE_NONE';

/** channel member management : member invite delete **/
export const CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_ACCEPT = 'CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_ACCEPT';
export const CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_ACCEPT_SUCCESS = 'CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_ACCEPT_SUCCESS';
export const CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_ACCEPT_ERROR = 'CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_ACCEPT_ERROR';
export const CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_ACCEPT_NONE = 'CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_ACCEPT_NONE';

/** channel member management : member leave **/
export const CHANNEL_MEMBER_MANAGEMENT_MEMBER_LEAVE = 'CHANNEL_MEMBER_MANAGEMENT_MEMBER_LEAVE';
export const CHANNEL_MEMBER_MANAGEMENT_MEMBER_LEAVE_SUCCESS = 'CHANNEL_MEMBER_MANAGEMENT_MEMBER_LEAVE_SUCCESS';
export const CHANNEL_MEMBER_MANAGEMENT_MEMBER_LEAVE_ERROR = 'CHANNEL_MEMBER_MANAGEMENT_MEMBER_LEAVE_ERROR';
export const CHANNEL_MEMBER_MANAGEMENT_MEMBER_LEAVE_NONE = 'CHANNEL_MEMBER_MANAGEMENT_MEMBER_LEAVE_NONE';

/** channel member management : member block notifications **/
export const CHANNEL_MEMBER_MANAGEMENT_MEMBER_BLOCK_NOTIFICATIONS = 'CHANNEL_MEMBER_MANAGEMENT_MEMBER_BLOCK_NOTIFICATIONS';
export const CHANNEL_MEMBER_MANAGEMENT_MEMBER_BLOCK_NOTIFICATIONS_SUCCESS = 'CHANNEL_MEMBER_MANAGEMENT_MEMBER_BLOCK_NOTIFICATIONS_SUCCESS';
export const CHANNEL_MEMBER_MANAGEMENT_MEMBER_BLOCK_NOTIFICATIONS_ERROR = 'CHANNEL_MEMBER_MANAGEMENT_MEMBER_BLOCK_NOTIFICATIONS_ERROR';
export const CHANNEL_MEMBER_MANAGEMENT_MEMBER_BLOCK_NOTIFICATIONS_NONE = 'CHANNEL_MEMBER_MANAGEMENT_MEMBER_BLOCK_NOTIFICATIONS_NONE';

/** channel member management : member unblock notifications **/
export const CHANNEL_MEMBER_MANAGEMENT_MEMBER_UNBLOCK_NOTIFICATIONS = 'CHANNEL_MEMBER_MANAGEMENT_MEMBER_UNBLOCK_NOTIFICATIONS';
export const CHANNEL_MEMBER_MANAGEMENT_MEMBER_UNBLOCK_NOTIFICATIONS_SUCCESS = 'CHANNEL_MEMBER_MANAGEMENT_MEMBER_UNBLOCK_NOTIFICATIONS_SUCCESS';
export const CHANNEL_MEMBER_MANAGEMENT_MEMBER_UNBLOCK_NOTIFICATIONS_ERROR = 'CHANNEL_MEMBER_MANAGEMENT_MEMBER_UNBLOCK_NOTIFICATIONS_ERROR';
export const CHANNEL_MEMBER_MANAGEMENT_MEMBER_UNBLOCK_NOTIFICATIONS_NONE = 'CHANNEL_MEMBER_MANAGEMENT_MEMBER_UNBLOCK_NOTIFICATIONS_NONE';

/** channel member management : member ban **/
export const CHANNEL_MEMBER_MANAGEMENT_MEMBER_BAN = 'CHANNEL_MEMBER_MANAGEMENT_MEMBER_BAN';
export const CHANNEL_MEMBER_MANAGEMENT_MEMBER_BAN_SUCCESS = 'CHANNEL_MEMBER_MANAGEMENT_MEMBER_BAN_SUCCESS';
export const CHANNEL_MEMBER_MANAGEMENT_MEMBER_BAN_ERROR = 'CHANNEL_MEMBER_MANAGEMENT_MEMBER_BAN_ERROR';
export const CHANNEL_MEMBER_MANAGEMENT_MEMBER_BAN_NONE = 'CHANNEL_MEMBER_MANAGEMENT_MEMBER_BAN_NONE';

/** channel member management : member change role **/
export const CHANNEL_MEMBER_MANAGEMENT_MEMBER_CHANGE_ROLE = 'CHANNEL_MEMBER_MANAGEMENT_MEMBER_CHANGE_ROLE';
export const CHANNEL_MEMBER_MANAGEMENT_MEMBER_CHANGE_ROLE_SUCCESS = 'CHANNEL_MEMBER_MANAGEMENT_MEMBER_CHANGE_ROLE_SUCCESS';
export const CHANNEL_MEMBER_MANAGEMENT_MEMBER_CHANGE_ROLE_ERROR = 'CHANNEL_MEMBER_MANAGEMENT_MEMBER_CHANGE_ROLE_ERROR';
export const CHANNEL_MEMBER_MANAGEMENT_MEMBER_CHANGE_ROLE_NONE = 'CHANNEL_MEMBER_MANAGEMENT_MEMBER_CHANGE_ROLE_NONE';

/** channel member management : member list **/
export const CHANNEL_MEMBER_MANAGEMENT_MEMBER_LIST = 'CHANNEL_MEMBER_MANAGEMENT_MEMBER_LIST';
export const CHANNEL_MEMBER_MANAGEMENT_MEMBER_LIST_SUCCESS = 'CHANNEL_MEMBER_MANAGEMENT_MEMBER_LIST_SUCCESS';
export const CHANNEL_MEMBER_MANAGEMENT_MEMBER_LIST_ERROR = 'CHANNEL_MEMBER_MANAGEMENT_MEMBER_LIST_ERROR';
export const CHANNEL_MEMBER_MANAGEMENT_MEMBER_LIST_NONE = 'CHANNEL_MEMBER_MANAGEMENT_MEMBER_LIST_NONE';

/**** personal messages ****/
/** personal messages : message send **/
export const PERSONAL_MESSAGES_MESSAGE_SEND = 'PERSONAL_MESSAGES_MESSAGE_SEND';
export const PERSONAL_MESSAGES_MESSAGE_SEND_SUCCESS = 'PERSONAL_MESSAGES_MESSAGE_SEND_SUCCESS';
export const PERSONAL_MESSAGES_MESSAGE_SEND_ERROR = 'PERSONAL_MESSAGES_MESSAGE_SEND_ERROR';
export const PERSONAL_MESSAGES_MESSAGE_SEND_NONE = 'PERSONAL_MESSAGES_MESSAGE_SEND_NONE';

/** personal messages : message edit **/
export const PERSONAL_MESSAGES_MESSAGE_EDIT = 'PERSONAL_MESSAGES_MESSAGE_EDIT';
export const PERSONAL_MESSAGES_MESSAGE_EDIT_SUCCESS = 'PERSONAL_MESSAGES_MESSAGE_EDIT_SUCCESS';
export const PERSONAL_MESSAGES_MESSAGE_EDIT_ERROR = 'PERSONAL_MESSAGES_MESSAGE_EDIT_ERROR';
export const PERSONAL_MESSAGES_MESSAGE_EDIT_NONE = 'PERSONAL_MESSAGES_MESSAGE_EDIT_NONE';

/** personal messages : message delete **/
export const PERSONAL_MESSAGES_MESSAGE_DELETE = 'PERSONAL_MESSAGES_MESSAGE_DELETE';
export const PERSONAL_MESSAGES_MESSAGE_DELETE_SUCCESS = 'PERSONAL_MESSAGES_MESSAGE_DELETE_SUCCESS';
export const PERSONAL_MESSAGES_MESSAGE_DELETE_ERROR = 'PERSONAL_MESSAGES_MESSAGE_DELETE_ERROR';
export const PERSONAL_MESSAGES_MESSAGE_DELETE_NONE = 'PERSONAL_MESSAGES_MESSAGE_DELETE_NONE';

/** personal messages : message read **/
export const PERSONAL_MESSAGES_MESSAGE_READ = 'PERSONAL_MESSAGES_MESSAGE_READ';
export const PERSONAL_MESSAGES_MESSAGE_READ_SUCCESS = 'PERSONAL_MESSAGES_MESSAGE_READ_SUCCESS';
export const PERSONAL_MESSAGES_MESSAGE_READ_ERROR = 'PERSONAL_MESSAGES_MESSAGE_READ_ERROR';
export const PERSONAL_MESSAGES_MESSAGE_READ_NONE = 'PERSONAL_MESSAGES_MESSAGE_READ_NONE';

/** personal messages : message read all **/
export const PERSONAL_MESSAGES_MESSAGE_READ_ALL = 'PERSONAL_MESSAGES_MESSAGE_READ_ALL';
export const PERSONAL_MESSAGES_MESSAGE_READ_ALL_SUCCESS = 'PERSONAL_MESSAGES_MESSAGE_READ_ALL_SUCCESS';
export const PERSONAL_MESSAGES_MESSAGE_READ_ALL_ERROR = 'PERSONAL_MESSAGES_MESSAGE_READ_ALL_ERROR';
export const PERSONAL_MESSAGES_MESSAGE_READ_ALL_NONE = 'PERSONAL_MESSAGES_MESSAGE_READ_ALL_NONE';

/** personal messages : message list **/
export const PERSONAL_MESSAGES_MESSAGE_LIST = 'PERSONAL_MESSAGES_MESSAGE_LIST';
export const PERSONAL_MESSAGES_MESSAGE_LIST_SUCCESS = 'PERSONAL_MESSAGES_MESSAGE_LIST_SUCCESS';
export const PERSONAL_MESSAGES_MESSAGE_LIST_ERROR = 'PERSONAL_MESSAGES_MESSAGE_LIST_ERROR';
export const PERSONAL_MESSAGES_MESSAGE_LIST_NONE = 'PERSONAL_MESSAGES_MESSAGE_LIST_NONE';

/** personal messages : messages forward **/
export const PERSONAL_MESSAGES_MESSAGE_FORWARD = 'PERSONAL_MESSAGES_MESSAGE_FORWARD';
export const PERSONAL_MESSAGES_MESSAGE_FORWARD_SUCCESS = 'PERSONAL_MESSAGES_MESSAGE_FORWARD_SUCCESS';
export const PERSONAL_MESSAGES_MESSAGE_FORWARD_ERROR = 'PERSONAL_MESSAGES_MESSAGE_FORWARD_ERROR';
export const PERSONAL_MESSAGES_MESSAGE_FORWARD_NONE = 'PERSONAL_MESSAGES_MESSAGE_FORWARD_NONE';

/** personal messages : mute unmute notifications **/
export const PERSONAL_MESSAGES_MUTE_UNMUTE_NOTIFICATIONS = 'PERSONAL_MESSAGES_MUTE_UNMUTE_NOTIFICATIONS';
export const PERSONAL_MESSAGES_MUTE_UNMUTE_NOTIFICATIONS_SUCCESS = 'PERSONAL_MESSAGES_MUTE_UNMUTE_NOTIFICATIONS_SUCCESS';
export const PERSONAL_MESSAGES_MUTE_UNMUTE_NOTIFICATIONS_ERROR = 'PERSONAL_MESSAGES_MUTE_UNMUTE_NOTIFICATIONS_ERROR';
export const PERSONAL_MESSAGES_MUTE_UNMUTE_NOTIFICATIONS_NONE = 'PERSONAL_MESSAGES_MUTE_UNMUTE_NOTIFICATIONS_NONE';

/**** channel or group messages ****/
/** channel or group messages : message send **/
export const GROUP_MESSAGES_MESSAGE_SEND = 'GROUP_MESSAGES_MESSAGE_SEND';
export const GROUP_MESSAGES_MESSAGE_SEND_SUCCESS = 'GROUP_MESSAGES_MESSAGE_SEND_SUCCESS';
export const GROUP_MESSAGES_MESSAGE_SEND_ERROR = 'GROUP_MESSAGES_MESSAGE_SEND_ERROR';
export const GROUP_MESSAGES_MESSAGE_SEND_NONE = 'GROUP_MESSAGES_MESSAGE_SEND_NONE';

/** channel or group messages : message delete **/
export const GROUP_MESSAGES_MESSAGE_DELETE = 'GROUP_MESSAGES_MESSAGE_DELETE';
export const GROUP_MESSAGES_MESSAGE_DELETE_SUCCESS = 'GROUP_MESSAGES_MESSAGE_DELETE_SUCCESS';
export const GROUP_MESSAGES_MESSAGE_DELETE_ERROR = 'GROUP_MESSAGES_MESSAGE_DELETE_ERROR';
export const GROUP_MESSAGES_MESSAGE_DELETE_NONE = 'GROUP_MESSAGES_MESSAGE_DELETE_NONE';

/** channel or group messages : message read **/
export const GROUP_MESSAGES_MESSAGE_READ = 'GROUP_MESSAGES_MESSAGE_READ';
export const GROUP_MESSAGES_MESSAGE_READ_SUCCESS = 'GROUP_MESSAGES_MESSAGE_READ_SUCCESS';
export const GROUP_MESSAGES_MESSAGE_READ_ERROR = 'GROUP_MESSAGES_MESSAGE_READ_ERROR';
export const GROUP_MESSAGES_MESSAGE_READ_NONE = 'GROUP_MESSAGES_MESSAGE_READ_NONE';

/** channel or group messages : message list **/
export const GROUP_MESSAGES_MESSAGE_LIST = 'GROUP_MESSAGES_MESSAGE_LIST';
export const GROUP_MESSAGES_MESSAGE_LIST_SUCCESS = 'GROUP_MESSAGES_MESSAGE_LIST_SUCCESS';
export const GROUP_MESSAGES_MESSAGE_LIST_ERROR = 'GROUP_MESSAGES_MESSAGE_LIST_ERROR';
export const GROUP_MESSAGES_MESSAGE_LIST_NONE = 'GROUP_MESSAGES_MESSAGE_LIST_NONE';

/** channel or group messages : message read all**/
export const GROUP_MESSAGES_MESSAGE_READ_ALL = 'GROUP_MESSAGES_MESSAGE_READ_ALL';
export const GROUP_MESSAGES_MESSAGE_READ_ALL_SUCCESS = 'GROUP_MESSAGES_MESSAGE_READ_ALL_SUCCESS';
export const GROUP_MESSAGES_MESSAGE_READ_ALL_ERROR = 'GROUP_MESSAGES_MESSAGE_READ_ALL_ERROR';
export const GROUP_MESSAGES_MESSAGE_READ_ALL_NONE = 'GROUP_MESSAGES_MESSAGE_READ_ALL_NONE';

/** channel or group messages : edit message **/
export const GROUP_MESSAGES_EDIT_MESSAGE = 'GROUP_MESSAGES_EDIT_MESSAGE';
export const GROUP_MESSAGES_EDIT_MESSAGE_SUCCESS = 'GROUP_MESSAGES_EDIT_MESSAGE_SUCCESS';
export const GROUP_MESSAGES_EDIT_MESSAGE_ERROR = 'GROUP_MESSAGES_EDIT_MESSAGE_ERROR';
export const GROUP_MESSAGES_EDIT_MESSAGE_NONE = 'GROUP_MESSAGES_EDIT_MESSAGE_NONE';

/** channel or group messages : mark message as unread **/
export const GROUP_MESSAGES_MARK_MESSAGE_AS_UNREAD = 'GROUP_MESSAGES_MARK_MESSAGE_AS_UNREAD';
export const GROUP_MESSAGES_MARK_MESSAGE_AS_UNREAD_SUCCESS = 'GROUP_MESSAGES_MARK_MESSAGE_AS_UNREAD_SUCCESS';
export const GROUP_MESSAGES_MARK_MESSAGE_AS_UNREAD_ERROR = 'GROUP_MESSAGES_MARK_MESSAGE_AS_UNREAD_ERROR';
export const GROUP_MESSAGES_MARK_MESSAGE_AS_UNREAD_NONE = 'GROUP_MESSAGES_MARK_MESSAGE_AS_UNREAD_NONE';

/** channel or group messages : mark message as delivered **/
export const GROUP_MESSAGES_MARK_MESSAGE_AS_DELIVERED = 'GROUP_MESSAGES_MARK_MESSAGE_AS_DELIVERED';
export const GROUP_MESSAGES_MARK_MESSAGE_AS_DELIVERED_SUCCESS = 'GROUP_MESSAGES_MARK_MESSAGE_AS_DELIVERED_SUCCESS';
export const GROUP_MESSAGES_MARK_MESSAGE_AS_DELIVERED_ERROR = 'GROUP_MESSAGES_MARK_MESSAGE_AS_DELIVERED_ERROR';
export const GROUP_MESSAGES_MARK_MESSAGE_AS_DELIVERED_NONE = 'GROUP_MESSAGES_MARK_MESSAGE_AS_DELIVERED_NONE';

/** channel or group messages : delete messages for user **/
export const GROUP_MESSAGES_DELETE_MESSAGES_FOR_USER = 'GROUP_MESSAGES_DELETE_MESSAGES_FOR_USER';
export const GROUP_MESSAGES_DELETE_MESSAGES_FOR_USER_SUCCESS = 'GROUP_MESSAGES_DELETE_MESSAGES_FOR_USER_SUCCESS';
export const GROUP_MESSAGES_DELETE_MESSAGES_FOR_USER_ERROR = 'GROUP_MESSAGES_DELETE_MESSAGES_FOR_USER_ERROR';
export const GROUP_MESSAGES_DELETE_MESSAGES_FOR_USER_NONE = 'GROUP_MESSAGES_DELETE_MESSAGES_FOR_USER_NONE';

/** channel or group messages : clear messages for user **/
export const GROUP_MESSAGES_CLEAR_MESSAGES_FOR_USER = 'GROUP_MESSAGES_CLEAR_MESSAGES_FOR_USER';
export const GROUP_MESSAGES_CLEAR_MESSAGES_FOR_USER_SUCCESS = 'GROUP_MESSAGES_CLEAR_MESSAGES_FOR_USER_SUCCESS';
export const GROUP_MESSAGES_CLEAR_MESSAGES_FOR_USER_ERROR = 'GROUP_MESSAGES_CLEAR_MESSAGES_FOR_USER_ERROR';
export const GROUP_MESSAGES_CLEAR_MESSAGES_FOR_USER_NONE = 'GROUP_MESSAGES_CLEAR_MESSAGES_FOR_USER_NONE';

/** channel or group messages : react on message **/
export const GROUP_MESSAGES_REACT_ON_MESSAGE = 'GROUP_MESSAGES_REACT_ON_MESSAGE';
export const GROUP_MESSAGES_REACT_ON_MESSAGE_SUCCESS = 'GROUP_MESSAGES_REACT_ON_MESSAGE_SUCCESS';
export const GROUP_MESSAGES_REACT_ON_MESSAGE_ERROR = 'GROUP_MESSAGES_REACT_ON_MESSAGE_ERROR';
export const GROUP_MESSAGES_REACT_ON_MESSAGE_NONE = 'GROUP_MESSAGES_REACT_ON_MESSAGE_NONE';

/** channel or group messages : to get the reply message history **/
export const GROUP_MESSAGES_GET_MESSAGE_HISTORY = 'GROUP_MESSAGES_GET_MESSAGE_HISTORY';
export const GROUP_MESSAGES_GET_MESSAGE_HISTORY_SUCCESS = 'GROUP_MESSAGES_GET_MESSAGE_HISTORY_SUCCESS';
export const GROUP_MESSAGES_GET_MESSAGE_HISTORY_ERROR = 'GROUP_MESSAGES_GET_MESSAGE_HISTORY_ERROR';
export const GROUP_MESSAGES_GET_MESSAGE_HISTORY_NONE = 'GROUP_MESSAGES_GET_MESSAGE_HISTORY_NONE';

/** channel or group messages : to filter messages by message field **/
export const GROUP_MESSAGES_FILTER_MESSAGES_BY_FIELD = 'GROUP_MESSAGES_FILTER_MESSAGES_BY_FIELD';
export const GROUP_MESSAGES_FILTER_MESSAGES_BY_FIELD_SUCCESS = 'GROUP_MESSAGES_FILTER_MESSAGES_BY_FIELD_SUCCESS';
export const GROUP_MESSAGES_FILTER_MESSAGES_BY_FIELD_ERROR = 'GROUP_MESSAGES_FILTER_MESSAGES_BY_FIELD_ERROR';
export const GROUP_MESSAGES_FILTER_MESSAGES_BY_FIELD_NONE = 'GROUP_MESSAGES_FILTER_MESSAGES_BY_FIELD_NONE';

/** channel or group messages : get common channels between users **/
export const GROUP_MESSAGES_GET_COMMON_CHANNELS_BETWEEN_USERS = 'GROUP_MESSAGES_GET_COMMON_CHANNELS_BETWEEN_USERS';
export const GROUP_MESSAGES_GET_COMMON_CHANNELS_BETWEEN_USERS_SUCCESS = 'GROUP_MESSAGES_GET_COMMON_CHANNELS_BETWEEN_USERS_SUCCESS';
export const GROUP_MESSAGES_GET_COMMON_CHANNELS_BETWEEN_USERS_ERROR = 'GROUP_MESSAGES_GET_COMMON_CHANNELS_BETWEEN_USERS_ERROR';
export const GROUP_MESSAGES_GET_COMMON_CHANNELS_BETWEEN_USERS_NONE = 'GROUP_MESSAGES_GET_COMMON_CHANNELS_BETWEEN_USERS_NONE';

/** channel or group messages : messages forward **/
export const GROUP_MESSAGES_MESSAGE_FORWARD = 'GROUP_MESSAGES_MESSAGE_FORWARD';
export const GROUP_MESSAGES_MESSAGE_FORWARD_SUCCESS = 'GROUP_MESSAGES_MESSAGE_FORWARD_SUCCESS';
export const GROUP_MESSAGES_MESSAGE_FORWARD_ERROR = 'GROUP_MESSAGES_MESSAGE_FORWARD_ERROR';
export const GROUP_MESSAGES_MESSAGE_FORWARD_NONE = 'GROUP_MESSAGES_MESSAGE_FORWARD_NONE';

/** relay : in channel **/
export const RELAY_NOTIFY_IN_CHANNEL = 'RELAY_NOTIFY_IN_CHANNEL';
export const RELAY_NOTIFY_IN_CHANNEL_SUCCESS = 'RELAY_NOTIFY_IN_CHANNEL_SUCCESS';
export const RELAY_NOTIFY_IN_CHANNEL_ERROR = 'RELAY_NOTIFY_IN_CHANNEL_ERROR';
export const RELAY_NOTIFY_IN_CHANNEL_NONE = 'RELAY_NOTIFY_IN_CHANNEL_NONE';

/** relay : in dm **/
export const RELAY_NOTIFY_IN_DM = 'RELAY_NOTIFY_IN_DM';
export const RELAY_NOTIFY_IN_DM_SUCCESS = 'RELAY_NOTIFY_IN_DM_SUCCESS';
export const RELAY_NOTIFY_IN_DM_ERROR = 'RELAY_NOTIFY_IN_DM_ERROR';
export const RELAY_NOTIFY_IN_DM_NONE = 'RELAY_NOTIFY_IN_DM_NONE';

/** chat mist : get chat snapshot **/
export const CHAT_MISC_GET_CHAT_SNAPSHOT = 'CHAT_MISC_GET_CHAT_SNAPSHOT';
export const CHAT_MISC_GET_CHAT_SNAPSHOT_SUCCESS = 'CHAT_MISC_GET_CHAT_SNAPSHOT_SUCCESS';
export const CHAT_MISC_GET_CHAT_SNAPSHOT_ERROR = 'CHAT_MISC_GET_CHAT_SNAPSHOT_ERROR';
export const CHAT_MISC_GET_CHAT_SNAPSHOT_NONE = 'CHAT_MISC_GET_CHAT_SNAPSHOT_NONE';

/** user status : to update profile image **/
export const CHAT_USER_STATUS_UPDATE_PROFILE_IMAGE = 'CHAT_USER_STATUS_UPDATE_PROFILE_IMAGE';
export const CHAT_USER_STATUS_UPDATE_PROFILE_IMAGE_SUCCESS = 'CHAT_USER_STATUS_UPDATE_PROFILE_IMAGE_SUCCESS';
export const CHAT_USER_STATUS_UPDATE_PROFILE_IMAGE_ERROR = 'CHAT_USER_STATUS_UPDATE_PROFILE_IMAGE_ERROR';
export const CHAT_USER_STATUS_UPDATE_PROFILE_IMAGE_NONE = 'CHAT_USER_STATUS_UPDATE_PROFILE_IMAGE_NONE';

/** user status : to update display name **/
export const CHAT_USER_STATUS_UPDATE_DISPLAY_NAME = 'CHAT_USER_STATUS_UPDATE_DISPLAY_NAME';
export const CHAT_USER_STATUS_UPDATE_DISPLAY_NAME_SUCCESS = 'CHAT_USER_STATUS_UPDATE_DISPLAY_NAME_SUCCESS';
export const CHAT_USER_STATUS_UPDATE_DISPLAY_NAME_ERROR = 'CHAT_USER_STATUS_UPDATE_DISPLAY_NAME_ERROR';
export const CHAT_USER_STATUS_UPDATE_DISPLAY_NAME_NONE = 'CHAT_USER_STATUS_UPDATE_DISPLAY_NAME_NONE';

/** user status : to update status message **/
export const CHAT_USER_STATUS_UPDATE_STATUS_MESSAGE = 'CHAT_USER_STATUS_UPDATE_STATUS_MESSAGE';
export const CHAT_USER_STATUS_UPDATE_STATUS_MESSAGE_SUCCESS = 'CHAT_USER_STATUS_UPDATE_STATUS_MESSAGE_SUCCESS';
export const CHAT_USER_STATUS_UPDATE_STATUS_MESSAGE_ERROR = 'CHAT_USER_STATUS_UPDATE_STATUS_MESSAGE_ERROR';
export const CHAT_USER_STATUS_UPDATE_STATUS_MESSAGE_NONE = 'CHAT_USER_STATUS_UPDATE_STATUS_MESSAGE_NONE';

/** snapshot pin : to update snapshot pin status **/
export const CHAT_SNAPSHOT_UPDATE_PIN_STATUS = 'CHAT_SNAPSHOT_UPDATE_PIN_STATUS';
export const CHAT_SNAPSHOT_UPDATE_PIN_STATUS_SUCCESS = 'CHAT_SNAPSHOT_UPDATE_PIN_STATUS_SUCCESS';
export const CHAT_SNAPSHOT_UPDATE_PIN_STATUS_ERROR = 'CHAT_SNAPSHOT_UPDATE_PIN_STATUS_ERROR';
export const CHAT_SNAPSHOT_UPDATE_PIN_STATUS_NONE = 'CHAT_SNAPSHOT_UPDATE_PIN_STATUS_NONE';


/** chat misc : socket init success **/
export const CHAT_MISC_SOCKET_CONNECT_STATUS = 'CHAT_MISC_SOCKET_CONNECT_STATUS';
export const CHAT_PERSONAL_CHAT_MARK_READ_ALL_STATUS = 'CHAT_PERSONAL_CHAT_MARK_READ_ALL_STATUS';
export const CHAT_PERSONAL_RESET_SCROLL_STATUS = 'CHAT_PERSONAL_RESET_SCROLL_STATUS';
export const CHAT_SET_CURRENT_CHAT_ID = 'CHAT_SET_CURRENT_CHAT_ID';
export const CHAT_HOME_SEARCH_UPDATE_SEARCH_RESULT = 'CHAT_HOME_SEARCH_UPDATE_SEARCH_RESULT';
export const CHAT_NEW_CHAT_SEARCH_UPDATE_SEARCH_RESULT = 'CHAT_NEW_CHAT_SEARCH_UPDATE_SEARCH_RESULT';
export const CHAT_ADD_PARTICIPANTS_SEARCH_UPDATE_SEARCH_RESULT = 'CHAT_ADD_PARTICIPANTS_SEARCH_UPDATE_SEARCH_RESULT';
export const CHAT_ADD_PARTICIPANTS_ADD_USER = 'CHAT_ADD_PARTICIPANTS_ADD_USER';
export const CHAT_ADD_PARTICIPANTS_REMOVE_USER = 'CHAT_ADD_PARTICIPANTS_REMOVE_USER';
export const CHAT_ADD_PARTICIPANTS_RESET = 'CHAT_ADD_PARTICIPANTS_RESET';

export const CHAT_INSERT_CONTACT_TO_SNAPSHOT = 'CHAT_INSERT_CONTACT_TO_SNAPSHOT';


/**** tribe chat tag ****/
/** tag : channel **/
export const CHAT_TAG_CHANNEL = 'CHAT_TAG_CHANNEL';
export const CHAT_TAG_CHANNEL_SUCCESS = 'CHAT_TAG_CHANNEL_SUCCESS';
export const CHAT_TAG_CHANNEL_ERROR = 'CHAT_TAG_CHANNEL_ERROR';
export const CHAT_TAG_CHANNEL_NONE = 'CHAT_TAG_CHANNEL_NONE';

/** tag : dm **/
export const CHAT_TAG_DM = 'CHAT_TAG_DM';
export const CHAT_TAG_DM_SUCCESS = 'CHAT_TAG_DM_SUCCESS';
export const CHAT_TAG_DM_ERROR = 'CHAT_TAG_DM_ERROR';
export const CHAT_TAG_DM_NONE = 'CHAT_TAG_DM_NONE';

/** tag : channel message **/
export const CHAT_TAG_CHANNEL_MESSAGE = 'CHAT_TAG_CHANNEL_MESSAGE';
export const CHAT_TAG_CHANNEL_MESSAGE_SUCCESS = 'CHAT_TAG_CHANNEL_MESSAGE_SUCCESS';
export const CHAT_TAG_CHANNEL_MESSAGE_ERROR = 'CHAT_TAG_CHANNEL_MESSAGE_ERROR';
export const CHAT_TAG_CHANNEL_MESSAGE_NONE = 'CHAT_TAG_CHANNEL_MESSAGE_NONE';

/** tag : dm message **/
export const CHAT_TAG_DM_MESSAGE = 'CHAT_TAG_DM_MESSAGE';
export const CHAT_TAG_DM_MESSAGE_SUCCESS = 'CHAT_TAG_DM_MESSAGE_SUCCESS';
export const CHAT_TAG_DM_MESSAGE_ERROR = 'CHAT_TAG_DM_MESSAGE_ERROR';
export const CHAT_TAG_DM_MESSAGE_NONE = 'CHAT_TAG_DM_MESSAGE_NONE';

/** tag : get all tags **/
export const CHAT_TAG_GET_ALL_TAGS = 'CHAT_TAG_GET_ALL_TAGS';
export const CHAT_TAG_GET_ALL_TAGS_SUCCESS = 'CHAT_TAG_GET_ALL_TAGS_SUCCESS';
export const CHAT_TAG_GET_ALL_TAGS_ERROR = 'CHAT_TAG_GET_ALL_TAGS_ERROR';
export const CHAT_TAG_GET_ALL_TAGS_NONE = 'CHAT_TAG_GET_ALL_TAGS_NONE';

/** tag : get all channel message tags **/
export const CHAT_TAG_GET_CHANNEL_TAG_MESSAGES = 'CHAT_TAG_GET_CHANNEL_TAG_MESSAGES';
export const CHAT_TAG_GET_CHANNEL_TAG_MESSAGES_SUCCESS = 'CHAT_TAG_GET_CHANNEL_TAG_MESSAGES_SUCCESS';
export const CHAT_TAG_GET_CHANNEL_TAG_MESSAGES_ERROR = 'CHAT_TAG_GET_CHANNEL_TAG_MESSAGES_ERROR';
export const CHAT_TAG_GET_CHANNEL_TAG_MESSAGES_NONE = 'CHAT_TAG_GET_CHANNEL_TAG_MESSAGES_NONE';

/** tag : get all dm message tags **/
export const CHAT_TAG_GET_DM_TAG_MESSAGES = 'CHAT_TAG_GET_DM_TAG_MESSAGES';
export const CHAT_TAG_GET_DM_TAG_MESSAGES_SUCCESS = 'CHAT_TAG_GET_DM_TAG_MESSAGES_SUCCESS';
export const CHAT_TAG_GET_DM_TAG_MESSAGES_ERROR = 'CHAT_TAG_GET_DM_TAG_MESSAGES_ERROR';
export const CHAT_TAG_GET_DM_TAG_MESSAGES_NONE = 'CHAT_TAG_GET_DM_TAG_MESSAGES_NONE';

/** personal messages : mark message as unread unread **/
export const CHAT_DM_MESSAGE_MARK_AS_UNREAD = 'CHAT_DM_MESSAGE_MARK_AS_UNREAD';
export const CHAT_DM_MESSAGE_MARK_AS_UNREAD_SUCCESS = 'CHAT_DM_MESSAGE_MARK_AS_UNREAD_SUCCESS';
export const CHAT_DM_MESSAGE_MARK_AS_UNREAD_ERROR = 'CHAT_DM_MESSAGE_MARK_AS_UNREAD_ERROR';
export const CHAT_DM_MESSAGE_MARK_AS_UNREAD_NONE = 'CHAT_DM_MESSAGE_MARK_AS_UNREAD_NONE';

/** personal messages : mark message as delivered **/
export const CHAT_DM_MESSAGE_MARK_AS_DELIVERED = 'CHAT_DM_MESSAGE_MARK_AS_DELIVERED';
export const CHAT_DM_MESSAGE_MARK_AS_DELIVERED_SUCCESS = 'CHAT_DM_MESSAGE_MARK_AS_DELIVERED_SUCCESS';
export const CHAT_DM_MESSAGE_MARK_AS_DELIVERED_ERROR = 'CHAT_DM_MESSAGE_MARK_AS_DELIVERED_ERROR';
export const CHAT_DM_MESSAGE_MARK_AS_DELIVERED_NONE = 'CHAT_DM_MESSAGE_MARK_AS_DELIVERED_NONE';

/** personal messages : delete multiple messages **/
export const CHAT_DM_MESSAGE_DELETE_MULTIPLE_MESSAGES = 'CHAT_DM_MESSAGE_DELETE_MULTIPLE_MESSAGES';
export const CHAT_DM_MESSAGE_DELETE_MULTIPLE_MESSAGES_SUCCESS = 'CHAT_DM_MESSAGE_DELETE_MULTIPLE_MESSAGES_SUCCESS';
export const CHAT_DM_MESSAGE_DELETE_MULTIPLE_MESSAGES_ERROR = 'CHAT_DM_MESSAGE_DELETE_MULTIPLE_MESSAGES_ERROR';
export const CHAT_DM_MESSAGE_DELETE_MULTIPLE_MESSAGES_NONE = 'CHAT_DM_MESSAGE_DELETE_MULTIPLE_MESSAGES_NONE';

/** personal messages : clear  messages **/
export const CHAT_DM_MESSAGE_CLEAR_MESSAGES = 'CHAT_DM_MESSAGE_CLEAR_MESSAGES';
export const CHAT_DM_MESSAGE_CLEAR_MESSAGES_SUCCESS = 'CHAT_DM_MESSAGE_CLEAR_MESSAGES_SUCCESS';
export const CHAT_DM_MESSAGE_CLEAR_MESSAGES_ERROR = 'CHAT_DM_MESSAGE_CLEAR_MESSAGES_ERROR';
export const CHAT_DM_MESSAGE_CLEAR_MESSAGES_NONE = 'CHAT_DM_MESSAGE_CLEAR_MESSAGES_NONE';

/** personal messages : react on messages **/
export const CHAT_DM_MESSAGE_REACT_ON_MESSAGE = 'CHAT_DM_MESSAGE_REACT_ON_MESSAGE';
export const CHAT_DM_MESSAGE_REACT_ON_MESSAGE_SUCCESS = 'CHAT_DM_MESSAGE_REACT_ON_MESSAGE_SUCCESS';
export const CHAT_DM_MESSAGE_REACT_ON_MESSAGE_ERROR = 'CHAT_DM_MESSAGE_REACT_ON_MESSAGE_ERROR';
export const CHAT_DM_MESSAGE_REACT_ON_MESSAGE_NONE = 'CHAT_DM_MESSAGE_REACT_ON_MESSAGE_NONE';

/** personal messages : to purge messages **/
export const CHAT_DM_MESSAGE_PURGE_MESSAGES = 'CHAT_DM_MESSAGE_PURGE_MESSAGES';
export const CHAT_DM_MESSAGE_PURGE_MESSAGES_SUCCESS = 'CHAT_DM_MESSAGE_PURGE_MESSAGES_SUCCESS';
export const CHAT_DM_MESSAGE_PURGE_MESSAGES_ERROR = 'CHAT_DM_MESSAGE_PURGE_MESSAGES_ERROR';
export const CHAT_DM_MESSAGE_PURGE_MESSAGES_NONE = 'CHAT_DM_MESSAGE_PURGE_MESSAGES_NONE';

/** personal messages : to get the reply message history **/
export const CHAT_DM_MESSAGE_GET_MESSAGE_HISTORY = 'CHAT_DM_MESSAGE_GET_MESSAGE_HISTORY';
export const CHAT_DM_MESSAGE_GET_MESSAGE_HISTORY_SUCCESS = 'CHAT_DM_MESSAGE_GET_MESSAGE_HISTORY_SUCCESS';
export const CHAT_DM_MESSAGE_GET_MESSAGE_HISTORY_ERROR = 'CHAT_DM_MESSAGE_GET_MESSAGE_HISTORY_ERROR';
export const CHAT_DM_MESSAGE_GET_MESSAGE_HISTORY_NONE = 'CHAT_DM_MESSAGE_GET_MESSAGE_HISTORY_NONE';

/** personal messages : to filter messages by message field **/
export const CHAT_DM_MESSAGE_FILTER_MESSAGES_BY_FIELD = 'CHAT_DM_MESSAGE_FILTER_MESSAGES_BY_FIELD';
export const CHAT_DM_MESSAGE_FILTER_MESSAGES_BY_FIELD_SUCCESS = 'CHAT_DM_MESSAGE_FILTER_MESSAGES_BY_FIELD_SUCCESS';
export const CHAT_DM_MESSAGE_FILTER_MESSAGES_BY_FIELD_ERROR = 'CHAT_DM_MESSAGE_FILTER_MESSAGES_BY_FIELD_ERROR';
export const CHAT_DM_MESSAGE_FILTER_MESSAGES_BY_FIELD_NONE = 'CHAT_DM_MESSAGE_FILTER_MESSAGES_BY_FIELD_NONE';


/** call : reject call event **/
export const CALL_EVENT_REJECT_CALL = 'CALL_EVENT_REJECT_CALL';
export const CALL_EVENT_JOIN_CALL = 'CALL_EVENT_JOIN_CALL';
export const CALL_EVENT_START_CALL = 'CALL_EVENT_START_CALL';
export const CALL_EVENT_RESET_CALL = 'CALL_EVENT_RESET_CALL';
