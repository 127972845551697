
/**** private room ****/
/** To get private room list **/
export const USER_PRIVATE_ROOM_GET_LIST = 'USER_PRIVATE_ROOM_GET_LIST';
export const USER_PRIVATE_ROOM_GET_LIST_SUCCESS = 'USER_PRIVATE_ROOM_GET_LIST_SUCCESS';
export const USER_PRIVATE_ROOM_GET_LIST_ERROR = 'USER_PRIVATE_ROOM_GET_LIST_ERROR';
export const USER_PRIVATE_ROOM_GET_LIST_NONE = 'USER_PRIVATE_ROOM_GET_LIST_NONE';

/** to save private room **/
export const USER_PRIVATE_ROOM_SAVE = 'USER_PRIVATE_ROOM_SAVE';
export const USER_PRIVATE_ROOM_SAVE_SUCCESS = 'USER_PRIVATE_ROOM_SAVE_SUCCESS';
export const USER_PRIVATE_ROOM_SAVE_ERROR = 'USER_PRIVATE_ROOM_SAVE_ERROR';
export const USER_PRIVATE_ROOM_SAVE_NONE = 'USER_PRIVATE_ROOM_SAVE_NONE';


/** to delete private room **/
export const USER_PRIVATE_ROOM_DELETE = 'USER_PRIVATE_ROOM_DELETE';
export const USER_PRIVATE_ROOM_DELETE_SUCCESS = 'USER_PRIVATE_ROOM_DELETE_SUCCESS';
export const USER_PRIVATE_ROOM_DELETE_ERROR = 'USER_PRIVATE_ROOM_DELETE_ERROR';
export const USER_PRIVATE_ROOM_DELETE_NONE = 'USER_PRIVATE_ROOM_DELETE_NONE';

export const USER_PRIVATE_ROOM_SET_CURRENT_ROOM = 'USER_PRIVATE_ROOM_SET_CURRENT_ROOM';