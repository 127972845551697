import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import axios from "axios";
import {
    URL_USER_UPDATE_PASSWORD,
    URL_USER_UPDATE_PROFILE_DETAILS,
} from "../../../util/tribe/urls";

import AppUtils from "../../../util/tribe/utils/appUtils";
import {
    USER_SETTINGS_CHANGE_PASSWORD,
    USER_SETTINGS_UPDATE_PROFILE
} from "../../../constants/actions/user_settings/userSettingsActionTypes";
import {
    userSettingsChangePasswordError,
    userSettingsChangePasswordSuccess,
    userSettingsUpdateProfileDetailsError,
    userSettingsUpdateProfileDetailsSuccess
} from "../../actions/user_settings/userActions";

/** http : user settings : change password **/
const httpSagaUserSettingsChangePassword = async (data) =>
    await axios.post(URL_USER_UPDATE_PASSWORD, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : user settings : update profile details **/
const httpSagaUserSettingsUpdateProfileDetails = async (data) =>
    await axios.post(URL_USER_UPDATE_PROFILE_DETAILS, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });


/**** ****/

/** handler : user settings : change password **/
function* handleHttpSagaUserSettingsChangePassword({payload}) {
    try {
        const result = yield call(httpSagaUserSettingsChangePassword, payload);
        if (result.message) {
            yield put(userSettingsChangePasswordError(result.message));
        } else {
            yield put(userSettingsChangePasswordSuccess(result.data));
        }
    } catch (error) {
        yield put(userSettingsChangePasswordError(error));
    }
}

/** handler : user settings : update profile details **/
function* handleHttpSagaUserSettingsUpdateProfileDetails({payload}) {
    try {
        const result = yield call(httpSagaUserSettingsUpdateProfileDetails, payload);
        if (result.message) {
            yield put(userSettingsUpdateProfileDetailsError(result.message));
        } else {
            yield put(userSettingsUpdateProfileDetailsSuccess(result.data));
        }
    } catch (error) {
        yield put(userSettingsUpdateProfileDetailsError(error));
    }
}

/** watcher : user settings : change password **/
export function* sagaUserSettingsChangePassword() {
    yield takeEvery(USER_SETTINGS_CHANGE_PASSWORD, handleHttpSagaUserSettingsChangePassword);
}

/** watcher : user settings : update profile details **/
export function* sagaUserSettingsUpdateProfileDetails() {
    yield takeEvery(USER_SETTINGS_UPDATE_PROFILE, handleHttpSagaUserSettingsUpdateProfileDetails);
}

export default function* rootSaga() {
    yield all([
        fork(sagaUserSettingsChangePassword),
        fork(sagaUserSettingsUpdateProfileDetails)
    ])
}
