import React from 'react';

const TribeOverflowMenu = ({params}) => {
    
    let subContainerClick = (event) => {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
    }
    
    let renderHeader = () => {
        return (
            <div className="tribe_overflow_menu_header">
                <div className="tribe_overflow_menu_header_back_button hand" onClick={() => params.handleRequestClose && params.handleRequestClose(params['key'], true)}>
                </div>
                <div className="tribe_overflow_menu_header_title">
                    {params.header && params.header}
                </div>
            </div>
        )
    }
    
    
    return (
        
        <div className={"tribe_overflow_menu"} onClick={() => params.handleRequestClose && params.handleRequestClose(params['key'], false)}>
            <div className={"tribe_overflow_menu_content " + (params["isRight"] ? "tribe_overflow_menu_content_right" : "tribe_overflow_menu_content_left")} onClick={subContainerClick}>
                {renderHeader()}
                <div className="tribe_overflow_menu_margin">
                    {params.content && params.content()}
                </div>
            
            </div>
        </div>
    )
};

export default TribeOverflowMenu;
