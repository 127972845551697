
/**** clients ****/
/** get clients list **/
export const CLIENTS_GET_ALL_CLIENTS = 'CLIENTS_GET_ALL_CLIENTS';
export const CLIENTS_GET_ALL_CLIENTS_SUCCESS = 'CLIENTS_GET_ALL_CLIENTS_SUCCESS';
export const CLIENTS_GET_ALL_CLIENTS_ERROR = 'CLIENTS_GET_ALL_CLIENTS_ERROR';
export const CLIENTS_GET_ALL_CLIENTS_NONE = 'CLIENTS_GET_ALL_CLIENTS_NONE';

/** save client **/
export const CLIENTS_SAVE_CLIENT = 'CLIENTS_SAVE_CLIENT';
export const CLIENTS_SAVE_CLIENT_SUCCESS = 'CLIENTS_SAVE_CLIENT_SUCCESS';
export const CLIENTS_SAVE_CLIENT_ERROR = 'CLIENTS_SAVE_CLIENT_ERROR';
export const CLIENTS_SAVE_CLIENT_NONE = 'CLIENTS_SAVE_CLIENT_NONE';

/** deactivate client **/
export const CLIENTS_DEACTIVATE_CLIENT = 'CLIENTS_DEACTIVATE_CLIENT';
export const CLIENTS_DEACTIVATE_CLIENT_SUCCESS = 'CLIENTS_DEACTIVATE_CLIENT_SUCCESS';
export const CLIENTS_DEACTIVATE_CLIENT_ERROR = 'CLIENTS_DEACTIVATE_CLIENT_ERROR';
export const CLIENTS_DEACTIVATE_CLIENT_NONE = 'CLIENTS_DEACTIVATE_CLIENT_NONE';

/** to get clients features list **/
export const CLIENTS_GET_CLIENT_FEATURES = 'CLIENTS_GET_CLIENT_FEATURES';
export const CLIENTS_GET_CLIENT_FEATURES_SUCCESS = 'CLIENTS_GET_CLIENT_FEATURES_SUCCESS';
export const CLIENTS_GET_CLIENT_FEATURES_ERROR = 'CLIENTS_GET_CLIENT_FEATURES_ERROR';
export const CLIENTS_GET_CLIENT_FEATURES_NONE = 'CLIENTS_GET_CLIENT_FEATURES_NONE';

/** to set clients feature private room status **/
export const CLIENTS_SET_CLIENT_FEATURE_PRIVATE_ROOM_STATUS = 'CLIENTS_SET_CLIENT_FEATURE_PRIVATE_ROOM_STATUS';
export const CLIENTS_SET_CLIENT_FEATURE_PRIVATE_ROOM_STATUS_SUCCESS = 'CLIENTS_SET_CLIENT_FEATURE_PRIVATE_ROOM_STATUS_SUCCESS';
export const CLIENTS_SET_CLIENT_FEATURE_PRIVATE_ROOM_STATUS_ERROR = 'CLIENTS_SET_CLIENT_FEATURE_PRIVATE_ROOM_STATUS_ERROR';
export const CLIENTS_SET_CLIENT_FEATURE_PRIVATE_ROOM_STATUS_NONE = 'CLIENTS_SET_CLIENT_FEATURE_PRIVATE_ROOM_STATUS_NONE';

/** to update client voip notification config **/
export const CLIENTS_SET_UPDATE_VOIP_NOTIFICATION_CONFIG = 'CLIENTS_SET_UPDATE_VOIP_NOTIFICATION_CONFIG';
export const CLIENTS_SET_UPDATE_VOIP_NOTIFICATION_CONFIG_SUCCESS = 'CLIENTS_SET_UPDATE_VOIP_NOTIFICATION_CONFIG_SUCCESS';
export const CLIENTS_SET_UPDATE_VOIP_NOTIFICATION_CONFIG_ERROR = 'CLIENTS_SET_UPDATE_VOIP_NOTIFICATION_CONFIG_ERROR';
export const CLIENTS_SET_UPDATE_VOIP_NOTIFICATION_CONFIG_NONE = 'CLIENTS_SET_UPDATE_VOIP_NOTIFICATION_CONFIG_NONE';

/** to get client voip notification config **/
export const CLIENTS_GET_VOIP_NOTIFICATION_CONFIG = 'CLIENTS_GET_VOIP_NOTIFICATION_CONFIG';
export const CLIENTS_GET_VOIP_NOTIFICATION_CONFIG_SUCCESS = 'CLIENTS_GET_VOIP_NOTIFICATION_CONFIG_SUCCESS';
export const CLIENTS_GET_VOIP_NOTIFICATION_CONFIG_ERROR = 'CLIENTS_GET_VOIP_NOTIFICATION_CONFIG_ERROR';
export const CLIENTS_GET_VOIP_NOTIFICATION_CONFIG_NONE = 'CLIENTS_GET_VOIP_NOTIFICATION_CONFIG_NONE';


/** to set current client in view page **/
export const CLIENTS_SET_CURRENT_CLIENT = 'CLIENTS_SET_CURRENT_CLIENT';

/** to filter clients **/
export const CLIENTS_FILTER_CLIENTS = 'CLIENTS_FILTER_CLIENTS';


/** to get client fcm notification config **/
export const CLIENTS_GET_FCM_NOTIFICATION_CONFIG = 'CLIENTS_GET_FCM_NOTIFICATION_CONFIG';
export const CLIENTS_GET_FCM_NOTIFICATION_CONFIG_SUCCESS = 'CLIENTS_GET_FCM_NOTIFICATION_CONFIG_SUCCESS';
export const CLIENTS_GET_FCM_NOTIFICATION_CONFIG_ERROR = 'CLIENTS_GET_FCM_NOTIFICATION_CONFIG_ERROR';
export const CLIENTS_GET_FCM_NOTIFICATION_CONFIG_NONE = 'CLIENTS_GET_FCM_NOTIFICATION_CONFIG_NONE';


/** to update client voip notification config **/
export const CLIENTS_UPDATE_FCM_NOTIFICATION_CONFIG = 'CLIENTS_UPDATE_FCM_NOTIFICATION_CONFIG';
export const CLIENTS_UPDATE_FCM_NOTIFICATION_CONFIG_SUCCESS = 'CLIENTS_UPDATE_FCM_NOTIFICATION_CONFIG_SUCCESS';
export const CLIENTS_UPDATE_FCM_NOTIFICATION_CONFIG_ERROR = 'CLIENTS_UPDATE_FCM_NOTIFICATION_CONFIG_ERROR';
export const CLIENTS_UPDATE_FCM_NOTIFICATION_CONFIG_NONE = 'CLIENTS_UPDATE_FCM_NOTIFICATION_CONFIG_NONE';