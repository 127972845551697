import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import axios from "axios";
import {
    URL_CHAT_RELAY_IN_CHANNEL,
    URL_CHAT_RELAY_IN_DM
} from "../../../util/tribe/urls";
import {
    RELAY_NOTIFY_IN_CHANNEL,
    RELAY_NOTIFY_IN_DM
} from "../../../constants/actions/chat/ChatActionTypes";
import AppUtils from "../../../util/tribe/utils/appUtils";
import {
    relayNotifyInChannelError,
    relayNotifyInChannelSuccess,
    relayNotifyDMError,
    relayNotifyDMSuccess
} from "../../actions";

/** http : to relay in channel **/
const httpRelayNotifyInChannel = async (data) =>
    await axios.post(URL_CHAT_RELAY_IN_CHANNEL, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : to relay in dm **/
const httpSagaRelayNotifyDM = async (data) =>
    await axios.post(URL_CHAT_RELAY_IN_DM, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** handler : to relay in channel **/
function* handleHttpRelayNotifyInChannel({payload}) {
    try {
        const result = yield call(httpRelayNotifyInChannel, payload);
        if (result.message) {
            yield put(relayNotifyInChannelError(result.message));
        } else {
            yield put(relayNotifyInChannelSuccess(result.data));
        }
    } catch (error) {
        yield put(relayNotifyInChannelError(error));
    }
}

/** handler : to relay in dm **/
function* handleHttpSagaRelayNotifyDM({payload}) {
    try {
        const result = yield call(httpSagaRelayNotifyDM, payload);
        if (result.message) {
            yield put(relayNotifyDMError(result.message));
        } else {
            yield put(relayNotifyDMSuccess(result.data));
        }
    } catch (error) {
        yield put(relayNotifyDMError(error));
    }
}

/** watcher : relay : update in channel **/
export function* relayNotifyInChannel() {
    yield takeEvery(RELAY_NOTIFY_IN_CHANNEL, handleHttpRelayNotifyInChannel);
}

/** watcher : relay : update in dm **/
export function* sagaRelayNotifyDM() {
    yield takeEvery(RELAY_NOTIFY_IN_DM, handleHttpSagaRelayNotifyDM);
}

export default function* rootSaga() {
    yield all([
        fork(relayNotifyInChannel),
        fork(sagaRelayNotifyDM)
    ])
}
