import {
    CALL_EVENT_JOIN_CALL,
    CALL_EVENT_REJECT_CALL,
    CALL_EVENT_RESET_CALL,
    CALL_EVENT_START_CALL,
    CHANNEL_MANAGEMENT_CHANNEL_CHANGE_OWNERSHIP,
    CHANNEL_MANAGEMENT_CHANNEL_CHANGE_OWNERSHIP_ERROR,
    CHANNEL_MANAGEMENT_CHANNEL_CHANGE_OWNERSHIP_NONE,
    CHANNEL_MANAGEMENT_CHANNEL_CREATE,
    CHANNEL_MANAGEMENT_CHANNEL_CREATE_ERROR,
    CHANNEL_MANAGEMENT_CHANNEL_CREATE_NONE,
    CHANNEL_MANAGEMENT_CHANNEL_DELETE,
    CHANNEL_MANAGEMENT_CHANNEL_DELETE_ERROR,
    CHANNEL_MANAGEMENT_CHANNEL_DELETE_NONE,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_LEAVE,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_LEAVE_ERROR,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_LEAVE_NONE,
    CHAT_ADD_PARTICIPANTS_ADD_USER,
    CHAT_ADD_PARTICIPANTS_REMOVE_USER,
    CHAT_ADD_PARTICIPANTS_RESET,
    CHAT_ADD_PARTICIPANTS_SEARCH_UPDATE_SEARCH_RESULT,
    CHAT_DM_MESSAGE_CLEAR_MESSAGES,
    CHAT_DM_MESSAGE_CLEAR_MESSAGES_ERROR,
    CHAT_DM_MESSAGE_CLEAR_MESSAGES_NONE,
    CHAT_DM_MESSAGE_PURGE_MESSAGES,
    CHAT_DM_MESSAGE_PURGE_MESSAGES_ERROR,
    CHAT_DM_MESSAGE_PURGE_MESSAGES_NONE,
    CHAT_DM_MESSAGE_REACT_ON_MESSAGE,
    CHAT_DM_MESSAGE_REACT_ON_MESSAGE_ERROR,
    CHAT_DM_MESSAGE_REACT_ON_MESSAGE_NONE,
    CHAT_DM_MESSAGE_REACT_ON_MESSAGE_SUCCESS,
    CHAT_GET_ALL_OTHER_CONTACTS,
    CHAT_GET_ALL_OTHER_CONTACTS_ERROR,
    CHAT_GET_ALL_OTHER_CONTACTS_NONE,
    CHAT_GET_ALL_OTHER_CONTACTS_SUCCESS,
    CHAT_HOME_SEARCH_UPDATE_SEARCH_RESULT,
    CHAT_INSERT_CONTACT_TO_SNAPSHOT,
    CHAT_MISC_SOCKET_CONNECT_STATUS,
    CHAT_NEW_CHAT_SEARCH_UPDATE_SEARCH_RESULT,
    CHAT_PERSONAL_CHAT_MARK_READ_ALL_STATUS,
    CHAT_PERSONAL_RESET_SCROLL_STATUS,
    CHAT_SET_CURRENT_CHAT_ID,
    CHAT_TAG_GET_CHANNEL_TAG_MESSAGES,
    CHAT_TAG_GET_DM_TAG_MESSAGES,
    DEVICE_MANAGEMENT_DEVICE_ATTACH,
    GROUP_MESSAGES_CLEAR_MESSAGES_FOR_USER,
    GROUP_MESSAGES_CLEAR_MESSAGES_FOR_USER_ERROR,
    GROUP_MESSAGES_CLEAR_MESSAGES_FOR_USER_NONE,
    GROUP_MESSAGES_EDIT_MESSAGE,
    GROUP_MESSAGES_EDIT_MESSAGE_ERROR,
    GROUP_MESSAGES_EDIT_MESSAGE_NONE,
    GROUP_MESSAGES_MESSAGE_LIST,
    GROUP_MESSAGES_MESSAGE_READ_ALL,
    GROUP_MESSAGES_MESSAGE_SEND,
    GROUP_MESSAGES_REACT_ON_MESSAGE,
    GROUP_MESSAGES_REACT_ON_MESSAGE_ERROR,
    GROUP_MESSAGES_REACT_ON_MESSAGE_NONE,
    PERSONAL_MESSAGES_MESSAGE_EDIT,
    PERSONAL_MESSAGES_MESSAGE_EDIT_ERROR,
    PERSONAL_MESSAGES_MESSAGE_EDIT_NONE,
    PERSONAL_MESSAGES_MESSAGE_LIST,
    PERSONAL_MESSAGES_MESSAGE_READ_ALL,
    PERSONAL_MESSAGES_MESSAGE_SEND,
    USER_MANAGEMENT_GET_USER_ACTIVE_STATUS_SUCCESS
} from "../../../constants/actions/chat/ChatActionTypes";
import {
    HTTP_REQUEST_STATUS,
    MESSAGE_ROUTER_CHANNEL_READ_ACCESS_INDEX,
    MESSAGE_ROUTER_CHANNEL_READ_ACCESS_TYPES,
    MESSAGE_ROUTER_CHANNEL_TYPES,
    MESSAGE_ROUTER_USER_STATUS,
    MESSAGE_ROUTER_USER_STATUS_VALUES,
    MESSAGE_TAG_TYPES,
    MSG_ROUTER_EVENTS,
    MSG_ROUTER_RELAY_TYPES,
    USER_STATUS_DEFAULT_GROUP_STATUS
} from "../../../util/tribe/constants/config";
import {
    SET_DEVICE_ID
} from "../../../constants/ActionTypes";
import AppUtils from "../../../util/tribe/utils/appUtils";
import IntlMessages from "../../../util/IntlMessages";
import {chatBaseDownloadUrl} from "../../../util/tribe/keys";
import ChatParserUtils from "../../../util/tribe/chat_parser/chatParsingUtils";
import {TribeChatSocketIOHandler} from "../../../util/tribe/socket_io/tribeChatSocketIOHandler";

const SNAPSHOT_LIST_MENU_OPTIONS = [
    {
        "type": "DELETE_CHAT",
        "labelKey": "DIC_KEY_CHAT_INFO_ACTION_LABEL_DELETE_CHAT"
    },
    {
        "type": "MARK_AS_UNREAD",
        "labelKey": "DIC_KEY_CHAT_INFO_ACTION_LABEL_MARK_AS_UNREAD"
    }
];

const MESSAGE_HOVER_ACTIONS = {
    "REACT_ON_MESSAGE": {
        "key": "REACT_ON_MESSAGE",
        "className": "tribe_transparent_emoji"
    },
    "FORWARD_MESSAGE": {
        "key": "FORWARD_MESSAGE",
        "className": "tribe_transparent_forward"
    }
};

const HEADER_OVERFLOW_MENU_OPTIONS_DM = [
    {
        "type": "HEADER_CONTACT_INFORMATION",
        "labelKey": "DIC_KEY_CHAT_INFO_ACTION_LABEL_CONTACT_INFORMATION"
    },
    {
        "type": "HEADER_SELECT_MESSAGES",
        "labelKey": "DIC_KEY_CHAT_INFO_ACTION_LABEL_SELECT_MESSAGES"
    },
    {
        "type": "HEADER_CLOSE_CHAT",
        "labelKey": "DIC_KEY_CHAT_INFO_ACTION_LABEL_CLOSE_CHAT"
    },
    {
        "type": "HEADER_CLEAR_MESSAGES",
        "labelKey": "DIC_KEY_CHAT_INFO_ACTION_LABEL_CLEAR_MESSAGES"
    },
    {
        "type": "HEADER_DELETE_CHAT",
        "labelKey": "DIC_KEY_CHAT_INFO_ACTION_LABEL_DELETE_CHAT"
    },
    {
      "type": "HEADER_DISAPPEAR_MESSAGES",
      "labelKey": "DIC_KEY_CHAT_INFO_ACTION_LABEL_DISAPPEAR_MESSAGES"
    }
];

const HEADER_OVERFLOW_MENU_OPTIONS_GROUP = {
    "s": [
        {
            "type": "HEADER_GROUP_INFORMATION",
            "labelKey": "DIC_KEY_CHAT_INFO_ACTION_LABEL_GROUP_INFORMATION"
        },
        {
            "type": "HEADER_SELECT_MESSAGES",
            "labelKey": "DIC_KEY_CHAT_INFO_ACTION_LABEL_SELECT_MESSAGES"
        },
        {
            "type": "HEADER_CLEAR_MESSAGES",
            "labelKey": "DIC_KEY_CHAT_INFO_ACTION_LABEL_CLEAR_MESSAGES"
        },
        {
            "type": "HEADER_EXIT_GROUP",
            "labelKey": "DIC_KEY_CHAT_INFO_ACTION_LABEL_EXIT_GROUP"
        },
        {
          "type": "HEADER_DISAPPEAR_MESSAGES",
          "labelKey": "DIC_KEY_CHAT_INFO_ACTION_LABEL_DISAPPEAR_MESSAGES"
        }
    ],
    "r": [
        {
            "type": "HEADER_GROUP_INFORMATION",
            "labelKey": "DIC_KEY_CHAT_INFO_ACTION_LABEL_GROUP_INFORMATION"
        },
        {
            "type": "HEADER_SELECT_MESSAGES",
            "labelKey": "DIC_KEY_CHAT_INFO_ACTION_LABEL_SELECT_MESSAGES"
        },
        {
            "type": "HEADER_CLEAR_MESSAGES",
            "labelKey": "DIC_KEY_CHAT_INFO_ACTION_LABEL_CLEAR_MESSAGES"
        },
        {
            "type": "HEADER_EXIT_GROUP",
            "labelKey": "DIC_KEY_CHAT_INFO_ACTION_LABEL_EXIT_GROUP"
        },
        {
          "type": "HEADER_DISAPPEAR_MESSAGES",
          "labelKey": "DIC_KEY_CHAT_INFO_ACTION_LABEL_DISAPPEAR_MESSAGES"
        }
    ],
    "w": [
        {
            "type": "HEADER_GROUP_INFORMATION",
            "labelKey": "DIC_KEY_CHAT_INFO_ACTION_LABEL_GROUP_INFORMATION"
        },
        {
            "type": "HEADER_SELECT_MESSAGES",
            "labelKey": "DIC_KEY_CHAT_INFO_ACTION_LABEL_SELECT_MESSAGES"
        },
        {
            "type": "HEADER_CLEAR_MESSAGES",
            "labelKey": "DIC_KEY_CHAT_INFO_ACTION_LABEL_CLEAR_MESSAGES"
        },
        {
            "type": "HEADER_EXIT_GROUP",
            "labelKey": "DIC_KEY_CHAT_INFO_ACTION_LABEL_EXIT_GROUP"
        },
        {
          "type": "HEADER_DISAPPEAR_MESSAGES",
          "labelKey": "DIC_KEY_CHAT_INFO_ACTION_LABEL_DISAPPEAR_MESSAGES"
        }
    ],
    "x": [
        {
            "type": "HEADER_GROUP_INFORMATION",
            "labelKey": "DIC_KEY_CHAT_INFO_ACTION_LABEL_GROUP_INFORMATION"
        },
        {
            "type": "HEADER_SELECT_MESSAGES",
            "labelKey": "DIC_KEY_CHAT_INFO_ACTION_LABEL_SELECT_MESSAGES"
        },
        {
            "type": "HEADER_CLEAR_MESSAGES",
            "labelKey": "DIC_KEY_CHAT_INFO_ACTION_LABEL_CLEAR_MESSAGES"
        },
        {
            "type": "HEADER_EXIT_GROUP",
            "labelKey": "DIC_KEY_CHAT_INFO_ACTION_LABEL_EXIT_GROUP"
        },
        {
          "type": "HEADER_DISAPPEAR_MESSAGES",
          "labelKey": "DIC_KEY_CHAT_INFO_ACTION_LABEL_DISAPPEAR_MESSAGES"
        }
    ]
};

const MESSAGE_OVERFLOW_MENU_OPTIONS = {
    "sent": [
        {
            "type": "MESSAGE_MENU_MESSAGE_INFO",
            "labelKey": "DIC_KEY_CHAT_INFO_ACTION_LABEL_MESSAGE_INFORMATION"
        },
        {
            "type": "MESSAGE_MENU_REPLY",
            "labelKey": "DIC_KEY_CHAT_INFO_ACTION_LABEL_REPLY"
        },
        {
            "type": "MESSAGE_MENU_DELETE_MESSAGE",
            "labelKey": "DIC_KEY_CHAT_INFO_ACTION_LABEL_DELETE_MESSAGE"
        }
    ],
    "dm_received": [
        {
            "type": "MESSAGE_MENU_REPLY",
            "labelKey": "DIC_KEY_CHAT_INFO_ACTION_LABEL_REPLY"
        },
        {
            "type": "MESSAGE_MENU_DELETE_MESSAGE",
            "labelKey": "DIC_KEY_CHAT_INFO_ACTION_LABEL_DELETE_MESSAGE"
        }
    ],
    "group_received": [
        {
            "type": "MESSAGE_MENU_REPLY",
            "labelKey": "DIC_KEY_CHAT_INFO_ACTION_LABEL_REPLY"
        },
        {
            "type": "MESSAGE_MENU_REPLY_PRIVATELY",
            "labelKey": "DIC_KEY_CHAT_INFO_ACTION_LABEL_REPLY_PRIVATELY"
        },
        {
            "type": "MESSAGE_MENU_DELETE_MESSAGE",
            "labelKey": "DIC_KEY_CHAT_INFO_ACTION_LABEL_DELETE_MESSAGE"
        }
    ]
}

const INIT_STATE = {
    SNAPSHOT_LIST_MENU_OPTIONS: SNAPSHOT_LIST_MENU_OPTIONS,
    HEADER_OVERFLOW_MENU_OPTIONS_DM: HEADER_OVERFLOW_MENU_OPTIONS_DM,
    HEADER_OVERFLOW_MENU_OPTIONS_GROUP: HEADER_OVERFLOW_MENU_OPTIONS_GROUP,
    MESSAGE_OVERFLOW_MENU_OPTIONS: MESSAGE_OVERFLOW_MENU_OPTIONS,
    MESSAGE_HOVER_ACTIONS: MESSAGE_HOVER_ACTIONS,

    chatAllOtherContactsGetStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    registerDeviceStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    saveChannelStatus: HTTP_REQUEST_STATUS.NOT_STARTED,

    channelDeleteStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    channelClearMessagesStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    dmClearMessagesStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    dmExitStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    groupOwnershipChangeStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    groupMemberLeaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    groupMemberLeftStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    dmMessageReactStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    channelMessageReactStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    dmEditMessagesStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    groupEditMessagesStatus: HTTP_REQUEST_STATUS.NOT_STARTED,

    selectedSnapshot: {},

    groupMemberLeftChannelId: "",
    deletedChannelId: "",


    personalMessagesReadAllStatus: false,
    personalMessagesViewId: "",
    messageCounter: 0,
    chatListRefreshed: false,

    selectedChatIndexId: "",
    selectedChatId: "",
    selectedChatType: "",
    socketConnectStatus: false,
    chatAllOtherUsers: [],
    chatAllOtherUsersMap: {},
    chatAllOtherUsersMapList: {},
    chatAllOtherUsersMapFilterList: {},
    chatGroupParticipantsMapFilterList: {},
    chatGroupSelectedParticipants: {},
    addParticipantsSearchText: "",
    chatUserListWithSnapshotMap: {
        "chatList": [],
        "contactList": []
    },
    currentDeviceId: "",
    selectedChatHistory: {},
    selectedChatHistoryDateMap: {},
    chatSnapshotUsersList: [],
    chatSnapshotUsersMap: {},

    userDetails: {},
    userUpdateCounter: 0,

    selectedGroup: {},

    tagsList: {},
    messageTagMap: {},
    channelDetailsMap: {},

    alertMessage: "",
    messageReactionsMap: {},

    isCallNotificationVisible: false,
    callNotificationData: {},
    currentMeetingId: "",
    isMeetingVideoEnabled: false,
    currentMeetingType: "",
    isDMMeetingForceTerminated: false

};

const ReducerChat = (state = INIT_STATE, action) => {

    console.log("action.type : ", action)

    switch (action.type) {

        /** dm : delete message event **/
        case MSG_ROUTER_EVENTS.DM.DM_DELETED_MESSAGES_SUCCESS: {

            let selectedChatHistory = state.selectedChatHistory;
            let selectedChatIndexId = state.selectedChatIndexId;

            if (typeof action.payload.data != "undefined") {

                let dmId = action.payload.data['for_uid'];
                let msgIds = action.payload.data.msgid;

                let indexId = MESSAGE_ROUTER_CHANNEL_TYPES.DM + "_" + dmId;

                if (selectedChatIndexId === indexId && typeof selectedChatHistory[indexId] != "undefined") {

                    for (let i = 0; i < msgIds.length; i++) {

                        for (let j = 0; j < selectedChatHistory[indexId].length; j++) {

                            if (selectedChatHistory[indexId][j]["id"] === msgIds[i]) {
                                selectedChatHistory[indexId].splice(j, 1);
                                break;
                            }
                        }
                    }
                }
            }

            return {
                ...state,
                selectedChatHistory: selectedChatHistory,
                messageCounter: state.messageCounter + 1,
                chatListRefreshed: true
            }
        }

        /** dm : delete message event **/
        case MSG_ROUTER_EVENTS.DM.DM_DELETED_MESSAGE_SUCCESS: {

            let selectedChatHistory = state.selectedChatHistory;
            let selectedChatIndexId = state.selectedChatIndexId;

            if (typeof action.payload.data != "undefined") {

                let msgId = action.payload.data.msgid;
                let userId = action.payload.data.user_id;

                let indexId = userId === AppUtils.getCurrentUserId() ? selectedChatIndexId : MESSAGE_ROUTER_CHANNEL_TYPES.DM + "_" + userId;

                if (typeof selectedChatHistory[indexId] != "undefined") {

                    for (let i = 0; i < selectedChatHistory[indexId].length; i++) {
                        if (selectedChatHistory[indexId][i]["id"] === msgId) {
                            selectedChatHistory[indexId].splice(i, 1);
                            break;
                        }
                    }
                }
            }

            return {
                ...state,
                selectedChatHistory: selectedChatHistory,
                messageCounter: state.messageCounter + 1,
                chatListRefreshed: true
            }
        }

        /** channel : delete multiple message event **/
        case MSG_ROUTER_EVENTS.CHANNEL.CHANNEL_DELETE_MESSAGES_SUCCESS:
        /** channel : delete message event **/
        case MSG_ROUTER_EVENTS.CHANNEL.CHANNEL_DELETE_MESSAGE_SUCCESS: {

            let selectedChatHistory = state.selectedChatHistory;
            let selectedChatIndexId = state.selectedChatIndexId;

            if (typeof action.payload.data != "undefined") {

                let channelId = action.payload.data.channel_id;
                let msgIds = action.payload.data.msgid;

                let indexId = MESSAGE_ROUTER_CHANNEL_TYPES.GROUP + "_" + channelId;

                if (selectedChatIndexId === indexId && typeof selectedChatHistory[indexId] != "undefined") {

                    for (let i = 0; i < msgIds.length; i++) {

                        for (let j = 0; j < selectedChatHistory[indexId].length; j++) {

                            if (selectedChatHistory[indexId][j]["id"] === msgIds[i]) {
                                selectedChatHistory[indexId].splice(j, 1);
                                break;
                            }
                        }
                    }
                }
            }

            return {
                ...state,
                selectedChatHistory: selectedChatHistory,
                messageCounter: state.messageCounter + 1,
                chatListRefreshed: true
            }
        }


        case MSG_ROUTER_EVENTS.CHANNEL.CHANNEL_POSTED_MESSAGE: {
            let chatSnapshotUsersList = state.chatSnapshotUsersList;
            let selectedChatHistory = state.selectedChatHistory;
            let selectedChatHistoryDateMap = state.selectedChatHistoryDateMap;
            let chatSnapshotUsersMap = state.chatSnapshotUsersMap;
            let chatAllOtherUsersMap = state.chatAllOtherUsersMap;
            let messageReactionsMap = state.messageReactionsMap;

            if (typeof action.payload != "undefined" && typeof action.payload.data != "undefined") {

                let currentMessage = action.payload.data;
                let currentUserId = AppUtils.getCurrentUserId();
                let chatIndexId = "";
                let selectedChatId = "";

                if (typeof currentMessage.channel_id !== "undefined") {
                    chatIndexId = MESSAGE_ROUTER_CHANNEL_TYPES.GROUP + "_" + currentMessage.channel_id;
                    selectedChatId = currentMessage.channel_id;
                }

                if (currentMessage.user_id !== AppUtils.getCurrentUserId()) {
                    TribeChatSocketIOHandler.markMessageAsDeliver(currentMessage.msgid, currentUserId, currentMessage.channel_id);
                }

                if (typeof selectedChatHistory[chatIndexId] == "undefined") {
                    selectedChatHistory[chatIndexId] = [];
                }

                ChatParserUtils.parseSingleMessage(action.payload.data, selectedChatHistory[chatIndexId], true, selectedChatHistoryDateMap, messageReactionsMap, chatIndexId, chatSnapshotUsersMap);

                if (typeof chatSnapshotUsersMap[chatIndexId] != "undefined") {

                    if (typeof action.payload.data != "undefined") {
                        if (typeof action.payload.data.message != "undefined" && typeof action.payload.data.message.text != "undefined" && action.payload.data.message.text !== "") {
                            chatSnapshotUsersMap[chatIndexId]["lastMessage"] = action.payload.data.message.text;
                        }

                        if (typeof action.payload.data["created_at"] != "undefined") {
                            let messageTime = new Date(action.payload.data["created_at"] + "Z");
                            chatSnapshotUsersMap[chatIndexId]["lastMessageTime"] = AppUtils.getChatCurrentValue(messageTime);
                        }
                    }

                    if (chatIndexId !== state.selectedChatIndexId) {
                        chatSnapshotUsersMap[chatIndexId]["unreadMessage"] += 1;
                    }

                    chatSnapshotUsersMap[chatIndexId]["sortTime"] = new Date().getTime();

                    chatSnapshotUsersList = getParsedSnapshotData(chatSnapshotUsersMap);
                }

                if (typeof action.payload.data["to"] != "undefined" && typeof action.payload.data["to"]["uid"] != "undefined" && action.payload.data["to"]["uid"] === AppUtils.getCurrentUserId() && action.payload.data["user_id"] !== AppUtils.getCurrentUserId()) {
                    if ((!chatSnapshotUsersMap[chatIndexId]["isMuteNotifications"] || (
                        chatSnapshotUsersMap[chatIndexId]["isMuteNotifications"] && chatSnapshotUsersMap[chatIndexId]["muteNotificationsTime"] != "-1" && chatSnapshotUsersMap[chatIndexId]["muteNotificationsTime"] < new Date().getTime()))) {
                        AppUtils.playNotification();
                    }
                }

            }

            return {
                ...state,
                chatSnapshotUsersMap: chatSnapshotUsersMap,
                chatSnapshotUsersList: chatSnapshotUsersList,
                selectedChatHistory: selectedChatHistory,
                selectedChatHistoryDateMap: selectedChatHistoryDateMap,
                messageCounter: state.messageCounter + 1
            }

        }

        case GROUP_MESSAGES_MESSAGE_SEND: {
            let selectedChatId = state.selectedChatId;
            let selectedChatIndexId = state.selectedChatIndexId;
            let chatSnapshotUsersList = state.chatSnapshotUsersList;
            let selectedChatHistory = state.selectedChatHistory;
            let selectedChatHistoryDateMap = state.selectedChatHistoryDateMap;
            let chatSnapshotUsersMap = state.chatSnapshotUsersMap;
            let chatAllOtherUsersMap = state.chatAllOtherUsersMap;
            let messageReactionsMap = state.messageReactionsMap;


            if (typeof action.payload != "undefined" && typeof action.payload.params != "undefined") {

                if (typeof selectedChatHistory[selectedChatIndexId] == "undefined") {
                    selectedChatHistory[selectedChatIndexId] = [];
                }

                ChatParserUtils.parseSingleMessage(action.payload.params, selectedChatHistory[selectedChatIndexId], false, selectedChatHistoryDateMap, messageReactionsMap, selectedChatIndexId, chatSnapshotUsersMap);

                if (typeof chatSnapshotUsersMap[selectedChatId] != "undefined") {
                    chatSnapshotUsersMap[selectedChatId]["sortTime"] = new Date().getTime();

                    if (typeof chatSnapshotUsersMap[selectedChatId] !== "undefined" && chatSnapshotUsersMap[selectedChatId]["isPlaceHolder"]) {
                        chatSnapshotUsersMap[selectedChatId]["msgid"] = action.payload.params["msgid"];
                    }

                    chatSnapshotUsersList = getParsedSnapshotData(chatSnapshotUsersMap);
                }

            }

            return {
                ...state,
                chatSnapshotUsersMap: chatSnapshotUsersMap,
                chatSnapshotUsersList: chatSnapshotUsersList,
                selectedChatHistory: selectedChatHistory,
                selectedChatHistoryDateMap: selectedChatHistoryDateMap,
                messageCounter: state.messageCounter + 1
            }
        }

        case GROUP_MESSAGES_MESSAGE_LIST: {

            let selectedChatHistoryDateMap = state.selectedChatHistoryDateMap;
            let selectedChatHistory = state.selectedChatHistory;

            if (typeof action.payload.params != "undefined" && typeof action.payload.params.idx != "undefined" && action.payload.params.idx === 0) {
                let indexId = MESSAGE_ROUTER_CHANNEL_TYPES.GROUP + "_" + action.payload.params.chid;

                selectedChatHistoryDateMap = {};
                selectedChatHistory[indexId] = [];
            }

            return {
                ...state,
                selectedChatHistoryDateMap: selectedChatHistoryDateMap,
                selectedChatHistory: selectedChatHistory
            }
        }

        /**** tribe event : channel : messages list  ****/
        case MSG_ROUTER_EVENTS.CHANNEL.CHANNEL_MESSAGES: {

            let chatSnapshotUsersMap = state.chatSnapshotUsersMap;
            let selectedChatHistory = state.selectedChatHistory;
            let selectedChatHistoryDateMap = state.selectedChatHistoryDateMap;
            let messageReactionsMap = state.messageReactionsMap;
            let chatAllOtherUsersMap = state.chatAllOtherUsersMap;


            if (typeof action.payload.data != "undefined") {
                if (typeof action.payload.data.messages != "undefined") {
                    let currentId = action.payload.data.chid;
                    let indexId = MESSAGE_ROUTER_CHANNEL_TYPES.GROUP + "_" + currentId;

                    if (typeof selectedChatHistory[indexId] === "undefined") {
                        selectedChatHistory[indexId] = [];
                    }

                    ChatParserUtils.parseMessageList(action.payload.data.messages, selectedChatHistory[indexId], true, messageReactionsMap, indexId, selectedChatHistoryDateMap, chatSnapshotUsersMap);


                } else if (action.payload.data.status == "404" && action.payload.data.uid === AppUtils.getCurrentUserId()) {
                    let currentId = action.payload.data.chid;
                    let indexId = MESSAGE_ROUTER_CHANNEL_TYPES.GROUP + "_" + currentId;

                    if (typeof selectedChatHistory[indexId] != "undefined") {
                        selectedChatHistory[indexId] = [];
                    }
                }
            }

            return {
                ...state,
                selectedChatHistory: selectedChatHistory,
                selectedChatHistoryDateMap: selectedChatHistoryDateMap,
                personalMessagesReadAllStatus: false,
                messageCounter: state.messageCounter + 1,
                chatListRefreshed: true,
                messageReactionsMap: messageReactionsMap,
                chatSnapshotUsersMap: chatSnapshotUsersMap
            }
        }

        /**** tribe event : channel : member added  ****/
        case MSG_ROUTER_EVENTS.CHANNEL.CHANNEL_MEMBER_ADDED: {

            const chatSnapshotUsersMap = state.chatSnapshotUsersMap;
            let chatSnapshotUsersList = state.chatSnapshotUsersList;
            const channelDetailsMap = state.channelDetailsMap;

            if (typeof action.payload.data != "undefined" && typeof action.payload.data['channel_details'] != "undefined") {
                let currentGroup = {};
                parseChannelAddedResponse(action.payload.data['channel_details'], currentGroup);

                if (typeof chatSnapshotUsersMap [currentGroup.id] == "undefined") {
                    chatSnapshotUsersMap[currentGroup.id] = currentGroup;
                    chatSnapshotUsersList = getParsedSnapshotData(chatSnapshotUsersMap);
                }
            }

            if (typeof action.payload.data != "undefined" && typeof action.payload.data['member_details'] != "undefined") {
                let channelId = action.payload.data.channel_id;
                let memberId = action.payload.data.member_id
                let role = action.payload.data.member_details.role;

                if (typeof channelDetailsMap[channelId] != "undefined") {
                    addUserToChannel(channelDetailsMap[channelId], memberId, role, state.chatAllOtherUsersMap, state.userDetails)
                }
            }

            return {
                ...state,
                chatSnapshotUsersMap: chatSnapshotUsersMap,
                chatSnapshotUsersList: chatSnapshotUsersList,
                channelDetailsMap: channelDetailsMap
            }
        }

        /**** tribe event : channel created  ****/
        case MSG_ROUTER_EVENTS.CHANNEL.CHANNEL_CREATED: {

            const chatSnapshotUsersMap = state.chatSnapshotUsersMap;
            let chatSnapshotUsersList = state.chatSnapshotUsersList;

            let selectedGroup = {};

            if (typeof action.payload.data != "undefined") {
                convertChannelToSnapshotData(action.payload.data, selectedGroup);

                if (typeof chatSnapshotUsersMap [selectedGroup.id] == "undefined") {
                    chatSnapshotUsersMap[selectedGroup.id] = selectedGroup;
                    chatSnapshotUsersList = getParsedSnapshotData(chatSnapshotUsersMap);
                }
            }

            return {
                ...state,
                saveChannelStatus: HTTP_REQUEST_STATUS.SUCCESS,
                selectedGroup: selectedGroup,
                chatSnapshotUsersMap: chatSnapshotUsersMap,
                chatSnapshotUsersList: chatSnapshotUsersList
            }
        }

        /** channel create **/
        case CHANNEL_MANAGEMENT_CHANNEL_CREATE: {

            return {
                ...state,
                saveChannelStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
            }
        }

        /** channel create : error **/
        case CHANNEL_MANAGEMENT_CHANNEL_CREATE_ERROR: {

            return {
                ...state,
                saveChannelStatus: HTTP_REQUEST_STATUS.ERROR
            }
        }

        /** channel create : none **/
        case CHANNEL_MANAGEMENT_CHANNEL_CREATE_NONE: {

            return {
                ...state,
                saveChannelStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }

        /** to remove selected user from list **/
        case CHAT_ADD_PARTICIPANTS_RESET: {
            const chatAllOtherUsersMapList = state.chatAllOtherUsersMapList;
            let chatGroupParticipantsMapFilterList = {};

            filterAddParticipantsWindowUsers("", chatAllOtherUsersMapList, {}, chatGroupParticipantsMapFilterList)

            return {
                ...state,
                addParticipantsSearchText: "",
                chatGroupSelectedParticipants: {},
                chatGroupParticipantsMapFilterList: chatGroupParticipantsMapFilterList
            }
        }

        /** to remove selected user from list **/
        case CHAT_ADD_PARTICIPANTS_REMOVE_USER: {
            const chatGroupSelectedParticipants = state.chatGroupSelectedParticipants;
            const addParticipantsSearchText = state.addParticipantsSearchText;
            const chatAllOtherUsersMapList = state.chatAllOtherUsersMapList;

            let selectedUser = action.payload;
            let chatGroupParticipantsMapFilterList = {};

            if (typeof chatGroupSelectedParticipants[selectedUser.id] != "undefined") {
                delete chatGroupSelectedParticipants[selectedUser.id];
            }

            filterAddParticipantsWindowUsers(addParticipantsSearchText, chatAllOtherUsersMapList, chatGroupSelectedParticipants, chatGroupParticipantsMapFilterList);

            return {
                ...state,
                chatGroupSelectedParticipants: chatGroupSelectedParticipants,
                chatGroupParticipantsMapFilterList: chatGroupParticipantsMapFilterList
            }
        }

        /** to add selected user to list **/
        case CHAT_ADD_PARTICIPANTS_ADD_USER: {
            const chatGroupSelectedParticipants = state.chatGroupSelectedParticipants;
            const addParticipantsSearchText = state.addParticipantsSearchText;
            const chatAllOtherUsersMapList = state.chatAllOtherUsersMapList;

            let chatGroupParticipantsMapFilterList = {};
            let selectedUser = action.payload;
            chatGroupSelectedParticipants[selectedUser.id] = selectedUser;

            filterAddParticipantsWindowUsers(addParticipantsSearchText, chatAllOtherUsersMapList, chatGroupSelectedParticipants, chatGroupParticipantsMapFilterList)

            return {
                ...state,
                chatGroupSelectedParticipants: chatGroupSelectedParticipants,
                chatGroupParticipantsMapFilterList: chatGroupParticipantsMapFilterList
            }
        }

        /** to filter group participants list  **/
        case CHAT_ADD_PARTICIPANTS_SEARCH_UPDATE_SEARCH_RESULT: {

            let chatAllOtherUsersMapList = state.chatAllOtherUsersMapList;
            let chatGroupSelectedParticipants = state.chatGroupSelectedParticipants;
            let chatGroupParticipantsMapFilterList = {};

            let searchText = action.payload;
            searchText = searchText.toString().toLowerCase();

            filterAddParticipantsWindowUsers(searchText, chatAllOtherUsersMapList, chatGroupSelectedParticipants, chatGroupParticipantsMapFilterList)

            return {
                ...state,
                chatGroupParticipantsMapFilterList: chatGroupParticipantsMapFilterList,
                addParticipantsSearchText: searchText
            }
        }

        /** to update chat home search list **/
        case CHAT_NEW_CHAT_SEARCH_UPDATE_SEARCH_RESULT: {

            let chatAllOtherUsersMapList = state.chatAllOtherUsersMapList;
            let chatAllOtherUsersMapFilterList = {};

            let searchText = action.payload;

            if (searchText === "") {
                chatAllOtherUsersMapFilterList = JSON.parse(JSON.stringify(chatAllOtherUsersMapList));
            } else {
                filterNewChatWindowUsers(searchText, chatAllOtherUsersMapList, chatAllOtherUsersMapFilterList)
            }

            return {
                ...state,
                chatAllOtherUsersMapFilterList: chatAllOtherUsersMapFilterList
            }
        }

        /** to update chat home search list **/
        case CHAT_HOME_SEARCH_UPDATE_SEARCH_RESULT: {

            let chatAllOtherUsersMap = state.chatAllOtherUsersMap;
            let chatSnapshotUsersMap = state.chatSnapshotUsersMap;
            let chatUserListWithSnapshotMap = {
                "chatList": [],
                "contactList": []
            };

            parseChatHomeSearchResults(action.payload, chatSnapshotUsersMap, chatAllOtherUsersMap, chatUserListWithSnapshotMap)


            return {
                ...state,
                chatUserListWithSnapshotMap: chatUserListWithSnapshotMap
            }
        }

        case USER_MANAGEMENT_GET_USER_ACTIVE_STATUS_SUCCESS: {

            let userDetails = state.userDetails;
            let chatSnapshotUsersMap = state.chatSnapshotUsersMap;
            let chatSnapshotUsersList = state.chatSnapshotUsersList;


            if (typeof action.payload != "undefined" && typeof action.payload.data != "undefined" && typeof action.payload.data.data != "undefined") {

                let data = action.payload.data.data;

                for (let i = 0; i < data.length; i++) {
                    let userId = data[i]["user_id__id"];
                    let optionalData = data[i]["optional_data"];

                    userDetails[userId] = optionalData;

                    if (typeof chatSnapshotUsersMap[userId] != "undefined") {
                        chatSnapshotUsersMap[userId]["statusClass"] = MESSAGE_ROUTER_USER_STATUS[optionalData["status"]];
                    }

                }

                chatSnapshotUsersList = getParsedSnapshotData(chatSnapshotUsersMap);
            }


            return {
                ...state,
                userDetails: userDetails,
                chatSnapshotUsersMap: chatSnapshotUsersMap,
                chatSnapshotUsersList: chatSnapshotUsersList

            }
        }

        /** socket event : users details **/
        case MSG_ROUTER_EVENTS.USERS.USER_DETAILS: {

            let userDetails = state.userDetails;
            let chatSnapshotUsersMap = state.chatSnapshotUsersMap;
            let chatSnapshotUsersList = state.chatSnapshotUsersList;

            if (typeof action.payload != "undefined" && typeof action.payload.data != "undefined" && typeof action.payload.data.details != "undefined" && typeof action.payload.data.details.optional != "undefined") {
                let optionalData = action.payload.data.details.optional;
                let userId = action.payload.data.uid;

                if (typeof optionalData["status"] != "undefined") {

                    if (typeof MESSAGE_ROUTER_USER_STATUS[optionalData["status"]] != "undefined") {
                        optionalData["statusClass"] = MESSAGE_ROUTER_USER_STATUS[optionalData["status"]];
                    }

                    let indexId = MESSAGE_ROUTER_CHANNEL_TYPES.DM + "_" + userId;

                    if (typeof chatSnapshotUsersMap[indexId] != "undefined") {
                        chatSnapshotUsersMap[indexId]["statusClass"] = MESSAGE_ROUTER_USER_STATUS[optionalData["status"]];
                        chatSnapshotUsersMap[indexId]["thumb"] = chatBaseDownloadUrl + optionalData["profile_image"];

                        if (typeof optionalData["display_name"] != "undefined" && optionalData["display_name"] !== "") {
                            chatSnapshotUsersMap[indexId]["name"] = optionalData["display_name"];
                        }

                        chatSnapshotUsersList = getParsedSnapshotData(chatSnapshotUsersMap);
                    }
                }

                userDetails[userId] = optionalData;
            }

            return {
                ...state,
                userUpdateCounter: state.userUpdateCounter + 1,
                userDetails: userDetails,
                chatSnapshotUsersMap: chatSnapshotUsersMap,
                chatSnapshotUsersList: chatSnapshotUsersList
            }
        }

        /** to set selected chat user id or channel id **/
        case CHAT_SET_CURRENT_CHAT_ID: {

            let chatSnapshotUsersMap = state.chatSnapshotUsersMap;

            for (let key in chatSnapshotUsersMap) {
                if (chatSnapshotUsersMap[key]["isPlaceHolder"]) {
                    delete chatSnapshotUsersMap[key];
                }
            }

            let chatSnapshotUsersList = getParsedSnapshotData(chatSnapshotUsersMap);

            return {
                ...state,
                selectedSnapshot: action.payload.user,
                selectedChat: action.payload.user,
                selectedChatId: action.payload.user.id,
                selectedChatIndexId: action.payload.user.indexId,
                selectedChatType: action.payload.type,
                chatSnapshotUsersList: chatSnapshotUsersList,
                chatSnapshotUsersMap: chatSnapshotUsersMap
            }
        }

        /** to set selected chat user id or channel id **/
        case CHAT_INSERT_CONTACT_TO_SNAPSHOT: {
            let chatSnapshotUsersMap = state.chatSnapshotUsersMap;
            let selectedUser = action.payload.user;
            let type = action.payload.type;
            let indexId = MESSAGE_ROUTER_CHANNEL_TYPES.DM + "_" + selectedUser.id;
            if (typeof chatSnapshotUsersMap [indexId] == "undefined") {
                chatSnapshotUsersMap[indexId] = getSnapshotRecordFromUser(selectedUser, type);
            }

            let chatSnapshotUsersList = getParsedSnapshotData(chatSnapshotUsersMap);

            return {
                ...state,
                chatSnapshotUsersMap: chatSnapshotUsersMap,
                chatSnapshotUsersList: chatSnapshotUsersList,
                userUpdateCounter: state.userUpdateCounter + 1
            }
        }

        case CHAT_PERSONAL_RESET_SCROLL_STATUS: {

            return {
                ...state,
                chatListRefreshed: false
            }
        }

        case CHAT_PERSONAL_CHAT_MARK_READ_ALL_STATUS: {

            let chatSnapshotUsersList = state.chatSnapshotUsersList;
            let selectedUser = action.payload;

            for (let i = 0; i < chatSnapshotUsersList.length; i++) {
                if (selectedUser.id === chatSnapshotUsersList[i]["id"]) {
                    chatSnapshotUsersList[i]["unreadMessage"] = 0;
                }
            }


            return {
                ...state,
                chatSnapshotUsersList: chatSnapshotUsersList
            }
        }

        /** socket event : personal message **/
        case MSG_ROUTER_EVENTS.USERS.USER_SNAPSHOT: {

            let chatSnapshotUsersList = [];
            let chatSnapshotUsersMap = {};
            let userDetails = state.userDetails;
            let tagsList = state.tagsList;

            if (typeof action.payload != "undefined" && typeof action.payload.data != "undefined" &&
                typeof action.payload.data.snapshot != "undefined" && typeof action.payload.data.snapshot.channels != "undefined") {
                parseSnapShotResult(action.payload.data.snapshot.channels, state.chatAllOtherUsersMap, chatSnapshotUsersMap, userDetails, tagsList);
            }

            chatSnapshotUsersList = getParsedSnapshotData(chatSnapshotUsersMap);


            return {
                ...state,
                chatSnapshotUsersList: chatSnapshotUsersList,
                chatSnapshotUsersMap: chatSnapshotUsersMap
            }
        }


        /** socket connect status **/
        case CHAT_MISC_SOCKET_CONNECT_STATUS: {
            return {
                ...state,
                socketConnectStatus: action.payload
            }
        }

        /** dm : mute and unmute notifications **/
        case MSG_ROUTER_EVENTS.DM.DM_NOTIFICATIONS_UNMUTED:
        case MSG_ROUTER_EVENTS.DM.DM_NOTIFICATIONS_MUTED:{
            let chatSnapshotUsersMap = state.chatSnapshotUsersMap;
            let chatSnapshotUsersList = state.chatSnapshotUsersList;

            if (typeof action.payload != "undefined" && typeof action.payload.data != "undefined" && typeof action.payload.data.who != "undefined"){
                let indexId = MESSAGE_ROUTER_CHANNEL_TYPES.DM + "_" + action.payload.data.who;
                chatSnapshotUsersMap[indexId]["isMuteNotifications"] = action.type === MSG_ROUTER_EVENTS.DM.DM_NOTIFICATIONS_MUTED;
                chatSnapshotUsersList = getParsedSnapshotData(chatSnapshotUsersMap);
            }

            return {
                  ...state,
                  chatSnapshotUsersMap: chatSnapshotUsersMap,
                  chatSnapshotUsersList: chatSnapshotUsersList
            }
        }

        /** channel : mute and unmute notifications **/
        case MSG_ROUTER_EVENTS.CHANNEL.CHANNEL_NOTIFICATIONS_MUTED:
        case MSG_ROUTER_EVENTS.CHANNEL.CHANNEL_NOTIFICATIONS_UNMUTED:{
            let chatSnapshotUsersMap = state.chatSnapshotUsersMap;
            let chatSnapshotUsersList = state.chatSnapshotUsersList;

            if (typeof action.payload != "undefined" && typeof action.payload.data != "undefined" && typeof action.payload.data.channel != "undefined"){
                let indexId = MESSAGE_ROUTER_CHANNEL_TYPES.GROUP + "_" + action.payload.data.channel;
                chatSnapshotUsersMap[indexId]["isMuteNotifications"] = action.type === MSG_ROUTER_EVENTS.CHANNEL.CHANNEL_NOTIFICATIONS_MUTED;
                chatSnapshotUsersList = getParsedSnapshotData(chatSnapshotUsersMap);
            }

            return {
                ...state,
                chatSnapshotUsersMap: chatSnapshotUsersMap,
                chatSnapshotUsersList: chatSnapshotUsersList
            }
        }

        /** personal message send **/
        case PERSONAL_MESSAGES_MESSAGE_SEND: {
            let selectedChatId = state.selectedChatId;
            let selectedChatIndexId = state.selectedChatIndexId;
            let chatSnapshotUsersList = state.chatSnapshotUsersList;
            let selectedChatHistory = state.selectedChatHistory;
            let selectedChatHistoryDateMap = state.selectedChatHistoryDateMap;
            let chatSnapshotUsersMap = state.chatSnapshotUsersMap;


            if (typeof action.payload != "undefined" && typeof action.payload.params != "undefined") {

                if (typeof selectedChatHistory[selectedChatIndexId] == "undefined") {
                    selectedChatHistory[selectedChatIndexId] = [];
                }

                ChatParserUtils.parseSingleMessage(action.payload.params, selectedChatHistory[selectedChatIndexId], false, selectedChatHistoryDateMap, {}, selectedChatIndexId, chatSnapshotUsersMap);

                if (typeof chatSnapshotUsersMap[selectedChatIndexId] != "undefined") {
                    chatSnapshotUsersMap[selectedChatIndexId]["sortTime"] = new Date().getTime();

                    if (typeof chatSnapshotUsersMap[selectedChatIndexId] != "undefined" && chatSnapshotUsersMap[selectedChatIndexId]["isPlaceHolder"]) {
                        chatSnapshotUsersMap[selectedChatIndexId]["msgid"] = action.payload.params["msgid"];
                    }

                    chatSnapshotUsersList = getParsedSnapshotData(chatSnapshotUsersMap);
                }

            }

            return {
                ...state,
                chatSnapshotUsersMap: chatSnapshotUsersMap,
                chatSnapshotUsersList: chatSnapshotUsersList,
                selectedChatHistory: selectedChatHistory,
                selectedChatHistoryDateMap: selectedChatHistoryDateMap,
                messageCounter: state.messageCounter + 1
            }
        }


        /** socket event : personal message **/
        case MSG_ROUTER_EVENTS.DM.DM_POST_MESSAGE: {
            let selectedChatHistory = state.selectedChatHistory;
            let selectedChatHistoryDateMap = state.selectedChatHistoryDateMap;
            let chatSnapshotUsersMap = state.chatSnapshotUsersMap;
            let chatSnapshotUsersList = state.chatSnapshotUsersList;
            let selectedChatIndexId = state.selectedChatIndexId;
            let chatAllOtherUsersMap = state.chatAllOtherUsersMap;
            let userDetails = state.userDetails;
            let tagsList = state.tagsList;
            let messageReactionsMap = state.messageReactionsMap;

            if (typeof action.payload != "undefined" && typeof action.payload.data != "undefined" && typeof action.payload.data["from_user"] != "undefined") {

                let currentMessage = action.payload.data;
                let currentUserId = AppUtils.getCurrentUserId();
                let senderId = currentMessage["from_user"];
                let receiverId = currentMessage["to_user"];

                let userId = currentUserId === senderId ? receiverId : senderId;
                let snapshotIndexId = MESSAGE_ROUTER_CHANNEL_TYPES.DM + "_" + userId;

                if (currentUserId !== senderId) {
                    TribeChatSocketIOHandler.markMessageAsDeliver(currentMessage.msgid, currentUserId);
                }

                if (typeof selectedChatHistory[snapshotIndexId] == "undefined") {
                    selectedChatHistory[snapshotIndexId] = [];
                }

                if (typeof chatSnapshotUsersMap[snapshotIndexId] == "undefined") {
                    insertRecordToSnapshot(chatAllOtherUsersMap, chatSnapshotUsersMap, userDetails, tagsList, userId, false, currentMessage);
                    chatSnapshotUsersList = getParsedSnapshotData(chatSnapshotUsersMap);
                } else {

                    if (typeof currentMessage != "undefined") {
                        if (typeof currentMessage.message != "undefined" && typeof currentMessage.message.text != "undefined" && currentMessage.message.text != "") {
                            chatSnapshotUsersMap[snapshotIndexId]["lastMessage"] = currentMessage.message.text;
                        }

                        if (typeof currentMessage["created_at"] != "undefined") {
                            let messageTime = new Date(currentMessage["created_at"] + "Z");
                            chatSnapshotUsersMap[snapshotIndexId]["lastMessageTime"] = AppUtils.getChatCurrentValue(messageTime);
                        }
                    }
                }

                if (senderId !== currentUserId && (!chatSnapshotUsersMap[snapshotIndexId]["isMuteNotifications"] || (
                    chatSnapshotUsersMap[snapshotIndexId]["isMuteNotifications"] && chatSnapshotUsersMap[snapshotIndexId]["muteNotificationsTime"] != "-1" && chatSnapshotUsersMap[snapshotIndexId]["muteNotificationsTime"] < new Date().getTime()))) {
                    AppUtils.playNotification();
                }

                chatSnapshotUsersList = getParsedSnapshotData(chatSnapshotUsersMap);

                if (typeof chatSnapshotUsersMap[snapshotIndexId] != "undefined" && chatSnapshotUsersMap[snapshotIndexId]["isPlaceHolder"]) {
                    delete chatSnapshotUsersMap[snapshotIndexId]["isPlaceHolder"];
                }

                let status = ChatParserUtils.parseSingleMessage(currentMessage, selectedChatHistory[snapshotIndexId], true, selectedChatHistoryDateMap, messageReactionsMap, snapshotIndexId, chatSnapshotUsersMap);

                if (status && snapshotIndexId !== selectedChatIndexId) {
                    if (typeof chatSnapshotUsersMap[snapshotIndexId] != "undefined") {
                        chatSnapshotUsersMap[snapshotIndexId]["unreadMessage"] += 1;
                        chatSnapshotUsersMap[snapshotIndexId]["sortTime"] = new Date(currentMessage["created_at"]).getTime();
                        chatSnapshotUsersList = getParsedSnapshotData(chatSnapshotUsersMap);
                    }
                }
            }

            return {
                ...state,
                chatSnapshotUsersList: chatSnapshotUsersList,
                chatSnapshotUsersMap: chatSnapshotUsersMap,
                selectedChatHistory: selectedChatHistory,
                selectedChatHistoryDateMap: selectedChatHistoryDateMap,
                messageCounter: state.messageCounter + 1,
                messageReactionsMap: messageReactionsMap
            }
        }

        case MSG_ROUTER_EVENTS.DM.DM_READ_ALL_MESSAGES: {

            let chatSnapshotUsersMap = state.chatSnapshotUsersMap;

            if (typeof action.payload.data != "undefined" && typeof action.payload.data['for_uid'] != "undefined") {
                let indexId = MESSAGE_ROUTER_CHANNEL_TYPES.DM + "_" + action.payload.data['for_uid'];

                if (typeof chatSnapshotUsersMap[indexId] != "undefined") {
                    chatSnapshotUsersMap[indexId]["unreadMessage"] = 0;
                }
            }

            let chatSnapshotUsersList = getParsedSnapshotData(chatSnapshotUsersMap);

            return {
                ...state,
                chatSnapshotUsersMap: chatSnapshotUsersMap,
                chatSnapshotUsersList: chatSnapshotUsersList
            }
        }

        case PERSONAL_MESSAGES_MESSAGE_LIST: {
            let selectedChatHistory = state.selectedChatHistory;
            let selectedChatHistoryDateMap = state.selectedChatHistoryDateMap;

            if (typeof action.payload.params != "undefined" && typeof action.payload.params.idx != "undefined" && action.payload.params.idx === 0) {
                let indexId = MESSAGE_ROUTER_CHANNEL_TYPES.DM + "_" + action.payload.params.tuid;

                selectedChatHistory[indexId] = [];
                selectedChatHistoryDateMap = {}
            }

            return {
                ...state,
                selectedChatHistoryDateMap: selectedChatHistoryDateMap,
                selectedChatHistory: selectedChatHistory
            }
        }

        /** socket event : personal messages list **/
        case MSG_ROUTER_EVENTS.DM.DM_MESSAGES: {
            let selectedChatHistory = state.selectedChatHistory;
            let selectedChatHistoryDateMap = state.selectedChatHistoryDateMap;
            let personalMessagesViewId = state.personalMessagesViewId;
            let chatSnapshotUsersMap = state.chatSnapshotUsersMap;
            let chatSnapshotUsersList = state.chatSnapshotUsersList;
            let messageReactionsMap = state.messageReactionsMap;
            let chatAllOtherUsersMap = state.chatAllOtherUsersMap;

            if (typeof action.payload.data != "undefined") {
                if (typeof action.payload.data.messages != "undefined") {
                    let currentId = action.payload.data.tuid;
                    let indexId = MESSAGE_ROUTER_CHANNEL_TYPES.DM + "_" + currentId;

                    if (typeof selectedChatHistory[indexId] === "undefined") {
                        selectedChatHistory[indexId] = [];
                    }

                    if (typeof chatSnapshotUsersMap[currentId] != "undefined" && chatSnapshotUsersMap[currentId]["isPlaceHolder"]) {
                        delete chatSnapshotUsersMap[currentId]["isPlaceHolder"];
                    }

                    ChatParserUtils.parseMessageList(action.payload.data.messages, selectedChatHistory[indexId], true, messageReactionsMap, indexId, selectedChatHistoryDateMap, chatSnapshotUsersMap);

                    personalMessagesViewId = currentId;

                    chatSnapshotUsersList = getParsedSnapshotData(chatSnapshotUsersMap);

                }
            }

            return {
                ...state,
                selectedChatHistory: selectedChatHistory,
                selectedChatHistoryDateMap: selectedChatHistoryDateMap,
                personalMessagesViewId: personalMessagesViewId,
                personalMessagesReadAllStatus: false,
                messageCounter: state.messageCounter + 1,
                chatListRefreshed: true,
                chatSnapshotUsersMap: chatSnapshotUsersMap,
                chatSnapshotUsersList: chatSnapshotUsersList,
                messageReactionsMap: messageReactionsMap
            }
        }

        case SET_DEVICE_ID: {

            return {
                ...state,
                currentDeviceId: action.payload
            }
        }

        case DEVICE_MANAGEMENT_DEVICE_ATTACH: {
            return {
                ...state,
                registerDeviceStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                currentDeviceId: action.payload.params.dvid
            }
        }

        case CHAT_GET_ALL_OTHER_CONTACTS: {

            return {
                ...state,
                chatAllOtherContactsGetStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
            }
        }

        case CHAT_GET_ALL_OTHER_CONTACTS_SUCCESS: {

            let chatAllOtherUsersMap = {};
            let chatAllOtherUsersMapList = {};
            let chatSnapshotUsersMap = state.chatSnapshotUsersMap;
            let chatSnapshotUsersList = state.chatSnapshotUsersList;

            let chatAllOtherUsers = prepareOtherUsersList(action.payload.data, chatAllOtherUsersMap, chatAllOtherUsersMapList);
            let chatAllOtherUsersMapFilterList = JSON.parse(JSON.stringify(chatAllOtherUsersMapList));
            let chatGroupParticipantsMapFilterList = JSON.parse(JSON.stringify(chatAllOtherUsersMapList));

            if (AppUtils.getJSONLength(chatSnapshotUsersMap) > 0) {
                updateSnapshotUserDetails(chatAllOtherUsersMap, chatSnapshotUsersMap, state.userDetails);
                chatSnapshotUsersList = getParsedSnapshotData(chatSnapshotUsersMap);
            }

            ChatParserUtils.setUserDetailsMap(chatAllOtherUsersMap);

            return {
                ...state,
                chatAllOtherContactsGetStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                chatAllOtherUsers: chatAllOtherUsers,
                chatAllOtherUsersMap: chatAllOtherUsersMap,
                chatAllOtherUsersMapList: chatAllOtherUsersMapList,
                chatAllOtherUsersMapFilterList: chatAllOtherUsersMapFilterList,
                chatGroupParticipantsMapFilterList: chatGroupParticipantsMapFilterList,
                chatSnapshotUsersMap: chatSnapshotUsersMap,
                chatSnapshotUsersList: chatSnapshotUsersList
            }
        }

        case CHAT_GET_ALL_OTHER_CONTACTS_ERROR: {

            return {
                ...state,
                chatAllOtherContactsGetStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }

        case CHAT_GET_ALL_OTHER_CONTACTS_NONE: {

            return {
                ...state,
                chatAllOtherContactsGetStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }

        /** tags : channel tag success **/
        case MSG_ROUTER_EVENTS.TAGS.TAG_CHANNEL:
        case MSG_ROUTER_EVENTS.TAGS.TAG_CHANNEL_SUCCESS: {

            let tagsList = state.tagsList;
            let chatSnapshotUsersMap = state.chatSnapshotUsersMap;

            if (typeof action.payload.data != "undefined") {
                let data = action.payload.data;
                let indexId = MESSAGE_ROUTER_CHANNEL_TYPES.GROUP + "_" + data.channel_id;
                tagsList[indexId] = data.tag;

                updateSnapshotPinnedData(chatSnapshotUsersMap, tagsList);
            }

            let chatSnapshotUsersList = getParsedSnapshotData(chatSnapshotUsersMap);

            return {
                ...state,
                tagsList: tagsList,
                chatSnapshotUsersMap: chatSnapshotUsersMap,
                chatSnapshotUsersList: chatSnapshotUsersList
            }
        }

        /** tags : dm tag success **/
        case MSG_ROUTER_EVENTS.TAGS.TAG_DM:
        case MSG_ROUTER_EVENTS.TAGS.TAG_DM_SUCCESS: {
            let tagsList = state.tagsList;
            let chatSnapshotUsersMap = state.chatSnapshotUsersMap;

            if (typeof action.payload.data != "undefined" && typeof action.payload.data['to_user'] != "undefined") {
                let data = action.payload.data;
                let indexId = MESSAGE_ROUTER_CHANNEL_TYPES.DM + "_" + data['to_user'];
                tagsList[indexId] = data.tag;

                updateSnapshotPinnedData(chatSnapshotUsersMap, tagsList);
            }

            let chatSnapshotUsersList = getParsedSnapshotData(chatSnapshotUsersMap);

            return {
                ...state,
                tagsList: tagsList,
                chatSnapshotUsersMap: chatSnapshotUsersMap,
                chatSnapshotUsersList: chatSnapshotUsersList
            }
        }

        /** tags : channel message tag success **/
        case MSG_ROUTER_EVENTS.TAGS.TAG_CHANNEL_MESSAGE_SUCCESS: {

            const messageTagMap = state.messageTagMap;

            if (typeof action.payload.data != "undefined") {
                let data = action.payload.data;
                let indexId = MESSAGE_ROUTER_CHANNEL_TYPES.GROUP + "_" + data['channel_id'];
                messageTagMap[indexId][data.msgid] = data["tag"];
            }

            return {
                ...state,
                messageTagMap: messageTagMap
            }
        }

        /** tags : dm message tag success **/
        case MSG_ROUTER_EVENTS.TAGS.TAG_DM_MESSAGE_SUCCESS: {

            const messageTagMap = state.messageTagMap;

            if (typeof action.payload.data != "undefined") {
                let data = action.payload.data;
                let indexId = MESSAGE_ROUTER_CHANNEL_TYPES.DM + "_" + data['to_user'];
                messageTagMap[indexId][data.msgid] = data["tag"];
            }

            return {
                ...state,
                messageTagMap: messageTagMap
            }
        }

        /** tags : tags list success **/
        case MSG_ROUTER_EVENTS.TAGS.TAGS_LIST_SUCCESS: {

            let tagsList = {};
            let chatSnapshotUsersMap = state.chatSnapshotUsersMap;
            let chatSnapshotUsersList = state.chatSnapshotUsersList;

            if (typeof action.payload != "undefined" && typeof action.payload.data != "undefined" && typeof action.payload.data.tags != "undefined" && action.payload.status != 404) {
                tagsList = parseUserTagData(action.payload.data.tags);
                updateSnapshotPinnedData(chatSnapshotUsersMap, tagsList);
                chatSnapshotUsersList = getParsedSnapshotData(chatSnapshotUsersMap);
            }

            return {
                ...state,
                tagsList: tagsList,
                chatSnapshotUsersMap: chatSnapshotUsersMap,
                chatSnapshotUsersList: chatSnapshotUsersList
            }
        }

        case CHAT_TAG_GET_CHANNEL_TAG_MESSAGES: {

            const messageTagMap = state.messageTagMap;

            if (typeof action.payload.params != "undefined" && typeof action.payload.params.chid != "undefined") {
                let indexId = MESSAGE_ROUTER_CHANNEL_TYPES.GROUP + "_" + action.payload.params.chid;
                messageTagMap[indexId] = [];
            }

            return {
                ...state,
                messageTagMap: messageTagMap
            }
        }


        /** tags : channel message tags list success **/
        case MSG_ROUTER_EVENTS.TAGS.TAG_CHANNEL_MESSAGE_LIST_SUCCESS: {
            const messageTagMap = state.messageTagMap;
            const chatAllOtherUsersMap = state.chatAllOtherUsersMap;

            if (typeof action.payload.data != "undefined" && action.payload.data.status !== 404) {
                let data = action.payload.data;
                let indexId = MESSAGE_ROUTER_CHANNEL_TYPES.GROUP + "_" + data.chid;

                for (let i = 0; i < data.messages.length; i++) {
                    parseStarredMessage(data.messages[i], chatAllOtherUsersMap, data.messages[i]["uid"], true);
                }

                messageTagMap[indexId] = data.messages;
            }

            return {
                ...state,
                messageTagMap: messageTagMap
            }
        }

        case CHAT_TAG_GET_DM_TAG_MESSAGES: {
            const messageTagMap = state.messageTagMap;
            let selectedChatHistoryDateMap = state.selectedChatHistoryDateMap;

            if (typeof action.payload.params != "undefined" && typeof action.payload.params.tuid != "undefined") {
                let indexId = MESSAGE_ROUTER_CHANNEL_TYPES.DM + "_" + action.payload.params.tuid;
                messageTagMap[indexId] = [];

                if (typeof action.payload.params.index != "undefined" && action.payload.params.index === 0) {
                    selectedChatHistoryDateMap = {};
                }
            }


            return {
                ...state,
                messageTagMap: messageTagMap,
                selectedChatHistoryDateMap: selectedChatHistoryDateMap
            }
        }

        /** tags : dm message tags list success **/
        case MSG_ROUTER_EVENTS.TAGS.TAG_DM_MESSAGE_LIST_SUCCESS: {

            const messageTagMap = state.messageTagMap;

            if (typeof action.payload.data != "undefined" && action.payload.data.status !== 404) {
                let data = action.payload.data;
                let indexId = MESSAGE_ROUTER_CHANNEL_TYPES.DM + "_" + data.tuid;

                for (let i = 0; i < data.messages.length; i++) {
                    parseStarredMessage(data.messages[i], state.chatAllOtherUsersMap, data.messages[i]["uid"], true);
                }

                messageTagMap[indexId] = data.messages;
            }

            return {
                ...state,
                messageTagMap: messageTagMap
            }
        }

        /** channel : channel details success **/
        case MSG_ROUTER_EVENTS.CHANNEL.CHANNEL_DETAILS: {

            const channelDetailsMap = state.channelDetailsMap;

            if (typeof action.payload.data != "undefined") {
                let data = action.payload.data;

                if (typeof data.chid != "undefined") {
                    channelDetailsMap[data.chid + ""] = parseChannelDetails(data, state.chatAllOtherUsersMap, state.userDetails);
                }
            }

            return {
                ...state,
                channelDetailsMap: channelDetailsMap
            }
        }

        /** channel : user remove event **/
        case MSG_ROUTER_EVENTS.CHANNEL.CHANNEL_USER_BAN: {

            const channelDetailsMap = state.channelDetailsMap;

            if (typeof action.payload.data != "undefined" && typeof action.payload.data.channel_id != "undefined" && typeof channelDetailsMap[action.payload.data.channel_id] != "undefined") {

                let channelId = action.payload.data.channel_id;
                let memberId = action.payload.data.member_id;

                for (let i = 0; i < channelDetailsMap[channelId].members.length; i++) {
                    if (channelDetailsMap[channelId].members[i]["id"] + "" === memberId + "") {
                        channelDetailsMap[channelId].members.splice(i, 1);
                        break;
                    }
                }
            }

            return {
                ...state,
                channelDetailsMap: channelDetailsMap
            }
        }

        /** channel : user role change event **/
        case MSG_ROUTER_EVENTS.CHANNEL.CHANNEL_USER_ROLE_CHANGE: {
            const channelDetailsMap = state.channelDetailsMap;

            if (typeof action.payload.data != "undefined" && typeof action.payload.data.channel_id != "undefined" && typeof channelDetailsMap[action.payload.data.channel_id] != "undefined") {
                let channelId = action.payload.data.channel_id;
                let memberId = action.payload.data.member_id;
                let role = action.payload.data.role;

                channelDetailsMap[channelId] = updateChannelUserRole(channelDetailsMap[channelId], memberId, role);
            }

            return {
                ...state,
                channelDetailsMap: channelDetailsMap
            }
        }

        /** channel : channel optional data update event **/
        case MSG_ROUTER_EVENTS.CHANNEL.CHANNEL_UPDATED: {
            const channelDetailsMap = state.channelDetailsMap;
            const chatSnapshotUsersMap = state.chatSnapshotUsersMap;
            let chatSnapshotUsersList = state.chatSnapshotUsersList;


            if (typeof action.payload.data != "undefined" && typeof action.payload.data.channel_id != "undefined" && typeof channelDetailsMap[action.payload.data.channel_id] != "undefined") {
                let channelId = action.payload.data.channel_id;
                let channelDetails = action.payload.data['channel_details'];
                let indexId = MESSAGE_ROUTER_CHANNEL_TYPES.GROUP + "_" + channelId;
                let selectedSnapshot = typeof chatSnapshotUsersMap[indexId] != "undefined" ? chatSnapshotUsersMap[indexId] : {};

                if (typeof channelDetails["name"] != "undefined" && channelDetails["name"] !== "") {
                    channelDetailsMap[channelId]['name'] = channelDetails["name"];

                    if (typeof chatSnapshotUsersMap[indexId] != "undefined") {
                        chatSnapshotUsersMap[indexId]["name"] = channelDetails["name"];
                    }
                }

                if (typeof channelDetails["description"] != "undefined" && channelDetails["description"] !== "") {
                    channelDetailsMap[channelId]['info'] = channelDetails["description"];

                    if (typeof chatSnapshotUsersMap[indexId] != "undefined") {
                        chatSnapshotUsersMap[indexId]["info"] = channelDetails["description"];
                    }
                }

                if (typeof channelDetails["group_image"] != "undefined" && channelDetails["group_image"] !== "") {
                    channelDetails["group_image"] = chatBaseDownloadUrl + channelDetails["group_image"];
                    channelDetailsMap[channelId]['thumb'] = channelDetails["group_image"];

                    if (typeof chatSnapshotUsersMap[indexId] != "undefined") {
                        chatSnapshotUsersMap[indexId]["thumb"] = channelDetails["group_image"];
                    }
                }

                chatSnapshotUsersList = getParsedSnapshotData(chatSnapshotUsersMap);
            }

            return {
                ...state,
                channelDetailsMap: channelDetailsMap,
                chatSnapshotUsersMap: chatSnapshotUsersMap,
                chatSnapshotUsersList: chatSnapshotUsersList
            }
        }

        /** channel message unread event **/
        case MSG_ROUTER_EVENTS.CHANNEL.CHANNEL_UNREAD_MESSAGE: {

            return {
                ...state
            }
        }

        /** dm message unread event **/
        case MSG_ROUTER_EVENTS.DM.DM_UNREAD_MESSAGE: {

            return {
                ...state
            }
        }

        case GROUP_MESSAGES_MESSAGE_READ_ALL: {
            let chatSnapshotUsersMap = state.chatSnapshotUsersMap;

            if (typeof action.payload.data != "undefined" && typeof action.payload.data.chid != "undefined") {
                let indexId = MESSAGE_ROUTER_CHANNEL_TYPES.GROUP + "_" + action.payload.data.chid;

                if (typeof chatSnapshotUsersMap[indexId] != "undefined") {
                    chatSnapshotUsersMap[indexId]["unreadMessage"] = 0;
                }
            }

            let chatSnapshotUsersList = getParsedSnapshotData(chatSnapshotUsersMap);

            return {
                ...state,
                chatSnapshotUsersMap: chatSnapshotUsersMap,
                chatSnapshotUsersList: chatSnapshotUsersList
            }
        }

        case PERSONAL_MESSAGES_MESSAGE_READ_ALL: {

            let chatSnapshotUsersMap = state.chatSnapshotUsersMap;

            if (typeof action.payload.data != "undefined" && typeof action.payload.data.tuid != "undefined") {
                let indexId = MESSAGE_ROUTER_CHANNEL_TYPES.DM + "_" + action.payload.data.tuid;

                if (typeof chatSnapshotUsersMap[indexId] != "undefined") {
                    chatSnapshotUsersMap[indexId]["unreadMessage"] = 0;
                }
            }

            let chatSnapshotUsersList = getParsedSnapshotData(chatSnapshotUsersMap);

            return {
                ...state,
                chatSnapshotUsersMap: chatSnapshotUsersMap,
                chatSnapshotUsersList: chatSnapshotUsersList
            }
        }


        /** dm : delete message **/
        case CHAT_DM_MESSAGE_PURGE_MESSAGES: {
            return {
                ...state,
                dmExitStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                alertMessage: ""
            }
        }

        /** dm : delete message : fail **/
        case CHAT_DM_MESSAGE_PURGE_MESSAGES_ERROR: {
            return {
                ...state,
                dmExitStatus: HTTP_REQUEST_STATUS.ERROR,
                alertMessage: "DIC_KEY_DM_DELETE_SNAPSHOT_FAIL"
            }
        }

        /** dm : delete message : none **/
        case CHAT_DM_MESSAGE_PURGE_MESSAGES_NONE: {
            return {
                ...state,
                dmExitStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                alertMessage: ""
            }
        }

        /** dm : delete message : success **/
        case MSG_ROUTER_EVENTS.DM.DM_EXIT_SUCCESS: {

            let chatSnapshotUsersMap = state.chatSnapshotUsersMap;


            if (typeof action.payload.data != "undefined" && typeof action.payload.data.tuid != "undefined") {
                let indexId = MESSAGE_ROUTER_CHANNEL_TYPES.DM + "_" + action.payload.data.tuid;

                if (typeof chatSnapshotUsersMap[indexId] != "undefined") {
                    delete chatSnapshotUsersMap[indexId];
                }
            }

            let chatSnapshotUsersList = getParsedSnapshotData(chatSnapshotUsersMap);

            return {
                ...state,
                chatSnapshotUsersList: chatSnapshotUsersList,
                chatSnapshotUsersMap: chatSnapshotUsersMap,
                dmExitStatus: HTTP_REQUEST_STATUS.SUCCESS,
                alertMessage: "DIC_KEY_DM_DELETE_SNAPSHOT_SUCCESS"
            }
        }

        /** group member leave **/

        case MSG_ROUTER_EVENTS.CHANNEL.CHANNEL_MEMBER_LEFT: {
            let chatSnapshotUsersList = state.chatSnapshotUsersList;
            let chatSnapshotUsersMap = state.chatSnapshotUsersMap;
            let groupMemberLeftChannelId = "";

            if (typeof action.payload.data != "undefined" && typeof action.payload.data["channel_id"] != "undefined") {

                if (AppUtils.getCurrentUserId() === action.payload.data["user_id"]) {
                    let indexId = MESSAGE_ROUTER_CHANNEL_TYPES.GROUP + "_" + action.payload.data["channel_id"];
                    groupMemberLeftChannelId = indexId;

                    if (typeof chatSnapshotUsersMap[indexId] != "undefined") {
                        delete chatSnapshotUsersMap[indexId];
                    }

                    chatSnapshotUsersList = getParsedSnapshotData(chatSnapshotUsersMap);
                }
            }

            return {
                ...state,
                groupMemberLeaveStatus: HTTP_REQUEST_STATUS.SUCCESS,
                groupMemberLeftStatus: HTTP_REQUEST_STATUS.SUCCESS,
                chatSnapshotUsersList: chatSnapshotUsersList,
                chatSnapshotUsersMap: chatSnapshotUsersMap,
                groupMemberLeftChannelId: groupMemberLeftChannelId,
                alertMessage: "DIC_KEY_GROUP_EXIT_SUCCESS"
            }
        }

        case CHANNEL_MEMBER_MANAGEMENT_MEMBER_LEAVE: {

            return {
                ...state,
                groupMemberLeaveStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                alertMessage: ""
            }
        }

        case CHANNEL_MEMBER_MANAGEMENT_MEMBER_LEAVE_ERROR: {
            return {
                ...state,
                groupMemberLeaveStatus: HTTP_REQUEST_STATUS.ERROR,
                alertMessage: "DIC_KEY_GROUP_EXIT_FAIL"
            }
        }

        case CHANNEL_MEMBER_MANAGEMENT_MEMBER_LEAVE_NONE: {
            return {
                ...state,
                groupMemberLeaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                alertMessage: ""
            }
        }

        /** group ownership change **/

        case MSG_ROUTER_EVENTS.CHANNEL.CHANNEL_OWNER_CHANGE: {

            return {
                ...state,
                groupOwnershipChangeStatus: HTTP_REQUEST_STATUS.SUCCESS
            }
        }

        case CHANNEL_MANAGEMENT_CHANNEL_CHANGE_OWNERSHIP: {
            return {
                ...state,
                groupOwnershipChangeStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
            }
        }

        case CHANNEL_MANAGEMENT_CHANNEL_CHANGE_OWNERSHIP_ERROR: {
            return {
                ...state,
                groupOwnershipChangeStatus: HTTP_REQUEST_STATUS.ERROR
            }
        }

        case CHANNEL_MANAGEMENT_CHANNEL_CHANGE_OWNERSHIP_NONE: {
            return {
                ...state,
                groupOwnershipChangeStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }

        /** group clear messages **/

        case MSG_ROUTER_EVENTS.CHANNEL.CHANNEL_CLEAR_MESSAGES: {

            const selectedChatHistory = state.selectedChatHistory;

            if (typeof action.payload.data != "undefined" && typeof action.payload.data["user_id"] != "undefined" && action.payload.data["user_id"] === AppUtils.getCurrentUserId()) {
                let indexId = MESSAGE_ROUTER_CHANNEL_TYPES.GROUP + "_" + action.payload.data["channel_id"];

                if (typeof selectedChatHistory[indexId] != "undefined") {
                    selectedChatHistory[indexId] = [];
                }
            }

            return {
                ...state,
                channelClearMessagesStatus: HTTP_REQUEST_STATUS.SUCCESS,
                alertMessage: "DIC_KEY_GROUP_CLEAR_MESSAGES_SUCCESS",
                selectedChatHistory: selectedChatHistory
            }
        }

        case GROUP_MESSAGES_CLEAR_MESSAGES_FOR_USER: {
            return {
                ...state,
                channelClearMessagesStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                alertMessage: ""
            }
        }

        case GROUP_MESSAGES_CLEAR_MESSAGES_FOR_USER_ERROR: {
            return {
                ...state,
                channelClearMessagesStatus: HTTP_REQUEST_STATUS.ERROR,
                alertMessage: "DIC_KEY_GROUP_CLEAR_MESSAGES_FAIL"
            }
        }

        case GROUP_MESSAGES_CLEAR_MESSAGES_FOR_USER_NONE: {
            return {
                ...state,
                channelClearMessagesStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                alertMessage: ""
            }
        }


        /** dm clear messages **/

        case MSG_ROUTER_EVENTS.DM.DM_CLEAR_MESSAGES: {

            const selectedChatHistory = state.selectedChatHistory;

            if (typeof action.payload.data != "undefined" && typeof action.payload.data["for_uid"] != "undefined" && action.payload.data["by_uid"] === AppUtils.getCurrentUserId()) {
                let indexId = MESSAGE_ROUTER_CHANNEL_TYPES.DM + "_" + action.payload.data["for_uid"];

                if (typeof selectedChatHistory[indexId] != "undefined") {
                    selectedChatHistory[indexId] = [];
                }
            }

            return {
                ...state,
                dmClearMessagesStatus: HTTP_REQUEST_STATUS.SUCCESS,
                selectedChatHistory: selectedChatHistory,
                alertMessage: "DIC_KEY_DM_CLEAR_MESSAGES_SUCCESS"
            }
        }

        case CHAT_DM_MESSAGE_CLEAR_MESSAGES: {
            return {
                ...state,
                dmClearMessagesStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                alertMessage: ""
            }
        }

        case CHAT_DM_MESSAGE_CLEAR_MESSAGES_ERROR: {
            return {
                ...state,
                dmClearMessagesStatus: HTTP_REQUEST_STATUS.ERROR,
                alertMessage: "DIC_KEY_DM_CLEAR_MESSAGES_FAIL"
            }
        }

        case CHAT_DM_MESSAGE_CLEAR_MESSAGES_NONE: {
            return {
                ...state,
                dmClearMessagesStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                alertMessage: ""
            }
        }

        /** channel delete **/
        case MSG_ROUTER_EVENTS.CHANNEL.CHANNEL_DELETED: {

            let chatSnapshotUsersList = state.chatSnapshotUsersList;
            let chatSnapshotUsersMap = state.chatSnapshotUsersMap;
            let deletedChannelId = "";

            if (typeof action.payload.data != "undefined" && typeof action.payload.data["channel_id"] != "undefined") {

                if (AppUtils.getCurrentUserId() === action.payload.data["user_id"]) {
                    let indexId = MESSAGE_ROUTER_CHANNEL_TYPES.GROUP + "_" + action.payload.data["channel_id"];
                    deletedChannelId = indexId;

                    if (typeof chatSnapshotUsersMap[indexId] != "undefined") {
                        delete chatSnapshotUsersMap[indexId];
                    }

                    chatSnapshotUsersList = getParsedSnapshotData(chatSnapshotUsersMap);
                }
            }


            return {
                ...state,
                channelDeleteStatus: HTTP_REQUEST_STATUS.SUCCESS,
                alertMessage: "DIC_KEY_GROUP_DELETE_SUCCESS",
                chatSnapshotUsersMap: chatSnapshotUsersMap,
                chatSnapshotUsersList: chatSnapshotUsersList,
                deletedChannelId: deletedChannelId

            }
        }

        case CHANNEL_MANAGEMENT_CHANNEL_DELETE: {
            return {
                ...state,
                channelDeleteStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                alertMessage: ""
            }
        }

        case CHANNEL_MANAGEMENT_CHANNEL_DELETE_ERROR: {
            return {
                ...state,
                channelDeleteStatus: HTTP_REQUEST_STATUS.ERROR,
                alertMessage: "DIC_KEY_GROUP_DELETE_FAIL"
            }
        }

        case CHANNEL_MANAGEMENT_CHANNEL_DELETE_NONE: {
            return {
                ...state,
                channelDeleteStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                alertMessage: ""
            }
        }

        /** dm : react message **/
        case MSG_ROUTER_EVENTS.DM.DM_MESSAGE_REACT: {

            let messageReactionsMap = state.messageReactionsMap;
            let selectedChatHistory = state.selectedChatHistory;
            let chatAllOtherUsersMap = state.chatAllOtherUsersMap;

            if (typeof action.payload.data != "undefined" && typeof action.payload.data["msgid"] != "undefined") {
                updateMessageReactionEvent(action.payload.data, messageReactionsMap, selectedChatHistory, chatAllOtherUsersMap, false);
            }

            return {
                ...state,
                dmMessageReactStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                selectedChatHistory: selectedChatHistory,
                messageReactionsMap: messageReactionsMap
            }
        }

        case CHAT_DM_MESSAGE_REACT_ON_MESSAGE: {

            return {
                ...state,
                dmMessageReactStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
            }
        }


        case CHAT_DM_MESSAGE_REACT_ON_MESSAGE_ERROR: {

            return {
                ...state,
                dmMessageReactStatus: HTTP_REQUEST_STATUS.ERROR
            }
        }

        case CHAT_DM_MESSAGE_REACT_ON_MESSAGE_NONE: {

            return {
                ...state,
                dmMessageReactStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }

        /** group messages : react on message **/
        case MSG_ROUTER_EVENTS.CHANNEL.CHANNEL_REACT_MESSAGE: {

            let messageReactionsMap = state.messageReactionsMap;
            let selectedChatHistory = state.selectedChatHistory;
            let chatAllOtherUsersMap = state.chatAllOtherUsersMap;

            if (typeof action.payload.data != "undefined" && typeof action.payload.data["msgid"] != "undefined") {
                updateMessageReactionEvent(action.payload.data, messageReactionsMap, selectedChatHistory, chatAllOtherUsersMap, true);
            }

            return {
                ...state,
                channelMessageReactStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                selectedChatHistory: selectedChatHistory,
                messageReactionsMap: messageReactionsMap
            }
        }

        case GROUP_MESSAGES_REACT_ON_MESSAGE: {

            return {
                ...state,
                channelMessageReactStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
            }
        }

        case GROUP_MESSAGES_REACT_ON_MESSAGE_ERROR: {

            return {
                ...state,
                channelMessageReactStatus: HTTP_REQUEST_STATUS.ERROR
            }
        }

        case GROUP_MESSAGES_REACT_ON_MESSAGE_NONE: {

            return {
                ...state,
                channelMessageReactStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }

        /** message edit : dm **/
        case PERSONAL_MESSAGES_MESSAGE_EDIT: {

            return {
                ...state,
                dmEditMessagesStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
            }
        }

        case MSG_ROUTER_EVENTS.DM.DM_MESSAGE_EDIT: {

            let chatSnapshotUsersList = state.chatSnapshotUsersList;
            let selectedChatHistory = state.selectedChatHistory;
            let selectedChatHistoryDateMap = state.selectedChatHistoryDateMap;
            let chatSnapshotUsersMap = state.chatSnapshotUsersMap;

            if (typeof action.payload != "undefined" && typeof action.payload.data != "undefined") {

                let chatIndexId = "";
                let senderId = action.payload.data["from_user"];
                let receiverId = action.payload.data["to_user"];


                let messageId = action.payload.data.msgid;
                let messageObj = action.payload.data.message;

                chatIndexId = MESSAGE_ROUTER_CHANNEL_TYPES.DM + "_" + (senderId === AppUtils.getCurrentUserId() ? receiverId : senderId);

                if (typeof selectedChatHistory[chatIndexId] != "undefined") {

                    for (let i = 0; i < selectedChatHistory[chatIndexId].length; i++) {

                        if (selectedChatHistory[chatIndexId][i]["id"] === messageId) {

                            if (typeof messageObj != "undefined" && typeof messageObj.text != "undefined") {
                                selectedChatHistory[chatIndexId][i]["message"] = messageObj.text;
                            }
                            break;
                        }
                    }
                }

            }

            return {
                ...state,
                dmEditMessagesStatus: HTTP_REQUEST_STATUS.SUCCESS,
                alertMessage: "DIC_KEY_MESSAGE_EDIT_SUCCESS",
                chatSnapshotUsersMap: chatSnapshotUsersMap,
                chatSnapshotUsersList: chatSnapshotUsersList,
                selectedChatHistory: selectedChatHistory,
                selectedChatHistoryDateMap: selectedChatHistoryDateMap,
                messageCounter: state.messageCounter + 1
            }
        }

        case PERSONAL_MESSAGES_MESSAGE_EDIT_ERROR: {

            return {
                ...state,
                dmEditMessagesStatus: HTTP_REQUEST_STATUS.ERROR,
                alertMessage: "DIC_KEY_MESSAGE_EDIT_FAIL"
            }
        }

        case PERSONAL_MESSAGES_MESSAGE_EDIT_NONE: {

            return {
                ...state,
                dmEditMessagesStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }

        /** message edit : group **/
        case GROUP_MESSAGES_EDIT_MESSAGE: {

            return {
                ...state,
                groupEditMessagesStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
            }
        }

        case MSG_ROUTER_EVENTS.CHANNEL.CHANNEL_EDIT_MESSAGE: {

            let chatSnapshotUsersList = state.chatSnapshotUsersList;
            let selectedChatHistory = state.selectedChatHistory;
            let selectedChatHistoryDateMap = state.selectedChatHistoryDateMap;
            let chatSnapshotUsersMap = state.chatSnapshotUsersMap;

            if (typeof action.payload != "undefined" && typeof action.payload.data != "undefined") {

                let chatIndexId = "";
                let selectedChatId = "";
                let messageId = action.payload.data.msgid;
                let messageObj = action.payload.data.message;

                if (typeof action.payload.data.channel_id !== "undefined") {
                    chatIndexId = MESSAGE_ROUTER_CHANNEL_TYPES.GROUP + "_" + action.payload.data.channel_id;
                    selectedChatId = action.payload.data.channel_id;

                    if (typeof selectedChatHistory[chatIndexId] != "undefined") {

                        for (let i = 0; i < selectedChatHistory[chatIndexId].length; i++) {

                            if (selectedChatHistory[chatIndexId][i]["id"] === messageId) {

                                if (typeof messageObj != "undefined" && typeof messageObj.text != "undefined") {
                                    selectedChatHistory[chatIndexId][i]["message"] = messageObj.text;
                                }
                                break;
                            }
                        }
                    }
                }
            }

            return {
                ...state,
                groupEditMessagesStatus: HTTP_REQUEST_STATUS.SUCCESS,
                alertMessage: "DIC_KEY_MESSAGE_EDIT_SUCCESS",
                chatSnapshotUsersMap: chatSnapshotUsersMap,
                chatSnapshotUsersList: chatSnapshotUsersList,
                selectedChatHistory: selectedChatHistory,
                selectedChatHistoryDateMap: selectedChatHistoryDateMap,
                messageCounter: state.messageCounter + 1
            }

        }

        case GROUP_MESSAGES_EDIT_MESSAGE_ERROR: {

            return {
                ...state,
                groupEditMessagesStatus: HTTP_REQUEST_STATUS.ERROR,
                alertMessage: "DIC_KEY_MESSAGE_EDIT_FAIL"
            }
        }

        case GROUP_MESSAGES_EDIT_MESSAGE_NONE: {

            return {
                ...state,
                groupEditMessagesStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }

        /** dm and channel : relay events  **/
        case MSG_ROUTER_EVENTS.RELAY.DM_RELAY:
        case MSG_ROUTER_EVENTS.RELAY.CHANNEL_RELAY: {

            let callNotificationData = state.callNotificationData;
            let isCallNotificationVisible = state.isCallNotificationVisible;
            let isDMMeetingForceTerminated = state.isDMMeetingForceTerminated;

            if (typeof action.payload.data != "undefined" && typeof action.payload.data.to != "undefined" && typeof action.payload.data.to.uid != "undefined" && action.payload.data.to.uid === AppUtils.getCurrentUserId() &&
                (action.payload.data.user_id !== AppUtils.getCurrentUserId() && action.payload.data.from_user !== AppUtils.getCurrentUserId())) {
                if (typeof action.payload.data.relay != "undefined" && typeof action.payload.data.relay.data != "undefined") {

                    let relayData = action.payload.data.relay.data;

                    let relayType = relayData.type;

                    switch (relayType) {

                        /** incoming call **/
                        case MSG_ROUTER_RELAY_TYPES.INCOMING_CALL: {

                            let callType = relayData.call_type;

                            if (callType === MSG_ROUTER_RELAY_TYPES.VIDEO_CALL || callType === MSG_ROUTER_RELAY_TYPES.AUDIO_CALL) {
                                callNotificationData = relayData;
                                isCallNotificationVisible = true;
                                AppUtils.initIncomingCallRingtone();
                            }

                            break;
                        }

                        /** terminate calle **/
                        case MSG_ROUTER_RELAY_TYPES.TERMINATE_DM_CALL: {

                            if (relayData.meeting_id === state.currentMeetingId) {
                                isDMMeetingForceTerminated = true;
                            }
                            break;
                        }

                        default: {
                            break;
                        }
                    }
                }
            }

            return {
                ...state,
                callNotificationData: callNotificationData,
                isCallNotificationVisible: isCallNotificationVisible,
                isDMMeetingForceTerminated: isDMMeetingForceTerminated
            }
        }

        /** incoming call reject event **/
        case CALL_EVENT_REJECT_CALL: {
            return {
                ...state,
                callNotificationData: {},
                isCallNotificationVisible: false
            }
        }

        /** Meeting join event from notification popup **/
        case CALL_EVENT_JOIN_CALL: {
            return {
                ...state,
                isCallNotificationVisible: false,
                currentMeetingId: action.payload.meeting_id,
                isMeetingVideoEnabled: action.payload.is_video,
                currentMeetingType: action.payload.meeting_type
            }
        }

        /** New Meeting start event **/
        case CALL_EVENT_START_CALL: {
            return {
                ...state,
                isCallNotificationVisible: false,
                currentMeetingId: action.payload.meeting_id,
                isMeetingVideoEnabled: action.payload.is_video,
                currentMeetingType: action.payload.meeting_type
            }
        }

        /** Meeting state reset event **/
        case CALL_EVENT_RESET_CALL: {
            return {
                ...state,
                isCallNotificationVisible: false,
                currentMeetingId: "",
                isMeetingVideoEnabled: false,
                currentMeetingType: ""
            }
        }


        default:
            return state;
    }
};

function parseStarredMessage(message, chatAllOtherUsersMap, userId, isChannel) {
    try {
        message["messageText"] = typeof message["message"] != "undefined" && typeof message["message"]["text"] != "undefined" ? message["message"]["text"] : "";
        message["sentAt"] = AppUtils.formatAMPM(typeof message["created_at"] != "undefined" ? new Date(message["created_at"] + "Z") : new Date());

        let userName = "";
        let statusMessage = "";
        let profileUrl = "";

        if (typeof chatAllOtherUsersMap[userId] != "undefined") {
            let currentUser = chatAllOtherUsersMap[userId];

            if (typeof currentUser["optional_data"] != "undefined") {
                let optionalData = currentUser["optional_data"];

                if (typeof optionalData["user_name"] != "undefined") {
                    userName = optionalData["user_name"];
                }

                statusMessage = optionalData["status_message"];
                profileUrl = optionalData["profile_image"];
            }

            if (userName === "") {
                userName = currentUser["name"];
            }
        }


        message["userName"] = userName;
        message["statusMessage"] = statusMessage;
        message["profileUrl"] = profileUrl;


    } catch (ex) {
        console.error("parseStarredMessage : error : ", ex);
    }
}


function updateMessageReactionEvent(data, messageReactionsMap, selectedChatHistory, allOtherUsersMap, isChannel) {
    try {

        let currentUserId = AppUtils.getCurrentUserId();
        let reactions = data["reaction"]
        let msgId = data["msgid"];
        let userId = "";
        let indexId = "";

        if (isChannel) {
            indexId = MESSAGE_ROUTER_CHANNEL_TYPES.GROUP + "_" + data["channel_id"];
            userId = data["user_id"];

        } else {
            userId = data["from_user"] == currentUserId ? data["to_user"] : data["from_user"];
            indexId = MESSAGE_ROUTER_CHANNEL_TYPES.DM + "_" + userId;
        }


        /** message update **/

        if (typeof selectedChatHistory[indexId] != "undefined") {

            for (let i = 0; i < selectedChatHistory[indexId].length; i++) {
                if (typeof selectedChatHistory[indexId][i]["id"] != "undefined" && selectedChatHistory[indexId][i]["id"] === msgId) {

                    let msgReactions = {};
                    let reactionsCount = 0;

                    if (typeof selectedChatHistory[indexId][i]["reactions"] != "undefined") {
                        msgReactions = selectedChatHistory[indexId][i]["reactions"];
                    }

                    if (typeof selectedChatHistory[indexId][i]["reactionsCount"] != "undefined") {
                        reactionsCount = selectedChatHistory[indexId][i]["reactionsCount"];
                    }

                    for (let i = 0; i < reactions.length; i++) {
                        if (typeof msgReactions[reactions[i]] == "undefined") {
                            msgReactions[reactions[i]] = [];
                        }

                        let users = msgReactions[reactions[i]];

                        let isValid = true;

                        for (let i = 0; i < users.length; i++) {
                            if (users[i]["userId"] === userId) {
                                isValid = false;
                                break;
                            }
                        }

                        if (isValid) {

                            let userName = "";

                            if (userId === currentUserId) {
                                userName = "You";
                            } else {
                                userName = allOtherUsersMap[userId]["name"];
                            }

                            msgReactions[reactions[i]].push({
                                "name": userName,
                                "userId": userId
                            });

                            reactionsCount += 1;

                            selectedChatHistory[indexId][i]["reactionsCount"] = reactionsCount;
                        }
                    }

                    break;
                }
            }
        }


        /** user reactions map **/
        if (typeof messageReactionsMap[indexId] == "undefined") {
            messageReactionsMap[indexId] = {};
        }

        if (typeof messageReactionsMap[indexId][userId] == "undefined") {
            messageReactionsMap[indexId][userId] = {};
        }

        if (typeof messageReactionsMap[indexId][userId][data["msgid"]] == "undefined") {
            messageReactionsMap[indexId][userId][data["msgid"]] = [];
        }

        messageReactionsMap[indexId][userId][data["msgid"]].push(reactions);

    } catch (ex) {
        console.error("updateMessageReactionEvent : error : ", ex);
    }
}

function addUserToChannel(currentGroup, memberId, role, chatAllOtherUsersMap, userDetails) {
    try {

        let userName = "";
        let statusMessage = "";
        let profileUrl = "";
        let currentRole = "";
        let roleTitle = "";
        let currentUserId = AppUtils.getCurrentUserId();
        let menuEnabled = memberId + "" !== currentUserId + "";

        let roleIndex = MESSAGE_ROUTER_CHANNEL_READ_ACCESS_INDEX.READ;

        if (role.indexOf(MESSAGE_ROUTER_CHANNEL_READ_ACCESS_TYPES.OWNER) !== -1) {
            roleIndex = MESSAGE_ROUTER_CHANNEL_READ_ACCESS_INDEX.OWNER;
        } else if (role.indexOf(MESSAGE_ROUTER_CHANNEL_READ_ACCESS_TYPES.READ_WRITE) !== -1) {
            roleIndex = MESSAGE_ROUTER_CHANNEL_READ_ACCESS_INDEX.READ_WRITE;
        } else if (role.indexOf(MESSAGE_ROUTER_CHANNEL_READ_ACCESS_TYPES.OWNER) !== -1) {
            roleIndex = MESSAGE_ROUTER_CHANNEL_READ_ACCESS_INDEX.WRITE;
        }

        if (typeof chatAllOtherUsersMap[memberId] != "undefined") {
            let currentUser = chatAllOtherUsersMap[memberId];

            if (typeof currentUser["optional_data"] != "undefined") {
                let optionalData = currentUser["optional_data"];

                if (typeof optionalData["user_name"] != "undefined") {
                    userName = optionalData["user_name"];
                }


                statusMessage = optionalData["status_message"];
                profileUrl = optionalData["profile_image"];
            }

            if (userName === "") {
                userName = currentUser["name"];
            }
        } else if (memberId + "" === currentUserId + "" && typeof userDetails[currentUserId] != "undefined") {
            let currentObj = userDetails[currentUserId]

            userName = <IntlMessages id={"DIC_KEY_GROUP_INFO_YOU"}/>;

            if (typeof currentObj["profile_image"] != "undefined") {
                profileUrl = currentObj["profile_image"];
            }

            if (typeof currentObj["status_message"] != "undefined") {
                statusMessage = currentObj["status_message"];
            }

            menuEnabled = false;
        }

        let currentItem = {
            "id": memberId,
            "name": userName,
            "statusMessage": statusMessage,
            "profileUrl": profileUrl,
            "role": currentRole,
            "status": "",
            "roleIndex": roleIndex,
            "roleTitle": roleTitle,
            "menuEnabled": menuEnabled
        };

        currentGroup.members.push(currentItem);

    } catch (ex) {
        console.error("addUserToChannel : error : ", ex);
    }
}

function updateChannelUserRole(currentGroup, memberId, role) {

    let result = currentGroup;

    try {

        let currentUserId = AppUtils.getCurrentUserId();

        for (let i = 0; i < currentGroup.members.length; i++) {

            if (currentGroup.members[i]["id"] + "" === memberId + "") {

                let roleIndex = MESSAGE_ROUTER_CHANNEL_READ_ACCESS_INDEX.READ;
                let currentRole = MESSAGE_ROUTER_CHANNEL_READ_ACCESS_TYPES.READ;
                let menuEnabled = currentGroup.members[i]["id"] + "" !== currentUserId + "";
                let roleTitle = "";

                currentGroup.members[i]["role"] = role;

                if (currentGroup.members[i]["role"].indexOf(MESSAGE_ROUTER_CHANNEL_READ_ACCESS_TYPES.OWNER) !== -1) {
                    roleIndex = MESSAGE_ROUTER_CHANNEL_READ_ACCESS_INDEX.OWNER;
                    currentRole = MESSAGE_ROUTER_CHANNEL_READ_ACCESS_TYPES.OWNER;
                    roleTitle = "DIC_KEY_GROUP_INFO_USER_ROLE_OWNER";

                } else if (currentGroup.members[i]["role"].indexOf(MESSAGE_ROUTER_CHANNEL_READ_ACCESS_TYPES.READ_WRITE) !== -1) {
                    roleIndex = MESSAGE_ROUTER_CHANNEL_READ_ACCESS_INDEX.READ_WRITE;
                    currentRole = MESSAGE_ROUTER_CHANNEL_READ_ACCESS_TYPES.READ_WRITE;
                    roleTitle = "DIC_KEY_GROUP_INFO_USER_ROLE_ADMIN";
                }

                currentGroup.members[i]["role"] = currentRole;
                currentGroup.members[i]["roleIndex"] = roleIndex;
                currentGroup.members[i]["roleTitle"] = roleTitle;
                currentGroup.members[i]["menuEnabled"] = menuEnabled;

                AppUtils.sortJSONArray(currentGroup.members, "roleIndex", true);

                break;
            }
        }
    } catch (ex) {
        console.error("updateChannelUserRole : error : ", ex);
    }

    return result;

}

function parseChannelDetails(data, chatAllOtherUsersMap, userDetails) {
    let result = {};

    try {

        result = {
            "chId": "",
            "name": "",
            "info": USER_STATUS_DEFAULT_GROUP_STATUS,
            "status": "",
            "isEditMode": false
        };

        let currentUserId = AppUtils.getCurrentUserId();
        let isEditMode = false;

        if (typeof data.channel != "undefined") {

            result["chId"] = data.channel.chid;
            result["status"] = data.channel.status;

            if (typeof data.channel.optional != "undefined") {

                result["optionalData"] = data.channel.optional;

                if (typeof data.channel.optional.name != "undefined") {
                    result["name"] = data.channel.optional.name;
                }

                if (typeof data.channel.optional.description != "undefined") {
                    result["info"] = data.channel.optional.description;
                }
            }
        }

        let membersDetails = data.members;
        let membersList = [];
        let membersMap = {};

        for (let i = 0; i < membersDetails.length; i++) {

            let roleIndex = MESSAGE_ROUTER_CHANNEL_READ_ACCESS_INDEX.READ;

            if (membersDetails[i]["role"].indexOf(MESSAGE_ROUTER_CHANNEL_READ_ACCESS_TYPES.OWNER) !== -1) {
                roleIndex = MESSAGE_ROUTER_CHANNEL_READ_ACCESS_INDEX.OWNER;
            } else if (membersDetails[i]["role"].indexOf(MESSAGE_ROUTER_CHANNEL_READ_ACCESS_TYPES.READ_WRITE) !== -1) {
                roleIndex = MESSAGE_ROUTER_CHANNEL_READ_ACCESS_INDEX.READ_WRITE;
            } else if (membersDetails[i]["role"].indexOf(MESSAGE_ROUTER_CHANNEL_READ_ACCESS_TYPES.OWNER) !== -1) {
                roleIndex = MESSAGE_ROUTER_CHANNEL_READ_ACCESS_INDEX.WRITE;
            }
            membersDetails[i].roleIndex = roleIndex;
        }

        AppUtils.sortJSONArray(membersDetails, "roleIndex", true);

        for (let i = 0; i < membersDetails.length; i++) {

            let currentRole = MESSAGE_ROUTER_CHANNEL_READ_ACCESS_TYPES.READ;
            let roleTitle = "";

            let userName = "";
            let statusMessage = "";
            let profileUrl = "";
            let menuEnabled = true;

            if (membersDetails[i]["role"].indexOf(MESSAGE_ROUTER_CHANNEL_READ_ACCESS_TYPES.OWNER) !== -1) {
                currentRole = MESSAGE_ROUTER_CHANNEL_READ_ACCESS_TYPES.OWNER;
                roleTitle = "DIC_KEY_GROUP_INFO_USER_ROLE_OWNER";

                if (!isEditMode) {
                    isEditMode = membersDetails[i]["uid"] + "" === currentUserId + "";
                }

            } else if (membersDetails[i]["role"].indexOf(MESSAGE_ROUTER_CHANNEL_READ_ACCESS_TYPES.READ_WRITE) !== -1) {
                currentRole = MESSAGE_ROUTER_CHANNEL_READ_ACCESS_TYPES.READ_WRITE;
                roleTitle = "DIC_KEY_GROUP_INFO_USER_ROLE_ADMIN";

                if (!isEditMode) {
                    isEditMode = membersDetails[i]["uid"] + "" === currentUserId + "";
                }

            } else if (membersDetails[i]["role"].indexOf(MESSAGE_ROUTER_CHANNEL_READ_ACCESS_TYPES.WRITE) !== -1) {
                currentRole = MESSAGE_ROUTER_CHANNEL_READ_ACCESS_TYPES.WRITE;
            }

            let currentItem = {};

            if (typeof chatAllOtherUsersMap[membersDetails[i]["uid"]] != "undefined") {
                let currentUser = chatAllOtherUsersMap[membersDetails[i]["uid"]];

                currentItem = JSON.parse(JSON.stringify(currentUser))

                if (typeof currentUser["optional_data"] != "undefined") {
                    let optionalData = currentUser["optional_data"];

                    if (typeof optionalData["user_name"] != "undefined") {
                        userName = optionalData["user_name"];
                    }


                    statusMessage = optionalData["status_message"];
                    profileUrl = optionalData["profile_image"];
                }

                if (userName === "") {
                    userName = currentUser["name"];
                }
            } else if (membersDetails[i]["uid"] + "" === currentUserId + "" && typeof userDetails[currentUserId] != "undefined") {
                let currentObj = userDetails[currentUserId]

                userName = <IntlMessages id={"DIC_KEY_GROUP_INFO_YOU"}/>;

                if (typeof currentObj["profile_image"] != "undefined") {
                    profileUrl = currentObj["profile_image"];
                }

                if (typeof currentObj["status_message"] != "undefined") {
                    statusMessage = currentObj["status_message"];
                }

                menuEnabled = false;
            }

            currentItem["id"] = membersDetails[i]["uid"];
            currentItem["name"] = userName;
            currentItem["statusMessage"] = statusMessage;
            currentItem["profileUrl"] = profileUrl;
            currentItem["role"] = currentRole;
            currentItem["status"] = membersDetails[i]["status"];
            currentItem["roleIndex"] = membersDetails[i]["roleIndex"];
            currentItem["roleTitle"] = roleTitle;
            currentItem["menuEnabled"] = menuEnabled;

            /*currentItem = {
                "id": membersDetails[i]["uid"],
                "name": userName,
                "statusMessage": statusMessage,
                "profileUrl": profileUrl,
                "role": currentRole,
                "status": membersDetails[i]["status"],
                "roleIndex": membersDetails[i]["roleIndex"],
                "roleTitle": roleTitle,
                "menuEnabled": menuEnabled
            };*/

            membersList.push(currentItem);

            if (membersDetails[i]["uid"] + "" !== currentUserId + "") {
                let key = currentItem["name"].length > 0 ? currentItem["name"].toString().substr(0, 1) : "";

                if (key != "") {

                    if (typeof membersMap[key] == "undefined") {
                        membersMap[key] = [];
                    }

                    membersMap[key].push(currentItem);
                }
            }
        }

        result["isEditMode"] = isEditMode;
        result["members"] = membersList;
        result["membersMap"] = membersMap;

    } catch (ex) {
        console.error("parseChannelDetails : error : ", ex);
    }

    return result;
}

function parseUserTagData(data) {
    let result = {};
    try {

        for (let i = 0; i < data.length; i++) {
            let indexId = data[i]["type"] + "_" + (data[i]["type"] === MESSAGE_ROUTER_CHANNEL_TYPES.GROUP ? data[i]["chid"] : data[i]["party"]);
            result[indexId] = data[i]["tags"];
        }

    } catch (ex) {
        console.error("parseUserTagData : error : ", ex);
    }

    return result;
}

function updateSnapshotPinnedData(chatSnapshotUsersMap, tagsList) {
    try {
        for (let key in chatSnapshotUsersMap) {
            let isPinned = false;
            let isPinnedSort = -1;
            let isMuteNotifications = false;
            let muteNotificationsTime = "";


            if (typeof tagsList[key] != "undefined" && tagsList[key]["is_pin"] == "1") {
                isPinned = true;
                isPinnedSort = 1;
            }

            if (typeof tagsList[key] != "undefined" && typeof tagsList[key][MESSAGE_TAG_TYPES.mute_notifications] != "undefined" && tagsList[key][MESSAGE_TAG_TYPES.mute_notifications] !== "") {
                isMuteNotifications = true;
                muteNotificationsTime = tagsList[key][MESSAGE_TAG_TYPES.mute_notifications];
            }

            chatSnapshotUsersMap[key]["isPinned"] = isPinned;
            chatSnapshotUsersMap[key]["isPinnedSort"] = isPinnedSort;
            chatSnapshotUsersMap[key]["isMuteNotifications"] = isMuteNotifications;
            chatSnapshotUsersMap[key]["muteNotificationsTime"] = muteNotificationsTime;
        }

    } catch (ex) {
        console.error("updateSnapshotPinnedData : error : ", ex);
    }
}

function getParsedSnapshotData(chatSnapshotUsersMap) {
    let result = [];
    try {
        for (let key in chatSnapshotUsersMap) {
            result.push(chatSnapshotUsersMap[key]);
        }

        AppUtils.sortJSONArray(result, "sortTime", false);
        AppUtils.sortJSONArray(result, "isPinnedSort", false);

    } catch (ex) {
        console.error("getParsedSnapshotData : error : ", ex);
    }

    return result;
}

function getSnapshotRecordFromUser(userRecord, type) {

    let result = {
        id: "",
        name: "",
        thumb: "",
        status: "",
        mood: "",
        lastMessage: "",
        unreadMessage: 0,
        lastMessageTime: "",
        recent: true,
        sortTime: "",
        statusClass: "",
        type: type,
        isPlaceHolder: true
    }

    try {

        result["id"] = userRecord.id;
        result["name"] = userRecord.name;
        result["thumb"] = userRecord.thumb;
        result["sortTime"] = new Date().getTime();

    } catch (ex) {
        console.error("getSnapshotRecordFromUser : error : ", ex);
    }

    return result;

}

function parseChannelAddedResponse(currentChannel, result) {
    try {
        let currentId = typeof currentChannel['chid'] != "undefined" ? currentChannel['chid'] : -1;

        let lastMessage = "";
        let unreadMessage = 0;
        let thumb = "";
        let status = MESSAGE_ROUTER_USER_STATUS_VALUES["APPEAR_OFFLINE"];
        let mood = "";
        let lastMessageTime = "";
        let optionalData = {};
        let sortTime = new Date().getTime();

        if (typeof currentChannel["optional"] != "undefined") {
            optionalData = currentChannel["optional"];
        }

        if (typeof currentChannel['created_at'] != "undefined") {
            sortTime = new Date(currentChannel['created_at'] + "Z").getTime();
        }


        if (typeof optionalData["group_image"] != "undefined") {
            thumb = chatBaseDownloadUrl + optionalData["group_image"]
        }

        result['id'] = currentId;
        result['name'] = typeof optionalData['name'] != "undefined" ? optionalData['name'] : "";
        result['thumb'] = thumb;
        result['status'] = status;
        result['mood'] = mood;
        result['lastMessage'] = lastMessage;
        result['unreadMessage'] = unreadMessage;
        result['lastMessageTime'] = lastMessageTime;
        result['sortTime'] = sortTime;
        result['statusClass'] = "";
        result['recent'] = true;
        result['type'] = MESSAGE_ROUTER_CHANNEL_TYPES.GROUP;

    } catch (ex) {
        console.error("parseChannelAddedResponse : error : ", ex);
    }
}

function convertChannelToSnapshotData(currentChannel, result) {
    try {
        let currentId = typeof currentChannel['channel_id'] != "undefined" ? currentChannel['channel_id'] : -1;

        let lastMessage = "";
        let unreadMessage = 0;
        let thumb = "";
        let status = MESSAGE_ROUTER_USER_STATUS_VALUES["APPEAR_OFFLINE"];
        let mood = "";
        let lastMessageTime = "";
        let optionalData = {};

        if (typeof currentChannel["details"] != "undefined" && typeof currentChannel["details"]["optional"] != "undefined") {
            optionalData = currentChannel["details"]["optional"];
        }

        if (typeof optionalData["group_image"] != "undefined") {
            thumb = chatBaseDownloadUrl + optionalData["group_image"]
        }

        result['id'] = currentId;
        result['name'] = typeof optionalData['name'] != "undefined" ? optionalData['name'] : "";
        result['thumb'] = thumb;
        result['status'] = status;
        result['mood'] = mood;
        result['lastMessage'] = lastMessage;
        result['unreadMessage'] = unreadMessage;
        result['lastMessageTime'] = lastMessageTime;
        result['sortTime'] = new Date().getTime();
        result['statusClass'] = "";
        result['recent'] = true;
        result['type'] = MESSAGE_ROUTER_CHANNEL_TYPES.GROUP;

    } catch (ex) {
        console.error("convertChannelToSnapshotData : error : ", ex);
    }
}

function updateSnapshotUserDetails(chatAllOtherUsersMap, chatSnapshotUsersMap, userDetails) {
    try {

        for (let indexId in chatSnapshotUsersMap) {

            if (chatSnapshotUsersMap[indexId].type === MESSAGE_ROUTER_CHANNEL_TYPES.DM) {
                let currentId = chatSnapshotUsersMap[indexId].id;

                if (typeof chatAllOtherUsersMap[currentId] != "undefined") {

                    if (typeof chatAllOtherUsersMap[currentId]["name"] != "undefined") {
                        chatSnapshotUsersMap[indexId]["name"] = chatAllOtherUsersMap[currentId]["name"];
                    }

                    if (typeof chatAllOtherUsersMap[currentId]["thumb"] != "undefined") {
                        chatSnapshotUsersMap[indexId]["thumb"] = chatAllOtherUsersMap[currentId]["thumb"];
                    }

                    if (typeof userDetails[currentId] != "undefined" && typeof userDetails[currentId]["status"] != "undefined") {
                        chatSnapshotUsersMap[indexId]["statusClass"] = MESSAGE_ROUTER_USER_STATUS[userDetails[currentId]["status"]];
                    }

                    if (typeof chatAllOtherUsersMap[currentId]["status"] != "undefined" && chatAllOtherUsersMap[currentId]["status"] !== "") {
                        chatSnapshotUsersMap[indexId]["status"] = chatAllOtherUsersMap[currentId]["status"];
                    }

                    if (typeof chatAllOtherUsersMap[currentId]["mood"] != "undefined") {
                        chatSnapshotUsersMap[indexId]["mood"] = chatAllOtherUsersMap[currentId]["mood"];
                    }
                }
            }
        }

    } catch (ex) {
        console.error("updateSnapshotUserDetails : error : ", ex);
    }
}

function insertRecordToSnapshot(chatAllOtherUsersMap, chatSnapshotUsersMap, userDetails, tagsList, userId, isChannel, messageData) {
    let result = [];
    try {

        let currentRecord = {};

        let currentId = userId;
        let lastMessage = "";
        let unreadCount = 0;
        let name = "";
        let thumb = "";
        let status = MESSAGE_ROUTER_USER_STATUS_VALUES["APPEAR_OFFLINE"];
        let mood = "";
        let lastMessageTime = "";
        let sortTime = -1;
        let statusClass = "";
        let type = isChannel ? MESSAGE_ROUTER_CHANNEL_TYPES.GROUP : MESSAGE_ROUTER_CHANNEL_TYPES.DM;
        let userRole = MESSAGE_ROUTER_CHANNEL_READ_ACCESS_TYPES.OWNER;
        let isMuteNotifications = false;
        let muteNotificationsTime = "";
        let indexId = type + "_" + userId;

        if (typeof messageData["message"] != "undefined" && typeof messageData["message"]["text"] != "undefined") {
            lastMessage = messageData["message"]["text"];
        }

        if (typeof messageData["created_at"] != "undefined") {
            let messageTime = new Date(messageData["created_at"] + "Z");
            lastMessageTime = AppUtils.getChatCurrentValue(messageTime);
            sortTime = messageTime.getTime();
        }

        if (typeof chatAllOtherUsersMap[userId] != "undefined") {

            if (typeof chatAllOtherUsersMap[userId]["name"] != "undefined") {
                name = chatAllOtherUsersMap[userId]["name"]
            }

            if (typeof chatAllOtherUsersMap[userId]["thumb"] != "undefined") {
                thumb = chatAllOtherUsersMap[userId]["thumb"]
            }

            if (typeof userDetails[userId] != "undefined" && typeof userDetails[userId]["status"] != "undefined") {
                statusClass = MESSAGE_ROUTER_USER_STATUS[userDetails[userId]["status"]];
            }

            if (typeof chatAllOtherUsersMap[userId]["status"] != "undefined" && chatAllOtherUsersMap[userId]["status"] !== "") {
                status = chatAllOtherUsersMap[userId]["status"]
            }

            if (typeof chatAllOtherUsersMap[userId]["mood"] != "undefined") {
                mood = chatAllOtherUsersMap[userId]["mood"]
            }
        }

        currentRecord = {
            id: currentId,
            name: name,
            thumb: thumb,
            status: status,
            mood: mood,
            lastMessage: lastMessage,
            unreadMessage: unreadCount,
            lastMessageTime: lastMessageTime,
            recent: true,
            sortTime: sortTime,
            statusClass: statusClass,
            type: type,
            indexId: indexId,
            isPinned: false,
            isPinnedSort: -1,
            userRole: userRole,
            isMuteNotifications: isMuteNotifications,
            muteNotificationsTime: muteNotificationsTime,
            isChannel: isChannel
        };

        if (currentId !== -1) {
            chatSnapshotUsersMap[indexId] = currentRecord;
        }

        result.push(currentRecord);

    } catch (ex) {
        console.error("insertRecordToSnapshot : error : ", ex);
    }

    return result;
}

function parseSnapShotResult(channels, chatAllOtherUsersMap, chatSnapshotUsersMap, userDetails, tagsList) {
    let result = [];
    try {

        let currentRecord = {};

        for (let i = 0; i < channels.length; i++) {

            currentRecord = {};
            let currentId = -1;
            let lastMessage = "";
            let unreadCount = 0;
            let name = "";
            let thumb = "";
            let status = MESSAGE_ROUTER_USER_STATUS_VALUES["APPEAR_OFFLINE"];
            let mood = "";
            let lastMessageTime = "";
            let sortTime = -1;
            let statusClass = "away";
            let type = channels[i]["type"];
            let userRole = MESSAGE_ROUTER_CHANNEL_READ_ACCESS_TYPES.READ;
            let isMuteNotifications = false;
            let muteNotificationsTime = "";
            let isChannel = false;

            if (typeof channels[i]["message"] != "undefined" && typeof channels[i]["message"]["data"] != "undefined" && typeof channels[i]["message"]["data"]["text"] != "undefined") {
                lastMessage = channels[i]["message"]["data"]["text"];
                let messageTime = new Date(channels[i]["message"]["timestamp"] + "Z");
                lastMessageTime = AppUtils.getChatCurrentValue(messageTime);
                sortTime = messageTime.getTime();
            }

            if (typeof channels[i]["self"] != "undefined" && typeof channels[i]["self"]["role"] != "undefined") {

                let currentRole = channels[i]["self"]["role"];

                if (currentRole.indexOf(MESSAGE_ROUTER_CHANNEL_READ_ACCESS_TYPES.OWNER) !== -1) {
                    userRole = MESSAGE_ROUTER_CHANNEL_READ_ACCESS_TYPES.OWNER;
                } else if (currentRole.indexOf(MESSAGE_ROUTER_CHANNEL_READ_ACCESS_TYPES.READ_WRITE) !== -1) {
                    userRole = MESSAGE_ROUTER_CHANNEL_READ_ACCESS_TYPES.READ_WRITE;
                } else if (currentRole.indexOf(MESSAGE_ROUTER_CHANNEL_READ_ACCESS_TYPES.WRITE) !== -1) {
                    userRole = MESSAGE_ROUTER_CHANNEL_READ_ACCESS_TYPES.WRITE;
                }
            }

            if (typeof channels[i]["unread"] != "undefined") {
                unreadCount = channels[i]["unread"];
            }

            if (type === MESSAGE_ROUTER_CHANNEL_TYPES.DM) {
                currentId = channels[i]["party"] && channels[i]["party"]["uid"] ? channels[i]["party"]["uid"] : "";

                if (typeof chatAllOtherUsersMap[currentId] != "undefined") {

                    if (typeof chatAllOtherUsersMap[currentId]["name"] != "undefined") {
                        name = chatAllOtherUsersMap[currentId]["name"]
                    }

                    if (typeof chatAllOtherUsersMap[currentId]["thumb"] != "undefined") {
                        thumb = chatAllOtherUsersMap[currentId]["thumb"]
                    }

                    if (typeof userDetails[currentId] != "undefined" && typeof userDetails[currentId]["status"] != "undefined") {
                        statusClass = MESSAGE_ROUTER_USER_STATUS[userDetails[currentId]["status"]];
                    }

                    if (typeof chatAllOtherUsersMap[currentId]["status"] != "undefined" && chatAllOtherUsersMap[currentId]["status"] !== "") {
                        status = chatAllOtherUsersMap[currentId]["status"]
                    }

                    if (typeof chatAllOtherUsersMap[currentId]["mood"] != "undefined") {
                        mood = chatAllOtherUsersMap[currentId]["mood"]
                    }
                }

            } else if (type === MESSAGE_ROUTER_CHANNEL_TYPES.GROUP) {

                currentId = channels[i]["chid"] ? channels[i]["chid"] : "";
                statusClass = "";
                status = 1;
                isChannel = true;

                let optionalData = channels[i]["optional"];

                if (typeof optionalData["name"] != "undefined") {
                    name = optionalData["name"]
                }

                if (typeof optionalData["group_image"] != "undefined") {
                    thumb = chatBaseDownloadUrl + optionalData["group_image"]
                }

                if (sortTime === -1) {
                    let modifiedTime = new Date(channels[i]["modified_at"] + "Z");
                    lastMessageTime = AppUtils.getChatCurrentValue(modifiedTime);
                    sortTime = modifiedTime.getTime();
                }
            }

            let indexId = type + "_" + currentId;
            let isPinned = typeof tagsList[indexId] != "undefined" && tagsList[indexId]["is_pin"] == "1";

            if( typeof channels[i]["mute_details"] != "undefined" ){
                isMuteNotifications = typeof channels[i]["mute_details"]["muted"] != "undefined" && channels[i]["mute_details"]["muted"];

                if( typeof channels[i]["mute_details"]["until"] != "undefined" && channels[i]["mute_details"]["until"] != "" ){
                  muteNotificationsTime = AppUtils.getUTCTimeValue(channels[i]["mute_details"]["until"]);
                }
            }

            /*if (typeof tagsList[indexId] != "undefined" && typeof tagsList[indexId][MESSAGE_TAG_TYPES.mute_notifications] !== "undefined" && tagsList[indexId][MESSAGE_TAG_TYPES.mute_notifications] !== "") {
                isMuteNotifications = true;
                muteNotificationsTime = tagsList[indexId][MESSAGE_TAG_TYPES.mute_notifications];
            }*/

            if (name === "") {
                continue;
            }

            currentRecord = {
                id: currentId,
                name: name,
                placeHolder: AppUtils.getAvatarPlaceHolderFromName(name),
                thumb: thumb,
                status: status,
                mood: mood,
                lastMessage: lastMessage,
                unreadMessage: unreadCount,
                lastMessageTime: lastMessageTime,
                recent: true,
                sortTime: sortTime,
                statusClass: statusClass,
                type: type,
                indexId: indexId,
                isPinned: isPinned,
                isPinnedSort: isPinned ? 1 : -1,
                userRole: userRole,
                isMuteNotifications: isMuteNotifications,
                muteNotificationsTime: muteNotificationsTime,
                isChannel: isChannel
            };

            if (currentId !== -1) {
                chatSnapshotUsersMap[indexId] = currentRecord;
            }

            result.push(currentRecord);
        }

    } catch (ex) {
        console.error("parseSnapShotResult : error : ", ex);
    }

    return result;
}

function prepareOtherUsersList(data, chatAllOtherUsersMap, chatAllOtherUsersMapList) {
    let result = data;
    try {

        for (let i = 0; i < data.length; i++) {
            data[i]['name'] = data[i]['first_name'] + " " + data[i]['last_name'];
            data[i]['thumb'] = "";
            data[i]['status'] = "";
            data[i]['mood'] = "";
            data[i]['lastMessage'] = "";
            data[i]['unreadMessage'] = "";
            data[i]['lastMessageTime'] = "";
            data[i]['recent'] = false;
            data[i]['value'] = data[i]['id'];
            data[i]['text'] = data[i]['name'];
            data[i]['title'] = data[i]['name'];
            data[i]['indexId'] = MESSAGE_ROUTER_CHANNEL_TYPES.DM + "_" + data[i]["id"];
            data[i]['type'] = MESSAGE_ROUTER_CHANNEL_TYPES.DM;
            data[i]['color'] = AppUtils.getRandomColor();

            chatAllOtherUsersMap[data[i]["id"]] = data[i];

            if (typeof data[i]["optional_data"] != "undefined" && typeof data[i]["optional_data"]["profile_image"] != "undefined" && data[i]["optional_data"]["profile_image"] !== "") {
                data[i]['thumb'] = chatBaseDownloadUrl + data[i]["optional_data"]["profile_image"];
            }

            let firstLetter = data[i]['name'].charAt(0);

            if (typeof chatAllOtherUsersMapList[firstLetter] == "undefined") {
                chatAllOtherUsersMapList[firstLetter] = [];
            }


            chatAllOtherUsersMapList[firstLetter].push(data[i]);
        }

    } catch (ex) {
        console.error("prepareOtherUsersList : error : ", ex);
    }

    return result;
}

function parseChatHomeSearchResults(searchText, snapshotData, contactsMap, chatHomeSearchResults) {
    try {

        searchText = searchText.toLowerCase();

        for (let key in snapshotData) {
            let currentItem = snapshotData[key];

            if (currentItem["type"] === MESSAGE_ROUTER_CHANNEL_TYPES.GROUP && currentItem["name"].toString().toLowerCase().indexOf(searchText) !== -1) {
                chatHomeSearchResults["chatList"].push(currentItem);
            }
        }

        for (let key in contactsMap) {

            let currentItem = contactsMap[key];

            if (currentItem["name"].toString().toLowerCase().indexOf(searchText) !== -1) {

                if (typeof snapshotData[currentItem.id] != "undefined" && snapshotData[currentItem.id]["type"] === MESSAGE_ROUTER_CHANNEL_TYPES.DM) {
                    chatHomeSearchResults["chatList"].push(currentItem);
                } else {
                    chatHomeSearchResults["contactList"].push(currentItem);
                }
            }
        }

    } catch (ex) {
        console.error("parseChatHomeSearchResults : error : ", ex);
    }
}

function filterNewChatWindowUsers(searchText, chatAllOtherUsersMapList, result) {
    try {
        searchText = searchText.toString().toLowerCase();

        for (let key in chatAllOtherUsersMapList) {
            let userList = chatAllOtherUsersMapList[key];

            for (let i = 0; i < userList.length; i++) {

                if (userList[i]["name"].toString().toLowerCase().indexOf(searchText) !== -1) {

                    if (typeof result[key] == "undefined") {
                        result[key] = [];
                    }
                    result[key].push(userList[i]);
                }
            }
        }


    } catch (ex) {
        console.error("filterNewChatWindowUsers : error : ", ex);
    }
}

function filterAddParticipantsWindowUsers(searchText, chatAllOtherUsersMapList, selectedUsers, result) {
    try {
        for (let key in chatAllOtherUsersMapList) {
            let userList = chatAllOtherUsersMapList[key];

            for (let i = 0; i < userList.length; i++) {

                if (userList[i]["name"].toString().toLowerCase().indexOf(searchText) !== -1 || searchText === "") {

                    if (typeof selectedUsers[userList[i]["id"]] == "undefined") {
                        if (typeof result[key] == "undefined") {
                            result[key] = [];
                        }
                        result[key].push(userList[i]);
                    }
                }
            }
        }
    } catch (ex) {
        console.error("filterAddParticipantsWindowUsers : error : ", ex);
    }
}

export default ReducerChat;
