import {
    CHANNEL_MANAGEMENT_CHANNEL_ACTIVATE,
    CHANNEL_MANAGEMENT_CHANNEL_ACTIVATE_ERROR,
    CHANNEL_MANAGEMENT_CHANNEL_ACTIVATE_NONE,
    CHANNEL_MANAGEMENT_CHANNEL_ACTIVATE_SUCCESS,
    CHANNEL_MANAGEMENT_CHANNEL_CHANGE_OWNERSHIP,
    CHANNEL_MANAGEMENT_CHANNEL_CHANGE_OWNERSHIP_ERROR,
    CHANNEL_MANAGEMENT_CHANNEL_CHANGE_OWNERSHIP_NONE,
    CHANNEL_MANAGEMENT_CHANNEL_CHANGE_OWNERSHIP_SUCCESS,
    CHANNEL_MANAGEMENT_CHANNEL_CREATE,
    CHANNEL_MANAGEMENT_CHANNEL_CREATE_ERROR,
    CHANNEL_MANAGEMENT_CHANNEL_CREATE_NONE,
    CHANNEL_MANAGEMENT_CHANNEL_CREATE_SUCCESS,
    CHANNEL_MANAGEMENT_CHANNEL_DEACTIVATE,
    CHANNEL_MANAGEMENT_CHANNEL_DEACTIVATE_ERROR,
    CHANNEL_MANAGEMENT_CHANNEL_DEACTIVATE_NONE,
    CHANNEL_MANAGEMENT_CHANNEL_DEACTIVATE_SUCCESS,
    CHANNEL_MANAGEMENT_CHANNEL_DELETE,
    CHANNEL_MANAGEMENT_CHANNEL_DELETE_ERROR,
    CHANNEL_MANAGEMENT_CHANNEL_DELETE_NONE,
    CHANNEL_MANAGEMENT_CHANNEL_DELETE_SUCCESS,
    CHANNEL_MANAGEMENT_CHANNEL_DETAILS,
    CHANNEL_MANAGEMENT_CHANNEL_DETAILS_ERROR,
    CHANNEL_MANAGEMENT_CHANNEL_DETAILS_NONE,
    CHANNEL_MANAGEMENT_CHANNEL_DETAILS_SUCCESS,
    CHANNEL_MANAGEMENT_CHANNEL_LIST,
    CHANNEL_MANAGEMENT_CHANNEL_LIST_ERROR,
    CHANNEL_MANAGEMENT_CHANNEL_LIST_NONE,
    CHANNEL_MANAGEMENT_CHANNEL_LIST_SUCCESS,
    CHANNEL_MANAGEMENT_CHANNEL_MUTE_UNMUTE_NOTIFICATIONS,
    CHANNEL_MANAGEMENT_CHANNEL_MUTE_UNMUTE_NOTIFICATIONS_ERROR,
    CHANNEL_MANAGEMENT_CHANNEL_MUTE_UNMUTE_NOTIFICATIONS_NONE,
    CHANNEL_MANAGEMENT_CHANNEL_MUTE_UNMUTE_NOTIFICATIONS_SUCCESS,
    CHANNEL_MANAGEMENT_CHANNEL_UPDATE,
    CHANNEL_MANAGEMENT_CHANNEL_UPDATE_DESCRIPTION,
    CHANNEL_MANAGEMENT_CHANNEL_UPDATE_DESCRIPTION_ERROR,
    CHANNEL_MANAGEMENT_CHANNEL_UPDATE_DESCRIPTION_NONE,
    CHANNEL_MANAGEMENT_CHANNEL_UPDATE_DESCRIPTION_SUCCESS,
    CHANNEL_MANAGEMENT_CHANNEL_UPDATE_ERROR,
    CHANNEL_MANAGEMENT_CHANNEL_UPDATE_IMAGE,
    CHANNEL_MANAGEMENT_CHANNEL_UPDATE_IMAGE_ERROR,
    CHANNEL_MANAGEMENT_CHANNEL_UPDATE_IMAGE_NONE,
    CHANNEL_MANAGEMENT_CHANNEL_UPDATE_IMAGE_SUCCESS,
    CHANNEL_MANAGEMENT_CHANNEL_UPDATE_NAME,
    CHANNEL_MANAGEMENT_CHANNEL_UPDATE_NAME_ERROR,
    CHANNEL_MANAGEMENT_CHANNEL_UPDATE_NAME_NONE,
    CHANNEL_MANAGEMENT_CHANNEL_UPDATE_NAME_SUCCESS,
    CHANNEL_MANAGEMENT_CHANNEL_UPDATE_NONE,
    CHANNEL_MANAGEMENT_CHANNEL_UPDATE_SUCCESS,

} from "../../../constants/actions/chat/ChatActionTypes";

/** channel management : channel create **/
export const channelManagementChannelCreate = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_CREATE,
        payload: payload
    };
};

/** channel management : channel create : success **/
export const channelManagementChannelCreateSuccess = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_CREATE_SUCCESS,
        payload: payload
    };
};

/** channel management : channel create : error **/
export const channelManagementChannelCreateError = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_CREATE_ERROR,
        payload: payload
    };
};

/** channel management : channel create : none **/
export const channelManagementChannelCreateNone = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_CREATE_NONE,
        payload: payload
    };
};


/** channel management : channel update **/
export const channelManagementChannelUpdate = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_UPDATE,
        payload: payload
    };
};

/** channel management : channel update : success **/
export const channelManagementChannelUpdateSuccess = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_UPDATE_SUCCESS,
        payload: payload
    };
};

/** channel management : channel update : error **/
export const channelManagementChannelUpdateError = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_UPDATE_ERROR,
        payload: payload
    };
};

/** channel management : channel update : none **/
export const channelManagementChannelUpdateNone = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_UPDATE_NONE,
        payload: payload
    };
};

/** channel management : channel update name **/
export const channelManagementUpdateName = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_UPDATE_NAME,
        payload: payload
    };
};

/** channel management : channel update name : success **/
export const channelManagementUpdateNameSuccess = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_UPDATE_NAME_SUCCESS,
        payload: payload
    };
};

/** channel management : channel update name : error **/
export const channelManagementUpdateNameError = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_UPDATE_NAME_ERROR,
        payload: payload
    };
};

/** channel management : channel update name : none **/
export const channelManagementUpdateNameNone = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_UPDATE_NAME_NONE,
        payload: payload
    };
};

/** channel management : channel update description **/
export const channelManagementUpdateDescription = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_UPDATE_DESCRIPTION,
        payload: payload
    };
};

/** channel management : channel update description : success **/
export const channelManagementUpdateDescriptionSuccess = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_UPDATE_DESCRIPTION_SUCCESS,
        payload: payload
    };
};

/** channel management : channel update description : error **/
export const channelManagementUpdateDescriptionError = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_UPDATE_DESCRIPTION_ERROR,
        payload: payload
    };
};

/** channel management : channel update description : none **/
export const channelManagementUpdateDescriptionNone = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_UPDATE_DESCRIPTION_NONE,
        payload: payload
    };
};

/** channel management : channel update image **/
export const channelManagementUpdateImage = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_UPDATE_IMAGE,
        payload: payload
    };
};

/** channel management : channel update image : success **/
export const channelManagementUpdateImageSuccess = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_UPDATE_IMAGE_SUCCESS,
        payload: payload
    };
};

/** channel management : channel update image : error **/
export const channelManagementUpdateImageError = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_UPDATE_IMAGE_ERROR,
        payload: payload
    };
};

/** channel management : channel update image : none **/
export const channelManagementUpdateImageNone = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_UPDATE_IMAGE_NONE,
        payload: payload
    };
};


/** channel management : channel delete **/
export const channelManagementChannelDelete = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_DELETE,
        payload: payload
    };
};

/** channel management : channel delete : success **/
export const channelManagementChannelDeleteSuccess = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_DELETE_SUCCESS,
        payload: payload
    };
};

/** channel management : channel delete : error **/
export const channelManagementChannelDeleteError = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_DELETE_ERROR,
        payload: payload
    };
};

/** channel management : channel delete : none **/
export const channelManagementChannelDeleteNone = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_DELETE_NONE,
        payload: payload
    };
};


/** channel management : channel activate **/
export const channelManagementChannelActivate = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_ACTIVATE,
        payload: payload
    };
};

/** channel management : channel activate : success **/
export const channelManagementChannelActivateSuccess = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_ACTIVATE_SUCCESS,
        payload: payload
    };
};

/** channel management : channel activate : error **/
export const channelManagementChannelActivateError = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_ACTIVATE_ERROR,
        payload: payload
    };
};

/** channel management : channel activate : none **/
export const channelManagementChannelActivateNone = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_ACTIVATE_NONE,
        payload: payload
    };
};


/** channel management : channel deactivate **/
export const channelManagementChannelDeactivate = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_DEACTIVATE,
        payload: payload
    };
};

/** channel management : channel deactivate : success **/
export const channelManagementChannelDeactivateSuccess = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_DEACTIVATE_SUCCESS,
        payload: payload
    };
};

/** channel management : channel deactivate : error **/
export const channelManagementChannelDeactivateError = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_DEACTIVATE_ERROR,
        payload: payload
    };
};

/** channel management : channel deactivate : none **/
export const channelManagementChannelDeactivateNone = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_DEACTIVATE_NONE,
        payload: payload
    };
};


/** channel management : channel change ownership **/
export const channelManagementChannelChangeOwnership = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_CHANGE_OWNERSHIP,
        payload: payload
    };
};

/** channel management : channel change ownership : success **/
export const channelManagementChannelChangeOwnershipSuccess = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_CHANGE_OWNERSHIP_SUCCESS,
        payload: payload
    };
};

/** channel management : channel change ownership : error **/
export const channelManagementChannelChangeOwnershipError = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_CHANGE_OWNERSHIP_ERROR,
        payload: payload
    };
};

/** channel management : channel change ownership : none **/
export const channelManagementChannelChangeOwnershipNone = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_CHANGE_OWNERSHIP_NONE,
        payload: payload
    };
};


/** channel management : channel details **/
export const channelManagementChannelDetails = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_DETAILS,
        payload: payload
    };
};

/** channel management : channel details : success **/
export const channelManagementChannelDetailsSuccess = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_DETAILS_SUCCESS,
        payload: payload
    };
};

/** channel management : channel details : error **/
export const channelManagementChannelDetailsError = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_DETAILS_ERROR,
        payload: payload
    };
};

/** channel management : channel details : none **/
export const channelManagementChannelDetailsNone = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_DETAILS_NONE,
        payload: payload
    };
};


/** channel management : channel list **/
export const channelManagementChannelList = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_LIST,
        payload: payload
    };
};

/** channel management : channel list : success **/
export const channelManagementChannelListSuccess = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_LIST_SUCCESS,
        payload: payload
    };
};

/** channel management : channel list : error **/
export const channelManagementChannelListError = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_LIST_ERROR,
        payload: payload
    };
};

/** channel management : channel list : none **/
export const channelManagementChannelListNone = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_LIST_NONE,
        payload: payload
    };
};

/** channel management : mute unmute notifications **/
export const channelManagementChannelMuteUnmuteNotifications = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_MUTE_UNMUTE_NOTIFICATIONS,
        payload: payload
    };
};

/** channel management : mute unmute notifications : success **/
export const channelManagementChannelMuteUnmuteNotificationsSuccess = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_MUTE_UNMUTE_NOTIFICATIONS_SUCCESS,
        payload: payload
    };
};

/** channel management : mute unmute notifications : error **/
export const channelManagementChannelMuteUnmuteNotificationsError = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_MUTE_UNMUTE_NOTIFICATIONS_ERROR,
        payload: payload
    };
};

/** channel management : mute unmute notifications : none **/
export const channelManagementChannelMuteUnmuteNotificationsNone = (payload) => {
    return {
        type: CHANNEL_MANAGEMENT_CHANNEL_MUTE_UNMUTE_NOTIFICATIONS_NONE,
        payload: payload
    };
};


