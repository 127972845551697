import {
    HIDE_MESSAGE,
    INIT_URL,
    ON_HIDE_LOADER,
    ON_SHOW_LOADER,
    SHOW_MESSAGE,
    SIGNIN_FACEBOOK_USER_SUCCESS,
    SIGNIN_GITHUB_USER_SUCCESS,
    SIGNIN_GOOGLE_USER_SUCCESS,
    SIGNIN_TWITTER_USER_SUCCESS,
    SIGNIN_USER_SUCCESS, SIGNOUT_USER,
    SIGNOUT_USER_SUCCESS,
    SIGNUP_USER_SUCCESS
} from "../../constants/ActionTypes";
import {APP_NAVIGATION_PATHS, HTTP_REQUEST_STATUS} from "../../util/tribe/constants/config";
import {TribeChatSocketIOHandler} from "../../util/tribe/socket_io/tribeChatSocketIOHandler";
import AppUtils from "../../util/tribe/utils/appUtils";
import StorageUtils from "../../util/tribe/storage_utils/storageUtils";
import {USER_SETTINGS_UPDATE_PROFILE_SUCCESS} from "../../constants/actions/user_settings/userSettingsActionTypes";

const menuConfiguration = [
    {
        key: "chat",
        name: 'chat',
        url: APP_NAVIGATION_PATHS.CHAT_HOME,
        iconClass: 'icon icon-chat-bubble -flex-column-reverse',
        dicClass: 'wovo_span_label',
        dic_key: 'DIC_KEY_SIDE_BAR_CHAT'
    },
    /*{
        key: "calender",
        name: 'calender',
        url: APP_NAVIGATION_PATHS.CALENDER_HOME,
        iconClass: 'icon icon-calendar-new -flex-column-reverse',
        dicClass: 'wovo_span_label',
        dic_key: 'DIC_KEY_SIDE_BAR_CALENDER'
    },
    {
        key: "calls",
        name: 'calls',
        url: APP_NAVIGATION_PATHS.CALLS_HOME,
        iconClass: 'icon icon-phone -flex-column-reverse',
        dicClass: 'wovo_span_label',
        dic_key: 'DIC_KEY_SIDE_BAR_CALLS'
    }*/
];

const INIT_STATE = {
    loader: false,
    alertMessage: '',
    showMessage: false,
    initURL: '',
    authUser: null,//localStorage.getItem('user_id'),
    menuConfiguration: menuConfiguration,
    accessToken: "",
    refreshToken: "",
    bearerHeader: "",
    deviceId: AppUtils.getUUID(),
    isSuperAdmin: false,
    clientFeatures: {},

    registerDeviceIdStatus: HTTP_REQUEST_STATUS.NOT_STARTED
};

const AuthReducer = (state = INIT_STATE, action) => {
    switch (action.type) {

        case SIGNOUT_USER: {

            StorageUtils.doLogout();
            localStorage.removeItem('user_id');
            TribeChatSocketIOHandler.disconnectSocket();

            return {
                ...state,
                loader: false,
                alertMessage: '',
                showMessage: false,
                initURL: '',
                authUser: localStorage.getItem('user_id'),
                menuConfiguration: [],
                accessToken: "",
                refreshToken: "",
                bearerHeader: "",
                deviceId: ""
            }
        }

        case SIGNUP_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                authUser: action.payload
            }
        }

        case USER_SETTINGS_UPDATE_PROFILE_SUCCESS: {
            return {
                ...state,
                authUser: action.payload.data
            }
        }

        case SIGNIN_USER_SUCCESS: {

            let accessToken = "";
            let refreshToken = "";
            let expireTime = "";
            let menuList = JSON.parse(JSON.stringify(menuConfiguration));
            let isSuperAdmin = (typeof action.payload.data.user != "undefined" && action.payload.data.user["super_admin"]);
            let clientFeatures = {};

            if (typeof action.payload.data != "undefined") {
                accessToken = action.payload.data["access_token"];
                refreshToken = action.payload.data["refresh_token"];
                expireTime = action.payload.data["expiry_time"];

                let tokens = {
                    accessToken: accessToken,
                    refreshToken: refreshToken,
                    expireTime: expireTime,
                    deviceId: state.deviceId
                };

                if (typeof action.payload.data.client_features != "undefined") {
                    clientFeatures = action.payload.data.client_features;
                }

                AppUtils.setCurrentUserId(action.payload.data.user.id);

                TribeChatSocketIOHandler.initSocketIO(action.payload.data.user.id, tokens);
            }

            if (isSuperAdmin) {

                menuList = [
                    {
                        key: "clients",
                        name: 'clients',
                        url: APP_NAVIGATION_PATHS.CLIENTS_HOME,
                        iconClass: 'icon icon-card -flex-column-reverse',
                        dicClass: 'wovo_span_label',
                        dic_key: 'DIC_KEY_SIDE_BAR_CLIENTS'
                    },
                    {
                        key: "users",
                        name: 'users',
                        url: APP_NAVIGATION_PATHS.USERS_HOME,
                        iconClass: 'icon icon-user-o -flex-column-reverse',
                        dicClass: 'wovo_span_label',
                        dic_key: 'DIC_KEY_SIDE_BAR_USERS'
                    }
                ];
            }

            return {
                ...state,
                loader: false,
                authUser: action.payload.data.user,
                expireTime: expireTime,
                bearerHeader: {Authorization: `Bearer ${accessToken}`},
                refreshKey: refreshToken,
                accessToken: `Bearer ${accessToken}`,
                menuConfiguration: menuList,
                isSuperAdmin: isSuperAdmin,
                clientFeatures: clientFeatures
            }
        }
        case INIT_URL: {
            return {
                ...state,
                initURL: action.payload
            }
        }
        case SIGNOUT_USER_SUCCESS: {
            return {
                ...state,
                authUser: null,
                initURL: '/',
                loader: false,
                clientFeatures: {}
            }
        }

        case SHOW_MESSAGE: {
            return {
                ...state,
                alertMessage: action.payload,
                showMessage: true,
                loader: false
            }
        }
        case HIDE_MESSAGE: {
            return {
                ...state,
                alertMessage: '',
                showMessage: false,
                loader: false
            }
        }

        case SIGNIN_GOOGLE_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                authUser: action.payload
            }
        }
        case SIGNIN_FACEBOOK_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                authUser: action.payload
            }
        }
        case SIGNIN_TWITTER_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                authUser: action.payload
            }
        }
        case SIGNIN_GITHUB_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                authUser: action.payload
            }
        }
        case ON_SHOW_LOADER: {
            return {
                ...state,
                loader: true
            }
        }
        case ON_HIDE_LOADER: {
            return {
                ...state,
                loader: false
            }
        }
        default:
            return state;
    }
}

export default AuthReducer;
