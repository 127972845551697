import {
    USER_SETTINGS_CHANGE_PASSWORD,
    USER_SETTINGS_CHANGE_PASSWORD_ERROR,
    USER_SETTINGS_CHANGE_PASSWORD_NONE,
    USER_SETTINGS_CHANGE_PASSWORD_SUCCESS,
    USER_SETTINGS_UPDATE_PROFILE,
    USER_SETTINGS_UPDATE_PROFILE_SUCCESS,
    USER_SETTINGS_UPDATE_PROFILE_ERROR,
    USER_SETTINGS_UPDATE_PROFILE_NONE
} from "../../../constants/actions/user_settings/userSettingsActionTypes";

/** user settings : change password **/
export const userSettingsChangePassword = (payload) => {
    return {
        type: USER_SETTINGS_CHANGE_PASSWORD,
        payload: payload
    };
};

/** user settings : change password : success **/
export const userSettingsChangePasswordSuccess = (payload) => {
    return {
        type: USER_SETTINGS_CHANGE_PASSWORD_SUCCESS,
        payload: payload
    };
};

/** user settings : change password : error **/
export const userSettingsChangePasswordError = (payload) => {
    return {
        type: USER_SETTINGS_CHANGE_PASSWORD_ERROR,
        payload: payload
    };
};

/** user settings : change password : none **/
export const userSettingsChangePasswordNone = (payload) => {
    return {
        type: USER_SETTINGS_CHANGE_PASSWORD_NONE,
        payload: payload
    };
};

/** user settings : update profile details **/
export const userSettingsUpdateProfileDetails = (payload) => {
    return {
        type: USER_SETTINGS_UPDATE_PROFILE,
        payload: payload
    };
};

/** user settings : update profile details : success **/
export const userSettingsUpdateProfileDetailsSuccess = (payload) => {
    return {
        type: USER_SETTINGS_UPDATE_PROFILE_SUCCESS,
        payload: payload
    };
};

/** user settings : update profile details : error **/
export const userSettingsUpdateProfileDetailsError = (payload) => {
    return {
        type: USER_SETTINGS_UPDATE_PROFILE_ERROR,
        payload: payload
    };
};

/** user settings : update profile details : none **/
export const userSettingsUpdateProfileDetailsNone = (payload) => {
    return {
        type: USER_SETTINGS_UPDATE_PROFILE_NONE,
        payload: payload
    };
};
