import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import axios from "axios";
import {
    URL_CHAT_MISC_GET_CHAT_SNAPSHOT,
    URL_CHAT_MISC_USER_STATUS_UPDATE_DISPLAY_NAME,
    URL_CHAT_MISC_USER_STATUS_UPDATE_PROFILE_IMAGE,
    URL_CHAT_MISC_USER_STATUS_UPDATE_STATUS_MESSAGE,
    URL_CHAT_UPDATE_SNAPSHOT_PIN
} from "../../../util/tribe/urls";
import {
    CHAT_MISC_GET_CHAT_SNAPSHOT,
    CHAT_SNAPSHOT_UPDATE_PIN_STATUS,
    CHAT_USER_STATUS_UPDATE_DISPLAY_NAME,
    CHAT_USER_STATUS_UPDATE_PROFILE_IMAGE,
    CHAT_USER_STATUS_UPDATE_STATUS_MESSAGE
} from "../../../constants/actions/chat/ChatActionTypes";
import AppUtils from "../../../util/tribe/utils/appUtils";
import {
    getChatSnapshotDetailsError,
    getChatSnapshotDetailsSuccess,
    updateSnapshotPinStatusError,
    updateSnapshotPinStatusSuccess,
    userStatusUpdateDisplayNameError,
    userStatusUpdateDisplayNameSuccess,
    userStatusUpdateProfileImageError,
    userStatusUpdateProfileImageSuccess,
    userStatusUpdateStatusMessageError,
    userStatusUpdateStatusMessageSuccess
} from "../../actions";

/** http : misc : to get chat snapshot details **/
const httpGetChatSnapshotDetails = async (data) =>
    await axios.post(URL_CHAT_MISC_GET_CHAT_SNAPSHOT, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** user status : to update profile image **/
const httpUserStatusUpdateProfileImage = async (data) =>
    await axios.post(URL_CHAT_MISC_USER_STATUS_UPDATE_PROFILE_IMAGE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });


/** user status : to update display name **/
const httpUserStatusUpdateDisplayName = async (data) =>
    await axios.post(URL_CHAT_MISC_USER_STATUS_UPDATE_DISPLAY_NAME, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** user status : to update status message **/
const httpUserStatusUpdateStatusMessage = async (data) =>
    await axios.post(URL_CHAT_MISC_USER_STATUS_UPDATE_STATUS_MESSAGE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : to update snapshot pin status **/
const httpUpdateSnapshotPinStatus = async (data) =>
    await axios.post(URL_CHAT_UPDATE_SNAPSHOT_PIN, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** handler : to update snapshot pin status **/
function* handleHttpUpdateSnapshotPinStatus({payload}) {
    try {
        const result = yield call(httpUpdateSnapshotPinStatus, payload);
        if (result.message) {
            yield put(updateSnapshotPinStatusError(result.message));
        } else {
            yield put(updateSnapshotPinStatusSuccess(result.data));
        }
    } catch (error) {
        yield put(updateSnapshotPinStatusError(error));
    }
}

/** handler : user status : to update status message **/
function* handleHttpUserStatusUpdateStatusMessage({payload}) {
    try {
        const result = yield call(httpUserStatusUpdateStatusMessage, payload);
        if (result.message) {
            yield put(userStatusUpdateStatusMessageError(result.message));
        } else {
            yield put(userStatusUpdateStatusMessageSuccess(result.data));
        }
    } catch (error) {
        yield put(userStatusUpdateStatusMessageError(error));
    }
}

/** handler : user status : to update display name **/
function* handleHttpUserStatusUpdateDisplayName({payload}) {
    try {
        const result = yield call(httpUserStatusUpdateDisplayName, payload);
        if (result.message) {
            yield put(userStatusUpdateDisplayNameError(result.message));
        } else {
            yield put(userStatusUpdateDisplayNameSuccess(result.data));
        }
    } catch (error) {
        yield put(userStatusUpdateDisplayNameError(error));
    }
}

/** handler : user status : to update profile image **/
function* handleHttpUserStatusUpdateProfileImage({payload}) {
    try {
        const result = yield call(httpUserStatusUpdateProfileImage, payload);
        if (result.message) {
            yield put(userStatusUpdateProfileImageError(result.message));
        } else {
            yield put(userStatusUpdateProfileImageSuccess(result.data));
        }
    } catch (error) {
        yield put(userStatusUpdateProfileImageError(error));
    }
}

/** handler : misc : to get chat snapshot details **/
function* handleHttpGetChatSnapshotDetails({payload}) {
    try {
        const result = yield call(httpGetChatSnapshotDetails, payload);
        if (result.message) {
            yield put(getChatSnapshotDetailsError(result.message));
        } else {
            yield put(getChatSnapshotDetailsSuccess(result.data));
        }
    } catch (error) {
        yield put(getChatSnapshotDetailsError(error));
    }
}


/** watcher : misc : to get chat snapshot details **/
export function* getChatSnapshotDetails() {
    yield takeEvery(CHAT_MISC_GET_CHAT_SNAPSHOT, handleHttpGetChatSnapshotDetails);
}

/** watcher : user status : to update profile image **/
export function* userStatusUpdateProfileImage() {
    yield takeEvery(CHAT_USER_STATUS_UPDATE_PROFILE_IMAGE, handleHttpUserStatusUpdateProfileImage);
}

/** watcher : user status : to update display name **/
export function* userStatusUpdateDisplayName() {
    yield takeEvery(CHAT_USER_STATUS_UPDATE_DISPLAY_NAME, handleHttpUserStatusUpdateDisplayName);
}

/** watcher : user status : to update status message **/
export function* userStatusUpdateStatusMessage() {
    yield takeEvery(CHAT_USER_STATUS_UPDATE_STATUS_MESSAGE, handleHttpUserStatusUpdateStatusMessage);
}

/** watcher : to update snapshot pin status **/
export function* updateSnapshotPinStatus() {
    yield takeEvery(CHAT_SNAPSHOT_UPDATE_PIN_STATUS, handleHttpUpdateSnapshotPinStatus);
}

export default function* rootSaga() {
    yield all([
        fork(getChatSnapshotDetails),
        fork(userStatusUpdateProfileImage),
        fork(userStatusUpdateDisplayName),
        fork(userStatusUpdateStatusMessage),
        fork(updateSnapshotPinStatus)
    ])
}
