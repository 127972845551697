import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import axios from "axios";
import {
  URL_CHAT_CHANNEL_MANAGEMENT_ACTIVATE,
  URL_CHAT_CHANNEL_MANAGEMENT_CHANGE_OWNERSHIP,
  URL_CHAT_CHANNEL_MANAGEMENT_CREATE,
  URL_CHAT_CHANNEL_MANAGEMENT_DEACTIVATE,
  URL_CHAT_CHANNEL_MANAGEMENT_DELETE,
  URL_CHAT_CHANNEL_MANAGEMENT_DETAILS,
  URL_CHAT_CHANNEL_MANAGEMENT_LIST, URL_CHAT_CHANNEL_MANAGEMENT_MUTE_UNMUTE_NOTIFICATIONS,
  URL_CHAT_CHANNEL_MANAGEMENT_UPDATE,
  URL_CHAT_CHANNEL_MANAGEMENT_UPDATE_DESCRIPTION,
  URL_CHAT_CHANNEL_MANAGEMENT_UPDATE_IMAGE,
  URL_CHAT_CHANNEL_MANAGEMENT_UPDATE_NAME
} from "../../../util/tribe/urls";
import {
  CHANNEL_MANAGEMENT_CHANNEL_ACTIVATE,
  CHANNEL_MANAGEMENT_CHANNEL_CHANGE_OWNERSHIP,
  CHANNEL_MANAGEMENT_CHANNEL_CREATE,
  CHANNEL_MANAGEMENT_CHANNEL_DEACTIVATE,
  CHANNEL_MANAGEMENT_CHANNEL_DELETE,
  CHANNEL_MANAGEMENT_CHANNEL_DETAILS,
  CHANNEL_MANAGEMENT_CHANNEL_LIST, CHANNEL_MANAGEMENT_CHANNEL_MUTE_UNMUTE_NOTIFICATIONS,
  CHANNEL_MANAGEMENT_CHANNEL_UPDATE,
  CHANNEL_MANAGEMENT_CHANNEL_UPDATE_DESCRIPTION,
  CHANNEL_MANAGEMENT_CHANNEL_UPDATE_IMAGE,
  CHANNEL_MANAGEMENT_CHANNEL_UPDATE_NAME
} from "../../../constants/actions/chat/ChatActionTypes";
import AppUtils from "../../../util/tribe/utils/appUtils";
import {
  channelManagementChannelActivateError,
  channelManagementChannelActivateSuccess,
  channelManagementChannelChangeOwnershipError,
  channelManagementChannelChangeOwnershipSuccess,
  channelManagementChannelCreateError,
  channelManagementChannelCreateSuccess,
  channelManagementChannelDeactivateError,
  channelManagementChannelDeactivateSuccess,
  channelManagementChannelDeleteError,
  channelManagementChannelDeleteSuccess,
  channelManagementChannelDetailsError,
  channelManagementChannelDetailsSuccess,
  channelManagementChannelListError,
  channelManagementChannelListSuccess,
  channelManagementUpdateDescriptionError,
  channelManagementUpdateDescriptionSuccess,
  channelManagementChannelUpdateError,
  channelManagementUpdateNameError,
  channelManagementUpdateNameSuccess,
  channelManagementChannelUpdateSuccess,
  channelManagementUpdateImageSuccess,
  channelManagementUpdateImageError, channelManagementChannelMuteUnmuteNotificationsSuccess, channelManagementChannelMuteUnmuteNotificationsError
} from "../../actions";

/** http : channel management : channel create **/
const httpChatChannelManagementChannelCreate = async (data) =>
  await axios.post(URL_CHAT_CHANNEL_MANAGEMENT_CREATE, data, AppUtils.getHttpHeaders())
    .then(authUser => authUser)
    .catch(error => {
      return error
    });


/** http : channel management : channel update **/
const httpChatChannelManagementChannelUpdate = async (data) =>
  await axios.post(URL_CHAT_CHANNEL_MANAGEMENT_UPDATE, data, AppUtils.getHttpHeaders())
    .then(authUser => authUser)
    .catch(error => {
      return error
    });

/** http : channel management : channel update name **/
const httpChatChannelManagementUpdateName = async (data) =>
  await axios.post(URL_CHAT_CHANNEL_MANAGEMENT_UPDATE_NAME, data, AppUtils.getHttpHeaders())
    .then(authUser => authUser)
    .catch(error => {
      return error
    });

/** http : channel management : channel update description **/
const httpChatChannelManagementUpdateDescription = async (data) =>
  await axios.post(URL_CHAT_CHANNEL_MANAGEMENT_UPDATE_DESCRIPTION, data, AppUtils.getHttpHeaders())
    .then(authUser => authUser)
    .catch(error => {
      return error
    });

/** http : channel management : channel update image **/
const httpChatChannelManagementUpdateImage = async (data) =>
  await axios.post(URL_CHAT_CHANNEL_MANAGEMENT_UPDATE_IMAGE, data, AppUtils.getHttpHeaders())
    .then(authUser => authUser)
    .catch(error => {
      return error
    });

/** http : channel management : channel delete **/
const httpChatChannelManagementChannelDelete = async (data) =>
  await axios.post(URL_CHAT_CHANNEL_MANAGEMENT_DELETE, data, AppUtils.getHttpHeaders())
    .then(authUser => authUser)
    .catch(error => {
      return error
    });

/** http : channel management : channel activate **/
const httpChatChannelManagementChannelActivate = async (data) =>
  await axios.post(URL_CHAT_CHANNEL_MANAGEMENT_ACTIVATE, data, AppUtils.getHttpHeaders())
    .then(authUser => authUser)
    .catch(error => {
      return error
    });

/** http : channel management : channel deactivate **/
const httpChatChannelManagementChannelDeactivate = async (data) =>
  await axios.post(URL_CHAT_CHANNEL_MANAGEMENT_DEACTIVATE, data, AppUtils.getHttpHeaders())
    .then(authUser => authUser)
    .catch(error => {
      return error
    });

/** http : channel management : channel change ownership **/
const httpChatChannelManagementChannelChangeOwnership = async (data) =>
  await axios.post(URL_CHAT_CHANNEL_MANAGEMENT_CHANGE_OWNERSHIP, data, AppUtils.getHttpHeaders())
    .then(authUser => authUser)
    .catch(error => {
      return error
    });

/** http : channel management : channel details **/
const httpChatChannelManagementChannelDetails = async (data) =>
  await axios.post(URL_CHAT_CHANNEL_MANAGEMENT_DETAILS, data, AppUtils.getHttpHeaders())
    .then(authUser => authUser)
    .catch(error => {
      return error
    });

/** http : channel management : channel details **/
const httpChatChannelManagementChannelList = async (data) =>
  await axios.post(URL_CHAT_CHANNEL_MANAGEMENT_LIST, data, AppUtils.getHttpHeaders())
    .then(authUser => authUser)
    .catch(error => {
      return error
    });

/** http : channel management : mute unmute notifications **/
const httpChatChannelManagementMuteUnmuteNotifications = async (data) =>
  await axios.post(URL_CHAT_CHANNEL_MANAGEMENT_MUTE_UNMUTE_NOTIFICATIONS, data, AppUtils.getHttpHeaders())
    .then(authUser => authUser)
    .catch(error => {
      return error
    });

/** handler : channel management : channel create **/
function* handleHttpChatChannelManagementChannelCreate({payload}) {
  try {
    const result = yield call(httpChatChannelManagementChannelCreate, payload);
    if (result.message) {
      yield put(channelManagementChannelCreateError(result.message));
    } else {
      yield put(channelManagementChannelCreateSuccess(result.data));
    }
  } catch (error) {
    yield put(channelManagementChannelCreateError(error));
  }
}

/** handler : channel management : channel update **/
function* handleHttpChatChannelManagementChannelUpdate({payload}) {
  try {
    const result = yield call(httpChatChannelManagementChannelUpdate, payload);
    if (result.message) {
      yield put(channelManagementChannelUpdateError(result.message));
    } else {
      yield put(channelManagementChannelUpdateSuccess(result.data));
    }
  } catch (error) {
    yield put(channelManagementChannelUpdateError(error));
  }
}

/** handler : channel management : channel update name **/
function* handleHttpChatChannelManagementUpdateName({payload}) {
  try {
    const result = yield call(httpChatChannelManagementUpdateName, payload);
    if (result.message) {
      yield put(channelManagementUpdateNameError(result.message));
    } else {
      yield put(channelManagementUpdateNameSuccess(result.data));
    }
  } catch (error) {
    yield put(channelManagementUpdateNameError(error));
  }
}

/** handler : channel management : channel update description **/
function* handleHttpChatChannelManagementUpdateDescription({payload}) {
  try {
    const result = yield call(httpChatChannelManagementUpdateDescription, payload);
    if (result.message) {
      yield put(channelManagementUpdateDescriptionError(result.message));
    } else {
      yield put(channelManagementUpdateDescriptionSuccess(result.data));
    }
  } catch (error) {
    yield put(channelManagementUpdateDescriptionError(error));
  }
}

/** handler : channel management : channel update description **/
function* handleHttpChatChannelManagementUpdateImage({payload}) {
  try {
    const result = yield call(httpChatChannelManagementUpdateImage, payload);
    if (result.message) {
      yield put(channelManagementUpdateImageError(result.message));
    } else {
      yield put(channelManagementUpdateImageSuccess(result.data));
    }
  } catch (error) {
    yield put(channelManagementUpdateImageError(error));
  }
}

/** handler : channel management : channel delete **/
function* handleHttpChatChannelManagementChannelDelete({payload}) {
  try {
    const result = yield call(httpChatChannelManagementChannelDelete, payload);
    if (result.message) {
      yield put(channelManagementChannelDeleteError(result.message));
    } else {
      yield put(channelManagementChannelDeleteSuccess(result.data));
    }
  } catch (error) {
    yield put(channelManagementChannelDeleteError(error));
  }
}

/** handler : channel management : channel activate **/
function* handleHttpChatChannelManagementChannelActivate({payload}) {
  try {
    const result = yield call(httpChatChannelManagementChannelActivate, payload);
    if (result.message) {
      yield put(channelManagementChannelActivateError(result.message));
    } else {
      yield put(channelManagementChannelActivateSuccess(result.data));
    }
  } catch (error) {
    yield put(channelManagementChannelActivateError(error));
  }
}

/** handler : channel management : channel deactivate **/
function* handleHttpChatChannelManagementChannelDeactivate({payload}) {
  try {
    const result = yield call(httpChatChannelManagementChannelDeactivate, payload);
    if (result.message) {
      yield put(channelManagementChannelDeactivateError(result.message));
    } else {
      yield put(channelManagementChannelDeactivateSuccess(result.data));
    }
  } catch (error) {
    yield put(channelManagementChannelDeactivateError(error));
  }
}

/** handler : channel management : channel change ownership **/
function* handleHttpChatChannelManagementChannelChangeOwnership({payload}) {
  try {
    const result = yield call(httpChatChannelManagementChannelChangeOwnership, payload);
    if (result.message) {
      yield put(channelManagementChannelChangeOwnershipError(result.message));
    } else {
      yield put(channelManagementChannelChangeOwnershipSuccess(result.data));
    }
  } catch (error) {
    yield put(channelManagementChannelChangeOwnershipError(error));
  }
}

/** handler : channel management : channel details **/
function* handleHttpChatChannelManagementChannelDetails({payload}) {
  try {
    const result = yield call(httpChatChannelManagementChannelDetails, payload);
    if (result.message) {
      yield put(channelManagementChannelDetailsError(result.message));
    } else {
      yield put(channelManagementChannelDetailsSuccess(result.data));
    }
  } catch (error) {
    yield put(channelManagementChannelDetailsError(error));
  }
}

/** handler : channel management : channel list **/
function* handleHttpChatChannelManagementChannelList({payload}) {
  try {
    const result = yield call(httpChatChannelManagementChannelList, payload);
    if (result.message) {
      yield put(channelManagementChannelListError(result.message));
    } else {
      yield put(channelManagementChannelListSuccess(result.data));
    }
  } catch (error) {
    yield put(channelManagementChannelListError(error));
  }
}

/** handler : channel management : mute unmute notifications **/
function* handleHttpChatChannelManagementMuteUnmuteNotifications({payload}) {
  try {
    const result = yield call(httpChatChannelManagementMuteUnmuteNotifications, payload);
    if (result.message) {
      yield put(channelManagementChannelMuteUnmuteNotificationsError(result.message));
    } else {
      yield put(channelManagementChannelMuteUnmuteNotificationsSuccess(result.data));
    }
  } catch (error) {
    yield put(channelManagementChannelMuteUnmuteNotificationsError(error));
  }
}


/** watcher : channel management : channel create **/
export function* chatChannelManagementChannelCreate() {
  yield takeEvery(CHANNEL_MANAGEMENT_CHANNEL_CREATE, handleHttpChatChannelManagementChannelCreate);
}

/** watcher : channel management : channel update **/
export function* chatChannelManagementChannelUpdate() {
  yield takeEvery(CHANNEL_MANAGEMENT_CHANNEL_UPDATE, handleHttpChatChannelManagementChannelUpdate);
}

/** watcher : channel management : update channel name **/
export function* chatChannelManagementUpdateName() {
  yield takeEvery(CHANNEL_MANAGEMENT_CHANNEL_UPDATE_NAME, handleHttpChatChannelManagementUpdateName);
}

/** watcher : channel management : update channel description  **/
export function* chatChannelManagementUpdateDescription() {
  yield takeEvery(CHANNEL_MANAGEMENT_CHANNEL_UPDATE_DESCRIPTION, handleHttpChatChannelManagementUpdateDescription);
}

/** watcher : channel management : update channel image  **/
export function* chatChannelManagementUpdateImage() {
  yield takeEvery(CHANNEL_MANAGEMENT_CHANNEL_UPDATE_IMAGE, handleHttpChatChannelManagementUpdateImage);
}

/** watcher : channel management : channel delete **/
export function* chatChannelManagementChannelDelete() {
  yield takeEvery(CHANNEL_MANAGEMENT_CHANNEL_DELETE, handleHttpChatChannelManagementChannelDelete);
}

/** watcher : channel management : channel activate **/
export function* chatChannelManagementChannelActivate() {
  yield takeEvery(CHANNEL_MANAGEMENT_CHANNEL_ACTIVATE, handleHttpChatChannelManagementChannelActivate);
}

/** watcher : channel management : channel deactivate **/
export function* chatChannelManagementChannelDeactivate() {
  yield takeEvery(CHANNEL_MANAGEMENT_CHANNEL_DEACTIVATE, handleHttpChatChannelManagementChannelDeactivate);
}

/** watcher : channel management : channel change ownership **/
export function* chatChannelManagementChannelChangeOwnership() {
  yield takeEvery(CHANNEL_MANAGEMENT_CHANNEL_CHANGE_OWNERSHIP, handleHttpChatChannelManagementChannelChangeOwnership);
}

/** watcher : channel management : channel details **/
export function* chatChannelManagementChannelDetails() {
  yield takeEvery(CHANNEL_MANAGEMENT_CHANNEL_DETAILS, handleHttpChatChannelManagementChannelDetails);
}

/** watcher : channel management : channel list **/
export function* chatChannelManagementChannelList() {
  yield takeEvery(CHANNEL_MANAGEMENT_CHANNEL_LIST, handleHttpChatChannelManagementChannelList);
}

/** watcher : channel management : mute unmute notifications **/
export function* chatChannelManagementMuteUnmuteNotifications() {
  yield takeEvery(CHANNEL_MANAGEMENT_CHANNEL_MUTE_UNMUTE_NOTIFICATIONS, handleHttpChatChannelManagementMuteUnmuteNotifications);
}

export default function* rootSaga() {
  yield all([
    fork(chatChannelManagementChannelCreate),
    fork(chatChannelManagementChannelUpdate),
    fork(chatChannelManagementChannelDelete),
    fork(chatChannelManagementChannelActivate),
    fork(chatChannelManagementChannelDeactivate),
    fork(chatChannelManagementChannelChangeOwnership),
    fork(chatChannelManagementChannelDetails),
    fork(chatChannelManagementChannelList),
    fork(chatChannelManagementUpdateName),
    fork(chatChannelManagementUpdateDescription),
    fork(chatChannelManagementUpdateImage),
    fork(chatChannelManagementMuteUnmuteNotifications)
  ])
}
