import {
    CHAT_ADD_PARTICIPANTS_ADD_USER,
    CHAT_ADD_PARTICIPANTS_REMOVE_USER,
    CHAT_ADD_PARTICIPANTS_RESET,
    CHAT_ADD_PARTICIPANTS_SEARCH_UPDATE_SEARCH_RESULT,
    CHAT_HOME_SEARCH_UPDATE_SEARCH_RESULT,
    CHAT_INSERT_CONTACT_TO_SNAPSHOT,
    CHAT_MISC_GET_CHAT_SNAPSHOT,
    CHAT_MISC_GET_CHAT_SNAPSHOT_ERROR,
    CHAT_MISC_GET_CHAT_SNAPSHOT_NONE,
    CHAT_MISC_GET_CHAT_SNAPSHOT_SUCCESS,
    CHAT_MISC_SOCKET_CONNECT_STATUS,
    CHAT_NEW_CHAT_SEARCH_UPDATE_SEARCH_RESULT,
    CHAT_SNAPSHOT_UPDATE_PIN_STATUS,
    CHAT_SNAPSHOT_UPDATE_PIN_STATUS_ERROR,
    CHAT_SNAPSHOT_UPDATE_PIN_STATUS_NONE,
    CHAT_SNAPSHOT_UPDATE_PIN_STATUS_SUCCESS,
    CHAT_USER_STATUS_UPDATE_DISPLAY_NAME,
    CHAT_USER_STATUS_UPDATE_DISPLAY_NAME_ERROR,
    CHAT_USER_STATUS_UPDATE_DISPLAY_NAME_NONE,
    CHAT_USER_STATUS_UPDATE_DISPLAY_NAME_SUCCESS,
    CHAT_USER_STATUS_UPDATE_PROFILE_IMAGE,
    CHAT_USER_STATUS_UPDATE_PROFILE_IMAGE_ERROR,
    CHAT_USER_STATUS_UPDATE_PROFILE_IMAGE_NONE,
    CHAT_USER_STATUS_UPDATE_PROFILE_IMAGE_SUCCESS,
    CHAT_USER_STATUS_UPDATE_STATUS_MESSAGE,
    CHAT_USER_STATUS_UPDATE_STATUS_MESSAGE_ERROR,
    CHAT_USER_STATUS_UPDATE_STATUS_MESSAGE_NONE,
    CHAT_USER_STATUS_UPDATE_STATUS_MESSAGE_SUCCESS,
} from "../../../constants/actions/chat/ChatActionTypes";
import {MEDIA_EVENTS} from "../../../constants/actions/media/mediaActionTypes";


/** misc : to get char snapshot details **/
export const getChatSnapshotDetails = (payload) => {
    return {
        type: CHAT_MISC_GET_CHAT_SNAPSHOT,
        payload: payload
    };
};

/** misc : to get char snapshot details : success **/
export const getChatSnapshotDetailsSuccess = (payload) => {
    return {
        type: CHAT_MISC_GET_CHAT_SNAPSHOT_SUCCESS,
        payload: payload
    };
};

/** misc : to get char snapshot details : error **/
export const getChatSnapshotDetailsError = (payload) => {
    return {
        type: CHAT_MISC_GET_CHAT_SNAPSHOT_ERROR,
        payload: payload
    };
};

/** misc : to get char snapshot details : none **/
export const getChatSnapshotDetailsNone = (payload) => {
    return {
        type: CHAT_MISC_GET_CHAT_SNAPSHOT_NONE,
        payload: payload
    };
};

/** chat misc : socket init success **/
export const chatMiscSocketConnectStatus = (payload) => {
    return {
        type: CHAT_MISC_SOCKET_CONNECT_STATUS,
        payload: payload
    };
};

/** chat misc : to update home search list  **/
export const chatMiscUpdateHomeSearchResults = (payload) => {
    return {
        type: CHAT_HOME_SEARCH_UPDATE_SEARCH_RESULT,
        payload: payload
    };
};

/** chat misc : insert user to snapshot data  **/
export const chatMiscInsertUserToSnapshot = (payload) => {
    return {
        type: CHAT_INSERT_CONTACT_TO_SNAPSHOT,
        payload: payload
    };
};

/** chat misc : to update new chat search result  **/
export const chatMiscUpdateNewChatSearchResults = (payload) => {
    return {
        type: CHAT_NEW_CHAT_SEARCH_UPDATE_SEARCH_RESULT,
        payload: payload
    };
};

/** chat misc : to update new participants search result  **/
export const chatMiscUpdateAddParticipantSearchResults = (payload) => {
    return {
        type: CHAT_ADD_PARTICIPANTS_SEARCH_UPDATE_SEARCH_RESULT,
        payload: payload
    };
};

/** chat misc : to add participants to selected users  **/
export const chatMiscGroupAddParticipantsToList = (payload) => {
    return {
        type: CHAT_ADD_PARTICIPANTS_ADD_USER,
        payload: payload
    };
};

/** chat misc : to remove participant from selected users  **/
export const chatMiscGroupRemoveParticipantFromList = (payload) => {
    return {
        type: CHAT_ADD_PARTICIPANTS_REMOVE_USER,
        payload: payload
    };
};

/** chat misc : to reset participant list from selected users  **/
export const chatMiscGroupResetParticipantList = (payload) => {
    return {
        type: CHAT_ADD_PARTICIPANTS_RESET,
        payload: payload
    };
};


/** user status : to update profile image **/
export const userStatusUpdateProfileImage = (payload) => {
    return {
        type: CHAT_USER_STATUS_UPDATE_PROFILE_IMAGE,
        payload: payload
    };
};

/** user status : to update profile image : success **/
export const userStatusUpdateProfileImageSuccess = (payload) => {
    return {
        type: CHAT_USER_STATUS_UPDATE_PROFILE_IMAGE_SUCCESS,
        payload: payload
    };
};

/** user status : to update profile image : error **/
export const userStatusUpdateProfileImageError = (payload) => {
    return {
        type: CHAT_USER_STATUS_UPDATE_PROFILE_IMAGE_ERROR,
        payload: payload
    };
};

/** user status : to update profile image : none **/
export const userStatusUpdateProfileImageNone = (payload) => {
    return {
        type: CHAT_USER_STATUS_UPDATE_PROFILE_IMAGE_NONE,
        payload: payload
    };
};

/** user status : to update display name **/
export const userStatusUpdateDisplayName = (payload) => {
    return {
        type: CHAT_USER_STATUS_UPDATE_DISPLAY_NAME,
        payload: payload
    };
};

/** user status : to update display name : success **/
export const userStatusUpdateDisplayNameSuccess = (payload) => {
    return {
        type: CHAT_USER_STATUS_UPDATE_DISPLAY_NAME_SUCCESS,
        payload: payload
    };
};

/** user status : to update display name : error **/
export const userStatusUpdateDisplayNameError = (payload) => {
    return {
        type: CHAT_USER_STATUS_UPDATE_DISPLAY_NAME_ERROR,
        payload: payload
    };
};

/** user status : to update display name : none **/
export const userStatusUpdateDisplayNameNone = (payload) => {
    return {
        type: CHAT_USER_STATUS_UPDATE_DISPLAY_NAME_NONE,
        payload: payload
    };
};


/** user status : to update status message **/
export const userStatusUpdateStatusMessage = (payload) => {
    return {
        type: CHAT_USER_STATUS_UPDATE_STATUS_MESSAGE,
        payload: payload
    };
};

/** user status : to update status message : success **/
export const userStatusUpdateStatusMessageSuccess = (payload) => {
    return {
        type: CHAT_USER_STATUS_UPDATE_STATUS_MESSAGE_SUCCESS,
        payload: payload
    };
};

/** user status : to update status message : error **/
export const userStatusUpdateStatusMessageError = (payload) => {
    return {
        type: CHAT_USER_STATUS_UPDATE_STATUS_MESSAGE_ERROR,
        payload: payload
    };
};

/** user status : to update status message : none **/
export const userStatusUpdateStatusMessageNone = (payload) => {
    return {
        type: CHAT_USER_STATUS_UPDATE_STATUS_MESSAGE_NONE,
        payload: payload
    };
};


/** to update snapshot pin status **/
export const updateSnapshotPinStatus = (payload) => {
    return {
        type: CHAT_SNAPSHOT_UPDATE_PIN_STATUS,
        payload: payload
    };
};

/** to update snapshot pin status : success **/
export const updateSnapshotPinStatusSuccess = (payload) => {
    return {
        type: CHAT_SNAPSHOT_UPDATE_PIN_STATUS_SUCCESS,
        payload: payload
    };
};

/** to update snapshot pin status : error **/
export const updateSnapshotPinStatusError = (payload) => {
    return {
        type: CHAT_SNAPSHOT_UPDATE_PIN_STATUS_ERROR,
        payload: payload
    };
};

/** to update snapshot pin status : none **/
export const updateSnapshotPinStatusNone = (payload) => {
    return {
        type: CHAT_SNAPSHOT_UPDATE_PIN_STATUS_NONE,
        payload: payload
    };
};

/** to reset tribe media terminate status **/
export const resetTribeMediaTerminateStatus = (payload) => {
    return {
        type: MEDIA_EVENTS.MEDIA_EVENT_MEDIA_TERMINATE_RESET,
        payload: payload
    };
};
