import AppUtils from "../utils/appUtils";
import IntlMessages from "../../IntlMessages";
import {chatBaseDownloadUrl} from "../keys";
import {TRIBE_CHAT_FILE_TYPES} from "../constants/config";
import {TribeChatSocketIOHandler} from "../socket_io/tribeChatSocketIOHandler";

let ChatParserUtils = {
    
    userDetailsMap: {},
    
    setUserDetailsMap: function (data) {
        ChatParserUtils.userDetailsMap = data;
    },
    
    generateIndexId: function (typeValue, idVal) {
        let result = "";
        try {
            result = typeValue + "_" + idVal;
        } catch (ex) {
            console.error("generateIndexId : error : ", ex);
        }
        
        return result;
    },
    
    parseMediaMessageList: function (data, result) {
        try {
            
            
            data.sort(function (a, b) {
                return new Date(a["created_at"]) - new Date(b["created_at"]);
            });
            
            for (let i = 0; i < data.length; i++) {
                ChatParserUtils.parseSingleMediaMessage(data[i], result, true);
            }
            
        } catch (ex) {
            console.error("parseMediaMessageList : parseMessageList : error : ", ex);
        }
    },
    
    parseSingleMediaMessage: function (messageData, result, isUTCTime) {
        try {
            
            let messageSentDate = "";
            let currentUserId = AppUtils.getCurrentUserId();
            
            if (isUTCTime) {
                messageSentDate = messageData["created_at"] ? new Date(messageData["created_at"] + "Z") : new Date();
            } else {
                messageSentDate = messageData["created_at"] ? new Date(messageData["created_at"]) : new Date();
            }
            
            let chatMonth = AppUtils.getMonthValueFromDate(messageSentDate);
            
            if (typeof result[chatMonth] === "undefined") {
                result[chatMonth] = {};
                
                result[TRIBE_CHAT_FILE_TYPES.MEDIA_TYPES.MEDIA] = {};
                result[TRIBE_CHAT_FILE_TYPES.MEDIA_TYPES.DOC] = {};
                result[TRIBE_CHAT_FILE_TYPES.MEDIA_TYPES.LINK] = {};
                
                result[TRIBE_CHAT_FILE_TYPES.MEDIA_TYPES.MEDIA][chatMonth] = [];
                result[TRIBE_CHAT_FILE_TYPES.MEDIA_TYPES.DOC][chatMonth] = [];
                result[TRIBE_CHAT_FILE_TYPES.MEDIA_TYPES.LINK][chatMonth] = [];
            }
            
            let media = [];
            let messageUser = "You";
            
            if (messageData["uid"] !== currentUserId) {
                messageUser = ChatParserUtils.userDetailsMap[messageData["uid"]]["name"];
            }
            
            if (typeof messageData["message"] != "undefined" && typeof messageData["message"]["media"] != "undefined") {
                media = messageData["message"]["media"];
                
                for (let i = 0; i < media.length; i++) {
                    media[i]["type"] = AppUtils.isFileContentType(media[i]["content_type"]);
                    media[i]["media_url"] = chatBaseDownloadUrl + media[i]["url"];
                    
                    let mediaType = TRIBE_CHAT_FILE_TYPES.MEDIA_TYPES.DOC;
                    
                    if (typeof messageData["message"] != "undefined" && typeof messageData["message"]["media_type"] != "undefined") {
                        mediaType = messageData["message"]["media_type"];
                    }
                    
                    let currentObject = {
                        'media': media[i],
                        'sentAt': AppUtils.formatAMPM(messageSentDate),
                        "id": messageData["msgid"],
                        "msgid": messageData["msgid"],
                        "senderId": messageData["uid"],
                        "msgObj": messageData,
                        "messageUser": messageUser,
                        "media_type": mediaType
                    };
                    
                    if (typeof result[mediaType] === "undefined") {
                        result[mediaType] = {};
                        result[mediaType][chatMonth] = [];
                    }
                    
                    result[mediaType][chatMonth].push(currentObject);
                }
            }
            
            return true;
            
        } catch (ex) {
            console.error("ChatParserUtils : parseSingleMediaMessage : error : ", ex);
        }
    },
    
    
    parseMessageList: function (data, result, isUTCTime, reactionsResultMap, indexId, dateMap, snapshotMap) {
        try {
            
            if (typeof reactionsResultMap[indexId] == "undefined") {
                reactionsResultMap[indexId] = {};
            }
            
            data.sort(function (a, b) {
                return new Date(a["created_at"]) - new Date(b["created_at"]);
            });
            
            for (let i = 0; i < data.length; i++) {
                ChatParserUtils.parseSingleMessage(data[i], result, isUTCTime, dateMap, reactionsResultMap, indexId, snapshotMap);
            }
            
        } catch (ex) {
            console.error("ChatParserUtils : parseMessageList : error : ", ex);
        }
    },
    
    parseSingleMessage: function (messageData, result, isUTCTime, dateMap, reactionsResultMap, indexId, snapshotMap) {
        try {
            
            let messageSentDate = "";
            let currentDate = AppUtils.getChatCurrentValue(new Date());
            let currentUserId = AppUtils.getCurrentUserId();
            let isSystem = typeof messageData["system"] != "undefined" && messageData["system"] === 1;
            
            
            if (result.some(item => item["msgid"] == messageData["msgid"])) {
                return false;
            }
            
            if (isUTCTime) {
                messageSentDate = messageData["created_at"] ? new Date(messageData["created_at"] + "Z") : new Date();
            } else {
                messageSentDate = messageData["created_at"] ? new Date(messageData["created_at"]) : new Date();
            }
            
            let chatDate = AppUtils.getChatDividerValue(messageSentDate);
            
            if (typeof dateMap[chatDate] === "undefined") {
                dateMap[chatDate] = chatDate;
                
                result.push({
                    'dateVal': chatDate === currentDate ? <IntlMessages id="DIC_KEY_CHAT_LIST_TODAY"/> : chatDate
                });
            }
            
            if (isSystem) {
                let typeVal = typeof messageData["message"] != "undefined" && typeof messageData["message"]["type"] != "undefined" && messageData["message"]["type"];
                let uid = typeof messageData["message"] != "undefined" && typeof messageData["message"]["uid"] != "undefined" && messageData["message"]["uid"];
                
                if (typeof ChatParserUtils.userDetailsMap[uid] != "undefined") {
                    
                    let typeMessage = "";
                    
                    if (typeof typeVal != "undefined" && typeVal + "" === "added") {
                        typeMessage = "Added " + ChatParserUtils.userDetailsMap[uid]['text'] + " to this group";
                    } else {
                        typeMessage = "Removed " + ChatParserUtils.userDetailsMap[uid]['text'] + " from this group";
                    }
                    
                    if (typeMessage !== "") {
                        result.push({
                            'dateVal': typeMessage
                        });
                    }
                }
                
                return;
            }
            
            let direction = "received";
            
            if (typeof messageData["uid"] == "undefined" && typeof messageData["user_id"] != "undefined") {
                messageData["uid"] = messageData["user_id"];
            }
            
            if ((typeof messageData["channel_id"] != "undefined" && typeof messageData["user_id"] != "undefined" && messageData["user_id"] === AppUtils.getCurrentUserId()) ||
                (typeof messageData["channel_id"] == "undefined" && (
                        (
                            (typeof messageData["from_user"] != "undefined" && messageData["from_user"] === AppUtils.getCurrentUserId())) ||
                        (typeof messageData["uid"] != "undefined" && messageData["uid"] === AppUtils.getCurrentUserId()) ||
                        (typeof messageData["suid"] != "undefined" && messageData["suid"] === AppUtils.getCurrentUserId())
                    )
                )
            ) {
                direction = "sent";
            }
            
            let reactions = {};
            let reactionsCount = 0;
            let reactionsMap = {};
            
            if (typeof messageData.reactions != "undefined") {
                
                for (let userId in messageData.reactions) {
                    
                    reactionsMap[userId] = messageData.reactions[userId];
                    
                    let userName = "";
                    
                    if (userId === currentUserId) {
                        userName = "You";
                    } else {
                        userName = ChatParserUtils.userDetailsMap[userId]["name"];
                    }
                    
                    let emojis = messageData.reactions[userId];
                    
                    for (let i = 0; i < emojis.length; i++) {
                        if (typeof reactions[emojis[i]] == "undefined") {
                            reactions[emojis[i]] = [];
                        }
                        
                        reactions[emojis[i]].push({
                            "userId": userId,
                            "name": userName
                        });
                        
                        reactionsCount += 1;
                    }
                }
            }
            
            let media = [];
            
            if (typeof messageData["message"] != "undefined" && typeof messageData["message"]["media"] != "undefined") {
                media = messageData["message"]["media"];
                
                for (let i = 0; i < media.length; i++) {
                    media[i]["type"] = AppUtils.isFileContentType(media[i]["content_type"]);
                    media[i]["media_url"] = chatBaseDownloadUrl + media[i]["url"];
                }
                
            }
            
            if (typeof reactionsResultMap[indexId] == "undefined") {
                reactionsResultMap[indexId] = {};
            }
            
            if (typeof messageData['msgid'] != "undefined") {
                reactionsResultMap[indexId][messageData['msgid']] = reactionsMap;
            }
            
            
            let messageUser = "You";
            let color = "";
            
            
            if (typeof messageData["uid"] != "undefined" && messageData["uid"] !== currentUserId) {
                messageUser = ChatParserUtils.userDetailsMap[messageData["uid"]]["name"];
                color = ChatParserUtils.userDetailsMap[messageData["uid"]]["color"];
            }
            
            if (typeof messageData.message != "undefined" && typeof messageData.message.parent_msg != "undefined") {
                let userId = messageData.message.parent_msg.user_id;
                
                messageData.message.parent_msg.messageUser = "You";
                
                if (userId !== currentUserId) {
                    messageData.message.parent_msg.messageUser = ChatParserUtils.userDetailsMap[userId]["name"];
                }
            }
            
            /** message delivery status **/
            let messageDeliveryStatus = "message_status_sent";
            let isDeliverAll = true;
            
            if (typeof messageData.delivered != "undefined" && messageData.delivered.length > 0) {
                messageDeliveryStatus = "message_status_deliver";
                
                if (!snapshotMap["isDeliverAll"] && messageData["uid"] !== currentUserId) {
                    for (let i = 0; i < messageData.delivered.length; i++) {
                        if (messageData.delivered[i]["uid"] === currentUserId) {
                            isDeliverAll = false;
                            break;
                        }
                    }
                }
            }
            
            if (isDeliverAll) {
                
                if( currentUserId !== messageData["uid"]){
                    if (typeof messageData["channel_id"] != "undefined") {
                        console.log("test : 3")
                        TribeChatSocketIOHandler.markMessageAsDeliver(messageData["msgid"], currentUserId, messageData["channel_id"]);
                    } else {
                        console.log("test : 4 : ", currentUserId, messageData)
                        TribeChatSocketIOHandler.markMessageAsDeliver(messageData["msgid"], currentUserId);
                    }
                }
            }
            
            if (typeof messageData.read != "undefined" && messageData.read.length > 0) {
                messageDeliveryStatus = "message_status_read";
                let isReadAll = true;
                
                if (snapshotMap[indexId] && !snapshotMap[indexId]["isReadAll"] && messageData["uid"] !== currentUserId) {
                    for (let i = 0; i < messageData.read.length; i++) {
                        if (messageData.read[i]["uid"] === currentUserId) {
                            isReadAll = false;
                            break;
                        }
                    }
                    
                    if (isReadAll) {
                        snapshotMap[indexId]["isReadAll"] = true;
                    }
                }
            } else {
                if (snapshotMap && snapshotMap[indexId] && !snapshotMap[indexId]["isReadAll"]) {
                    snapshotMap[indexId]["isReadAll"] = true;
                }
            }
            
            let currentObject = {
                'type': direction,
                'message': messageData["message"]["text"],
                'media': media,
                'sentAt': AppUtils.formatAMPM(messageSentDate),
                "id": messageData["msgid"],
                "msgid": messageData["msgid"],
                "senderId": messageData["uid"],
                "reactions": reactions,
                "reactionsCount": reactionsCount,
                "msgObj": messageData,
                "messageUser": messageUser,
                "color": color,
                "delivery": messageDeliveryStatus
            }
            
            if (typeof messageData["message"] != "undefined" && typeof messageData["message"]["parent_msg"] != "undefined") {
                currentObject["parent_msg"] = messageData["message"]["parent_msg"];
            }
            
            if (typeof messageData["message"] != "undefined" && typeof messageData["message"]["base_id"] != "undefined") {
                currentObject["base_id"] = messageData["message"]["base_id"];
            }
            
            result.push(currentObject);
            
            return true;
            
        } catch (ex) {
            console.error("ChatParserUtils : parseSingleMessage : error : ", ex);
        }
    }
    
}


export default ChatParserUtils;
