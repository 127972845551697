import UIUtils from "../utils/uiUtils";

export const DEFAULT_LANGUAGE_CODE = "en";
export const APP_NAVIGATION_PATHS = {
    CHAT_HOME: "chat",
    CALENDER_HOME: "calender",
    CALLS_HOME: "calls",
    SIGN_IN: "signin",
    MY_ACCOUNT: "settings",
    PRIVATE_ROOM: "private_room",
    VIEW_PRIVATE_ROOM: "view_private_room",
    CLIENTS_HOME: "clients_home",
    VIEW_CLIENT: "client_view",
    USERS_HOME: "users_home",
    VIEW_USER: "user_view",
}

export const HTTP_REQUEST_STATUS = {
    "NOT_STARTED": "NOT_STARTED",
    "IN_PROGRESS": "IN_PROGRESS",
    "SUCCESS": "SUCCESS",
    "ERROR": "ERROR",
};

export const HTTP_REQUEST_STATUS_CODES = {
    OK: 200,
    CREATED: 201,
    ACCEPTED: 202
};

export const TRIBE_APP_CONFIG = {
    "DELETE_OTHER_DEVICES": true,
    "USER_PASSWORD_MIN_LENGTH": 6,
    "IS_AUDIO_VIDEO_ENABLED": false
};


export const MSG_ROUTER_EVENTS = {

    USERS: {
        "USER_CREATE": "msgrouter/user-create",
        "USER_DELETED": "msgrouter/user-deleted",
        "USER_UPDATE": "msgrouter/user-update",
        "USER_UPDATED": "msgrouter/user-updated",
        "USER_ACTIVATED": "msgrouter/user-activated",
        "USER_READ_NOTIFY": "msgrouter/user-read-notify",
        "USER_DETAILS": "msgrouter/user-details",
        "USER_SNAPSHOT": "msgrouter/snapshot"
    },

    DEVICE: {
        "DEVICE_ATTACH": "msgrouter/device-attach",
        "DEVICE_DETACH": "msgrouter/device-detach",
        "DEVICE_UPDATE": "msgrouter/device-update",
        "DEVICE_LIST": "msgrouter/devices"
    },

    CHANNEL: {
        "CHANNEL_CREATED": "msgrouter/channel-created",
        "CHANNEL_DELETED": "msgrouter/channel-deleted",
        "CHANNEL_UPDATE": "msgrouter/channel-update",
        "CHANNEL_UPDATED": "msgrouter/channel-updated",
        "CHANNEL_MEMBER_ADDED": "msgrouter/channel-added",
        "CHANNEL_MEMBER_INVITED": "msgrouter/channel-invited",
        "CHANNEL_MEMBER_JOINED": "msgrouter/channel-joined",
        "CHANNEL_MEMBER_INVITE_DECLINED": "msgrouter/channel-rejected",
        "CHANNEL_MEMBER_LEFT": "msgrouter/channel-left",
        "CHANNEL_USER_BAN": "msgrouter/channel-banned",
        "CHANNEL_USER_ROLE_CHANGE": "msgrouter/channel-role",
        "CHANNEL_OWNER_CHANGE": "msgrouter/channel-owner",
        "CHANNEL_POSTED_MESSAGE": "msgrouter/channel-posted-message",
        "CHANNEL_EDIT_MESSAGE": "msgrouter/channel-edited-message",
        "CHANNEL_DELETE_MESSAGE_SUCCESS": "msgrouter/channel-deleted-message",
        "CHANNEL_READ_MESSAGE": "msgrouter/channel-read-message",
        "CHANNEL_UNREAD_MESSAGE": "msgrouter/channel-unread-message",
        "CHANNEL_READ_ALL_MESSAGES": "msgrouter/channel-readall-message",
        "CHANNEL_DELIVERED_MESSAGE": "msgrouter/channel-delivered-message",
        "CHANNEL_DELETE_MESSAGES_SUCCESS": "msgrouter/channel-deleted-messages",
        "CHANNEL_CLEAR_MESSAGES": "msgrouter/channel-cleared-messages",
        "CHANNEL_REACT_MESSAGE": "msgrouter/channel-reacted-message",
        "CHANNEL_MESSAGES": "msgrouter/channel-messages",
        "CHANNEL_DETAILS": "msgrouter/channel-details",
        "CHANNEL_GET_MEMBERS": "msgrouter/channel-members",
        "CHANNEL_MESSAGE_HISTORY": "msgrouter/channel-message-history",
        "CHANNEL_MESSAGE_FILTER_FIELD": " msgrouter/channel-message-filter",
        "CHANNEL_BELONGS": "msgrouter/channel-belongs",
        "CHANNEL_NOTIFICATIONS_MUTED": "msgrouter/channel-muted",
        "CHANNEL_NOTIFICATIONS_MUTE": "msgrouter/channel-mute",
        "CHANNEL_NOTIFICATIONS_UNMUTED": "msgrouter/channel-unmuted",
        "CHANNEL_NOTIFICATIONS_UNMUTE": "msgrouter/channel-unmute"
    },

    DM: {
        "DM_POST_MESSAGE": "msgrouter/dm-posted-message",
        "DM_MESSAGE_EDIT": "msgrouter/dm-edited-message",
        "DM_DELETE_MESSAGE_SUCCESS": "msgrouter/dm-delete-messages",
        "DM_MESSAGES": "msgrouter/dm-messages",
        "DM_READ_MESSAGE": "msgrouter/dm-read-message",
        "DM_READ_ALL_MESSAGES": "msgrouter/dm-readall-message",
        "DM_UNREAD_MESSAGE": "msgrouter/dm-unread-message",
        "DM_MESSAGE_DELIVERED": "msgrouter/dm-delivered-message",
        "DM_DELETED_MESSAGES_SUCCESS": "msgrouter/dm-deleted-messages",
        "DM_DELETED_MESSAGE_SUCCESS": "msgrouter/dm-deleted-message",
        "DM_CLEAR_MESSAGES": "msgrouter/dm-cleared-messages",
        "DM_MESSAGE_REACT": "msgrouter/dm-reacted-message",
        "DM_EXIT_SUCCESS": "msgrouter/dm-exited",
        "DM_MESSAGE_HISTORY": "msgrouter/dm-message-history",
        "DM_MESSAGE_FILTER_FIELD": "msgrouter/dm-message-filter",
        "DM_BELONGS": "msgrouter/user-belongs",
        "DM_NOTIFICATIONS_MUTED": "msgrouter/dm-muted",
        "DM_NOTIFICATIONS_MUTE": "msgrouter/dm-mute",
        "DM_NOTIFICATIONS_UNMUTED": "msgrouter/dm-unmuted",
        "DM_NOTIFICATIONS_UNMUTE": "msgrouter/dm-unmute"
    },

    TAGS: {
        "TAG_CHANNEL_SUCCESS": "msgrouter/channel-tagged",
        "TAG_CHANNEL": "msgrouter/channel-tag",
        "TAG_DM": "msgrouter/dm-tag",
        "TAG_DM_SUCCESS": "msgrouter/dm-tagged",
        "TAG_CHANNEL_MESSAGE": "msgrouter/channel-message-tag",
        "TAG_CHANNEL_MESSAGE_SUCCESS": "msgrouter/channel-message-tagged",
        "TAG_DM_MESSAGE": "msgrouter/dm-message-tag",
        "TAG_DM_MESSAGE_SUCCESS": "msgrouter/dm-message-tagged",
        "TAGS_LIST_SUCCESS": "msgrouter/tags",
        "TAG_CHANNEL_MESSAGE_LIST_SUCCESS": "msgrouter/channel-tagged-messages",
        "TAG_DM_MESSAGE_LIST_SUCCESS": "msgrouter/dm-tagged-messages"
    },

    RELAY: {
        "CHANNEL_RELAY": "msgrouter/channel-relay",
        "DM_RELAY": "msgrouter/dm-relay"
    }
};

export const MSG_ROUTER_RELAY_TYPES = {
    "INCOMING_CALL" : "INCOMING_CALL",
    "TERMINATE_DM_CALL" : "TERMINATE_DM_CALL",
    "AUDIO_CALL": "AUDIO_CALL",
    "VIDEO_CALL": "VIDEO_CALL",
    "TYPING_START": "TYPING_START",
    "TYPING_END": "TYPING_END"
};

export const MESSAGE_CONTENT_TYPES = {
    "TEXT": 0,
    "TEXT_AND_EMOJI": 1,
    "EMOJI": 2,
    "MEDIA": 3,
    "DOC": 4
};

export const UI_TEXT_CONFIG = {
    "DEFAULT_DATE_DELIMITER": "-"
};

export const MESSAGE_ROUTER_CHANNEL_TYPES = {
    "DM": "dm",
    "GROUP": "group"
};

export const MESSAGE_ROUTER_API_CONFIG = {
    "DM_MESSAGES_PAGINATION_COUNT": 100
};

export const MESSAGE_ROUTER_USER_STATUS_VALUES = {
    "AVAILABLE": 1,
    "BUSY": 2,
    "DO_NOT_DISTURB": 3,
    "BE_RIGHT_BACK": 4,
    "APPEAR_AWAY": 5,
    "APPEAR_OFFLINE": 6
};

export const MESSAGE_ROUTER_USER_STATUS = {
    1: "online",
    2: "",
    3: "",
    4: "away",
    5: "away",
    6: "offline"
};

export const MESSAGE_ROUTER_USER_STATUS_COLORS = {
    1: "green",
    2: "red",
    3: "red",
    4: "yellow",
    5: "orange",
    6: "grey"
};

export const THEME_COLORS = {
    "light_purple": {
        color: "light_purple",
        className: "gx-link gx-color-light-purple "
    },
    "red": {
        color: "red",
        className: "gx-link gx-color-red"
    },
    "blue": {
        color: "blue",
        className: "gx-link gx-color-blue "
    },
    "dark_blue": {
        color: "dark_blue",
        className: "gx-link gx-color-dark-blue "
    },
    "orange": {
        color: "orange",
        className: "gx-link gx-color-orange "
    },
    "light_blue": {
        color: "light_blue",
        className: "gx-link gx-color-light-blue "
    },
    "deep_orange": {
        color: "deep_orange",
        className: "gx-link gx-color-deep-orange "
    },
    "light_purple_1": {
        color: "light_purple_1",
        className: "gx-link gx-color-light-purple1 "
    },
    "light_purple_2": {
        color: "light_purple_2",
        className: "gx-link gx-color-light-purple2 "
    }
}

export const MESSAGE_ROUTER_CHANNEL_READ_ACCESS_TYPES = {
    "READ": "r",
    "WRITE": "w",
    "READ_WRITE": "x",
    "OWNER": "s"
};

export const MESSAGE_ROUTER_CHANNEL_READ_ACCESS_INDEX = {
    "READ": 4,
    "WRITE": 3,
    "READ_WRITE": 2,
    "OWNER": 1
};

export const USER_STATUS_DEFAULT_GROUP_STATUS = "Hi! There!";
export const USER_STATUS_DEFAULT_MESSAGE = "Hi! There!";
export const DEFAULT_MESSAGE_EDIT_TIME = 1000 * 60 * 10;

export const MESSAGE_TAG_TYPES = {
    "is_pinned": "is_pinned",
    "is_star": "is_star",
    "mute_notifications": "mute_notifications",
    "msg_expiry_threshold": "msg_expiry_threshold"
};

export const FILTER_SEARCH_BUTTON_COLOR = "#2196F3";
export const FILTER_CLEAR_BUTTON_TRANSPARENT_COLOR = "transparent";


export const TRIBE_CHAT_CLIENT_CONFIG = {
    "is_sender_chat_avatar_visible": false,
    "is_dm_chat_avatar_visible": false,
    "is_group_forward_enabled": true,
    "is_edit_message_always_enabled": true
};

export const TRIBE_CHAT_SUPPORTED_ATTACHMENT_TYPES = [
    {
        "type": "DOCUMENT",
        "iconType": UIUtils.MATERIAL_ICONS.INSERT_FILE_ICON,
        "localisation": "DIC_KEY_ATTACHMENT_TYPES_FILES",
        "inputTypes": "*"
    },
    {
        "type": "PHOTOS_VIDEOS",
        "iconType": UIUtils.MATERIAL_ICONS.INSERT_PHOTO_ICON,
        "localisation": "DIC_KEY_ATTACHMENT_TYPES_PHOTOS_AND_VIDEOS",
        "inputTypes": "image/*,video/mp4,video/3gpp,video/quicktime"
    }
];

export const TRIBE_CHAT_FILE_TYPES = {
    "IMAGE": "IMAGE",
    "AUDIO": "AUDIO",
    "VIDEO": "VIDEO",
    "LINK": "LINK",
    "OTHERS": "OTHERS",
    "VIDEO_FILE_TYPES": ["video/x-matroska", "video/quicktime", "video/mp4", "video/webm"],
    "MEDIA_TYPES": {
        "MEDIA": "MEDIA",
        "DOC": "DOC",
        "LINK": "LINK"
    }
};

export const TRIBE_CHAT_MESSAGE_SENT_STATUS = {
    "SENT": "message_status_sent",
    "DELIVERED": "message_status_deliver",
    "READ": "message_status_read"
};

export const APP_DEFAULT_DATE_FORMAT = "DD-MM-YYYY";
export const APP_DATE_FORMAT_YYYY_MM_DD = "YYYY-MM-DD";
export const APP_DATE_FORMAT_YYYY_MM_DD_HH_MM_SS = "YYYY-MM-DD HH:mm:ss";
export const APP_DATE_FORMAT_YYYY_MM_DD_HH_MM = "YYYY-MM-DD HH:mm";
export const CLIENTS_DEFAULT_VALIDITY_DAYS = 30;
