//export const chatBaseServerUrl = "http://143.110.189.113:9000";

export const chatBaseServerUrl = "https://chat.gotribe.cc";
export const chatSocketPath = "/socketio";

/*export const chatBaseServerUrl = "https://uc.zengroup.co.in";
export const chatSocketPath = "/socketiochat";*/

export const chatBaseUrl = chatBaseServerUrl + '/v1/api';
export const chatBaseDownloadUrl = chatBaseServerUrl + '/downloads';

//export const signalHubPortalUrl = 'http://localhost:3001/';
export const signalHubPortalUrl = 'https://chatvideo.gotribe.cc/';

//export const signalHubPortalUrl = 'https://skool.gotribe.cc/';
