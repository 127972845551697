import TribeOverflowMenu from "../../../components/overflowSlider/tribeOverflowSlider";
import {Input, Modal, Switch, Menu, DatePicker} from "antd";
import React from "react";
import CustomScrollbars from "../../CustomScrollbars";
import IntlMessages from "../../IntlMessages";
import AppUtils from "./appUtils";
import ContactList from "../../../components/chat/ContactList";
import TextField from "@material-ui/core/TextField";
import ListItemText from '@material-ui/core/ListItemText';
import {Checkbox, MenuItem, Popover} from "@material-ui/core";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from "@material-ui/core/Button";

import MaterialInput from '@material-ui/core/Input';
import {FormControl, IconButton, InputAdornment, InputLabel} from "@material-ui/core";
import {
    Visibility,
    Done,
    Cancel,
    Close,
    InsertPhoto,
    InsertDriveFile
} from "@mui/icons-material";

import {
    APP_DATE_FORMAT_YYYY_MM_DD,
    APP_DATE_FORMAT_YYYY_MM_DD_HH_MM
} from "../constants/config";
import moment from "moment";
import IntegrationReactSelect from "../../../components/tribe/IntegrationReactSelect";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const UI_COMPONENT_TYPES = {
    "MENU_POPOVER": "MENU_POPOVER",
    "LS_OVERFLOW_MENU": "LS_OVERFLOW_MENU",
    "SEARCH_FILTER": "SEARCH_FILTER",
    "JSON_USERS_WITH_LABEL": "JSON_USERS_WITH_LABEL",
    "MATERIAL_TEXT_BOX": "MATERIAL_TEXT_BOX",
    "INPUT_WITH_SAVE_ICON": "INPUT_WITH_SAVE_ICON",
    "ICON_LABEL_NAVIGATION": "ICON_LABEL_NAVIGATION",
    "LABEL_NAVIGATION": "LABEL_NAVIGATION",
    "POPOVER": "POPOVER",
    "LIST_MENU": "LIST_MENU",
    "TRIBE_POPUP": "TRIBE_POPUP",
    "EDITABLE_LABEL": "EDITABLE_LABEL",
    "CHECKBOX": "CHECKBOX",
    "HORIZONTAL_NAVIGATION_MENU": "HORIZONTAL_NAVIGATION_MENU",
    "BUTTON_STATIC_POSITIVE": "BUTTON_STATIC_POSITIVE",
    "BUTTON_STATIC_NEGATIVE": "BUTTON_STATIC_NEGATIVE",
    "LABEL_CONTAINER": "LABEL_CONTAINER",
    "HORIZONTAL_LABEL_INPUT": "HORIZONTAL_LABEL_INPUT",
    "HORIZONTAL_LABEL_TEXT": "HORIZONTAL_LABEL_TEXT",
    "HORIZONTAL_LABEL_FILE": "HORIZONTAL_LABEL_FILE",
    "ICON_BORDER_BUTTON_PRIMARY_ADD": "ICON_BORDER_BUTTON_PRIMARY_ADD",
    "ICON_SQUARE_BUTTON_SEARCH": "ICON_SQUARE_BUTTON_SEARCH",
    "SEARCH_INPUT": "SEARCH_INPUT",
    "DATE_FIELD_HORIZONTAL": "DATE_FIELD_HORIZONTAL",
    "DATE_TIME_FIELD_HORIZONTAL": "DATE_TIME_FIELD_HORIZONTAL",
    "HORIZONTAL_LABEL_DROPDOWN": "HORIZONTAL_LABEL_DROPDOWN",
    "SWITCH_LABEL_HORIZONTAL": "SWITCH_LABEL_HORIZONTAL",
};

const INPUT_ADORNMENT = {
    "EYE": "EYE",
    "DONE": "DONE",
    "CANCEL": "CANCEL"
};

const FONT_ICONS = {
    "star": "zmdi zmdi-star",
    "notifications": "zmdi zmdi-notifications",
    "block": "zmdi zmdi-block",
    "delete": "zmdi zmdi-delete",
    "media_collection": "zmdi zmdi-collection-image",
    "forward_message": "zmdi zmdi-forward",
    "download_message": "zmdi zmdi-download",
    "video_call": "zmdi zmdi-videocam",
    "audio_call": "zmdi zmdi-phone",
    "overflow": "zmdi zmdi-more-vert",
    "phone_end": "zmdi zmdi-phone-end"
};

const MATERIAL_ICONS = {
    "INSERT_PHOTO_ICON": "INSERT_PHOTO_ICON",
    "INSERT_FILE_ICON": "INSERT_FILE_ICON",
    "VIDEO_CALL": "VIDEO_CALL",
    "AUDIO_CALL": "AUDIO_CALL",
};


const UIUtils = {
    UI_COMPONENT_TYPES: UI_COMPONENT_TYPES,
    INPUT_ADORNMENT: INPUT_ADORNMENT,
    MATERIAL_ICONS: MATERIAL_ICONS,
    FONT_ICONS: FONT_ICONS,

    getUIComponent: function (params) {
        try {
            switch (params.type) {

                case UI_COMPONENT_TYPES.HORIZONTAL_LABEL_DROPDOWN: {
                    return this.getHorizontalLabelDropdown(params);
                }

                case UI_COMPONENT_TYPES.DATE_FIELD_HORIZONTAL: {
                    return this.getDateFieldHorizontal(params);
                }

                case UI_COMPONENT_TYPES.DATE_TIME_FIELD_HORIZONTAL: {
                    return this.getDateTimeFieldHorizontal(params);
                }

                case UI_COMPONENT_TYPES.SWITCH_LABEL_HORIZONTAL: {
                    return this.getSwitchFieldHorizontal(params);
                }

                case UI_COMPONENT_TYPES.BUTTON_STATIC_NEGATIVE: {
                    return this.getStaticActionButtonNegative(params);
                }

                case UI_COMPONENT_TYPES.MENU_POPOVER: {
                    return this.getMenuPopOver(params);
                }

                case UI_COMPONENT_TYPES.SEARCH_INPUT: {
                    return this.getSearchInput(params);
                }

                case UI_COMPONENT_TYPES.ICON_SQUARE_BUTTON_SEARCH: {
                    return this.getSquareIconButton(params, "button_icon_search");
                }

                case UI_COMPONENT_TYPES.ICON_BORDER_BUTTON_PRIMARY_ADD: {
                    return this.getIconBorderButton(params, "tribe_button_icon tribe_button_icon_plus_active");
                }

                case UI_COMPONENT_TYPES.HORIZONTAL_LABEL_INPUT: {
                    return this.getHorizontalLabelInput(params);
                }

                case UI_COMPONENT_TYPES.HORIZONTAL_LABEL_FILE:{
                    return this.getHorizontalLabelFile(params)
                }

                case UI_COMPONENT_TYPES.HORIZONTAL_LABEL_TEXT: {
                    return this.getHorizontalLabelText(params);
                }

                case UI_COMPONENT_TYPES.LABEL_CONTAINER: {
                    return this.getLabelContainer(params);
                }

                case UI_COMPONENT_TYPES.BUTTON_STATIC_POSITIVE: {
                    return this.getStaticActionButtonPositive(params);
                }

                case UI_COMPONENT_TYPES.HORIZONTAL_NAVIGATION_MENU: {
                    return this.renderHorizontalNavigationMenu(params);
                }

                case UI_COMPONENT_TYPES.CHECKBOX: {
                    return this.renderCheckbox(params);
                }

                case UI_COMPONENT_TYPES.EDITABLE_LABEL: {
                    return this.renderEditableLabel(params);
                }

                case UI_COMPONENT_TYPES.TRIBE_POPUP: {
                    return this.renderPopup(params);
                }

                case UI_COMPONENT_TYPES.LIST_MENU: {
                    return this.renderListMenu(params);
                }

                case UI_COMPONENT_TYPES.POPOVER: {
                    return this.renderPopover(params);
                }

                case UI_COMPONENT_TYPES.LABEL_NAVIGATION: {
                    return this.renderLabelWithNavigation(params);
                }

                case UI_COMPONENT_TYPES.ICON_LABEL_NAVIGATION: {
                    return this.renderIconLabelWithNavigation(params);
                }

                case UI_COMPONENT_TYPES.INPUT_WITH_SAVE_ICON: {
                    return this.renderInputWithSaveIcon(params);
                }

                case UI_COMPONENT_TYPES.MATERIAL_TEXT_BOX: {
                    return this.renderMaterialTextBox(params);
                }

                /** to render user list with first letter as label **/
                case UI_COMPONENT_TYPES.JSON_USERS_WITH_LABEL: {
                    return this.renderLabeledUsersFromJSON(params);
                }

                /** search filter **/
                case UI_COMPONENT_TYPES.SEARCH_FILTER: {
                    return this.renderSearchFilter(params);
                }

                /** overflow left or right layout **/
                case UI_COMPONENT_TYPES.LS_OVERFLOW_MENU: {
                    return this.getTribeOverflowMenu(params);
                }
            }
        } catch (ex) {
            console.error("getUIComponent : error : ", ex)
        }
    },

    getHorizontalLabelDropdown: function (params) {
        return (

            <div className="input_container_horizontal">
                <div className="input_container_horizontal_input_parent">
                    <IntegrationReactSelect

                        items={params['items']}
                        onChange={params.changeCallback}
                        onBlur={(e) => {
                            if (params.handleBlur) {
                                params.handleBlur(params["key"])
                            }
                        }}
                        content_id={params["key"]}
                        value={params['value']}
                        defaultValue={params['defaultValue']}
                        disabled={params['disabled']}
                        variant={params['variant']}
                        placeholder={params['placeholder']}
                        isOverflowEnabled={params['isOverflowEnabled']}
                    />
                </div>
                <div className="input_container_horizontal_label_parent">
                    {UIUtils.getComponentLabel(params)}
                </div>
            </div>
        )
    },

    /** to get the date field horizontal**/
    getDateFieldHorizontal: function (params) {
        return (
            <div className="input_container_horizontal">
                <div className="input_container_horizontal_input_parent">

                    <DatePicker
                        format={APP_DATE_FORMAT_YYYY_MM_DD}
                        placeholder={params['placeholder']}
                        className="gx-mb-3 gx-w-100"
                        defaultValue={params['value'] !== "" ? moment(params['value'], APP_DATE_FORMAT_YYYY_MM_DD) : ""}
                        value={params['value'] !== "" ? moment(params['value'], APP_DATE_FORMAT_YYYY_MM_DD) : ""}
                        onChange={(e) => {
                            if (params.changeCallback) {
                                params.changeCallback(params["key"], e.format(APP_DATE_FORMAT_YYYY_MM_DD))
                            }
                        }}/>
                </div>
                <div className="input_container_horizontal_label_parent">
                    {UIUtils.getComponentLabel(params)}
                </div>
            </div>

        )
    },

    /** to get the date time field horizontal**/
    getDateTimeFieldHorizontal: function (params) {
        return (
            <div className="input_container_horizontal">
                <div className="input_container_horizontal_input_parent">

                    <DatePicker
                        showTime
                        format={APP_DATE_FORMAT_YYYY_MM_DD_HH_MM}
                        placeholder={params['placeholder']}
                        className="gx-mb-3 gx-w-100"
                        defaultValue={params['value'] !== "" ? moment(params['value'], APP_DATE_FORMAT_YYYY_MM_DD_HH_MM) : ""}
                        value={params['value'] !== "" ? moment(params['value'], APP_DATE_FORMAT_YYYY_MM_DD_HH_MM) : ""}
                        onChange={(e) => {
                            if (params.changeCallback) {
                                params.changeCallback(params["key"], e.format(APP_DATE_FORMAT_YYYY_MM_DD_HH_MM))
                            }
                        }}/>
                </div>
                <div className="input_container_horizontal_label_parent">
                    {UIUtils.getComponentLabel(params)}
                </div>
            </div>

        )
    },

    /** to get the switch field horizontal**/
    getSwitchFieldHorizontal: function (params) {
        return (
            <div className="input_container_horizontal">
                <div className="input_container_horizontal_input_parent gx-pt-2">

                    <Switch
                        disabled={params["disabled"]}
                        checked={params['value'] !== "" ? params['value'] : false}
                        onChange={(e) => {
                            if (params.changeCallback) {
                                params.changeCallback(params["key"], e)
                            }
                        }}/>
                </div>

                <div className="input_container_horizontal_label_parent">
                    {UIUtils.getComponentLabel(params)}
                </div>
            </div>

        )
    },

    getStaticActionButtonNegative: function (params) {
        try {
            return (
                <Button
                    id={params.key}
                    aria-haspopup="true"
                    className={"staticActionButtonNegative " + (params.isHidden ? "d-none" : "")}
                    onClick={(event) => {
                        if (params.onClick) {
                            params.onClick(params.key)
                        }
                    }}
                >
                    <span>{params && params.title}</span>

                </Button>
            )

        } catch (ex) {
            console.error("getStaticActionButtonNegative : error : ", ex);
        }
    },

    /** table custom checkbox **/
    getLSCheckBox: function (params) {

        let clickHandler = function (event) {
            if (params.onClick) {
                params.onClick(params.key, params.data, event);
            }
        }

        return (

            <div className={"hand " + (params.className ? params.className : "")} onClick={clickHandler}>
                <div className={(params.checked ? "info_round_checkbox_checked" : "info_round_checkbox") + " tribe_table_header_checkbox_icon"}/>
            </div>
        )
    },

    getMenuPopOver: function (params) {
        let defaultTitle = "";

        if (params["isValueTitle"]) {
            defaultTitle = "All";
            if (typeof params['value'] != "undefined" && params['value'] !== "") {
                defaultTitle = params['value'];
            }
        } else {
            if (typeof params['label'] != "undefined") {
                defaultTitle = params['label'];
            } else if (typeof params['localisation'] != "undefined") {
                defaultTitle = <IntlMessages id={params['localisation']}/>;
            }
        }

        return (
            <div className="form-group" style={params['style']}>
                <div
                    className={"menu_popover_button"}
                    onClick={(event) => {
                        if (params.menuTitleClick) {
                            params.menuTitleClick(params['key'], event)
                        }
                    }}
                >
                    <span className={'menu_popover_button_icon'}/>
                    <span className="pl-1 pt-1 menu_popover_button_title ">{defaultTitle}</span>
                </div>

                <Menu
                    id={params['key']}
                    anchorEl={params['anchorEl']}
                    open={Boolean(params['menuState'])}
                    onClose={() => params.handleRequestClose && params.handleRequestClose(params['key'])}
                    getContentAnchorEl={null}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                    transformOrigin={{vertical: 'top', horizontal: 'left'}}
                >
                    {params['items'].map(option => {
                        return (
                            <MenuItem
                                key={option['value']}
                                value={option['value']}
                                onClick={() => {
                                    if (params.handleRequestClose) {
                                        params.handleRequestClose(params['key'])
                                    }
                                    if (params.changeCallback) {
                                        params.changeCallback(option['key'], option)
                                    }
                                }}
                            >
                                {option['localisation'] ? <IntlMessages id={option['localisation']}/> : ""}
                                {option['label'] ? option['label'] : ""}
                                {option['content'] ? option['content'] : ""}
                            </MenuItem>
                        )
                    })}
                </Menu>
            </div>
        )
    },

    getSearchInput: function (params) {
        return (
            <div className="search_input_container">
                <Input
                    value={params["value"]}
                    onChange={(e) => {

                        if (params.changeCallback) {
                            params.changeCallback(params["key"], e.target.value)
                        }
                    }}
                    onKeyUp={(e) => {
                        if (e.key == "Enter") {
                            if (params.onClick) {
                                params.onClick(params["key"]);
                            }
                        }
                    }}

                    startAdornment={
                        <InputAdornment position="start">
                            <div className="input_search_icon"/>
                        </InputAdornment>
                    }
                />
            </div>
        )
    },

    getSquareIconButton: function (params, icon_class) {
        return (
            <div
                className="headerButton button_icon_square"
                onClick={(event) => {
                    if (params.onClick) {
                        params.onClick(params.key)
                    }
                }}
            >
                <div className={icon_class}/>
                {params["super_label"] && <span className="ls_filter_button_badge">{params["super_label"]}</span>}
            </div>
        )
    },

    getIconBorderButton: function (params, iconClass) {
        return (
            <Button
                disabled={params["disabled"]}
                variant="contained"
                className={"jr-btn text-white button_primary_border"}
                onClick={() => {
                    if (params.onClick) {
                        params.onClick(params["key"])
                    }
                }}>

                <span className={iconClass}/>
                <span className={"p-1 " + (params["disabled"] ? "button_text_color_disabled" : "button_text_color_primary")}>
                    {params["label_id"] ? <IntlMessages id={params["label_id"]}/> : (params["label"] ? params["label"] : "")}
                </span>
            </Button>
        )
    },

    getHorizontalLabelText: function (params) {
        return (

            <div className="input_container_horizontal">
                <div className="input_container_horizontal_input_parent">
                    <span className="input_container_text">
                        {params['value']}
                    </span>
                </div>
                <div className="input_container_horizontal_label_parent">
                    {UIUtils.getComponentLabel(params)}
                </div>
            </div>
        )
    },

    getComponentLabel: function (params) {
        return (
            typeof params['localisation'] != "undefined" && params['localisation'] !== "" ?
                (<span className="form_container_label_content">
                    {params['title'] ? (params['title']) : (<label className={typeof params['labelClass'] != "undefined" ? params['labelClass'] : ''}><IntlMessages id={params['localisation']}/></label>)}
                    {params['isStar'] ? (<label className="text-danger" style={params['star_style']}><sup>{"*"}</sup></label>) : (null)}
                    {params['subLabel'] && (<label className={params['subLabel']['className'] ? params['subLabel']['className'] : ''} onClick={() => {
                        if (params['changeCallback']) {
                            params['changeCallback'](params['subLabel']['key'])
                        }
                    }}><IntlMessages id={params['subLabel']['localisation']}/></label>)}
                </span>) : (typeof params['title'] != "undefined" && params['title'] !== "" ?
                (<span className="form_container_label_content">
                        <span>{params['title']}</span>
                    {params['isStar'] ? (<label className="text-danger" style={params['star_style']}><sup>{"*"}</sup></label>) : (null)}

                    {params['subLabel'] && (<label className={params['subLabel']['className'] ? params['subLabel']['className'] : ''} onClick={() => {
                        if (params['changeCallback']) {
                            params['changeCallback'](params['subLabel']['key'])
                        }
                    }}><IntlMessages id={params['subLabel']['localisation']}/></label>)}
            </span>) : null)
        )
    },

    getHorizontalLabelInput: function (params) {
        return (
            <div className="input_container_horizontal">
                <div className="input_container_horizontal_input_parent">
                    <TextField
                        error={params['isError']}
                        margin="normal"
                        fullWidth
                        id={params['key']}
                        value={params['value']}
                        onChange={(event) => {
                            if (params.changeCallback) {
                                params.changeCallback(params['key'], event.target.value)
                            }
                        }}
                        onBlur={() => {
                            if (params.blurCallback) {
                                params.blurCallback(params['key'])
                            }
                        }}
                        disabled={params['disabled']}
                        select={params['select']}
                        label={params['label']}
                        SelectProps={params['selectProps']}
                        helperText={params['helperText']}
                        variant={params['variant']}
                        placeholder={params['placeholder']}
                        type={params["isPassword"] ? "password" : (params["isFile"] ? "file" : "text")}
                        accept={params['accept']}
                    />
                </div>
                <div className="input_container_horizontal_label_parent">
                    {UIUtils.getComponentLabel(params)}
                </div>
            </div>
        )
    },

    getHorizontalLabelFile: function (params) {
        return (
            <div className="input_container_horizontal">
                <div className="input_container_horizontal_input_parent">
                    <input
                        type="file"
                        id={params['key']}
                        onChange={(event) => {
                            if (params.changeCallback) {
                                params.changeCallback(params['key'], event.target.files)
                            }
                        }}
                        onBlur={() => {
                            if (params.blurCallback) {
                                params.blurCallback(params['key'])
                            }
                        }}
                        disabled={params['disabled']}
                        placeholder={params['placeholder']}
                        accept={params['accept']}
                    />
                </div>
                <div className="input_container_horizontal_label_parent">
                    {UIUtils.getComponentLabel(params)}
                </div>
            </div>
        )
    },

    getLabelContainer: function (params) {
        return (
            <div className={"label_container " + (params["disabled"] ? "label_container_disabled" : "")} onClick={() => params.onClick && params.onClick(params["key"])}>
                {params && params.title}
            </div>
        )
    },

    getStaticActionButtonPositive: function (params) {
        try {
            return (
                <Button
                    id={params.key}
                    aria-haspopup="true"
                    className={"staticActionButton " + (params.disabled ? " staticActionButtonDisabled " : "") + (params.isHidden ? "d-none" : "")}
                    onClick={(event) => {
                        if (params.onClick) {
                            params.onClick(params.key)
                        }
                    }}
                    disabled={params.disabled}
                >
                    <span>{params && params.title}</span>

                </Button>
            )

        } catch (ex) {
            console.error("getStaticActionButtonPositive : error : ", ex);
        }
    },

    getAdornment: function (adornmentType) {
        try {

            switch (adornmentType) {

                case INPUT_ADORNMENT.EYE:
                    return <Visibility/>

                case INPUT_ADORNMENT.DONE:
                    return <Done/>;

                case INPUT_ADORNMENT.CANCEL:
                    return <Close/>

                default: {
                    break;
                }
            }


        } catch (ex) {
            console.error("getAdornment : error : ", ex);
        }

        return null;
    },

    getMaterialIcon: function (icon) {
        try {

            switch (icon) {

                case MATERIAL_ICONS.INSERT_PHOTO_ICON:
                    return <InsertPhoto/>

                case MATERIAL_ICONS.INSERT_FILE_ICON:
                    return <InsertDriveFile/>

                default: {
                    break;
                }
            }

        } catch (ex) {
            console.error("getMaterialIcon : error : ", ex);
        }

        return null;
    },

    renderHorizontalNavigationMenu: function (params) {
        return (
            <Menu
                onClick={(e) => params.onClick && params.onClick(params["key"], e.key)}
                selectedKeys={[params["value"]]}
                mode="horizontal"
            >
                {params["items"].map((item, index) => (
                    <Menu.Item key={item["key"]}>
                        {params.getHeaderContent(item["key"])}
                    </Menu.Item>
                ))}
            </Menu>
        )
    },

    renderCheckbox: function (params) {
        return (
            <Checkbox color="primary"
                      checked={params.isChecked}
                      onChange={() => {
                          if (params.callback) {
                              params.callback(params.key, !params.isChecked)
                          }
                      }}
                      value={params.isChecked}
            />
        )
    },

    renderEditableLabel: function (params) {
        return (
            <div className={"editable_label_container " + (params.className ? params.className : "")} id={params["key"]}>
                {params.isEditable ? (
                    this.renderInputWithSaveIcon(params)
                ) : (
                    <div className={"label_container " + (params.labelClassName ? params.labelClassName : "")}>
                        <span className="editable_label_container_label">
                            {params.label}
                        </span>

                        <span className="label_container_icon" onClick={() => {
                            if (params.editIconCallback) {
                                params.editIconCallback(params["key"])
                            }
                        }}>
                            <i className="zmdi zmdi-edit"/>
                        </span>

                    </div>

                )}
            </div>
        )
    },

    renderPopup: function (params) {
        return (
            <Modal
                visible={params.isVisible}
                title={params.title}
                onOk={() => {
                    if (params.callbackOk) {
                        params.callbackOk()
                    }
                }}
                onCancel={() => {
                    if (params.callbackCancel) {
                        params.callbackCancel()
                    }
                }}
                footer={[
                    <Button key="back" onClick={() => {
                        if (params.callbackCancel) {
                            params.callbackCancel()
                        }
                    }}>
                        {params.cancelLabel && params.cancelLabel !== "" && <IntlMessages id={params.cancelLabel}/>}
                    </Button>,
                    <Button key="submit" type="primary" onClick={() => {
                        if (params.callbackOk) {
                            params.callbackOk()
                        }
                    }}>
                        {params.okLabel && params.okLabel !== "" && <IntlMessages id={params.okLabel}/>}
                    </Button>,
                ]}
            >
                <p>
                    {params.content && params.content()}
                </p>

            </Modal>
        )
    },

    renderListMenu: function (params) {
        return (
            <div className="w-100">
                <List>
                    {params["items"].map((item, index) => (
                        <ListItem className="list_menu_label_parent" button>
                            <ListItemText className="list_menu_label" primary={item['labelKey'] && item['labelKey'] !== "" && <IntlMessages id={item['labelKey']}/>} onClick={() => {
                                if (params.onItemClick) {
                                    params.onItemClick(item.type)
                                }
                            }}/>
                        </ListItem>
                    ))}
                </List>

            </div>
        )
    },

    renderPopover: function (params) {
        return (
            <Popover
                key={params["key"]}
                open={params['isOpen']}
                anchorEl={params['anchorElement']}
                onClose={() => {
                    if (params.onClose) {
                        params.onClose(params["key"])
                    }
                }}
                anchorOrigin={params.anchorOrigin ? params.anchorOrigin : {vertical: "bottom", horizontal: "center"}}
                transformOrigin={params.transformOrigin ? params.transformOrigin : {vertical: "top", horizontal: "center"}}
                className={params.isTransparent && "transparent_popup"}
            >
                {params.content()}
            </Popover>
        )
    },

    renderIconLabelWithNavigation: function (params) {
        return (
            <div className="icon_label_navigation_container hand">
                {params["isNavigation"] && <div className="navigation_icon_right">
                    {params.switchConfig ? (
                        <Switch
                            checkedChildren={params.switchConfig.onLabel}
                            unCheckedChildren={params.switchConfig.offLabel}
                            checked={params.isChecked}
                            onChange={params.onClick}
                        />
                    ) : (
                        <span className="gx-p-1 gx-pl-2  gx-pr-2 hand" onClick={params.onClick}>
                            {params.navigationLabel && <span className="gx-pr-2" style={{}}>
                                {params.navigationLabel}
                            </span>}
                            <i className="zmdi zmdi-chevron-right zmdi-hc-2x"></i>
                        </span>
                    )}

                </div>}
                {params["icon"] && <div className="icon_label_navigation_container_start_icon" onClick={params.onClick}>{UIUtils.getFontIcon(params["icon"])}</div>}
                {params['label'] && <div className="icon_label_navigation_container_label" onClick={params.onClick}>{params['label'] && params['label'] !== "" && <IntlMessages id={params['label']}/>}</div>}

            </div>
        )
    },

    renderLabelWithNavigation: function (params) {
        return (
            <div className="icon_label_navigation_container hand">
                {params["isNavigation"] && <div className="navigation_icon_right">
                    {params.switchConfig ? (
                        <Switch
                            checkedChildren={params.switchConfig.onLabel}
                            unCheckedChildren={params.switchConfig.offLabel}
                            checked={params.isChecked}
                            onChange={params.onClick}
                        />
                    ) : (
                        <span className="gx-p-1 gx-pl-2  gx-pr-2 hand" onClick={params.onClick}>
                            <i className="zmdi zmdi-chevron-right zmdi-hc-2x"></i>
                        </span>
                    )}

                </div>}
                {params['label'] && <div className="icon_label_navigation_container_label" onClick={params.onClick}>{params['label'] && params['label'] !== "" && <IntlMessages id={params['label']}/>}</div>}
            </div>
        )
    },

    renderInputWithSaveIcon: function (params) {
        return (
            <FormControl className="mb-3" fullWidth>
                <MaterialInput
                    id={params["key"]}
                    type={'text'}
                    value={params['value']}
                    onChange={(e) => {
                        if (params.changeCallback) {
                            params.changeCallback(params['key'], e.target.value);
                        }
                    }}
                    endAdornment={
                        params['endAdornment'] && (
                            <InputAdornment position="end">
                                <IconButton onClick={() => {
                                    if (params['onClick']) {
                                        params['onClick']()
                                    }
                                }}>
                                    {this.getAdornment(params['endAdornment'])}
                                </IconButton>
                            </InputAdornment>
                        )
                    }
                    placeholder={params['place_holder'] ? params['place_holder'] : ""}
                />
            </FormControl>
        )
    },

    renderMaterialTextBox: function (params) {
        return (
            <TextField
                id={params['key']}
                label={params['label']}
                onChange={(e) => {
                    if (params.changeCallback) {
                        params.changeCallback(params['key'], e.target.value);
                    }
                }}
                margin="normal"
                fullWidth
            />
        )
    },

    renderLabeledUsersFromJSON: function (params) {

        return (
            <CustomScrollbars className="gx-chat-sidenav-scroll">
                {AppUtils.getJSONLength(params["items"]) === 0 ?
                    (
                        <div className="gx-p-5"><IntlMessages id="DIC_KEY_CHAT_NO_DATA_FOUND"/></div>
                    ) : (
                        Object.keys(params["items"]).map((item) => {
                            return (
                                <>
                                    <div className="chat_window_user_list_label">
                                        {item}
                                    </div>

                                    <ContactList
                                        contactList={params["items"][item]}
                                        onSelectUser={params["changeCallback"]}/>
                                </>
                            )
                        })
                    )
                }

            </CustomScrollbars>
        )
    },

    /** to render search container **/
    renderSearchFilter: function (params) {
        return (
            <div className="gx-chat-search-wrapper">
                <div className="certain-category-search-wrapper" style={{minWidth: "100%"}}>
                    <Input
                        placeholder={params['placeholder'] ? params['placeholder'] : ""}
                        style={{width: '100%'}}
                        className={params["className"] && params["className"]}
                        onChange={(e) => {
                            if (params.changeCallback) {
                                params.changeCallback(params['key'], e.target.value)
                            }
                        }}
                        value={params['value']}
                    />
                </div>
            </div>
        )
    },

    /** left or right side overflow filter menu **/
    getTribeOverflowMenu: function (params) {
        return (
            <TribeOverflowMenu
                params={params}
            />
        )
    },

    getFontIcon: function (iconType) {
        try {
            return <i className={iconType} style={{fontSize: "1.5rem"}}/>
        } catch (ex) {
            console.error("getFontIcon : error : ", ex);
        }

        return <></>
    },

    getIframe: function (loadCallback) {
        let result = {
            "parentDiv": null,
            "iframe": null
        };

        try {

            let parentDiv = document.createElement('div');
            parentDiv.className = 'tribe_iframe_parent';
            document.body.appendChild(parentDiv);

            let iFrame = document.createElement('iframe');
            iFrame.className = 'tribe_iframe';
            //iFrame.allow = "camera *;microphone *";
            iFrame.allow = "microphone;camera;encrypted-media;fullscreen;autoplay;display-capture;layout-animations;";

            parentDiv.appendChild(iFrame);

            if (loadCallback) {
                iFrame.addEventListener("load", loadCallback);
            }

            result["parentDiv"] = parentDiv;
            result["iframe"] = iFrame;

        } catch (ex) {
            console.error("getIframe : error : ", ex);
        }

        return result;
    }

}

export default UIUtils;
