import {
    CALL_EVENT_JOIN_CALL,
    CALL_EVENT_REJECT_CALL, CALL_EVENT_RESET_CALL,
    CALL_EVENT_START_CALL,
    CHAT_DM_MESSAGE_CLEAR_MESSAGES,
    CHAT_DM_MESSAGE_CLEAR_MESSAGES_ERROR,
    CHAT_DM_MESSAGE_CLEAR_MESSAGES_NONE,
    CHAT_DM_MESSAGE_CLEAR_MESSAGES_SUCCESS,
    CHAT_DM_MESSAGE_DELETE_MULTIPLE_MESSAGES,
    CHAT_DM_MESSAGE_DELETE_MULTIPLE_MESSAGES_ERROR,
    CHAT_DM_MESSAGE_DELETE_MULTIPLE_MESSAGES_NONE,
    CHAT_DM_MESSAGE_DELETE_MULTIPLE_MESSAGES_SUCCESS,
    CHAT_DM_MESSAGE_FILTER_MESSAGES_BY_FIELD,
    CHAT_DM_MESSAGE_FILTER_MESSAGES_BY_FIELD_ERROR,
    CHAT_DM_MESSAGE_FILTER_MESSAGES_BY_FIELD_NONE,
    CHAT_DM_MESSAGE_FILTER_MESSAGES_BY_FIELD_SUCCESS,
    CHAT_DM_MESSAGE_GET_MESSAGE_HISTORY,
    CHAT_DM_MESSAGE_GET_MESSAGE_HISTORY_ERROR,
    CHAT_DM_MESSAGE_GET_MESSAGE_HISTORY_NONE,
    CHAT_DM_MESSAGE_GET_MESSAGE_HISTORY_SUCCESS,
    CHAT_DM_MESSAGE_MARK_AS_DELIVERED,
    CHAT_DM_MESSAGE_MARK_AS_DELIVERED_ERROR,
    CHAT_DM_MESSAGE_MARK_AS_DELIVERED_NONE,
    CHAT_DM_MESSAGE_MARK_AS_DELIVERED_SUCCESS,
    CHAT_DM_MESSAGE_MARK_AS_UNREAD,
    CHAT_DM_MESSAGE_MARK_AS_UNREAD_ERROR,
    CHAT_DM_MESSAGE_MARK_AS_UNREAD_NONE,
    CHAT_DM_MESSAGE_MARK_AS_UNREAD_SUCCESS,
    CHAT_DM_MESSAGE_PURGE_MESSAGES,
    CHAT_DM_MESSAGE_PURGE_MESSAGES_ERROR,
    CHAT_DM_MESSAGE_PURGE_MESSAGES_NONE,
    CHAT_DM_MESSAGE_PURGE_MESSAGES_SUCCESS,
    CHAT_DM_MESSAGE_REACT_ON_MESSAGE,
    CHAT_DM_MESSAGE_REACT_ON_MESSAGE_ERROR,
    CHAT_DM_MESSAGE_REACT_ON_MESSAGE_NONE,
    CHAT_DM_MESSAGE_REACT_ON_MESSAGE_SUCCESS,
    CHAT_PERSONAL_CHAT_MARK_READ_ALL_STATUS,
    CHAT_PERSONAL_RESET_SCROLL_STATUS,
    CHAT_SET_CURRENT_CHAT_ID,
    PERSONAL_MESSAGES_MESSAGE_DELETE,
    PERSONAL_MESSAGES_MESSAGE_DELETE_ERROR,
    PERSONAL_MESSAGES_MESSAGE_DELETE_NONE,
    PERSONAL_MESSAGES_MESSAGE_DELETE_SUCCESS,
    PERSONAL_MESSAGES_MESSAGE_EDIT,
    PERSONAL_MESSAGES_MESSAGE_EDIT_ERROR,
    PERSONAL_MESSAGES_MESSAGE_EDIT_NONE,
    PERSONAL_MESSAGES_MESSAGE_EDIT_SUCCESS,
    PERSONAL_MESSAGES_MESSAGE_FORWARD,
    PERSONAL_MESSAGES_MESSAGE_FORWARD_ERROR,
    PERSONAL_MESSAGES_MESSAGE_FORWARD_NONE,
    PERSONAL_MESSAGES_MESSAGE_FORWARD_SUCCESS,
    PERSONAL_MESSAGES_MESSAGE_LIST,
    PERSONAL_MESSAGES_MESSAGE_LIST_ERROR,
    PERSONAL_MESSAGES_MESSAGE_LIST_NONE,
    PERSONAL_MESSAGES_MESSAGE_LIST_SUCCESS,
    PERSONAL_MESSAGES_MESSAGE_READ,
    PERSONAL_MESSAGES_MESSAGE_READ_ALL,
    PERSONAL_MESSAGES_MESSAGE_READ_ALL_ERROR,
    PERSONAL_MESSAGES_MESSAGE_READ_ALL_NONE,
    PERSONAL_MESSAGES_MESSAGE_READ_ALL_SUCCESS,
    PERSONAL_MESSAGES_MESSAGE_READ_ERROR,
    PERSONAL_MESSAGES_MESSAGE_READ_NONE,
    PERSONAL_MESSAGES_MESSAGE_READ_SUCCESS,
    PERSONAL_MESSAGES_MESSAGE_SEND,
    PERSONAL_MESSAGES_MESSAGE_SEND_ERROR,
    PERSONAL_MESSAGES_MESSAGE_SEND_NONE,
    PERSONAL_MESSAGES_MESSAGE_SEND_SUCCESS,
    PERSONAL_MESSAGES_MUTE_UNMUTE_NOTIFICATIONS,
    PERSONAL_MESSAGES_MUTE_UNMUTE_NOTIFICATIONS_ERROR,
    PERSONAL_MESSAGES_MUTE_UNMUTE_NOTIFICATIONS_NONE,
    PERSONAL_MESSAGES_MUTE_UNMUTE_NOTIFICATIONS_SUCCESS
} from "../../../constants/actions/chat/ChatActionTypes";

/** personal messages : message send **/
export const personalMessagesMessageSend = (payload) => {
    return {
        type: PERSONAL_MESSAGES_MESSAGE_SEND,
        payload: payload
    };
};

/** personal messages : message send : success **/
export const personalMessagesMessageSendSuccess = (payload) => {
    return {
        type: PERSONAL_MESSAGES_MESSAGE_SEND_SUCCESS,
        payload: payload
    };
};

/** personal messages : message send : error **/
export const personalMessagesMessageSendError = (payload) => {
    return {
        type: PERSONAL_MESSAGES_MESSAGE_SEND_ERROR,
        payload: payload
    };
};

/** personal messages : message send : none **/
export const personalMessagesMessageSendNone = (payload) => {
    return {
        type: PERSONAL_MESSAGES_MESSAGE_SEND_NONE,
        payload: payload
    };
};

/** personal messages : message edit **/
export const personalMessagesMessageEdit = (payload) => {
    return {
        type: PERSONAL_MESSAGES_MESSAGE_EDIT,
        payload: payload
    };
};

/** personal messages : message edit : success **/
export const personalMessagesMessageEditSuccess = (payload) => {
    return {
        type: PERSONAL_MESSAGES_MESSAGE_EDIT_SUCCESS,
        payload: payload
    };
};

/** personal messages : message edit : error **/
export const personalMessagesMessageEditError = (payload) => {
    return {
        type: PERSONAL_MESSAGES_MESSAGE_EDIT_ERROR,
        payload: payload
    };
};

/** personal messages : message edit : none **/
export const personalMessagesMessageEditNone = (payload) => {
    return {
        type: PERSONAL_MESSAGES_MESSAGE_EDIT_NONE,
        payload: payload
    };
};


/** personal messages : message delete **/
export const personalMessagesMessageDelete = (payload) => {
    return {
        type: PERSONAL_MESSAGES_MESSAGE_DELETE,
        payload: payload
    };
};

/** personal messages : message delete : success **/
export const personalMessagesMessageDeleteSuccess = (payload) => {
    return {
        type: PERSONAL_MESSAGES_MESSAGE_DELETE_SUCCESS,
        payload: payload
    };
};

/** personal messages : message delete : error **/
export const personalMessagesMessageDeleteError = (payload) => {
    return {
        type: PERSONAL_MESSAGES_MESSAGE_DELETE_ERROR,
        payload: payload
    };
};

/** personal messages : message delete : none **/
export const personalMessagesMessageDeleteNone = (payload) => {
    return {
        type: PERSONAL_MESSAGES_MESSAGE_DELETE_NONE,
        payload: payload
    };
};


/** personal messages : message read **/
export const personalMessagesMessageRead = (payload) => {
    return {
        type: PERSONAL_MESSAGES_MESSAGE_READ,
        payload: payload
    };
};

/** personal messages : message read : success **/
export const personalMessagesMessageReadSuccess = (payload) => {
    return {
        type: PERSONAL_MESSAGES_MESSAGE_READ_SUCCESS,
        payload: payload
    };
};

/** personal messages : message read : error **/
export const personalMessagesMessageReadError = (payload) => {
    return {
        type: PERSONAL_MESSAGES_MESSAGE_READ_ERROR,
        payload: payload
    };
};

/** personal messages : message read : none **/
export const personalMessagesMessageReadNone = (payload) => {
    return {
        type: PERSONAL_MESSAGES_MESSAGE_READ_NONE,
        payload: payload
    };
};

/** personal messages : message read all **/
export const personalMessagesMessageReadAll = (payload) => {
    return {
        type: PERSONAL_MESSAGES_MESSAGE_READ_ALL,
        payload: payload
    };
};

/** personal messages : message read all : success **/
export const personalMessagesMessageReadAllSuccess = (payload) => {
    return {
        type: PERSONAL_MESSAGES_MESSAGE_READ_ALL_SUCCESS,
        payload: payload
    };
};

/** personal messages : message read all : error **/
export const personalMessagesMessageReadAllError = (payload) => {
    return {
        type: PERSONAL_MESSAGES_MESSAGE_READ_ALL_ERROR,
        payload: payload
    };
};

/** personal messages : message read all : none **/
export const personalMessagesMessageReadAllNone = (payload) => {
    return {
        type: PERSONAL_MESSAGES_MESSAGE_READ_ALL_NONE,
        payload: payload
    };
};


/** personal messages : message list **/
export const personalMessagesMessageList = (payload) => {
    return {
        type: PERSONAL_MESSAGES_MESSAGE_LIST,
        payload: payload
    };
};

/** personal messages : message list : success **/
export const personalMessagesMessageListSuccess = (payload) => {
    return {
        type: PERSONAL_MESSAGES_MESSAGE_LIST_SUCCESS,
        payload: payload
    };
};

/** personal messages : message list : error **/
export const personalMessagesMessageListError = (payload) => {
    return {
        type: PERSONAL_MESSAGES_MESSAGE_LIST_ERROR,
        payload: payload
    };
};

/** personal messages : message list : none **/
export const personalMessagesMessageListNone = (payload) => {
    return {
        type: PERSONAL_MESSAGES_MESSAGE_LIST_NONE,
        payload: payload
    };
};


/** personal messages : message read all status update**/
export const personalMessagesMarkReadAllStatus = (payload) => {
    return {
        type: CHAT_PERSONAL_CHAT_MARK_READ_ALL_STATUS,
        payload: payload
    };
};


/** to reset chat messages scroll status **/
export const resetChatMessagesScrollStatus = (payload) => {
    return {
        type: CHAT_PERSONAL_RESET_SCROLL_STATUS,
        payload: payload
    };
};

/** to set selected chat id **/
export const setSelectedChatId = (payload) => {
    return {
        type: CHAT_SET_CURRENT_CHAT_ID,
        payload: payload
    };
};


/** personal messages : mark message as unread **/
export const personalMessagesMarkMessageAsUnread = (payload) => {
    return {
        type: CHAT_DM_MESSAGE_MARK_AS_UNREAD,
        payload: payload
    };
};

/** personal messages : mark message as unread : success **/
export const personalMessagesMarkMessageAsUnreadSuccess = (payload) => {
    return {
        type: CHAT_DM_MESSAGE_MARK_AS_UNREAD_SUCCESS,
        payload: payload
    };
};

/** personal messages : mark message as unread : error **/
export const personalMessagesMarkMessageAsUnreadError = (payload) => {
    return {
        type: CHAT_DM_MESSAGE_MARK_AS_UNREAD_ERROR,
        payload: payload
    };
};

/** personal messages : mark message as unread : none **/
export const personalMessagesMarkMessageAsUnreadNone = (payload) => {
    return {
        type: CHAT_DM_MESSAGE_MARK_AS_UNREAD_NONE,
        payload: payload
    };
};

/** personal messages : mark message as delivered **/
export const personalMessagesMarkMessageAsDelivered = (payload) => {
    return {
        type: CHAT_DM_MESSAGE_MARK_AS_DELIVERED,
        payload: payload
    };
};

/** personal messages : mark message as delivered : success **/
export const personalMessagesMarkMessageAsDeliveredSuccess = (payload) => {
    return {
        type: CHAT_DM_MESSAGE_MARK_AS_DELIVERED_SUCCESS,
        payload: payload
    };
};

/** personal messages : mark message as delivered : error **/
export const personalMessagesMarkMessageAsDeliveredError = (payload) => {
    return {
        type: CHAT_DM_MESSAGE_MARK_AS_DELIVERED_ERROR,
        payload: payload
    };
};

/** personal messages : mark message as delivered : none **/
export const personalMessagesMarkMessageAsDeliveredNone = (payload) => {
    return {
        type: CHAT_DM_MESSAGE_MARK_AS_DELIVERED_NONE,
        payload: payload
    };
};

/** personal messages : delete multiple messages **/
export const personalMessagesDeleteMultipleMessages = (payload) => {
    return {
        type: CHAT_DM_MESSAGE_DELETE_MULTIPLE_MESSAGES,
        payload: payload
    };
};

/** personal messages : delete multiple messages : success **/
export const personalMessagesDeleteMultipleMessagesSuccess = (payload) => {
    return {
        type: CHAT_DM_MESSAGE_DELETE_MULTIPLE_MESSAGES_SUCCESS,
        payload: payload
    };
};

/** personal messages : delete multiple messages : error **/
export const personalMessagesDeleteMultipleMessagesError = (payload) => {
    return {
        type: CHAT_DM_MESSAGE_DELETE_MULTIPLE_MESSAGES_ERROR,
        payload: payload
    };
};

/** personal messages : delete multiple messages : none **/
export const personalMessagesDeleteMultipleMessagesNone = (payload) => {
    return {
        type: CHAT_DM_MESSAGE_DELETE_MULTIPLE_MESSAGES_NONE,
        payload: payload
    };
};

/** personal messages : clear  messages **/
export const personalMessagesClearMessages = (payload) => {
    return {
        type: CHAT_DM_MESSAGE_CLEAR_MESSAGES,
        payload: payload
    };
};

/** personal messages : clear  messages : success **/
export const personalMessagesClearMessagesSuccess = (payload) => {
    return {
        type: CHAT_DM_MESSAGE_CLEAR_MESSAGES_SUCCESS,
        payload: payload
    };
};

/** personal messages : clear  messages : error **/
export const personalMessagesClearMessagesError = (payload) => {
    return {
        type: CHAT_DM_MESSAGE_CLEAR_MESSAGES_ERROR,
        payload: payload
    };
};

/** personal messages : clear  messages : none **/
export const personalMessagesClearMessagesNone = (payload) => {
    return {
        type: CHAT_DM_MESSAGE_CLEAR_MESSAGES_NONE,
        payload: payload
    };
};

/** personal messages : react on message **/
export const personalMessagesReactOnMessage = (payload) => {
    return {
        type: CHAT_DM_MESSAGE_REACT_ON_MESSAGE,
        payload: payload
    };
};

/** personal messages : react on message : success **/
export const personalMessagesReactOnMessageSuccess = (payload) => {
    return {
        type: CHAT_DM_MESSAGE_REACT_ON_MESSAGE_SUCCESS,
        payload: payload
    };
};

/** personal messages : react on message : error **/
export const personalMessagesReactOnMessageError = (payload) => {
    return {
        type: CHAT_DM_MESSAGE_REACT_ON_MESSAGE_ERROR,
        payload: payload
    };
};

/** personal messages : react on message : none **/
export const personalMessagesReactOnMessageNone = (payload) => {
    return {
        type: CHAT_DM_MESSAGE_REACT_ON_MESSAGE_NONE,
        payload: payload
    };
};

/** personal messages : purge  messages **/
export const personalMessagesPurgeMessages = (payload) => {
    return {
        type: CHAT_DM_MESSAGE_PURGE_MESSAGES,
        payload: payload
    };
};

/** personal messages : purge  messages : success **/
export const personalMessagesPurgeMessagesSuccess = (payload) => {
    return {
        type: CHAT_DM_MESSAGE_PURGE_MESSAGES_SUCCESS,
        payload: payload
    };
};

/** personal messages : purge  messages : error **/
export const personalMessagesPurgeMessagesError = (payload) => {
    return {
        type: CHAT_DM_MESSAGE_PURGE_MESSAGES_ERROR,
        payload: payload
    };
};

/** personal messages : purge  messages : none **/
export const personalMessagesPurgeMessagesNone = (payload) => {
    return {
        type: CHAT_DM_MESSAGE_PURGE_MESSAGES_NONE,
        payload: payload
    };
};

/** personal messages : to get reply message history **/
export const personalMessagesGetMessageHistory = (payload) => {
    return {
        type: CHAT_DM_MESSAGE_GET_MESSAGE_HISTORY,
        payload: payload
    };
};

/** personal messages : to get reply message history : success **/
export const personalMessagesGetMessageHistorySuccess = (payload) => {
    return {
        type: CHAT_DM_MESSAGE_GET_MESSAGE_HISTORY_SUCCESS,
        payload: payload
    };
};

/** personal messages : to get reply message history : error **/
export const personalMessagesGetMessageHistoryError = (payload) => {
    return {
        type: CHAT_DM_MESSAGE_GET_MESSAGE_HISTORY_ERROR,
        payload: payload
    };
};

/** personal messages : to get reply message history : none **/
export const personalMessagesGetMessageHistoryNone = (payload) => {
    return {
        type: CHAT_DM_MESSAGE_GET_MESSAGE_HISTORY_NONE,
        payload: payload
    };
};

/** personal messages : to filter by message field **/
export const personalMessagesFilterMessagesByField = (payload) => {
    return {
        type: CHAT_DM_MESSAGE_FILTER_MESSAGES_BY_FIELD,
        payload: payload
    };
};

/** personal messages : to filter by message field : success **/
export const personalMessagesFilterMessagesByFieldSuccess = (payload) => {
    return {
        type: CHAT_DM_MESSAGE_FILTER_MESSAGES_BY_FIELD_SUCCESS,
        payload: payload
    };
};

/** personal messages : to filter by message field : error **/
export const personalMessagesFilterMessagesByFieldError = (payload) => {
    return {
        type: CHAT_DM_MESSAGE_FILTER_MESSAGES_BY_FIELD_ERROR,
        payload: payload
    };
};

/** personal messages : to filter by message field : none **/
export const personalMessagesFilterMessagesByFieldNone = (payload) => {
    return {
        type: CHAT_DM_MESSAGE_FILTER_MESSAGES_BY_FIELD_NONE,
        payload: payload
    };
};


/** call : reject call event **/
export const callEventRejectCall = (payload) => {
    return {
        type: CALL_EVENT_REJECT_CALL,
        payload: payload
    };
};

/** call : to join the call from notification popup **/
export const callEventJoinCall = (payload) => {
    return {
        type: CALL_EVENT_JOIN_CALL,
        payload: payload
    };
};

/** call : to start the new call **/
export const callEventStartCall = (payload) => {
    return {
        type: CALL_EVENT_START_CALL,
        payload: payload
    };
};

/** call : to reset call status data **/
export const callEventResetCallData = (payload) => {
    return {
        type: CALL_EVENT_RESET_CALL,
        payload: payload
    };
};

/** personal messages : messages forward **/
export const personalMessagesMessagesForward = (payload) => {
    return {
        type: PERSONAL_MESSAGES_MESSAGE_FORWARD,
        payload: payload
    };
};

/** personal messages : messages forward : success **/
export const personalMessagesMessagesForwardSuccess = (payload) => {
    return {
        type: PERSONAL_MESSAGES_MESSAGE_FORWARD_SUCCESS,
        payload: payload
    };
};

/** personal messages : messages forward : error **/
export const personalMessagesMessagesForwardError = (payload) => {
    return {
        type: PERSONAL_MESSAGES_MESSAGE_FORWARD_ERROR,
        payload: payload
    };
};

/** personal messages : messages forward : none **/
export const personalMessagesMessagesForwardNone = (payload) => {
    return {
        type: PERSONAL_MESSAGES_MESSAGE_FORWARD_NONE,
        payload: payload
    };
};

/** personal messages : mute unmute notifications **/
export const personalMessagesMuteUnmuteNotifications = (payload) => {
    return {
        type: PERSONAL_MESSAGES_MUTE_UNMUTE_NOTIFICATIONS,
        payload: payload
    };
};

/** personal messages : mute unmute notifications : success **/
export const personalMessagesMuteUnmuteNotificationsSuccess = (payload) => {
    return {
        type: PERSONAL_MESSAGES_MUTE_UNMUTE_NOTIFICATIONS_SUCCESS,
        payload: payload
    };
};

/** personal messages : mute unmute notifications : error **/
export const personalMessagesMuteUnmuteNotificationsError = (payload) => {
    return {
        type: PERSONAL_MESSAGES_MUTE_UNMUTE_NOTIFICATIONS_ERROR,
        payload: payload
    };
};

/** personal messages : mute unmute notifications : none **/
export const personalMessagesMuteUnmuteNotificationsNone = (payload) => {
    return {
        type: PERSONAL_MESSAGES_MUTE_UNMUTE_NOTIFICATIONS_NONE,
        payload: payload
    };
};
