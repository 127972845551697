
/**** users ****/
/** get users list **/
export const USERS_GET_ALL_USERS = 'USERS_GET_ALL_USERS';
export const USERS_GET_ALL_USERS_SUCCESS = 'USERS_GET_ALL_USERS_SUCCESS';
export const USERS_GET_ALL_USERS_ERROR = 'USERS_GET_ALL_USERS_ERROR';
export const USERS_GET_ALL_USERS_NONE = 'USERS_GET_ALL_USERS_NONE';

/** s user **/
export const USERS_SAVE_USER = 'USERS_SAVE_USER';
export const USERS_SAVE_USER_SUCCESS = 'USERS_SAVE_USER_SUCCESS';
export const USERS_SAVE_USER_ERROR = 'USERS_SAVE_USER_ERROR';
export const USERS_SAVE_USER_NONE = 'USERS_SAVE_USER_NONE';

/** deactivate user **/
export const USERS_DEACTIVATE_USER = 'USERS_DEACTIVATE_USER';
export const USERS_DEACTIVATE_USER_SUCCESS = 'USERS_DEACTIVATE_USER_SUCCESS';
export const USERS_DEACTIVATE_USER_ERROR = 'USERS_DEACTIVATE_USER_ERROR';
export const USERS_DEACTIVATE_USER_NONE = 'USERS_DEACTIVATE_USER_NONE';


/** to set current user in view page **/
export const USERS_SET_CURRENT_USER = 'USERS_SET_CURRENT_USER';

/** to filter users **/
export const USERS_FILTER_USERS = 'USERS_FILTER_USERS';
