import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import axios from "axios";
import {
    URL_CHAT_USER_ACTIVE_STATUS,
    URL_CHAT_USER_DELETE,
    URL_CHAT_USER_GET_DETAILS,
    URL_CHAT_USER_LOGIN,
    URL_CHAT_USER_LOGINS,
    URL_CHAT_USER_LOGOUT,
    URL_CHAT_USER_REGISTER,
    URL_CHAT_USER_UPDATE
} from "../../../util/tribe/urls";
import {
    USER_MANAGEMENT_DELETE_USER,
    USER_MANAGEMENT_DO_LOGIN,
    USER_MANAGEMENT_DO_LOGOUT,
    USER_MANAGEMENT_GET_DETAILS,
    USER_MANAGEMENT_GET_LOGINS,
    USER_MANAGEMENT_GET_USER_ACTIVE_STATUS,
    USER_MANAGEMENT_REGISTER
} from "../../../constants/actions/chat/ChatActionTypes";
import AppUtils from "../../../util/tribe/utils/appUtils";
import {
    userManagementGetUserActiveStatusError,
    userManagementGetUserActiveStatusSuccess,
    userManagementUserDeleteError,
    userManagementUserDeleteSuccess,
    userManagementUserGetDetailsError,
    userManagementUserGetDetailsSuccess,
    userManagementUserGetLoginsError,
    userManagementUserGetLoginsSuccess,
    userManagementUserLoginError,
    userManagementUserLoginSuccess,
    userManagementUserLogOutError,
    userManagementUserLogOutSuccess,
    userManagementUserRegisterError,
    userManagementUserRegisterSuccess,
    userManagementUserUpdateDetailsError,
    userManagementUserUpdateDetailsSuccess,
} from "../../actions";

/** http : user management : register **/
const httpChatUserManagementUserRegister = async (data) =>
    await axios.post(URL_CHAT_USER_REGISTER, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : user management : user details **/
const httpChatUserManagementUserDetails = async (data) =>
    await axios.post(URL_CHAT_USER_GET_DETAILS, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : user management : user update **/
const httpChatUserManagementUserUpdate = async (data) =>
    await axios.post(URL_CHAT_USER_UPDATE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : user management : user delete **/
const httpChatUserManagementUserDelete = async (data) =>
    await axios.post(URL_CHAT_USER_DELETE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : user management : user logins **/
const httpChatUserManagementUserLogins = async (data) =>
    await axios.post(URL_CHAT_USER_LOGINS, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : user management : user login **/
const httpChatUserManagementUserLogin = async (data) =>
    await axios.post(URL_CHAT_USER_LOGIN, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : user management : user logout **/
const httpChatUserManagementUserLogout = async (data) =>
    await axios.post(URL_CHAT_USER_LOGOUT, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : user management : to get user status by user ids **/
const httpChatUserManagementGetUserActiveStatus = async (data) =>
    await axios.post(URL_CHAT_USER_ACTIVE_STATUS, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** handler : user management : to get user active status **/
function* handleHttpChatUserManagementGetUserActiveStatus({payload}) {
    try {
        const result = yield call(httpChatUserManagementGetUserActiveStatus, payload);
        if (result.message) {
            yield put(userManagementGetUserActiveStatusError(result.message));
        } else {
            yield put(userManagementGetUserActiveStatusSuccess(result.data));
        }
    } catch (error) {
        yield put(userManagementGetUserActiveStatusError(error));
    }
}

/** handler : user management : user register **/
function* handleHttpChatUserManagementUserRegister({payload}) {
    try {
        const result = yield call(httpChatUserManagementUserRegister, payload);
        if (result.message) {
            yield put(userManagementUserRegisterError(result.message));
        } else {
            yield put(userManagementUserRegisterSuccess(result.data));
        }
    } catch (error) {
        yield put(userManagementUserRegisterError(error));
    }
}

/** handler : user management : user details **/
function* handleHttpChatUserManagementUserDetails({payload}) {
    try {
        const result = yield call(httpChatUserManagementUserDetails, payload);
        if (result.message) {
            yield put(userManagementUserGetDetailsError(result.message));
        } else {
            yield put(userManagementUserGetDetailsSuccess(result.data));
        }
    } catch (error) {
        yield put(userManagementUserGetDetailsError(error));
    }
}

/** handler : user management : user update **/
function* handleHttpChatUserManagementUserUpdate({payload}) {
    try {
        const result = yield call(httpChatUserManagementUserUpdate, payload);
        if (result.message) {
            yield put(userManagementUserUpdateDetailsError(result.message));
        } else {
            yield put(userManagementUserUpdateDetailsSuccess(result.data));
        }
    } catch (error) {
        yield put(userManagementUserUpdateDetailsError(error));
    }
}

/** handler : user management : user delete **/
function* handleHttpChatUserManagementUserDelete({payload}) {
    try {
        const result = yield call(httpChatUserManagementUserDelete, payload);
        if (result.message) {
            yield put(userManagementUserDeleteError(result.message));
        } else {
            yield put(userManagementUserDeleteSuccess(result.data));
        }
    } catch (error) {
        yield put(userManagementUserDeleteError(error));
    }
}

/** handler : user management : user logins **/
function* handleHttpChatUserManagementUserLogins({payload}) {
    try {
        const result = yield call(httpChatUserManagementUserLogins, payload);
        if (result.message) {
            yield put(userManagementUserGetLoginsError(result.message));
        } else {
            yield put(userManagementUserGetLoginsSuccess(result.data));
        }
    } catch (error) {
        yield put(userManagementUserGetLoginsError(error));
    }
}

/** handler : user management : user login **/
function* handleHttpChatUserManagementUserLogin({payload}) {
    try {
        const result = yield call(httpChatUserManagementUserLogin, payload);
        if (result.message) {
            yield put(userManagementUserLoginError(result.message));
        } else {
            yield put(userManagementUserLoginSuccess(result.data));
        }
    } catch (error) {
        yield put(userManagementUserLoginError(error));
    }
}

/** handler : user management : user logout **/
function* handleHttpChatUserManagementUserLogout({payload}) {
    try {
        const result = yield call(httpChatUserManagementUserLogout, payload);
        if (result.message) {
            yield put(userManagementUserLogOutError(result.message));
        } else {
            yield put(userManagementUserLogOutSuccess(result.data));
        }
    } catch (error) {
        yield put(userManagementUserLogOutError(error));
    }
}


/** watcher : user management : user register **/
export function* chatUserManagementUserRegister() {
    yield takeEvery(USER_MANAGEMENT_REGISTER, handleHttpChatUserManagementUserRegister);
}

/** watcher : user management : user details **/
export function* chatUserManagementUserDetails() {
    yield takeEvery(USER_MANAGEMENT_GET_DETAILS, handleHttpChatUserManagementUserDetails);
}

/** watcher : user management : user update **/
export function* chatUserManagementUserUpdate() {
    yield takeEvery(USER_MANAGEMENT_GET_DETAILS, handleHttpChatUserManagementUserUpdate);
}

/** watcher : user management : user delete **/
export function* chatUserManagementUserDelete() {
    yield takeEvery(USER_MANAGEMENT_DELETE_USER, handleHttpChatUserManagementUserDelete);
}

/** watcher : user management : user logins **/
export function* chatUserManagementUserLogins() {
    yield takeEvery(USER_MANAGEMENT_GET_LOGINS, handleHttpChatUserManagementUserLogins);
}

/** watcher : user management : user login **/
export function* chatUserManagementUserLogin() {
    yield takeEvery(USER_MANAGEMENT_DO_LOGIN, handleHttpChatUserManagementUserLogin);
}

/** watcher : user management : user logout **/
export function* chatUserManagementUserLogout() {
    yield takeEvery(USER_MANAGEMENT_DO_LOGOUT, handleHttpChatUserManagementUserLogout);
}

/** watcher : user management : user logout **/
export function* chatUserManagementGetUserActiveStatus() {
    yield takeEvery(USER_MANAGEMENT_GET_USER_ACTIVE_STATUS, handleHttpChatUserManagementGetUserActiveStatus);
}


export default function* rootSaga() {
    yield all([
        fork(chatUserManagementUserRegister),
        fork(chatUserManagementUserDetails),
        fork(chatUserManagementUserUpdate),
        fork(chatUserManagementUserDelete),
        fork(chatUserManagementUserLogins),
        fork(chatUserManagementUserLogin),
        fork(chatUserManagementUserLogout),
        fork(chatUserManagementGetUserActiveStatus)
    ])
}
