import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import axios from "axios";
import {
    URL_CHAT_CHANNEL_MEMBER_MANAGEMENT_ADD,
    URL_CHAT_CHANNEL_MEMBER_MANAGEMENT_BLOCK_NOTIFICATIONS,
    URL_CHAT_CHANNEL_MEMBER_MANAGEMENT_INVITE,
    URL_CHAT_CHANNEL_MEMBER_MANAGEMENT_INVITE_ACCEPT,
    URL_CHAT_CHANNEL_MEMBER_MANAGEMENT_INVITE_DELETE,
    URL_CHAT_CHANNEL_MEMBER_MANAGEMENT_LEAVE,
    URL_CHAT_CHANNEL_MEMBER_MANAGEMENT_MEMBER_BAN,
    URL_CHAT_CHANNEL_MEMBER_MANAGEMENT_MEMBER_CHANGE_ROLE,
    URL_CHAT_CHANNEL_MEMBER_MANAGEMENT_MEMBER_LIST,
    URL_CHAT_CHANNEL_MEMBER_MANAGEMENT_UNBLOCK_NOTIFICATIONS
} from "../../../util/tribe/urls";
import {
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_ADD,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_BAN,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_BLOCK_NOTIFICATIONS,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_CHANGE_ROLE,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_ACCEPT,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_DELETE,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_LEAVE,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_LIST,
    CHANNEL_MEMBER_MANAGEMENT_MEMBER_UNBLOCK_NOTIFICATIONS
} from "../../../constants/actions/chat/ChatActionTypes";
import AppUtils from "../../../util/tribe/utils/appUtils";
import {
    channelMemberManagementMemberAddError,
    channelMemberManagementMemberAddSuccess,
    channelMemberManagementMemberBanError,
    channelMemberManagementMemberBanSuccess,
    channelMemberManagementMemberBlockNotificationsError,
    channelMemberManagementMemberBlockNotificationsSuccess,
    channelMemberManagementMemberChangeRoleError,
    channelMemberManagementMemberChangeRoleSuccess,
    channelMemberManagementMemberInviteAcceptError,
    channelMemberManagementMemberInviteAcceptSuccess,
    channelMemberManagementMemberInviteDeleteError,
    channelMemberManagementMemberInviteDeleteSuccess,
    channelMemberManagementMemberInviteError,
    channelMemberManagementMemberInviteSuccess,
    channelMemberManagementMemberLeaveError,
    channelMemberManagementMemberLeaveSuccess,
    channelMemberManagementMemberListError,
    channelMemberManagementMemberListSuccess,
    channelMemberManagementMemberUnblockNotificationsError,
    channelMemberManagementMemberUnblockNotificationsSuccess
} from "../../actions";

/** http : channel member management : member add **/
const httpChatChannelMemberManagementMemberAdd = async (data) =>
    await axios.post(URL_CHAT_CHANNEL_MEMBER_MANAGEMENT_ADD, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : channel member management : member invite **/
const httpChatChannelMemberManagementMemberInvite = async (data) =>
    await axios.post(URL_CHAT_CHANNEL_MEMBER_MANAGEMENT_INVITE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : channel member management : member invite delete **/
const httpChatChannelMemberManagementMemberInviteDelete = async (data) =>
    await axios.post(URL_CHAT_CHANNEL_MEMBER_MANAGEMENT_INVITE_DELETE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : channel member management : member invite accept **/
const httpChatChannelMemberManagementMemberInviteAccept = async (data) =>
    await axios.post(URL_CHAT_CHANNEL_MEMBER_MANAGEMENT_INVITE_ACCEPT, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : channel member management : member leave **/
const httpChatChannelMemberManagementMemberLeave = async (data) =>
    await axios.post(URL_CHAT_CHANNEL_MEMBER_MANAGEMENT_LEAVE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : channel member management : member block notifications **/
const httpChatChannelMemberManagementMemberBlockNotifications = async (data) =>
    await axios.post(URL_CHAT_CHANNEL_MEMBER_MANAGEMENT_BLOCK_NOTIFICATIONS, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : channel member management : member unblock notifications **/
const httpChatChannelMemberManagementMemberUnblockNotifications = async (data) =>
    await axios.post(URL_CHAT_CHANNEL_MEMBER_MANAGEMENT_UNBLOCK_NOTIFICATIONS, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : channel member management : member ban **/
const httpChatChannelMemberManagementMemberBan = async (data) =>
    await axios.post(URL_CHAT_CHANNEL_MEMBER_MANAGEMENT_MEMBER_BAN, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : channel member management : member change role **/
const httpChatChannelMemberManagementMemberChangeRole = async (data) =>
    await axios.post(URL_CHAT_CHANNEL_MEMBER_MANAGEMENT_MEMBER_CHANGE_ROLE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : channel member management : member list **/
const httpChatChannelMemberManagementMemberList = async (data) =>
    await axios.post(URL_CHAT_CHANNEL_MEMBER_MANAGEMENT_MEMBER_LIST, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });


/** handler : channel member management : member add **/
function* handleHttpChatChannelMemberManagementMemberAdd({payload}) {
    try {
        const result = yield call(httpChatChannelMemberManagementMemberAdd, payload);
        if (result.message) {
            yield put(channelMemberManagementMemberAddError(result.message));
        } else {
            yield put(channelMemberManagementMemberAddSuccess(result.data));
        }
    } catch (error) {
        yield put(channelMemberManagementMemberAddError(error));
    }
}

/** handler : channel member management : member invite **/
function* handleHttpChatChannelMemberManagementMemberInvite({payload}) {
    try {
        const result = yield call(httpChatChannelMemberManagementMemberInvite, payload);
        if (result.message) {
            yield put(channelMemberManagementMemberInviteError(result.message));
        } else {
            yield put(channelMemberManagementMemberInviteSuccess(result.data));
        }
    } catch (error) {
        yield put(channelMemberManagementMemberInviteError(error));
    }
}

/** handler : channel member management : member invite delete **/
function* handleHttpChatChannelMemberManagementMemberInviteDelete({payload}) {
    try {
        const result = yield call(httpChatChannelMemberManagementMemberInviteDelete, payload);
        if (result.message) {
            yield put(channelMemberManagementMemberInviteDeleteError(result.message));
        } else {
            yield put(channelMemberManagementMemberInviteDeleteSuccess(result.data));
        }
    } catch (error) {
        yield put(channelMemberManagementMemberInviteDeleteError(error));
    }
}

/** handler : channel member management : member invite accept **/
function* handleHttpChatChannelMemberManagementMemberInviteAccept({payload}) {
    try {
        const result = yield call(httpChatChannelMemberManagementMemberInviteAccept, payload);
        if (result.message) {
            yield put(channelMemberManagementMemberInviteAcceptError(result.message));
        } else {
            yield put(channelMemberManagementMemberInviteAcceptSuccess(result.data));
        }
    } catch (error) {
        yield put(channelMemberManagementMemberInviteAcceptError(error));
    }
}

/** handler : channel member management : member leave **/
function* handleHttpChatChannelMemberManagementMemberLeave({payload}) {
    try {
        const result = yield call(httpChatChannelMemberManagementMemberLeave, payload);
        if (result.message) {
            yield put(channelMemberManagementMemberLeaveError(result.message));
        } else {
            yield put(channelMemberManagementMemberLeaveSuccess(result.data));
        }
    } catch (error) {
        yield put(channelMemberManagementMemberLeaveError(error));
    }
}

/** handler : channel member management : member block notifications **/
function* handleHttpChatChannelMemberManagementMemberBlockNotifications({payload}) {
    try {
        const result = yield call(httpChatChannelMemberManagementMemberBlockNotifications, payload);
        if (result.message) {
            yield put(channelMemberManagementMemberBlockNotificationsError(result.message));
        } else {
            yield put(channelMemberManagementMemberBlockNotificationsSuccess(result.data));
        }
    } catch (error) {
        yield put(channelMemberManagementMemberBlockNotificationsError(error));
    }
}

/** handler : channel member management : member unblock notifications **/
function* handleHttpChatChannelMemberManagementMemberUnblockNotifications({payload}) {
    try {
        const result = yield call(httpChatChannelMemberManagementMemberUnblockNotifications, payload);
        if (result.message) {
            yield put(channelMemberManagementMemberUnblockNotificationsError(result.message));
        } else {
            yield put(channelMemberManagementMemberUnblockNotificationsSuccess(result.data));
        }
    } catch (error) {
        yield put(channelMemberManagementMemberUnblockNotificationsError(error));
    }
}

/** handler : channel member management : member ban **/
function* handleHttpChatChannelMemberManagementMemberBan({payload}) {
    try {
        const result = yield call(httpChatChannelMemberManagementMemberBan, payload);
        if (result.message) {
            yield put(channelMemberManagementMemberBanError(result.message));
        } else {
            yield put(channelMemberManagementMemberBanSuccess(result.data));
        }
    } catch (error) {
        yield put(channelMemberManagementMemberBanError(error));
    }
}

/** handler : channel member management : member change role **/
function* handleHttpChatChannelMemberManagementMemberChangeRole({payload}) {
    try {
        const result = yield call(httpChatChannelMemberManagementMemberChangeRole, payload);
        if (result.message) {
            yield put(channelMemberManagementMemberChangeRoleError(result.message));
        } else {
            yield put(channelMemberManagementMemberChangeRoleSuccess(result.data));
        }
    } catch (error) {
        yield put(channelMemberManagementMemberChangeRoleError(error));
    }
}

/** handler : channel member management : member list **/
function* handleHttpChatChannelMemberManagementMemberList({payload}) {
    try {
        const result = yield call(httpChatChannelMemberManagementMemberList, payload);
        if (result.message) {
            yield put(channelMemberManagementMemberListError(result.message));
        } else {
            yield put(channelMemberManagementMemberListSuccess(result.data));
        }
    } catch (error) {
        yield put(channelMemberManagementMemberListError(error));
    }
}


/** watcher : channel member management : member add **/
export function* chatChannelMemberManagementMemberAdd() {
    yield takeEvery(CHANNEL_MEMBER_MANAGEMENT_MEMBER_ADD, handleHttpChatChannelMemberManagementMemberAdd);
}

/** watcher : channel member management : member invite **/
export function* chatChannelMemberManagementMemberInvite() {
    yield takeEvery(CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE, handleHttpChatChannelMemberManagementMemberInvite);
}

/** watcher : channel member management : member invite delete **/
export function* chatChannelMemberManagementMemberInviteDelete() {
    yield takeEvery(CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_DELETE, handleHttpChatChannelMemberManagementMemberInviteDelete);
}

/** watcher : channel member management : member invite accept **/
export function* chatChannelMemberManagementMemberInviteAccept() {
    yield takeEvery(CHANNEL_MEMBER_MANAGEMENT_MEMBER_INVITE_ACCEPT, handleHttpChatChannelMemberManagementMemberInviteAccept);
}

/** watcher : channel member management : member leave **/
export function* chatChannelMemberManagementMemberLeave() {
    yield takeEvery(CHANNEL_MEMBER_MANAGEMENT_MEMBER_LEAVE, handleHttpChatChannelMemberManagementMemberLeave);
}

/** watcher : channel member management : member block notifications **/
export function* chatChannelMemberManagementMemberBlockNotifications() {
    yield takeEvery(CHANNEL_MEMBER_MANAGEMENT_MEMBER_BLOCK_NOTIFICATIONS, handleHttpChatChannelMemberManagementMemberBlockNotifications);
}

/** watcher : channel member management : member unblock notifications **/
export function* chatChannelMemberManagementMemberUnblockNotifications() {
    yield takeEvery(CHANNEL_MEMBER_MANAGEMENT_MEMBER_UNBLOCK_NOTIFICATIONS, handleHttpChatChannelMemberManagementMemberUnblockNotifications);
}


/** watcher : channel member management : member unblock notifications **/
export function* chatChannelMemberManagementMemberBan() {
    yield takeEvery(CHANNEL_MEMBER_MANAGEMENT_MEMBER_BAN, handleHttpChatChannelMemberManagementMemberBan);
}

/** watcher : channel member management : member change role **/
export function* chatChannelMemberManagementMemberChangeRole() {
    yield takeEvery(CHANNEL_MEMBER_MANAGEMENT_MEMBER_CHANGE_ROLE, handleHttpChatChannelMemberManagementMemberChangeRole);
}

/** watcher : channel member management : member list **/
export function* chatChannelMemberManagementMemberList() {
    yield takeEvery(CHANNEL_MEMBER_MANAGEMENT_MEMBER_LIST, handleHttpChatChannelMemberManagementMemberList);
}


export default function* rootSaga() {
    yield all([
        fork(chatChannelMemberManagementMemberAdd),
        fork(chatChannelMemberManagementMemberInvite),
        fork(chatChannelMemberManagementMemberInviteDelete),
        fork(chatChannelMemberManagementMemberInviteAccept),
        fork(chatChannelMemberManagementMemberLeave),
        fork(chatChannelMemberManagementMemberBlockNotifications),
        fork(chatChannelMemberManagementMemberUnblockNotifications),
        fork(chatChannelMemberManagementMemberBan),
        fork(chatChannelMemberManagementMemberChangeRole),
        fork(chatChannelMemberManagementMemberList)
    ])
}
