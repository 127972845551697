import {all} from "redux-saga/effects";
import authSagas from "./Auth";
import notesSagas from "./Notes";
import sagaChat from "./chat/sagaChat";
import sagaChatDeviceManagement from "./chat/sagaChatDeviceManagement";
import sagaChatPersonalMessages from "./chat/sagaChatPersonalMessages";
import sagaChatGroupMessages from "./chat/sagaChatGroupMessages";
import sagaChatChannelManagement from "./chat/sagaChatChannelManagement";
import sagaChatChannelMemberManagement from "./chat/sagaChatChannelMemberManagement";
import sagaChatSubscriptionManagement from "./chat/sagaChatSubscriptionManagement";
import sagaChatUserManagement from "./chat/sagaChatUserManagement";
import sagaChatMisc from "./chat/sagaChatMisc";
import sagaChatTags from "./chat/sagaChatTags";
import sagaChatRelay from "./chat/sagaChatRelay";
import sagaUserSettings from "./user_settings/sagaUserSettings";
import sagaClients from "./clients/sagaClients";
import sagaUsers from "./users/sagaUsers";
import sagaUserPrivateRoom from "./private_room/sagaUserPrivateRoom";


export default function* rootSaga(getState) {
    yield all([
        authSagas(),
        notesSagas(),
        sagaChat(),
        sagaChatDeviceManagement(),
        sagaChatPersonalMessages(),
        sagaChatGroupMessages(),
        sagaChatChannelManagement(),
        sagaChatChannelMemberManagement(),
        sagaChatSubscriptionManagement(),
        sagaChatUserManagement(),
        sagaChatMisc(),
        sagaChatTags(),
        sagaChatRelay(),
        sagaUserSettings(),
        sagaClients(),
        sagaUsers(),
        sagaUserPrivateRoom()
    ]);
}
