import {HTTP_REQUEST_STATUS} from "../../../util/tribe/constants/config";
import UIUtils from "../../../util/tribe/utils/uiUtils";
import IntlMessages from "../../../util/IntlMessages";

import {
    USERS_DEACTIVATE_USER,
    USERS_DEACTIVATE_USER_ERROR,
    USERS_DEACTIVATE_USER_NONE,
    USERS_DEACTIVATE_USER_SUCCESS,
    USERS_FILTER_USERS,
    USERS_GET_ALL_USERS,
    USERS_GET_ALL_USERS_ERROR,
    USERS_GET_ALL_USERS_NONE,
    USERS_GET_ALL_USERS_SUCCESS,
    USERS_SAVE_USER,
    USERS_SAVE_USER_ERROR,
    USERS_SAVE_USER_NONE,
    USERS_SAVE_USER_SUCCESS,
    USERS_SET_CURRENT_USER
} from "../../../constants/actions/users/actionTypesUsers";

export const TABLE_PAGINATION_OPTIONS = [10, 20, 50, 100, 150, 200];

const usersListFilterConfig = [
    /*{
        'key': 'users_select_actions',
        'type': UIUtils.UI_COMPONENT_TYPES.MENU_POPOVER,
        'localisation': "DIC_KEY_WC_TABLE_FILTER_ACTIONS",
        'parent_class': "table_filter_action_dropdown float_right "
    },*/
    {
        'key': 'users_search_input',
        'type': UIUtils.UI_COMPONENT_TYPES.SEARCH_INPUT,
        'localisation': "",
        'isStar': false,
        'isMandatory': false
    },
    {
        'key': 'users_status_dropdown',
        'type': UIUtils.UI_COMPONENT_TYPES.MENU_POPOVER,
        'isValueTitle': true,
        'localisation': "",
        'isStar': false,
        'isMandatory': false,
        'parent_class': "table_search_input_dropdown"
    }
];

const usersViewSubHeaderConfig = [
    {
        'key': 'users_view_action_save',
        'type': UIUtils.UI_COMPONENT_TYPES.BUTTON_STATIC_POSITIVE,
        'title': <IntlMessages id="DIC_KEY_USERS_VIEW_ACTION_LABEL_SAVE"/>,
        'parent_class': "float_right "
    },
    {
        'key': 'users_view_action_deactivate',
        'type': UIUtils.UI_COMPONENT_TYPES.BUTTON_STATIC_NEGATIVE,
        'title': <IntlMessages id="DIC_KEY_USERS_VIEW_ACTION_LABEL_DEACTIVATE"/>,
        'parent_class': "float_right gx-mr-2"
    },
    {
        'key': 'users_view_action_header',
        'type': UIUtils.UI_COMPONENT_TYPES.LABEL_CONTAINER,
        'title': <IntlMessages id="DIC_KEY_USERS_VIEW_ACTION_LABEL_DETAIL_INFORMATION"/>
    }
];

const usersViewInformationConfig = [
    {
        'key': 'selected_client',
        'type': UIUtils.UI_COMPONENT_TYPES.HORIZONTAL_LABEL_DROPDOWN,
        'style': {},
        'labelClass': "gx-pl-2",
        'localisation': "DIC_KEY_USERS_VIEW_USER_CLIENT",
        'isStar': true,
        'isMandatory': true,
        'parent_class': "form_container_root",
        'placeholderKey': "DIC_KEY_USERS_VIEW_USER_CLIENT_PLACEHOLDER",
        'variant': "outlined"
    },
    {
        'key': 'first_name',
        'type': UIUtils.UI_COMPONENT_TYPES.HORIZONTAL_LABEL_INPUT,
        'style': {},
        'labelClass': "gx-pl-2",
        'localisation': "DIC_KEY_USERS_VIEW_USER_FIRST_NAME",
        'isStar': true,
        'isMandatory': true,
        'parent_class': "form_container_root form_container_root_next_dropdown",
        'placeholderKey': "DIC_KEY_USERS_VIEW_USER_FIRST_NAME_PLACEHOLDER",
        'variant': "outlined"
    },
    {
        'key': 'last_name',
        'type': UIUtils.UI_COMPONENT_TYPES.HORIZONTAL_LABEL_INPUT,
        'style': {},
        'labelClass': "gx-pl-2",
        'localisation': "DIC_KEY_USERS_VIEW_USER_LAST_NAME",
        'isStar': true,
        'isMandatory': false,
        'parent_class': "form_container_root",
        'placeholderKey': "DIC_KEY_USERS_VIEW_USER_LAST_NAME_PLACEHOLDER",
        'variant': "outlined"
    },
    {
        'key': 'email',
        'type': UIUtils.UI_COMPONENT_TYPES.HORIZONTAL_LABEL_INPUT,
        'style': {},
        'labelClass': "gx-pl-2",
        'localisation': "DIC_KEY_USERS_VIEW_USER_EMAIL",
        'isStar': true,
        'isMandatory': false,
        'parent_class': "form_container_root",
        'placeholderKey': "DIC_KEY_USERS_VIEW_USER_EMAIL_PLACEHOLDER",
        'variant': "outlined"
    }
];

const usersTableHeaderConfig = [
    /*{
        id: 'list_checkbox',
        label: <div className="info_round_checkbox table_header_checkbox_icon"/>,
        visible: true,
        sortable: false,
        isDynamicComponent: true
    },*/
    {
        id: 'first_name',
        label: <IntlMessages id="DIC_KEY_USERS_TABLE_HEADER_FIRST_NAME"/>,
        visible: true,
        sortable: true,
        isClickable: true,
        className: "table_column_text_highlight gx-pl-4"
    },
    {
        id: 'last_name',
        label: <IntlMessages id="DIC_KEY_USERS_TABLE_HEADER_LAST_NAME"/>,
        visible: true,
        sortable: true,
        isClickable: true,
        className: "table_column_text_normal"
    },
    {
        id: 'email',
        label: <IntlMessages id="DIC_KEY_USERS_TABLE_HEADER_EMAIL"/>,
        visible: true,
        sortable: true,
        isClickable: true,
        className: "table_column_text_normal"
    },
    {
        id: 'status',
        label: <IntlMessages id="DIC_KEY_USERS_TABLE_HEADER_STATUS"/>,
        visible: true,
        sortable: true,
        isClickable: true,
        className: "table_column_text_normal"
    }
];

const INIT_STATE = {
    usersTableHeaderConfig: usersTableHeaderConfig,
    usersListFilterConfig: usersListFilterConfig,
    TABLE_PAGINATION_OPTIONS: TABLE_PAGINATION_OPTIONS,
    usersViewSubHeaderConfig: usersViewSubHeaderConfig,
    usersViewInformationConfig: usersViewInformationConfig,
    
    usersListGetStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    userSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    userDeactivateStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    
    alertMessage: "",
    usersAllList: [],
    usersList: [],
    selectedUser: {}
    
};


const ReducerUsers = (state = INIT_STATE, action) => {
    
    switch (action.type) {
        
        case USERS_FILTER_USERS: {
            const {usersAllList} = state;
            let usersList = [];
            
            for (let i = 0; i < usersAllList.length; i++) {
                if (usersAllList[i]["first_name"].toUpperCase().indexOf(action.payload.name.toUpperCase()) === -1 && usersAllList[i]["last_name"].toUpperCase().indexOf(action.payload.name.toUpperCase()) === -1) {
                    continue;
                }
                
                usersList.push(usersAllList[i]);
            }
            
            return {
                ...state,
                usersList: usersList
            }
        }
        
        /** user deactivate **/
        case USERS_DEACTIVATE_USER: {
            return {
                ...state,
                alertMessage: "",
                userDeactivateStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
            }
        }
        
        /** user deactivate : success **/
        case USERS_DEACTIVATE_USER_SUCCESS: {
            return {
                ...state,
                alertMessage: action.payload.message,
                userDeactivateStatus: HTTP_REQUEST_STATUS.SUCCESS,
                selectedUser: action.payload.data
            }
        }
        
        /** user deactivate : error **/
        case USERS_DEACTIVATE_USER_ERROR: {
            return {
                ...state,
                alertMessage: action.payload.message,
                userDeactivateStatus: HTTP_REQUEST_STATUS.ERROR
            }
        }
        
        /** user deactivate : reset **/
        case USERS_DEACTIVATE_USER_NONE: {
            return {
                ...state,
                alertMessage: "",
                userDeactivateStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }
        
        
        /** user save **/
        case USERS_SAVE_USER: {
            return {
                ...state,
                alertMessage: "",
                userSaveStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
            }
        }
        
        /** user save : success **/
        case USERS_SAVE_USER_SUCCESS: {
            return {
                ...state,
                alertMessage: action.payload.message,
                userSaveStatus: HTTP_REQUEST_STATUS.SUCCESS,
                selectedUser: action.payload.data
            }
        }
        
        /** user save : error **/
        case USERS_SAVE_USER_ERROR: {
            return {
                ...state,
                alertMessage: action.payload.message,
                userSaveStatus: HTTP_REQUEST_STATUS.ERROR
            }
        }
        
        /** user save : reset **/
        case USERS_SAVE_USER_NONE: {
            return {
                ...state,
                alertMessage: "",
                userSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }
        
        /** client list **/
        case USERS_GET_ALL_USERS: {
            return {
                ...state,
                usersListGetStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
            }
        }
        
        /** client list : success **/
        case USERS_GET_ALL_USERS_SUCCESS: {
            
            let usersList = [];
            let usersAllList = [];
            
            if (typeof action.payload.data != "undefined") {
                usersList = parseUsersList(action.payload.data);
                usersAllList = JSON.parse(JSON.stringify(usersList));
            }
            
            
            return {
                ...state,
                usersList: usersList,
                usersAllList: usersAllList,
                usersListGetStatus: HTTP_REQUEST_STATUS.SUCCESS
            }
        }
        
        /** client list : error **/
        case USERS_GET_ALL_USERS_ERROR: {
            return {
                ...state,
                usersListGetStatus: HTTP_REQUEST_STATUS.ERROR
            }
        }
        
        /** client list : reset **/
        case USERS_GET_ALL_USERS_NONE: {
            return {
                ...state,
                usersListGetStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }
        
        /** to set current user **/
        case USERS_SET_CURRENT_USER: {
            return {
                ...state,
                selectedUser: action.payload
            }
        }
        
        default: {
            return {
                ...state
            }
        }
        
        
    }
    
}

function parseUsersList(data) {
    let result = data;
    
    try {
        
        for (let i = 0; i < data.length; i++) {
            data[i]["status"] = data[i]["is_active"] ? "Active" : "Inactive";
        }
        
        
    } catch (ex) {
        console.error("parseUsersList : error : ", ex);
    }
    
    return result;
}


export default ReducerUsers;
