import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Avatar, Popover} from "antd";
import {userSignOut} from "appRedux/actions/Auth";
import IntlMessages from "../../util/IntlMessages";
import ReducerUserStatus from "../../appRedux/reducers/chat/reducerUserStatus";
import {useHistory} from "react-router-dom";
import {APP_NAVIGATION_PATHS} from "../../util/tribe/constants/config";
import UIUtils from "../../util/tribe/utils/uiUtils";

const UserInfo = () => {

    const dispatch = useDispatch();
    const userDetails = useSelector(({ReducerChat}) => ReducerChat.userDetails);
    const optionalData = useSelector(({ReducerUserStatus}) => ReducerUserStatus.optionalData);
    const authUser = useSelector(({auth}) => auth.authUser);
    const clientFeatures = useSelector(({auth}) => auth.clientFeatures);
    const history = useHistory();
    const [isLogoutPopupVisible, setIsLogoutPopupVisible] = useState(false);

    let userName = (typeof authUser.first_name != "undefined" ? authUser.first_name : "") + " " + (typeof authUser.last_name != "undefined" ? authUser.last_name : "");

    if (typeof authUser["optional_data"] != "undefined" && typeof authUser["optional_data"]["display_name"] != "undefined" && authUser["optional_data"]["display_name"] !== "") {
        userName = authUser["optional_data"]["display_name"];
    }

    let logoutUser = function () {
        try {
            setIsLogoutPopupVisible(true);
            //dispatch(userSignOut())
        } catch (ex) {

        }
    };

    let navigateToProfilePage = function () {
        try {
            history.push(APP_NAVIGATION_PATHS.MY_ACCOUNT);
        } catch (ex) {
        }
    };

    let navigateToPrivateRoomDetails = function () {
        try {
            history.push(APP_NAVIGATION_PATHS.PRIVATE_ROOM);
        } catch (ex) {
        }
    };

    let currentStatus = "away";

    if (typeof userDetails[authUser.id] != "undefined" && typeof userDetails[authUser.id]["statusClass"] != "undefined") {
        currentStatus = userDetails[authUser.id]["statusClass"];
    }

    const userMenuOptions = (
        <ul className="gx-user-popover">
            <li onClick={navigateToProfilePage}><IntlMessages id="DIC_KEY_USER_MY_ACCOUNT"/></li>
            { typeof clientFeatures != "undefined" && clientFeatures["is_private_room_enabled"] && <li onClick={navigateToPrivateRoomDetails}><IntlMessages id="DIC_KEY_USER_PRIVATE_ROOM"/></li>}
            <li onClick={logoutUser}><IntlMessages id="DIC_KEY_USER_LOGOUT"/>
            </li>
        </ul>
    );

    const renderPopupContent = function () {
        return (
            <IntlMessages id="DIC_KEY_USER_LOGOUT_POPUP_CONTENT"/>
        )
    };

    const renderLogOutConfirmation = function () {

        return (
            UIUtils.getUIComponent(
                {
                    'key': "delete_chat",
                    'type': UIUtils.UI_COMPONENT_TYPES.TRIBE_POPUP,
                    'title': <IntlMessages id="DIC_KEY_USER_LOGOUT_POPUP_TITLE"/>,
                    'isVisible': isLogoutPopupVisible,
                    'callbackCancel': () => setIsLogoutPopupVisible(false),
                    'callbackOk': () => dispatch(userSignOut()),
                    'cancelLabel': "DIC_KEY_USER_LOGOUT_POPUP_BUTTON_CANCEL",
                    'okLabel': "DIC_KEY_USER_LOGOUT_POPUP_BUTTON_LOGOUT",
                    'content': renderPopupContent
                }
            )
        )
    };

    return (

        <>
            <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions} trigger="click">

                <div className="tribe_header_logo_container gx-pointer">
                    <div className="tribe_header_user_name">
                        {userName}
                    </div>

                    <div className="gx-chat-avatar">
                        <div className="gx-status-pos gx-mt-1">
                            <Avatar src={optionalData && optionalData["profile_image"] ? optionalData["profile_image"] : ""} className="gx-avatar gx-pointer gx-size-50" alt={""}/>
                            <span className={`gx-status gx-small gx-${currentStatus}`}/>
                        </div>
                    </div>
                </div>
            </Popover>

            {isLogoutPopupVisible && renderLogOutConfirmation()}

        </>
    );
};

export default UserInfo;
