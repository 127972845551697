import {SIGNIN_USER_SUCCESS, SWITCH_LANGUAGE} from "../../constants/ActionTypes";
import {
    LAYOUT_TYPE,
    LAYOUT_TYPE_FULL,
    NAV_STYLE,
    NAV_STYLE_FIXED,
    NAV_STYLE_MINI_SIDEBAR,
    THEME_COLOR,
    THEME_TYPE,
    THEME_TYPE_LITE,
    THEME_TYPE_SEMI_DARK,
    UPDATE_RTL_STATUS
} from "../../constants/ThemeSetting";
import EnLang from "../../lngProvider/entries/en-US";
import AppUtils from "../../util/tribe/utils/appUtils";
import {DEFAULT_LANGUAGE_CODE} from "../../util/tribe/constants/config";


const initialSettings = {
    navStyle: NAV_STYLE_MINI_SIDEBAR,
    layoutType: LAYOUT_TYPE_FULL,
    themeType: THEME_TYPE_LITE,
    themeColor: 'deep_orange',
    versionName: "22.3.1",
    isDirectionRTL: false,
    locale: {
        languageId: 'english',
        locale: 'en',
        name: 'English',
        icon: 'us'
    },
    appTranslations: {}
};

const SettingsReducer = (state = initialSettings, action) => {
    switch (action.type) {
        
        case SIGNIN_USER_SUCCESS: {
            
            let translations = {};
            translations[DEFAULT_LANGUAGE_CODE] = {
                "data": EnLang.messages
            };
            
            AppUtils.setCurrentTranslations(translations, state.locale.locale);
            
            return {
                ...state
            }
        }
        
        case THEME_TYPE:
            return {
                ...state,
                themeType: action.themeType
            };
        case THEME_COLOR:
            return {
                ...state,
                themeColor: action.themeColor
            };
        
        case UPDATE_RTL_STATUS:
            return {
                ...state,
                isDirectionRTL: action.rtlStatus
            };
        
        case NAV_STYLE:
            return {
                ...state,
                navStyle: action.navStyle
            };
        case LAYOUT_TYPE:
            return {
                ...state,
                layoutType: action.layoutType
            };
        
        case SWITCH_LANGUAGE:
            return {
                ...state,
                locale: action.payload,
                
            };
        default:
            return state;
    }
};

export default SettingsReducer;
