import antdES from "antd/lib/locale-provider/es_ES";
import saMessages from "../locales/es_ES.json";

const saLang = {
    messages: {
        ...saMessages
    },
    antd: antdES,
    locale: 'es',
};
export default saLang;
