import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import axios from "axios";
import {
    URL_CHAT_GROUP_MESSAGES_CLEAR_FOR_USER,
    URL_CHAT_GROUP_MESSAGES_DELETE,
    URL_CHAT_GROUP_MESSAGES_DELETE_FOR_USER,
    URL_CHAT_GROUP_MESSAGES_DELIVERED,
    URL_CHAT_GROUP_MESSAGES_EDIT,
    URL_CHAT_GROUP_MESSAGES_FILTER_MESSAGES_BY_FIELD,
    URL_CHAT_GROUP_MESSAGES_FORWARD,
    URL_CHAT_GROUP_MESSAGES_GET_COMMON_CHANNELS_BETWEEN_USERS,
    URL_CHAT_GROUP_MESSAGES_GET_MESSAGE_HISTORY,
    URL_CHAT_GROUP_MESSAGES_LIST,
    URL_CHAT_GROUP_MESSAGES_REACT_ON_MESSAGE,
    URL_CHAT_GROUP_MESSAGES_READ,
    URL_CHAT_GROUP_MESSAGES_READ_ALL,
    URL_CHAT_GROUP_MESSAGES_SEND,
    URL_CHAT_GROUP_MESSAGES_UNREAD,
} from "../../../util/tribe/urls";
import {
    GROUP_MESSAGES_CLEAR_MESSAGES_FOR_USER,
    GROUP_MESSAGES_DELETE_MESSAGES_FOR_USER,
    GROUP_MESSAGES_EDIT_MESSAGE,
    GROUP_MESSAGES_FILTER_MESSAGES_BY_FIELD,
    GROUP_MESSAGES_GET_COMMON_CHANNELS_BETWEEN_USERS,
    GROUP_MESSAGES_GET_MESSAGE_HISTORY,
    GROUP_MESSAGES_MARK_MESSAGE_AS_DELIVERED,
    GROUP_MESSAGES_MARK_MESSAGE_AS_UNREAD,
    GROUP_MESSAGES_MESSAGE_DELETE,
    GROUP_MESSAGES_MESSAGE_FORWARD,
    GROUP_MESSAGES_MESSAGE_LIST,
    GROUP_MESSAGES_MESSAGE_READ,
    GROUP_MESSAGES_MESSAGE_READ_ALL,
    GROUP_MESSAGES_MESSAGE_SEND,
    GROUP_MESSAGES_REACT_ON_MESSAGE
} from "../../../constants/actions/chat/ChatActionTypes";
import AppUtils from "../../../util/tribe/utils/appUtils";
import {
    groupMessagesClearMessagesForUserError,
    groupMessagesClearMessagesForUserSuccess,
    groupMessagesDeleteMessagesForUserError,
    groupMessagesDeleteMessagesForUserSuccess,
    groupMessagesEditMessageError,
    groupMessagesEditMessageSuccess,
    groupMessagesFilterMessageByFieldError,
    groupMessagesFilterMessageByFieldSuccess,
    groupMessagesGetCommonChannelsBetweenUsers,
    groupMessagesGetCommonChannelsBetweenUsersError,
    groupMessagesGetCommonChannelsBetweenUsersSuccess,
    groupMessagesGetMessageHistoryError,
    groupMessagesGetMessageHistorySuccess,
    groupMessagesMarkAsDeliveredError,
    groupMessagesMarkAsDeliveredSuccess,
    groupMessagesMarkAsUnreadError,
    groupMessagesMarkAsUnreadSuccess,
    groupMessagesMessageDeleteError,
    groupMessagesMessageDeleteSuccess,
    groupMessagesMessageForwardError,
    groupMessagesMessageForwardSuccess,
    groupMessagesMessageListError,
    groupMessagesMessageListSuccess,
    groupMessagesMessageReadAllError,
    groupMessagesMessageReadAllSuccess,
    groupMessagesMessageReadError,
    groupMessagesMessageReadSuccess,
    groupMessagesMessageSendError,
    groupMessagesMessageSendSuccess,
    groupMessagesReactOnMessageError,
    groupMessagesReactOnMessageSuccess
} from "../../actions";

/** http : group messages : message send **/
const httpChatGroupMessagesMessageSend = async (data) =>
    await axios.post(URL_CHAT_GROUP_MESSAGES_SEND, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : group messages : message delete **/
const httpChatGroupMessagesMessageDelete = async (data) =>
    await axios.post(URL_CHAT_GROUP_MESSAGES_DELETE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : group messages : message read **/
const httpChatGroupMessagesMessageRead = async (data) =>
    await axios.post(URL_CHAT_GROUP_MESSAGES_READ, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : group messages : message list **/
const httpChatGroupMessagesMessageList = async (data) =>
    await axios.post(URL_CHAT_GROUP_MESSAGES_LIST, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : group messages : message read all **/
const httpChatGroupMessagesMessageReadAll = async (data) =>
    await axios.post(URL_CHAT_GROUP_MESSAGES_READ_ALL, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : group messages : edit message **/
const httpChatGroupMessagesEditMessage = async (data) =>
    await axios.post(URL_CHAT_GROUP_MESSAGES_EDIT, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : group messages : mark message as unread **/
const httpChatGroupMessagesMarkMessageAsUnread = async (data) =>
    await axios.post(URL_CHAT_GROUP_MESSAGES_UNREAD, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : group messages : mark message as delivered **/
const httpChatGroupMessagesMarkMessageAsDelivered = async (data) =>
    await axios.post(URL_CHAT_GROUP_MESSAGES_DELIVERED, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : group messages : delete all messages for user **/
const httpChatGroupMessagesDeleteAllMessages = async (data) =>
    await axios.post(URL_CHAT_GROUP_MESSAGES_DELETE_FOR_USER, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : group messages : clear all messages for user **/
const httpChatGroupMessagesClearAllMessages = async (data) =>
    await axios.post(URL_CHAT_GROUP_MESSAGES_CLEAR_FOR_USER, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : group messages : to react on message **/
const httpChatGroupMessagesReactOnMessage = async (data) =>
    await axios.post(URL_CHAT_GROUP_MESSAGES_REACT_ON_MESSAGE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : group messages : to get the reply message history **/
const httpChatGroupMessagesGetHistory = async (data) =>
    await axios.post(URL_CHAT_GROUP_MESSAGES_GET_MESSAGE_HISTORY, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : group messages : to filter messages by message field **/
const httpChatGroupMessagesFilterMessagesByField = async (data) =>
    await axios.post(URL_CHAT_GROUP_MESSAGES_FILTER_MESSAGES_BY_FIELD, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : group messages : get common channels between users **/
const httpChatGroupMessagesGetCommonChannelsBetweenUsers = async (data) =>
    await axios.post(URL_CHAT_GROUP_MESSAGES_GET_COMMON_CHANNELS_BETWEEN_USERS, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : group messages : messages forward **/
const httpChatGroupMessagesMessagesForward = async (data) =>
    await axios.post(URL_CHAT_GROUP_MESSAGES_FORWARD, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });


/** handler : group messages : message send **/
function* handleHttpChatGroupMessagesMessageSend({payload}) {
    try {
        const result = yield call(httpChatGroupMessagesMessageSend, payload);
        if (result.message) {
            yield put(groupMessagesMessageSendError(result.message));
        } else {
            yield put(groupMessagesMessageSendSuccess(result.data));
        }
    } catch (error) {
        yield put(groupMessagesMessageSendError(error));
    }
}

/** handler : group messages : message delete **/
function* handleHttpChatGroupMessagesMessageDelete({payload}) {
    try {
        const result = yield call(httpChatGroupMessagesMessageDelete, payload);
        if (result.message) {
            yield put(groupMessagesMessageDeleteError(result.message));
        } else {
            yield put(groupMessagesMessageDeleteSuccess(result.data));
        }
    } catch (error) {
        yield put(groupMessagesMessageDeleteError(error));
    }
}

/** handler : group messages : message read **/
function* handleHttpChatGroupMessagesMessageRead({payload}) {
    try {
        const result = yield call(httpChatGroupMessagesMessageRead, payload);
        if (result.message) {
            yield put(groupMessagesMessageReadError(result.message));
        } else {
            yield put(groupMessagesMessageReadSuccess(result.data));
        }
    } catch (error) {
        yield put(groupMessagesMessageReadError(error));
    }
}

/** handler : group messages : message list **/
function* handleHttpChatGroupMessagesMessageList({payload}) {
    try {
        const result = yield call(httpChatGroupMessagesMessageList, payload);
        if (result.message) {
            yield put(groupMessagesMessageListError(result.message));
        } else {
            yield put(groupMessagesMessageListSuccess(result.data));
        }
    } catch (error) {
        yield put(groupMessagesMessageListError(error));
    }
}

/** handler : group messages : message read all **/
function* handleHttpChatGroupMessagesMessageReadAll({payload}) {
    try {
        const result = yield call(httpChatGroupMessagesMessageReadAll, payload);
        if (result.message) {
            yield put(groupMessagesMessageReadAllError(result.message));
        } else {
            yield put(groupMessagesMessageReadAllSuccess(result.data));
        }
    } catch (error) {
        yield put(groupMessagesMessageReadAllError(error));
    }
}

/** handler : group messages : message edit **/
function* handleHttpChatGroupMessagesEditMessage({payload}) {
    try {
        const result = yield call(httpChatGroupMessagesEditMessage, payload);
        if (result.message) {
            yield put(groupMessagesEditMessageError(result.message));
        } else {
            yield put(groupMessagesEditMessageSuccess(result.data));
        }
    } catch (error) {
        yield put(groupMessagesEditMessageError(error));
    }
}

/** handler : group messages : mark message as unread **/
function* handleHttpChatGroupMessagesMarkMessageAsUnread({payload}) {
    try {
        const result = yield call(httpChatGroupMessagesMarkMessageAsUnread, payload);
        if (result.message) {
            yield put(groupMessagesMarkAsUnreadError(result.message));
        } else {
            yield put(groupMessagesMarkAsUnreadSuccess(result.data));
        }
    } catch (error) {
        yield put(groupMessagesMarkAsUnreadError(error));
    }
}

/** handler : group messages : mark message as delivered **/
function* handleHttpChatGroupMessagesMarkMessageAsDelivered({payload}) {
    try {
        const result = yield call(httpChatGroupMessagesMarkMessageAsDelivered, payload);
        if (result.message) {
            yield put(groupMessagesMarkAsDeliveredError(result.message));
        } else {
            yield put(groupMessagesMarkAsDeliveredSuccess(result.data));
        }
    } catch (error) {
        yield put(groupMessagesMarkAsDeliveredError(error));
    }
}

/** handler : group messages : delete all messages for user **/
function* handleHttpChatGroupMessagesDeleteAllMessages({payload}) {
    try {
        const result = yield call(httpChatGroupMessagesDeleteAllMessages, payload);
        if (result.message) {
            yield put(groupMessagesDeleteMessagesForUserError(result.message));
        } else {
            yield put(groupMessagesDeleteMessagesForUserSuccess(result.data));
        }
    } catch (error) {
        yield put(groupMessagesDeleteMessagesForUserError(error));
    }
}

/** handler : group messages : clear all messages for user **/
function* handleHttpChatGroupMessagesClearAllMessages({payload}) {
    try {
        const result = yield call(httpChatGroupMessagesClearAllMessages, payload);
        if (result.message) {
            yield put(groupMessagesClearMessagesForUserError(result.message));
        } else {
            yield put(groupMessagesClearMessagesForUserSuccess(result.data));
        }
    } catch (error) {
        yield put(groupMessagesClearMessagesForUserError(error));
    }
}

/** handler : group messages : to react on message **/
function* handleHttpChatGroupMessagesReactOnMessage({payload}) {
    try {
        const result = yield call(httpChatGroupMessagesReactOnMessage, payload);
        if (result.message) {
            yield put(groupMessagesReactOnMessageError(result.message));
        } else {
            yield put(groupMessagesReactOnMessageSuccess(result.data));
        }
    } catch (error) {
        yield put(groupMessagesReactOnMessageError(error));
    }
}

/** handler : group messages : to get the reply message history **/
function* handleHttpChatGroupMessagesGetHistory({payload}) {
    try {
        const result = yield call(httpChatGroupMessagesGetHistory, payload);
        if (result.message) {
            yield put(groupMessagesGetMessageHistoryError(result.message));
        } else {
            yield put(groupMessagesGetMessageHistorySuccess(result.data));
        }
    } catch (error) {
        yield put(groupMessagesGetMessageHistoryError(error));
    }
}

/** handler : group messages : to filter messages by message field **/
function* handleHttpChatGroupMessagesFilterMessagesByField({payload}) {
    try {
        const result = yield call(httpChatGroupMessagesFilterMessagesByField, payload);
        if (result.message) {
            yield put(groupMessagesFilterMessageByFieldError(result.message));
        } else {
            yield put(groupMessagesFilterMessageByFieldSuccess(result.data));
        }
    } catch (error) {
        yield put(groupMessagesFilterMessageByFieldError(error));
    }
}

/** handler : group messages : get common channels between users **/
function* handleHttpChatGroupMessagesGetCommonChannelsBetweenUsers({payload}) {
    try {
        const result = yield call(httpChatGroupMessagesGetCommonChannelsBetweenUsers, payload);
        if (result.message) {
            yield put(groupMessagesGetCommonChannelsBetweenUsersError(result.message));
        } else {
            yield put(groupMessagesGetCommonChannelsBetweenUsersSuccess(result.data));
        }
    } catch (error) {
        yield put(groupMessagesGetCommonChannelsBetweenUsersError(error));
    }
}

/** handler : group messages : messages forward **/
function* handleHttpChatGroupMessagesMessagesForward({payload}) {
    try {
        const result = yield call(httpChatGroupMessagesMessagesForward, payload);
        if (result.message) {
            yield put(groupMessagesMessageForwardError(result.message));
        } else {
            yield put(groupMessagesMessageForwardSuccess(result.data));
        }
    } catch (error) {
        yield put(groupMessagesMessageForwardError(error));
    }
}

/** watcher : group messages : message send **/
export function* chatGroupMessagesMessageSend() {
    yield takeEvery(GROUP_MESSAGES_MESSAGE_SEND, handleHttpChatGroupMessagesMessageSend);
}

/** watcher : group messages : message delete **/
export function* chatGroupMessagesMessageDelete() {
    yield takeEvery(GROUP_MESSAGES_MESSAGE_DELETE, handleHttpChatGroupMessagesMessageDelete);
}

/** watcher : group messages : message read **/
export function* chatGroupMessagesMessageRead() {
    yield takeEvery(GROUP_MESSAGES_MESSAGE_READ, handleHttpChatGroupMessagesMessageRead);
}

/** watcher : group messages : message list **/
export function* chatGroupMessagesMessageList() {
    yield takeEvery(GROUP_MESSAGES_MESSAGE_LIST, handleHttpChatGroupMessagesMessageList);
}

/** watcher : group messages : message read all **/
export function* chatGroupMessagesMessageReadAll() {
    yield takeEvery(GROUP_MESSAGES_MESSAGE_READ_ALL, handleHttpChatGroupMessagesMessageReadAll);
}

/** watcher : channel or group messages : edit message **/
export function* chatGroupMessagesEditMessage() {
    yield takeEvery(GROUP_MESSAGES_EDIT_MESSAGE, handleHttpChatGroupMessagesEditMessage);
}

/** watcher : channel or group messages : mark message as unread **/
export function* chatGroupMessagesMarkMessageAsUnread() {
    yield takeEvery(GROUP_MESSAGES_MARK_MESSAGE_AS_UNREAD, handleHttpChatGroupMessagesMarkMessageAsUnread);
}

/** watcher : channel or group messages : mark message as delivered **/
export function* chatGroupMessagesMarkMessageAsDelivered() {
    yield takeEvery(GROUP_MESSAGES_MARK_MESSAGE_AS_DELIVERED, handleHttpChatGroupMessagesMarkMessageAsDelivered);
}

/** watcher : channel or group messages : delete messages **/
export function* chatGroupMessagesDeleteAllMessages() {
    yield takeEvery(GROUP_MESSAGES_DELETE_MESSAGES_FOR_USER, handleHttpChatGroupMessagesDeleteAllMessages);
}

/** watcher : channel or group messages : clear messages **/
export function* chatGroupMessagesClearAllMessages() {
    yield takeEvery(GROUP_MESSAGES_CLEAR_MESSAGES_FOR_USER, handleHttpChatGroupMessagesClearAllMessages);
}

/** watcher : channel or group messages : clear messages **/
export function* chatGroupMessagesReactOnMessage() {
    yield takeEvery(GROUP_MESSAGES_REACT_ON_MESSAGE, handleHttpChatGroupMessagesReactOnMessage);
}

/** watcher : channel or group messages : to get the reply message history **/
export function* chatGroupMessagesGetHistory() {
    yield takeEvery(GROUP_MESSAGES_GET_MESSAGE_HISTORY, handleHttpChatGroupMessagesGetHistory);
}

/** watcher : channel or group messages : to filter messages by message field **/
export function* chatGroupMessagesFilterMessagesByField() {
    yield takeEvery(GROUP_MESSAGES_FILTER_MESSAGES_BY_FIELD, handleHttpChatGroupMessagesFilterMessagesByField);
}

/** watcher : channel or group messages : get common channels between users **/
export function* chatGroupMessagesGetCommonChannelsBetweenUsers() {
    yield takeEvery(GROUP_MESSAGES_GET_COMMON_CHANNELS_BETWEEN_USERS, handleHttpChatGroupMessagesGetCommonChannelsBetweenUsers);
}

/** watcher : channel or group messages : messages forward **/
export function* chatGroupMessagesMessagesForward() {
    yield takeEvery(GROUP_MESSAGES_MESSAGE_FORWARD, handleHttpChatGroupMessagesMessagesForward);
}

export default function* rootSaga() {
    yield all([
        fork(chatGroupMessagesMessageSend),
        fork(chatGroupMessagesMessageDelete),
        fork(chatGroupMessagesMessageRead),
        fork(chatGroupMessagesMessageList),
        fork(chatGroupMessagesMessageReadAll),
        fork(chatGroupMessagesEditMessage),
        fork(chatGroupMessagesMarkMessageAsUnread),
        fork(chatGroupMessagesMarkMessageAsDelivered),
        fork(chatGroupMessagesDeleteAllMessages),
        fork(chatGroupMessagesClearAllMessages),
        fork(chatGroupMessagesReactOnMessage),
        fork(chatGroupMessagesGetHistory),
        fork(chatGroupMessagesFilterMessagesByField),
        fork(chatGroupMessagesGetCommonChannelsBetweenUsers),
        fork(chatGroupMessagesMessagesForward)
    ])
}
