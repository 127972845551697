import {
    HTTP_REQUEST_STATUS, MESSAGE_ROUTER_CHANNEL_TYPES, MSG_ROUTER_EVENTS,
} from "../../../util/tribe/constants/config";
import {
    CHAT_DM_MESSAGE_FILTER_MESSAGES_BY_FIELD,
    CHAT_DM_MESSAGE_FILTER_MESSAGES_BY_FIELD_ERROR,
    CHAT_DM_MESSAGE_FILTER_MESSAGES_BY_FIELD_NONE,
    GROUP_MESSAGES_FILTER_MESSAGES_BY_FIELD,
    GROUP_MESSAGES_FILTER_MESSAGES_BY_FIELD_ERROR,
    GROUP_MESSAGES_FILTER_MESSAGES_BY_FIELD_NONE,
} from "../../../constants/actions/chat/ChatActionTypes";
import ChatParserUtils from "../../../util/tribe/chat_parser/chatParsingUtils";

const INIT_STATE = {
    getChatMessageMediaListStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    
    mediaMessages: {},
    mediaMessagesMap: {},
    mediaMessagesDateMap: {},
    selectedIndexId: "",
    nextIndex: 0
}


const ReducerMessageMediaList = (state = INIT_STATE, action) => {
    
    switch (action.type) {
        
        /** dm : to get media messages **/
        case CHAT_DM_MESSAGE_FILTER_MESSAGES_BY_FIELD:
        /** group : to get media messages  **/
        case GROUP_MESSAGES_FILTER_MESSAGES_BY_FIELD: {
            let mediaMessages = state.mediaMessages;
            let mediaMessagesDateMap = state.mediaMessagesDateMap;
            let mediaMessagesMap = state.mediaMessagesMap;
            
            let selectedIndexId = "";
            
            if (typeof action.payload.params != "undefined") {
                if (action.type === CHAT_DM_MESSAGE_FILTER_MESSAGES_BY_FIELD) {
                    selectedIndexId = ChatParserUtils.generateIndexId(MESSAGE_ROUTER_CHANNEL_TYPES.DM, action.payload.params.tuid);
                } else {
                    selectedIndexId = ChatParserUtils.generateIndexId(MESSAGE_ROUTER_CHANNEL_TYPES.GROUP, action.payload.params.chid);
                }
                
                if (action.payload.params.idx === 0) {
                    mediaMessages[selectedIndexId] = [];
                    mediaMessagesMap[selectedIndexId] = {};
                }
            }
            
            
            return {
                ...state,
                getChatMessageMediaListStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                selectedIndexId: selectedIndexId,
                mediaMessages: mediaMessages,
                mediaMessagesMap: mediaMessagesMap,
                mediaMessagesDateMap: mediaMessagesDateMap
            }
        }
        
        /** dm : to get media messages  : error **/
        case CHAT_DM_MESSAGE_FILTER_MESSAGES_BY_FIELD_ERROR:
        /** group : to get media messages  : error **/
        case GROUP_MESSAGES_FILTER_MESSAGES_BY_FIELD_ERROR: {
            return {
                ...state,
                getChatMessageMediaListStatus: HTTP_REQUEST_STATUS.ERROR
            }
        }
        
        /** dm : to get media messages  : none **/
        case CHAT_DM_MESSAGE_FILTER_MESSAGES_BY_FIELD_NONE:
        /** group : to get media messages  : none **/
        case GROUP_MESSAGES_FILTER_MESSAGES_BY_FIELD_NONE: {
            return {
                ...state,
                getChatMessageMediaListStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }
        
        /** dm : to get media messages  : none **/
        case MSG_ROUTER_EVENTS.DM.DM_MESSAGE_FILTER_FIELD:
        /** group : to get media messages  : none **/
        case MSG_ROUTER_EVENTS.CHANNEL.CHANNEL_MESSAGE_FILTER_FIELD: {
            
            const mediaMessages = state.mediaMessages;
            const mediaMessagesMap = state.mediaMessagesMap;
            const mediaMessagesDateMap = state.mediaMessagesDateMap;
            const selectedIndexId = state.selectedIndexId;
            
            let nextIndex = state.nextIndex;
            
            
            if (typeof action.payload.data != "undefined" && action.payload.data.status != "404") {
                if (typeof action.payload.data.messages != "undefined") {
                    ChatParserUtils.parseMediaMessageList(action.payload.data.messages, mediaMessages[selectedIndexId]);
                }
                nextIndex = action.payload.data['next_index'];
            }
            
            let selectedItems = mediaMessages[selectedIndexId];
            
            for (let tabKey in selectedItems) {
                let dateItems = selectedItems[tabKey];
                
                for (let dateKey in dateItems) {
                    let messagesList = dateItems[dateKey];
                    
                    for (let i = 0; i < messagesList.length; i++) {
                        mediaMessagesMap[selectedIndexId][messagesList[i]["id"]] = messagesList[i];
                    }
                }
            }
            
            return {
                ...state,
                getChatMessageMediaListStatus: HTTP_REQUEST_STATUS.SUCCESS,
                mediaMessages: mediaMessages,
                mediaMessagesMap: mediaMessagesMap,
                mediaMessagesDateMap: mediaMessagesDateMap,
                nextIndex: nextIndex
            }
        }
        
        
        default: {
            return {
                ...state
            }
        }
    }
}

export default ReducerMessageMediaList;

