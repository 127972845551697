import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import axios from "axios";
import AppUtils from "../../../util/tribe/utils/appUtils";
import {
    CLIENTS_DEACTIVATE_CLIENT,
    CLIENTS_GET_ALL_CLIENTS,
    CLIENTS_GET_CLIENT_FEATURES, CLIENTS_GET_FCM_NOTIFICATION_CONFIG,
    CLIENTS_GET_VOIP_NOTIFICATION_CONFIG,
    CLIENTS_SAVE_CLIENT,
    CLIENTS_SET_CLIENT_FEATURE_PRIVATE_ROOM_STATUS,
    CLIENTS_SET_UPDATE_VOIP_NOTIFICATION_CONFIG, CLIENTS_UPDATE_FCM_NOTIFICATION_CONFIG
} from "../../../constants/actions/clients/actionTypesClients";
import {
    URL_CLIENTS_DEACTIVATE_CLIENT,
    URL_CLIENTS_GET_ALL_CLIENTS,
    URL_CLIENTS_GET_CLIENTS_FEATURES, URL_CLIENTS_GET_FCM_NOTIFICATION_CONFIG,
    URL_CLIENTS_GET_VOIP_NOTIFICATION_CONFIG,
    URL_CLIENTS_SAVE_CLIENT,
    URL_CLIENTS_SET_CLIENT_PRIVATE_ROOM_STATUS, URL_CLIENTS_UPDATE_FCM_NOTIFICATION_CONFIG,
    URL_CLIENTS_UPDATE_VOIP_NOTIFICATION_CONFIG
} from "../../../util/tribe/urls";
import {
    clientsDeactivateClientError,
    clientsDeactivateClientSuccess,
    clientsGetAllClientsError,
    clientsGetAllClientsSuccess,
    clientsGetClientFeaturesError,
    clientsGetClientFeaturesSuccess, clientsGetFCMNotificationConfigError, clientsGetFCMNotificationConfigSuccess,
    clientsGetVOIPNotificationConfigError,
    clientsGetVOIPNotificationConfigSuccess,
    clientsSaveClientError,
    clientsSaveClientSuccess,
    clientsSetClientFeaturePrivateRoomStatusError,
    clientsSetClientFeaturePrivateRoomStatusSuccess, clientsUpdateFCMNotificationConfigError, clientsUpdateFCMNotificationConfigSuccess,
    clientsUpdateVOIPNotificationConfigError,
    clientsUpdateVOIPNotificationConfigSuccess
} from "../../actions";

/** http : clients : get all clients **/
const httpGetAllClientsList = async (data) =>
    await axios.post(URL_CLIENTS_GET_ALL_CLIENTS, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : clients : save client **/
const httpSaveClient = async (data) =>
    await axios.post(URL_CLIENTS_SAVE_CLIENT, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : clients : deactivate client **/
const httpDeactivateClient = async (data) =>
    await axios.post(URL_CLIENTS_DEACTIVATE_CLIENT, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : clients : get clients features list **/
const httpGetClientFeaturesList = async (data) =>
    await axios.post(URL_CLIENTS_GET_CLIENTS_FEATURES, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : clients : to set client private room status **/
const httpSetClientPrivateRoomStatus = async (data) =>
    await axios.post(URL_CLIENTS_SET_CLIENT_PRIVATE_ROOM_STATUS, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : clients : to update client voip notification config **/
const httpClientsUpdateVOIPNotificationConfig = async (data) =>
    await axios.post(URL_CLIENTS_UPDATE_VOIP_NOTIFICATION_CONFIG, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });


/** http : clients : to get client voip notification config **/
const httpClientsGetVOIPNotificationConfig = async (data) =>
    await axios.post(URL_CLIENTS_GET_VOIP_NOTIFICATION_CONFIG, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });


/** http : clients : to update client fcm notification config **/
const httpClientsUpdateFCMNotificationConfig = async (data) =>
    await axios.post(URL_CLIENTS_UPDATE_FCM_NOTIFICATION_CONFIG, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });


/** http : clients : to get client fcm notification config **/
const httpClientsGetFCMNotificationConfig = async (data) =>
    await axios.post(URL_CLIENTS_GET_FCM_NOTIFICATION_CONFIG, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });


/** handler : clients : get all clients **/
function* handleHttpGetAllClientsList({payload}) {
    try {
        const result = yield call(httpGetAllClientsList, payload);
        if (result.message) {
            yield put(clientsGetAllClientsError(result.message));
        } else {
            yield put(clientsGetAllClientsSuccess(result.data));
        }
    } catch (error) {
        yield put(clientsGetAllClientsError(error));
    }
}

/** handler : clients : save client **/
function* handleHttpSaveClient({payload}) {
    try {
        const result = yield call(httpSaveClient, payload);
        if (result.message) {
            yield put(clientsSaveClientError(result.message));
        } else {
            yield put(clientsSaveClientSuccess(result.data));
        }
    } catch (error) {
        yield put(clientsSaveClientError(error));
    }
}

/** handler : clients : deactivate client **/
function* handleHttpDeactivateClient({payload}) {
    try {
        const result = yield call(httpDeactivateClient, payload);
        if (result.message) {
            yield put(clientsDeactivateClientError(result.message));
        } else {
            yield put(clientsDeactivateClientSuccess(result.data));
        }
    } catch (error) {
        yield put(clientsDeactivateClientError(error));
    }
}

/** handler : clients : to get clients features **/
function* handleHttpGetClientFeatures({payload}) {
    try {
        const result = yield call(httpGetClientFeaturesList, payload);
        if (result.message) {
            yield put(clientsGetClientFeaturesError(result.message));
        } else {
            yield put(clientsGetClientFeaturesSuccess(result.data));
        }
    } catch (error) {
        yield put(clientsGetClientFeaturesError(error));
    }
}

/** handler : clients features : to update private roo, status **/
function* handleHttpSetClientFeaturesPrivateRoomStatus({payload}) {
    try {
        const result = yield call(httpSetClientPrivateRoomStatus, payload);
        if (result.message) {
            yield put(clientsSetClientFeaturePrivateRoomStatusError(result.message));
        } else {
            yield put(clientsSetClientFeaturePrivateRoomStatusSuccess(result.data));
        }
    } catch (error) {
        yield put(clientsSetClientFeaturePrivateRoomStatusError(error));
    }
}

/** handler : clients : to update voip notification config **/
function* handleHttpClientsUpdateVOIPNotificationConfig({payload}) {
    try {
        const result = yield call(httpClientsUpdateVOIPNotificationConfig, payload);
        if (result.message) {
            yield put(clientsUpdateVOIPNotificationConfigError(result.message));
        } else {
            yield put(clientsUpdateVOIPNotificationConfigSuccess(result.data));
        }
    } catch (error) {
        yield put(clientsUpdateVOIPNotificationConfigError(error));
    }
}

/** handler : clients : to get voip notification config **/
function* handleHttpClientsGetVOIPNotificationConfig({payload}) {
    try {
        const result = yield call(httpClientsGetVOIPNotificationConfig, payload);
        if (result.message) {
            yield put(clientsGetVOIPNotificationConfigError(result.message));
        } else {
            yield put(clientsGetVOIPNotificationConfigSuccess(result.data));
        }
    } catch (error) {
        yield put(clientsGetVOIPNotificationConfigError(error));
    }
}

/** handler : clients : to update fcm notification config **/
function* handleHttpClientsUpdateFCMNotificationConfig({payload}) {
    try {
        const result = yield call(httpClientsUpdateFCMNotificationConfig, payload);
        if (result.message) {
            yield put(clientsUpdateFCMNotificationConfigError(result.message));
        } else {
            yield put(clientsUpdateFCMNotificationConfigSuccess(result.data));
        }
    } catch (error) {
        yield put(clientsUpdateFCMNotificationConfigError(error));
    }
}

/** handler : clients : to get fcm notification config **/
function* handleHttpClientsGetFCMNotificationConfig({payload}) {
    try {
        const result = yield call(httpClientsGetFCMNotificationConfig, payload);
        if (result.message) {
            yield put(clientsGetFCMNotificationConfigError(result.message));
        } else {
            yield put(clientsGetFCMNotificationConfigSuccess(result.data));
        }
    } catch (error) {
        yield put(clientsGetFCMNotificationConfigError(error));
    }
}


/** watcher : clients : get all clients **/
export function* getAllClientsList() {
    yield takeEvery(CLIENTS_GET_ALL_CLIENTS, handleHttpGetAllClientsList);
}

/** watcher : clients : save client **/
export function* saveClient() {
    yield takeEvery(CLIENTS_SAVE_CLIENT, handleHttpSaveClient);
}

/** watcher : clients : save client **/
export function* deactivateClient() {
    yield takeEvery(CLIENTS_DEACTIVATE_CLIENT, handleHttpDeactivateClient);
}

/** watcher : clients : to get client features **/
export function* sageGetClientFeatures() {
    yield takeEvery(CLIENTS_GET_CLIENT_FEATURES, handleHttpGetClientFeatures);
}

/** watcher : clients features : to update private room status **/
export function* sageSetClientFeaturesPrivateRoomStatus() {
    yield takeEvery(CLIENTS_SET_CLIENT_FEATURE_PRIVATE_ROOM_STATUS, handleHttpSetClientFeaturesPrivateRoomStatus);
}

/** watcher : clients : to update voip notification config **/
export function* sageClientsUpdateVOIPNotificationConfig() {
    yield takeEvery(CLIENTS_SET_UPDATE_VOIP_NOTIFICATION_CONFIG, handleHttpClientsUpdateVOIPNotificationConfig);
}

/** watcher : clients : to get voip notification config **/
export function* sagaClientsGetVOIPNotificationConfig() {
    yield takeEvery(CLIENTS_GET_VOIP_NOTIFICATION_CONFIG, handleHttpClientsGetVOIPNotificationConfig);
}

/** watcher : clients : to update fcm notification config **/
export function* sageClientsUpdateFCMNotificationConfig() {
    yield takeEvery(CLIENTS_UPDATE_FCM_NOTIFICATION_CONFIG, handleHttpClientsUpdateFCMNotificationConfig);
}

/** watcher : clients : to get fcm notification config **/
export function* sagaClientsGetFCMNotificationConfig() {
    yield takeEvery(CLIENTS_GET_FCM_NOTIFICATION_CONFIG, handleHttpClientsGetFCMNotificationConfig);
}


export default function* rootSaga() {
    yield all([
        fork(getAllClientsList),
        fork(saveClient),
        fork(deactivateClient),
        fork(sageGetClientFeatures),
        fork(sageSetClientFeaturesPrivateRoomStatus),
        fork(sageClientsUpdateVOIPNotificationConfig),
        fork(sagaClientsGetVOIPNotificationConfig),
        fork(sageClientsUpdateFCMNotificationConfig),
        fork(sagaClientsGetFCMNotificationConfig)
    ])
}
