
/**** user settings ****/
/** change password **/
export const USER_SETTINGS_CHANGE_PASSWORD = 'USER_SETTINGS_CHANGE_PASSWORD';
export const USER_SETTINGS_CHANGE_PASSWORD_SUCCESS = 'USER_SETTINGS_CHANGE_PASSWORD_SUCCESS';
export const USER_SETTINGS_CHANGE_PASSWORD_ERROR = 'USER_SETTINGS_CHANGE_PASSWORD_ERROR';
export const USER_SETTINGS_CHANGE_PASSWORD_NONE = 'USER_SETTINGS_CHANGE_PASSWORD_NONE';

/** update profile settings **/
export const USER_SETTINGS_UPDATE_PROFILE = 'USER_SETTINGS_UPDATE_PROFILE';
export const USER_SETTINGS_UPDATE_PROFILE_SUCCESS = 'USER_SETTINGS_UPDATE_PROFILE_SUCCESS';
export const USER_SETTINGS_UPDATE_PROFILE_ERROR = 'USER_SETTINGS_UPDATE_PROFILE_ERROR';
export const USER_SETTINGS_UPDATE_PROFILE_NONE = 'USER_SETTINGS_UPDATE_PROFILE_NONE';
