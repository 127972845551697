import {
    HTTP_REQUEST_STATUS, MESSAGE_ROUTER_CHANNEL_TYPES, MSG_ROUTER_EVENTS,
} from "../../../util/tribe/constants/config";
import {
    CHAT_DM_MESSAGE_GET_MESSAGE_HISTORY,
    CHAT_DM_MESSAGE_GET_MESSAGE_HISTORY_ERROR,
    CHAT_DM_MESSAGE_GET_MESSAGE_HISTORY_NONE,
    GROUP_MESSAGES_GET_MESSAGE_HISTORY,
    GROUP_MESSAGES_GET_MESSAGE_HISTORY_ERROR,
    GROUP_MESSAGES_GET_MESSAGE_HISTORY_NONE,
} from "../../../constants/actions/chat/ChatActionTypes";
import ChatParserUtils from "../../../util/tribe/chat_parser/chatParsingUtils";

const INIT_STATE = {
    getChatMessageHistoryStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    
    historyMessages: {},
    historyMessagesRelationsMap: {},
    historyMessagesDateMap: {},
    selectedIndexId: ""
}


const ReducerChatMessageHistory = (state = INIT_STATE, action) => {
    
    switch (action.type) {
        
        /** dm : to get the reply message history **/
        case CHAT_DM_MESSAGE_GET_MESSAGE_HISTORY:
        /** group : to get the reply message history **/
        case GROUP_MESSAGES_GET_MESSAGE_HISTORY: {
            let historyMessages = state.historyMessages;
            let historyMessagesRelationsMap = state.historyMessagesRelationsMap;
            let historyMessagesDateMap = state.historyMessagesDateMap;
            
            let selectedIndexId = "";
            
            if (typeof action.payload.params != "undefined") {
                if (action.type === CHAT_DM_MESSAGE_GET_MESSAGE_HISTORY) {
                    selectedIndexId = ChatParserUtils.generateIndexId(MESSAGE_ROUTER_CHANNEL_TYPES.DM, action.payload.params.tuid);
                } else {
                    selectedIndexId = ChatParserUtils.generateIndexId(MESSAGE_ROUTER_CHANNEL_TYPES.GROUP, action.payload.params.chid);
                }
            }
            
            if (typeof historyMessages[selectedIndexId] === "undefined") {
                historyMessages[selectedIndexId] = [];
            }
            
            return {
                ...state,
                getChatMessageHistoryStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                selectedIndexId: selectedIndexId,
                historyMessages: historyMessages,
                historyMessagesRelationsMap: historyMessagesRelationsMap,
                historyMessagesDateMap: historyMessagesDateMap
            }
        }
        
        /** dm : to get the reply message history : error **/
        case CHAT_DM_MESSAGE_GET_MESSAGE_HISTORY_ERROR:
        /** group : to get the reply message history : error **/
        case GROUP_MESSAGES_GET_MESSAGE_HISTORY_ERROR: {
            return {
                ...state,
                getChatMessageHistoryStatus: HTTP_REQUEST_STATUS.ERROR
            }
        }
        
        /** dm : to get the reply message history : none **/
        case CHAT_DM_MESSAGE_GET_MESSAGE_HISTORY_NONE:
        /** group : to get the reply message history : none **/
        case GROUP_MESSAGES_GET_MESSAGE_HISTORY_NONE: {
            return {
                ...state,
                getChatMessageHistoryStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }
        
        /** dm : to get the reply message history : none **/
        case MSG_ROUTER_EVENTS.DM.DM_MESSAGE_HISTORY:
        /** group : to get the reply message history : none **/
        case MSG_ROUTER_EVENTS.CHANNEL.CHANNEL_MESSAGE_HISTORY: {
            
            const historyMessages = state.historyMessages;
            const historyMessagesRelationsMap = state.historyMessagesRelationsMap;
            const historyMessagesDateMap = state.historyMessagesDateMap;
            const selectedIndexId = state.selectedIndexId;
            
            if (typeof action.payload.data != "undefined" && typeof action.payload.data.messages != "undefined") {
                ChatParserUtils.parseMessageList(action.payload.data.messages, historyMessages[selectedIndexId], true, historyMessagesRelationsMap, selectedIndexId, historyMessagesDateMap);
            }
            
            return {
                ...state,
                groupGetChatMessageHistoryStatus: HTTP_REQUEST_STATUS.SUCCESS,
                historyMessages: historyMessages,
                historyMessagesRelationsMap: historyMessagesRelationsMap,
                historyMessagesDateMap: historyMessagesDateMap
            }
        }
        
        
        default: {
            return {
                ...state
            }
        }
    }
}

export default ReducerChatMessageHistory;

