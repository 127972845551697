import {
    CLIENTS_DEACTIVATE_CLIENT,
    CLIENTS_DEACTIVATE_CLIENT_ERROR,
    CLIENTS_DEACTIVATE_CLIENT_NONE,
    CLIENTS_DEACTIVATE_CLIENT_SUCCESS,
    CLIENTS_FILTER_CLIENTS,
    CLIENTS_GET_ALL_CLIENTS,
    CLIENTS_GET_ALL_CLIENTS_ERROR,
    CLIENTS_GET_ALL_CLIENTS_NONE,
    CLIENTS_GET_ALL_CLIENTS_SUCCESS,
    CLIENTS_GET_CLIENT_FEATURES,
    CLIENTS_GET_CLIENT_FEATURES_ERROR,
    CLIENTS_GET_CLIENT_FEATURES_NONE,
    CLIENTS_GET_CLIENT_FEATURES_SUCCESS,
    CLIENTS_GET_FCM_NOTIFICATION_CONFIG,
    CLIENTS_GET_FCM_NOTIFICATION_CONFIG_ERROR,
    CLIENTS_GET_FCM_NOTIFICATION_CONFIG_NONE,
    CLIENTS_GET_FCM_NOTIFICATION_CONFIG_SUCCESS,
    CLIENTS_GET_VOIP_NOTIFICATION_CONFIG,
    CLIENTS_GET_VOIP_NOTIFICATION_CONFIG_ERROR,
    CLIENTS_GET_VOIP_NOTIFICATION_CONFIG_NONE,
    CLIENTS_GET_VOIP_NOTIFICATION_CONFIG_SUCCESS,
    CLIENTS_SAVE_CLIENT,
    CLIENTS_SAVE_CLIENT_ERROR,
    CLIENTS_SAVE_CLIENT_NONE,
    CLIENTS_SAVE_CLIENT_SUCCESS,
    CLIENTS_SET_CLIENT_FEATURE_PRIVATE_ROOM_STATUS,
    CLIENTS_SET_CLIENT_FEATURE_PRIVATE_ROOM_STATUS_ERROR,
    CLIENTS_SET_CLIENT_FEATURE_PRIVATE_ROOM_STATUS_NONE,
    CLIENTS_SET_CLIENT_FEATURE_PRIVATE_ROOM_STATUS_SUCCESS,
    CLIENTS_SET_CURRENT_CLIENT,
    CLIENTS_SET_UPDATE_VOIP_NOTIFICATION_CONFIG,
    CLIENTS_SET_UPDATE_VOIP_NOTIFICATION_CONFIG_ERROR,
    CLIENTS_SET_UPDATE_VOIP_NOTIFICATION_CONFIG_NONE,
    CLIENTS_SET_UPDATE_VOIP_NOTIFICATION_CONFIG_SUCCESS,
    CLIENTS_UPDATE_FCM_NOTIFICATION_CONFIG,
    CLIENTS_UPDATE_FCM_NOTIFICATION_CONFIG_ERROR,
    CLIENTS_UPDATE_FCM_NOTIFICATION_CONFIG_NONE,
    CLIENTS_UPDATE_FCM_NOTIFICATION_CONFIG_SUCCESS
} from "../../../constants/actions/clients/actionTypesClients";

/** clients : to get all clients list **/
export const clientsGetAllClients = (payload) => {
    return {
        type: CLIENTS_GET_ALL_CLIENTS,
        payload: payload
    };
};

/** clients : to get all clients list : success **/
export const clientsGetAllClientsSuccess = (payload) => {
    return {
        type: CLIENTS_GET_ALL_CLIENTS_SUCCESS,
        payload: payload
    };
};

/** clients : to get all clients list : error **/
export const clientsGetAllClientsError = (payload) => {
    return {
        type: CLIENTS_GET_ALL_CLIENTS_ERROR,
        payload: payload
    };
};

/** clients : to get all clients list : none **/
export const clientsGetAllClientsNone = (payload) => {
    return {
        type: CLIENTS_GET_ALL_CLIENTS_NONE,
        payload: payload
    };
};

/** clients : to save client **/
export const clientsSaveClient = (payload) => {
    return {
        type: CLIENTS_SAVE_CLIENT,
        payload: payload
    };
};

/** clients : to save client : success **/
export const clientsSaveClientSuccess = (payload) => {
    return {
        type: CLIENTS_SAVE_CLIENT_SUCCESS,
        payload: payload
    };
};

/** clients : to save client : error **/
export const clientsSaveClientError = (payload) => {
    return {
        type: CLIENTS_SAVE_CLIENT_ERROR,
        payload: payload
    };
};

/** clients : to save client : none **/
export const clientsSaveClientNone = (payload) => {
    return {
        type: CLIENTS_SAVE_CLIENT_NONE,
        payload: payload
    };
};

/** clients : deactivate client **/
export const clientsDeactivateClient = (payload) => {
    return {
        type: CLIENTS_DEACTIVATE_CLIENT,
        payload: payload
    };
};

/** clients : deactivate client : success **/
export const clientsDeactivateClientSuccess = (payload) => {
    return {
        type: CLIENTS_DEACTIVATE_CLIENT_SUCCESS,
        payload: payload
    };
};

/** clients : deactivate client : error **/
export const clientsDeactivateClientError = (payload) => {
    return {
        type: CLIENTS_DEACTIVATE_CLIENT_ERROR,
        payload: payload
    };
};

/** clients : deactivate client : none **/
export const clientsDeactivateClientNone = (payload) => {
    return {
        type: CLIENTS_DEACTIVATE_CLIENT_NONE,
        payload: payload
    };
};


/** clients : to set current client for view page **/
export const setViewCurrentClient = (payload) => {
    return {
        type: CLIENTS_SET_CURRENT_CLIENT,
        payload: payload
    };
};

/** clients : to filter clients **/
export const filterClientsList = (payload) => {
    return {
        type: CLIENTS_FILTER_CLIENTS,
        payload: payload
    };
};


/** clients : to get client features **/
export const clientsGetClientFeatures = (payload) => {
    return {
        type: CLIENTS_GET_CLIENT_FEATURES,
        payload: payload
    };
};

/** clients : to get client features : success **/
export const clientsGetClientFeaturesSuccess = (payload) => {
    return {
        type: CLIENTS_GET_CLIENT_FEATURES_SUCCESS,
        payload: payload
    };
};

/** clients : to get client features : error **/
export const clientsGetClientFeaturesError = (payload) => {
    return {
        type: CLIENTS_GET_CLIENT_FEATURES_ERROR,
        payload: payload
    };
};

/** clients : to get client features : none **/
export const clientsGetClientFeaturesNone = (payload) => {
    return {
        type: CLIENTS_GET_CLIENT_FEATURES_NONE,
        payload: payload
    };
};

/** clients : to set client features private room status **/
export const clientsSetClientFeaturePrivateRoomStatus = (payload) => {
    return {
        type: CLIENTS_SET_CLIENT_FEATURE_PRIVATE_ROOM_STATUS,
        payload: payload
    };
};

/** clients : to set client features private room status : success **/
export const clientsSetClientFeaturePrivateRoomStatusSuccess = (payload) => {
    return {
        type: CLIENTS_SET_CLIENT_FEATURE_PRIVATE_ROOM_STATUS_SUCCESS,
        payload: payload
    };
};

/** clients : to set client features private room status : error **/
export const clientsSetClientFeaturePrivateRoomStatusError = (payload) => {
    return {
        type: CLIENTS_SET_CLIENT_FEATURE_PRIVATE_ROOM_STATUS_ERROR,
        payload: payload
    };
};

/** clients : to set client features private room status : none **/
export const clientsSetClientFeaturePrivateRoomStatusNone = (payload) => {
    return {
        type: CLIENTS_SET_CLIENT_FEATURE_PRIVATE_ROOM_STATUS_NONE,
        payload: payload
    };
};

/** clients : to update voip notification config **/
export const clientsUpdateVOIPNotificationConfig = (payload) => {
    return {
        type: CLIENTS_SET_UPDATE_VOIP_NOTIFICATION_CONFIG,
        payload: payload
    };
};

/** clients : to update voip notification config : success **/
export const clientsUpdateVOIPNotificationConfigSuccess = (payload) => {
    return {
        type: CLIENTS_SET_UPDATE_VOIP_NOTIFICATION_CONFIG_SUCCESS,
        payload: payload
    };
};

/** clients : to update voip notification config : error **/
export const clientsUpdateVOIPNotificationConfigError = (payload) => {
    return {
        type: CLIENTS_SET_UPDATE_VOIP_NOTIFICATION_CONFIG_ERROR,
        payload: payload
    };
};

/** clients : to update voip notification config : none **/
export const clientsUpdateVOIPNotificationConfigNone = (payload) => {
    return {
        type: CLIENTS_SET_UPDATE_VOIP_NOTIFICATION_CONFIG_NONE,
        payload: payload
    };
};


/** clients : to get voip notification config **/
export const clientsGetVOIPNotificationConfig = (payload) => {
    return {
        type: CLIENTS_GET_VOIP_NOTIFICATION_CONFIG,
        payload: payload
    };
};

/** clients : to get voip notification config : success **/
export const clientsGetVOIPNotificationConfigSuccess = (payload) => {
    return {
        type: CLIENTS_GET_VOIP_NOTIFICATION_CONFIG_SUCCESS,
        payload: payload
    };
};

/** clients : to get voip notification config : error **/
export const clientsGetVOIPNotificationConfigError = (payload) => {
    return {
        type: CLIENTS_GET_VOIP_NOTIFICATION_CONFIG_ERROR,
        payload: payload
    };
};

/** clients : to get voip notification config : none **/
export const clientsGetVOIPNotificationConfigNone = (payload) => {
    return {
        type: CLIENTS_GET_VOIP_NOTIFICATION_CONFIG_NONE,
        payload: payload
    };
};


/** clients : to get fcm notification config **/
export const clientsGetFCMNotificationConfig = (payload) => {
    return {
        type: CLIENTS_GET_FCM_NOTIFICATION_CONFIG,
        payload: payload
    };
};

/** clients : to get fcm notification config : success **/
export const clientsGetFCMNotificationConfigSuccess = (payload) => {
    return {
        type: CLIENTS_GET_FCM_NOTIFICATION_CONFIG_SUCCESS,
        payload: payload
    };
};

/** clients : to get fcm notification config : error **/
export const clientsGetFCMNotificationConfigError = (payload) => {
    return {
        type: CLIENTS_GET_FCM_NOTIFICATION_CONFIG_ERROR,
        payload: payload
    };
};

/** clients : to get fcm notification config : none **/
export const clientsGetFCMNotificationConfigNone = (payload) => {
    return {
        type: CLIENTS_GET_FCM_NOTIFICATION_CONFIG_NONE,
        payload: payload
    };
};

/** clients : to update fcm notification config **/
export const clientsUpdateFCMNotificationConfig = (payload) => {
    return {
        type: CLIENTS_UPDATE_FCM_NOTIFICATION_CONFIG,
        payload: payload
    };
};

/** clients : to update fcm notification config : success **/
export const clientsUpdateFCMNotificationConfigSuccess = (payload) => {
    return {
        type: CLIENTS_UPDATE_FCM_NOTIFICATION_CONFIG_SUCCESS,
        payload: payload
    };
};

/** clients : to update fcm notification config : error **/
export const clientsUpdateFCMNotificationConfigError = (payload) => {
    return {
        type: CLIENTS_UPDATE_FCM_NOTIFICATION_CONFIG_ERROR,
        payload: payload
    };
};

/** clients : to update fcm notification config : none **/
export const clientsUpdateFCMNotificationConfigNone = (payload) => {
    return {
        type: CLIENTS_UPDATE_FCM_NOTIFICATION_CONFIG_NONE,
        payload: payload
    };
};
