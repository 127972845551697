import {
    HTTP_REQUEST_STATUS
} from "../../../util/tribe/constants/config";
import IntlMessages from "../../../util/IntlMessages";
import UIUtils from "../../../util/tribe/utils/uiUtils";
import {
    USER_SETTINGS_CHANGE_PASSWORD,
    USER_SETTINGS_CHANGE_PASSWORD_ERROR,
    USER_SETTINGS_CHANGE_PASSWORD_NONE,
    USER_SETTINGS_CHANGE_PASSWORD_SUCCESS,
    USER_SETTINGS_UPDATE_PROFILE,
    USER_SETTINGS_UPDATE_PROFILE_ERROR,
    USER_SETTINGS_UPDATE_PROFILE_NONE,
    USER_SETTINGS_UPDATE_PROFILE_SUCCESS
} from "../../../constants/actions/user_settings/userSettingsActionTypes";

const myAccountViewSubHeaderConfig = [
    {
        'key': 'settings_action_save',
        'type': UIUtils.UI_COMPONENT_TYPES.BUTTON_STATIC_POSITIVE,
        'title': <IntlMessages id="DIC_KEY_MY_ACCOUNT_BUTTON_TITLE_SAVE"/>,
        'parent_class': "float_right "
    },
    {
        'key': 'settings_action_header',
        'type': UIUtils.UI_COMPONENT_TYPES.LABEL_CONTAINER,
        'title': <IntlMessages id="DIC_KEY_MY_ACCOUNT_INFO_TITLE_SETTINGS"/>
    },
    {
        'key': 'change_password_action_header',
        'type': UIUtils.UI_COMPONENT_TYPES.LABEL_CONTAINER,
        'title': <IntlMessages id="DIC_KEY_MY_ACCOUNT_INFO_TITLE_CHANGE_PASSWORD"/>
    }
];

const myAccountViewBasicInformationConfig = [
    {
        'key': 'my_account_info_employee_id',
        'type': UIUtils.UI_COMPONENT_TYPES.HORIZONTAL_LABEL_INPUT,
        'style': {},
        'labelClass': "gx-pl-3",
        'localisation': "DIC_KEY_MY_ACCOUNT_CONTAINER_EMPLOYEE_ID",
        'isStar': true,
        'isMandatory': false,
        'parent_class': "form_container_root",
        'placeholderKey': "DIC_KEY_MY_ACCOUNT_CONTAINER_EMPLOYEE_ID_PLACEHOLDER",
        'variant': "outlined",
        'disabled': true
    },
    {
        'key': 'my_account_info_email',
        'type': UIUtils.UI_COMPONENT_TYPES.HORIZONTAL_LABEL_INPUT,
        'style': {},
        'labelClass': "gx-pl-3",
        'localisation': "DIC_KEY_MY_ACCOUNT_CONTAINER_EMAIL",
        'isStar': false,
        'isMandatory': false,
        'parent_class': "form_container_root",
        'placeholderKey': "DIC_KEY_MY_ACCOUNT_CONTAINER_EMAIL_PLACEHOLDER",
        'variant': "outlined"
    },
    {
        'key': 'my_account_info_first_name',
        'type': UIUtils.UI_COMPONENT_TYPES.HORIZONTAL_LABEL_INPUT,
        'style': {},
        'labelClass': "gx-pl-3",
        'localisation': "DIC_KEY_MY_ACCOUNT_CONTAINER_FIRST_NAME",
        'isStar': false,
        'isMandatory': false,
        'parent_class': "form_container_root",
        'placeholderKey': "DIC_KEY_MY_ACCOUNT_CONTAINER_FIRST_NAME_PLACEHOLDER",
        'variant': "outlined"
    },
    {
        'key': 'my_account_info_last_name',
        'type': UIUtils.UI_COMPONENT_TYPES.HORIZONTAL_LABEL_INPUT,
        'style': {},
        'labelClass': "gx-pl-3",
        'localisation': "DIC_KEY_MY_ACCOUNT_CONTAINER_LAST_NAME",
        'isStar': false,
        'isMandatory': false,
        'parent_class': "form_container_root",
        'placeholderKey': "DIC_KEY_MY_ACCOUNT_CONTAINER_LAST_NAME_PLACEHOLDER",
        'variant': "outlined"
    },
    {
        'key': 'my_account_info_display_name',
        'type': UIUtils.UI_COMPONENT_TYPES.HORIZONTAL_LABEL_INPUT,
        'style': {},
        'labelClass': "gx-pl-3",
        'localisation': "DIC_KEY_MY_ACCOUNT_CONTAINER_DISPLAY_NAME",
        'isStar': false,
        'isMandatory': false,
        'parent_class': "form_container_root",
        'placeholderKey': "DIC_KEY_MY_ACCOUNT_CONTAINER_DISPLAY_NAME_PLACEHOLDER",
        'variant': "outlined"
    },
    {
        'key': 'my_account_info_status_message',
        'type': UIUtils.UI_COMPONENT_TYPES.HORIZONTAL_LABEL_INPUT,
        'style': {},
        'labelClass': "gx-pl-3",
        'localisation': "DIC_KEY_MY_ACCOUNT_CONTAINER_STATUS_MESSAGE",
        'isStar': false,
        'isMandatory': false,
        'parent_class': "form_container_root",
        'placeholderKey': "DIC_KEY_MY_ACCOUNT_CONTAINER_STATUS_MESSAGE_PLACEHOLDER",
        'variant': "outlined"
    }
    /*,
    {
        'key': 'my_account_info_phone',
        'type': UIUtils.UI_COMPONENT_TYPES.HORIZONTAL_LABEL_INPUT,
        'style': {},
        'labelClass': "gx-pl-3",
        'localisation': "DIC_KEY_MY_ACCOUNT_CONTAINER_PHONE_NUMBER",
        'isStar': false,
        'isMandatory': false,
        'parent_class': "form_container_root",
        'placeholderKey': "DIC_KEY_MY_ACCOUNT_CONTAINER_PHONE_NUMBER_PLACEHOLDER",
        'variant': "outlined"
    }*/
];

const myAccountViewChangePasswordConfig = [
    {
        'key': 'my_account_info_current_password',
        'type': UIUtils.UI_COMPONENT_TYPES.HORIZONTAL_LABEL_INPUT,
        'style': {},
        'labelClass': "gx-pl-3",
        'localisation': "DIC_KEY_MY_ACCOUNT_CONTAINER_CURRENT_PASSWORD",
        'isStar': false,
        'isMandatory': false,
        'parent_class': "form_container_root",
        'placeholderKey': "DIC_KEY_MY_ACCOUNT_CONTAINER_CURRENT_PASSWORD_PLACEHOLDER",
        'variant': "outlined",
        'isPassword': true
    },
    {
        'key': 'my_account_info_new_password',
        'type': UIUtils.UI_COMPONENT_TYPES.HORIZONTAL_LABEL_INPUT,
        'style': {},
        'labelClass': "gx-pl-3",
        'localisation': "DIC_KEY_MY_ACCOUNT_CONTAINER_NEW_PASSWORD",
        'isStar': false,
        'isMandatory': false,
        'parent_class': "form_container_root",
        'placeholderKey': "DIC_KEY_MY_ACCOUNT_CONTAINER_NEW_PASSWORD_PLACEHOLDER",
        'variant': "outlined",
        'isPassword': true
    },
    {
        'key': 'my_account_info_re_type_password',
        'type': UIUtils.UI_COMPONENT_TYPES.HORIZONTAL_LABEL_INPUT,
        'style': {},
        'labelClass': "gx-pl-3",
        'localisation': "DIC_KEY_MY_ACCOUNT_CONTAINER_RE_TYPE_PASSWORD",
        'isStar': false,
        'isMandatory': false,
        'parent_class': "form_container_root",
        'placeholderKey': "DIC_KEY_MY_ACCOUNT_CONTAINER_RE_TYPE_PASSWORD_PLACEHOLDER",
        'variant': "outlined",
        'isPassword': true
    }
];

const INIT_STATE = {
    myAccountViewSubHeaderConfig: myAccountViewSubHeaderConfig,
    myAccountViewBasicInformationConfig: myAccountViewBasicInformationConfig,
    myAccountViewChangePasswordConfig: myAccountViewChangePasswordConfig,
    
    alertMessage: "",
    changePasswordStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    updateProfileDetailsStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    
}


const ReducerUserSettings = (state = INIT_STATE, action) => {
    
    switch (action.type) {
        
        /** change password **/
        case USER_SETTINGS_CHANGE_PASSWORD: {
            
            return {
                ...state,
                changePasswordStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                alertMessage: ""
            }
        }
        
        case USER_SETTINGS_CHANGE_PASSWORD_SUCCESS: {
            
            return {
                ...state,
                changePasswordStatus: HTTP_REQUEST_STATUS.SUCCESS,
                alertMessage: action.payload.message
            }
        }
        
        case USER_SETTINGS_CHANGE_PASSWORD_ERROR: {
            let alertMessage = "";
            
            if (typeof action.payload.error != "undefined") {
                alertMessage = action.payload.error;
            }
            
            return {
                ...state,
                changePasswordStatus: HTTP_REQUEST_STATUS.ERROR,
                alertMessage: alertMessage
            }
        }
        
        case USER_SETTINGS_CHANGE_PASSWORD_NONE: {
            
            return {
                ...state,
                changePasswordStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                alertMessage: ""
            }
        }
        
        /** update profile **/
        case USER_SETTINGS_UPDATE_PROFILE: {
            
            return {
                ...state,
                updateProfileDetailsStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                alertMessage: ""
            }
        }
        
        case USER_SETTINGS_UPDATE_PROFILE_SUCCESS: {
            
            return {
                ...state,
                updateProfileDetailsStatus: HTTP_REQUEST_STATUS.SUCCESS,
                alertMessage: action.payload.message
            }
        }
        
        case USER_SETTINGS_UPDATE_PROFILE_ERROR: {
            
            let alertMessage = "";
            
            if (typeof action.payload.error != "undefined") {
                alertMessage = action.payload.error;
            }
            
            return {
                ...state,
                updateProfileDetailsStatus: HTTP_REQUEST_STATUS.ERROR,
                alertMessage: alertMessage
            }
        }
        
        case USER_SETTINGS_UPDATE_PROFILE_NONE: {
            
            return {
                ...state,
                updateProfileDetailsStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                alertMessage: ""
            }
        }
        
        default: {
            return {
                ...state
            }
        }
        
        
    }
    
}

export default ReducerUserSettings;
