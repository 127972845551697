import {HTTP_REQUEST_STATUS} from "../../../util/tribe/constants/config";
import UIUtils from "../../../util/tribe/utils/uiUtils";
import IntlMessages from "../../../util/IntlMessages";
import {
    CLIENTS_DEACTIVATE_CLIENT,
    CLIENTS_DEACTIVATE_CLIENT_ERROR,
    CLIENTS_DEACTIVATE_CLIENT_NONE,
    CLIENTS_DEACTIVATE_CLIENT_SUCCESS,
    CLIENTS_FILTER_CLIENTS,
    CLIENTS_GET_ALL_CLIENTS,
    CLIENTS_GET_ALL_CLIENTS_ERROR,
    CLIENTS_GET_ALL_CLIENTS_NONE,
    CLIENTS_GET_ALL_CLIENTS_SUCCESS,
    CLIENTS_SAVE_CLIENT,
    CLIENTS_SAVE_CLIENT_ERROR,
    CLIENTS_SAVE_CLIENT_NONE,
    CLIENTS_SAVE_CLIENT_SUCCESS,
    CLIENTS_SET_CURRENT_CLIENT
} from "../../../constants/actions/clients/actionTypesClients";
import React from "react";

export const TABLE_PAGINATION_OPTIONS = [50, 100, 150, 200];

const clientsListFilterConfig = [
    /*{
        'key': 'clients_select_actions',
        'type': UIUtils.UI_COMPONENT_TYPES.MENU_POPOVER,
        'localisation': "DIC_KEY_WC_TABLE_FILTER_ACTIONS",
        'parent_class': "table_filter_action_dropdown float_right "
    },*/
    {
        'key': 'clients_search_input',
        'type': UIUtils.UI_COMPONENT_TYPES.SEARCH_INPUT,
        'localisation': "",
        'isStar': false,
        'isMandatory': false
    },
    {
        'key': 'clients_status_dropdown',
        'type': UIUtils.UI_COMPONENT_TYPES.MENU_POPOVER,
        'isValueTitle': true,
        'localisation': "",
        'isStar': false,
        'isMandatory': false,
        'parent_class': "table_search_input_dropdown"
    }
];

const clientsViewSubHeaderConfig = [
    {
        'key': 'clients_view_action_save',
        'type': UIUtils.UI_COMPONENT_TYPES.BUTTON_STATIC_POSITIVE,
        'title': <IntlMessages id="DIC_KEY_CLIENTS_VIEW_ACTION_LABEL_SAVE"/>,
        'parent_class': "float_right "
    },
    {
        'key': 'clients_view_action_deactivate',
        'type': UIUtils.UI_COMPONENT_TYPES.BUTTON_STATIC_NEGATIVE,
        'title': <IntlMessages id="DIC_KEY_CLIENTS_VIEW_ACTION_LABEL_DEACTIVATE"/>,
        'parent_class': "float_right gx-mr-2"
    },
    {
        'key': 'clients_view_action_header_information',
        'type': UIUtils.UI_COMPONENT_TYPES.LABEL_CONTAINER,
        'title': <IntlMessages id="DIC_KEY_CLIENTS_VIEW_ACTION_LABEL_DETAIL_INFORMATION"/>
    },
    {
        'key': 'clients_view_action_header_features',
        'type': UIUtils.UI_COMPONENT_TYPES.LABEL_CONTAINER,
        'title': <IntlMessages id="DIC_KEY_CLIENTS_VIEW_ACTION_LABEL_FEATURES"/>
    },
    {
        'key': 'clients_view_action_header_voip_config',
        'type': UIUtils.UI_COMPONENT_TYPES.LABEL_CONTAINER,
        'title': <IntlMessages id="DIC_KEY_CLIENTS_VIEW_ACTION_LABEL_VOIP_CONFIG"/>
    },
    {
        'key': 'clients_view_action_header_fcm_config',
        'type': UIUtils.UI_COMPONENT_TYPES.LABEL_CONTAINER,
        'title': <IntlMessages id="DIC_KEY_CLIENTS_VIEW_ACTION_LABEL_FCM_CONFIG"/>
    }
];

const clientsViewInformationConfig = [
    {
        'key': 'client_info_name',
        'type': UIUtils.UI_COMPONENT_TYPES.HORIZONTAL_LABEL_INPUT,
        'style': {},
        'labelClass': "gx-pl-2",
        'localisation': "DIC_KEY_CLIENTS_VIEW_CLIENT_NAME",
        'isStar': true,
        'isMandatory': true,
        'parent_class': "form_container_root",
        'placeholderKey': "DIC_KEY_CLIENTS_VIEW_CLIENT_NAME_PLACEHOLDER",
        'variant': "outlined"
    },
    {
        'key': 'client_info_city',
        'type': UIUtils.UI_COMPONENT_TYPES.HORIZONTAL_LABEL_INPUT,
        'style': {},
        'labelClass': "gx-pl-2",
        'localisation': "DIC_KEY_CLIENTS_VIEW_CLIENT_CITY",
        'isStar': false,
        'isMandatory': false,
        'parent_class': "form_container_root",
        'placeholderKey': "DIC_KEY_CLIENTS_VIEW_CLIENT_CITY_PLACEHOLDER",
        'variant': "outlined"
    },
    {
        'key': 'client_info_country',
        'type': UIUtils.UI_COMPONENT_TYPES.HORIZONTAL_LABEL_INPUT,
        'style': {},
        'labelClass': "gx-pl-2",
        'localisation': "DIC_KEY_CLIENTS_VIEW_CLIENT_COUNTRY",
        'isStar': false,
        'isMandatory': false,
        'parent_class': "form_container_root",
        'placeholderKey': "DIC_KEY_CLIENTS_VIEW_CLIENT_COUNTRY_PLACEHOLDER",
        'variant': "outlined"
    },
    {
        'key': 'client_info_phone',
        'type': UIUtils.UI_COMPONENT_TYPES.HORIZONTAL_LABEL_INPUT,
        'style': {},
        'labelClass': "gx-pl-2",
        'localisation': "DIC_KEY_CLIENTS_VIEW_CLIENT_PHONE",
        'isStar': false,
        'isMandatory': false,
        'parent_class': "form_container_root",
        'placeholderKey': "DIC_KEY_CLIENTS_VIEW_CLIENT_PHONE_PLACEHOLDER",
        'variant': "outlined"
    },
    {
        'key': 'client_info_web_site',
        'type': UIUtils.UI_COMPONENT_TYPES.HORIZONTAL_LABEL_INPUT,
        'style': {},
        'labelClass': "gx-pl-2",
        'localisation': "DIC_KEY_CLIENTS_VIEW_CLIENT_WEBSITE",
        'isStar': false,
        'isMandatory': false,
        'parent_class': "form_container_root",
        'placeholderKey': "DIC_KEY_CLIENTS_VIEW_CLIENT_WEBSITE_PLACEHOLDER",
        'variant': "outlined"
    }
];

const clientsViewFeaturesConfig = [
    {
        "key": "feature_chat",
        "type": UIUtils.UI_COMPONENT_TYPES.SWITCH_LABEL_HORIZONTAL,
        'labelClass': "gx-pl-2",
        'localisation': "DIC_KEY_CLIENTS_FEATURES_VIEW_CHAT",
        'isStar': false,
        'isMandatory': false,
        'parent_class': "form_container_root",
        'placeholderKey': "DIC_KEY_CLIENTS_FEATURES_VIEW_CHAT_PLACEHOLDER",
        'variant': "outlined"
    },
    {
        "key": "feature_media",
        "type": UIUtils.UI_COMPONENT_TYPES.SWITCH_LABEL_HORIZONTAL,
        'labelClass': "gx-pl-2",
        'localisation': "DIC_KEY_CLIENTS_FEATURES_VIEW_MEDIA",
        'isStar': false,
        'isMandatory': false,
        'parent_class': "form_container_root",
        'placeholderKey': "DIC_KEY_CLIENTS_FEATURES_VIEW_MEDIA_PLACEHOLDER",
        'variant': "outlined"
    },
    {
        "key": "feature_private_room",
        "type": UIUtils.UI_COMPONENT_TYPES.SWITCH_LABEL_HORIZONTAL,
        'labelClass': "gx-pl-2",
        'localisation': "DIC_KEY_CLIENTS_FEATURES_VIEW_PRIVATE_ROOM",
        'isStar': false,
        'isMandatory': false,
        'parent_class': "form_container_root",
        'placeholderKey': "DIC_KEY_CLIENTS_FEATURES_VIEW_PRIVATE_ROOM_PLACEHOLDER",
        'variant': "outlined"
    }
];

const clientsViewVOIPNotificationConfig = [
    {
        'key': 'voip_config_team_id',
        'type': UIUtils.UI_COMPONENT_TYPES.HORIZONTAL_LABEL_INPUT,
        'style': {},
        'labelClass': "gx-pl-2",
        'localisation': "DIC_KEY_CLIENTS_VIEW_TEAM_ID",
        'isStar': true,
        'isMandatory': true,
        'parent_class': "form_container_root",
        'placeholderKey': "DIC_KEY_CLIENTS_VIEW_TEAM_ID_PLACEHOLDER",
        'variant': "outlined"
    },
    {
        'key': 'voip_config_bundle_id',
        'type': UIUtils.UI_COMPONENT_TYPES.HORIZONTAL_LABEL_INPUT,
        'style': {},
        'labelClass': "gx-pl-2",
        'localisation': "DIC_KEY_CLIENTS_VIEW_BUNDLE_ID",
        'isStar': true,
        'isMandatory': true,
        'parent_class': "form_container_root",
        'placeholderKey': "DIC_KEY_CLIENTS_VIEW_BUNDLE_ID_PLACEHOLDER",
        'variant': "outlined"
    },
    {
        'key': 'voip_config_passphrase',
        'type': UIUtils.UI_COMPONENT_TYPES.HORIZONTAL_LABEL_INPUT,
        'style': {},
        'labelClass': "gx-pl-2",
        'localisation': "DIC_KEY_CLIENTS_VIEW_PASS_PHRASE",
        'isStar': false,
        'isMandatory': false,
        'isFile': false,
        'parent_class': "form_container_root",
        'placeholderKey': "DIC_KEY_CLIENTS_VIEW_PASS_PHRASE_PLACEHOLDER",
        'variant': "outlined"
    },
    {
        'key': 'voip_config_pem_file',
        'type': UIUtils.UI_COMPONENT_TYPES.HORIZONTAL_LABEL_FILE,
        'style': {},
        'labelClass': "gx-pl-2",
        'localisation': "DIC_KEY_CLIENTS_VIEW_PEM_FILE",
        'isStar': true,
        'isMandatory': true,
        'isFile': true,
        'parent_class': "form_container_root",
        'placeholderKey': "DIC_KEY_CLIENTS_VIEW_PEM_FILE_PLACEHOLDER",
        'variant': "outlined",
        'accept':'.pem'
    },
    {
        'key': 'voip_config_key_file',
        'type': UIUtils.UI_COMPONENT_TYPES.HORIZONTAL_LABEL_FILE,
        'style': {},
        'labelClass': "gx-pl-2",
        'localisation': "DIC_KEY_CLIENTS_VIEW_KEY_FILE",
        'isStar': true,
        'isMandatory': true,
        'isFile': true,
        'parent_class': "form_container_root",
        'placeholderKey': "DIC_KEY_CLIENTS_VIEW_KEY_FILE_PLACEHOLDER",
        'variant': "outlined",
        'accept':'.key'
    }
];

const clientsViewFCMNotificationConfig = [
    {
        'key': 'fcm_project_id',
        'type': UIUtils.UI_COMPONENT_TYPES.HORIZONTAL_LABEL_INPUT,
        'style': {},
        'labelClass': "gx-pl-2",
        'localisation': "DIC_KEY_CLIENTS_VIEW_PROJECT_ID",
        'isStar': true,
        'isMandatory': true,
        'parent_class': "form_container_root",
        'placeholderKey': "DIC_KEY_CLIENTS_VIEW_PROJECT_ID_PLACEHOLDER",
        'variant': "outlined"
    },
    {
        'key': 'fcm_service_file',
        'type': UIUtils.UI_COMPONENT_TYPES.HORIZONTAL_LABEL_FILE,
        'style': {},
        'labelClass': "gx-pl-2",
        'localisation': "DIC_KEY_CLIENTS_VIEW_SERVICE_ACCOUNT_FILE",
        'isStar': true,
        'isMandatory': true,
        'isFile': true,
        'parent_class': "form_container_root",
        'placeholderKey': "DIC_KEY_CLIENTS_VIEW_SERVICE_ACCOUNT_FILE_PLACEHOLDER",
        'variant': "outlined",
        'accept':'.json'
    }
];

const clientsViewActivationDateConfig = [
    {
        'key': 'activation_start_date',
        'type': UIUtils.UI_COMPONENT_TYPES.DATE_FIELD_HORIZONTAL,
        'style': {},
        'labelClass': "gx-pl-2",
        'localisation': "DIC_KEY_CLIENTS_VIEW_CLIENT_START_DATE",
        'isStar': true,
        'isMandatory': true,
        'parent_class': "form_container_root",
        'placeholderKey': "DIC_KEY_CLIENTS_VIEW_CLIENT_START_DATE_PLACEHOLDER",
        'variant': "outlined"
    },
    {
        'key': 'activation_end_date',
        'type': UIUtils.UI_COMPONENT_TYPES.DATE_FIELD_HORIZONTAL,
        'style': {},
        'labelClass': "gx-pl-2",
        'localisation': "DIC_KEY_CLIENTS_VIEW_CLIENT_END_DATE",
        'isStar': true,
        'isMandatory': true,
        'parent_class': "form_container_root",
        'placeholderKey': "DIC_KEY_CLIENTS_VIEW_CLIENT_END_DATE_PLACEHOLDER",
        'variant': "outlined"
    }
];


const clientsTableHeaderConfig = [
    /*{
        id: 'list_checkbox',
        label: <div className="info_round_checkbox table_header_checkbox_icon"/>,
        visible: true,
        sortable: false,
        isDynamicComponent: true
    },*/
    {
        id: 'name',
        label: <IntlMessages id="DIC_KEY_CLIENTS_TABLE_HEADER_NAME"/>,
        visible: true,
        sortable: true,
        isClickable: true,
        className: "table_column_text_highlight gx-pl-4"
    },
    {
        id: 'status',
        label: <IntlMessages id="DIC_KEY_CLIENTS_TABLE_HEADER_STATUS"/>,
        visible: true,
        sortable: true,
        isClickable: true,
        className: "table_column_text_normal"
    },
    {
        id: 'activation_start_date',
        label: <IntlMessages id="DIC_KEY_CLIENTS_TABLE_HEADER_START_DATE"/>,
        visible: true,
        sortable: true,
        isClickable: true,
        className: "table_column_text_normal"
    },
    {
        id: 'activation_end_date',
        label: <IntlMessages id="DIC_KEY_CLIENTS_TABLE_HEADER_ACTIVATION_END_DATE"/>,
        visible: true,
        sortable: true,
        isClickable: true,
        className: "table_column_text_normal"
    }
];

const INIT_STATE = {
    clientsTableHeaderConfig: clientsTableHeaderConfig,
    clientsListFilterConfig: clientsListFilterConfig,
    TABLE_PAGINATION_OPTIONS: TABLE_PAGINATION_OPTIONS,
    clientsViewSubHeaderConfig: clientsViewSubHeaderConfig,
    clientsViewInformationConfig: clientsViewInformationConfig,
    clientsViewActivationDateConfig: clientsViewActivationDateConfig,
    clientsViewFeaturesConfig: clientsViewFeaturesConfig,
    clientsViewVOIPNotificationConfig: clientsViewVOIPNotificationConfig,
    clientsViewFCMNotificationConfig: clientsViewFCMNotificationConfig,

    clientListGetStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    clientSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    clientDeactivateStatus: HTTP_REQUEST_STATUS.NOT_STARTED,

    alertMessage: "",
    clientsAllList: [],
    clientsDropdownList: [],
    clientsList: [],
    selectedClient: {}

};


const ReducerClients = (state = INIT_STATE, action) => {

    switch (action.type) {

        case CLIENTS_FILTER_CLIENTS: {
            const {clientsAllList} = state;
            let clientsList = [];

            for (let i = 0; i < clientsAllList.length; i++) {
                if (clientsAllList[i]["name"].toUpperCase().indexOf(action.payload.name.toUpperCase()) === -1) {
                    continue;
                }

                clientsList.push(clientsAllList[i]);
            }

            return {
                ...state,
                clientsList: clientsList
            }
        }

        /** client deactivate **/
        case CLIENTS_DEACTIVATE_CLIENT: {
            return {
                ...state,
                alertMessage: "",
                clientDeactivateStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
            }
        }

        /** client deactivate : success **/
        case CLIENTS_DEACTIVATE_CLIENT_SUCCESS: {
            return {
                ...state,
                alertMessage: action.payload.message,
                clientDeactivateStatus: HTTP_REQUEST_STATUS.SUCCESS,
                selectedClient: action.payload.data
            }
        }

        /** client deactivate : error **/
        case CLIENTS_DEACTIVATE_CLIENT_ERROR: {
            return {
                ...state,
                alertMessage: action.payload.message,
                clientDeactivateStatus: HTTP_REQUEST_STATUS.ERROR
            }
        }

        /** client deactivate : reset **/
        case CLIENTS_DEACTIVATE_CLIENT_NONE: {
            return {
                ...state,
                alertMessage: "",
                clientDeactivateStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }


        /** client save **/
        case CLIENTS_SAVE_CLIENT: {
            return {
                ...state,
                alertMessage: "",
                clientSaveStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
            }
        }

        /** client save : success **/
        case CLIENTS_SAVE_CLIENT_SUCCESS: {
            return {
                ...state,
                alertMessage: action.payload.message,
                clientSaveStatus: HTTP_REQUEST_STATUS.SUCCESS,
                selectedClient: action.payload.data
            }
        }

        /** client save : error **/
        case CLIENTS_SAVE_CLIENT_ERROR: {
            return {
                ...state,
                alertMessage: action.payload.message,
                clientSaveStatus: HTTP_REQUEST_STATUS.ERROR
            }
        }

        /** client save : reset **/
        case CLIENTS_SAVE_CLIENT_NONE: {
            return {
                ...state,
                alertMessage: "",
                clientSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }

        /** client list **/
        case CLIENTS_GET_ALL_CLIENTS: {
            return {
                ...state,
                clientListGetStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
            }
        }

        /** client list : success **/
        case CLIENTS_GET_ALL_CLIENTS_SUCCESS: {

            let clientsList = [];
            let clientsAllList = [];
            let clientsDropdownList = [];

            if (typeof action.payload.data != "undefined") {
                clientsList = parseClientsList(action.payload.data);
                clientsAllList = JSON.parse(JSON.stringify(clientsList));

                for (let i = 0; i < clientsAllList.length; i++) {

                    if (clientsAllList[i]["is_active"]) {
                        clientsDropdownList.push({
                            key: clientsAllList[i]["id"],
                            value: clientsAllList[i]["id"],
                            label: clientsAllList[i]["name"]
                        });
                    }
                }
            }


            return {
                ...state,
                clientsList: clientsList,
                clientsAllList: clientsAllList,
                clientsDropdownList: clientsDropdownList,
                clientListGetStatus: HTTP_REQUEST_STATUS.SUCCESS
            }
        }

        /** client list : error **/
        case CLIENTS_GET_ALL_CLIENTS_ERROR: {
            return {
                ...state,
                clientListGetStatus: HTTP_REQUEST_STATUS.ERROR
            }
        }

        /** client list : reset **/
        case CLIENTS_GET_ALL_CLIENTS_NONE: {
            return {
                ...state,
                clientListGetStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }

        /** to set current client **/
        case CLIENTS_SET_CURRENT_CLIENT: {
            return {
                ...state,
                selectedClient: action.payload
            }
        }

        default: {
            return {
                ...state
            }
        }


    }

}

function parseClientsList(data) {
    let result = data;

    try {

        for (let i = 0; i < data.length; i++) {
            data[i]["status"] = data[i]["is_active"] ? "Active" : "Inactive";
        }


    } catch (ex) {
        console.error("parseClientsList : error : ", ex);
    }

    return result;
}


export default ReducerClients;
