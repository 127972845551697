import {
    CHAT_TAG_CHANNEL,
    CHAT_TAG_CHANNEL_ERROR,
    CHAT_TAG_CHANNEL_MESSAGE,
    CHAT_TAG_CHANNEL_MESSAGE_ERROR,
    CHAT_TAG_CHANNEL_MESSAGE_NONE,
    CHAT_TAG_CHANNEL_MESSAGE_SUCCESS,
    CHAT_TAG_CHANNEL_NONE,
    CHAT_TAG_CHANNEL_SUCCESS,
    CHAT_TAG_DM, CHAT_TAG_DM_ERROR,
    CHAT_TAG_DM_MESSAGE,
    CHAT_TAG_DM_MESSAGE_ERROR,
    CHAT_TAG_DM_MESSAGE_NONE,
    CHAT_TAG_DM_MESSAGE_SUCCESS,
    CHAT_TAG_DM_NONE,
    CHAT_TAG_DM_SUCCESS,
    CHAT_TAG_GET_ALL_TAGS,
    CHAT_TAG_GET_ALL_TAGS_ERROR,
    CHAT_TAG_GET_ALL_TAGS_NONE,
    CHAT_TAG_GET_ALL_TAGS_SUCCESS,
    CHAT_TAG_GET_CHANNEL_TAG_MESSAGES,
    CHAT_TAG_GET_CHANNEL_TAG_MESSAGES_ERROR,
    CHAT_TAG_GET_CHANNEL_TAG_MESSAGES_NONE,
    CHAT_TAG_GET_CHANNEL_TAG_MESSAGES_SUCCESS,
    CHAT_TAG_GET_DM_TAG_MESSAGES,
    CHAT_TAG_GET_DM_TAG_MESSAGES_ERROR,
    CHAT_TAG_GET_DM_TAG_MESSAGES_NONE,
    CHAT_TAG_GET_DM_TAG_MESSAGES_SUCCESS
} from "../../../constants/actions/chat/ChatActionTypes";

/** tag : tag channel **/
export const chatTagChannel = (payload) => {
    return {
        type: CHAT_TAG_CHANNEL,
        payload: payload
    };
};

/** tag : tag channel : success **/
export const chatTagChannelSuccess = (payload) => {
    return {
        type: CHAT_TAG_CHANNEL_SUCCESS,
        payload: payload
    };
};

/** tag : tag channel : error **/
export const chatTagChannelError = (payload) => {
    return {
        type: CHAT_TAG_CHANNEL_ERROR,
        payload: payload
    };
};

/** tag : tag channel : none **/
export const chatTagChannelNone = (payload) => {
    return {
        type: CHAT_TAG_CHANNEL_NONE,
        payload: payload
    };
};


/** tag : tag dm **/
export const chatTagDM = (payload) => {
    return {
        type: CHAT_TAG_DM,
        payload: payload
    };
};

/** tag : tag dm : success **/
export const chatTagDMSuccess = (payload) => {
    return {
        type: CHAT_TAG_DM_SUCCESS,
        payload: payload
    };
};

/** tag : tag dm : error **/
export const chatTagDMError = (payload) => {
    return {
        type: CHAT_TAG_DM_ERROR,
        payload: payload
    };
};

/** tag : tag dm : none **/
export const chatTagDMNone = (payload) => {
    return {
        type: CHAT_TAG_DM_NONE,
        payload: payload
    };
};


/** tag : tag channel message **/
export const tagChatChannelMessage = (payload) => {
    return {
        type: CHAT_TAG_CHANNEL_MESSAGE,
        payload: payload
    };
};

/** tag : tag channel message : success **/
export const tagChatChannelMessageSuccess = (payload) => {
    return {
        type: CHAT_TAG_CHANNEL_MESSAGE_SUCCESS,
        payload: payload
    };
};

/** tag : tag channel message : error **/
export const tagChatChannelMessageError = (payload) => {
    return {
        type: CHAT_TAG_CHANNEL_MESSAGE_ERROR,
        payload: payload
    };
};

/** tag : tag channel message : none **/
export const tagChatChannelMessageNone = (payload) => {
    return {
        type: CHAT_TAG_CHANNEL_MESSAGE_NONE,
        payload: payload
    };
};


/** tag : tag dm message **/
export const tagChatDMMessage = (payload) => {
    return {
        type: CHAT_TAG_DM_MESSAGE,
        payload: payload
    };
};

/** tag : tag dm message : success **/
export const tagChatDMMessageSuccess = (payload) => {
    return {
        type: CHAT_TAG_DM_MESSAGE_SUCCESS,
        payload: payload
    };
};

/** tag : tag dm message : error **/
export const tagChatDMMessageError = (payload) => {
    return {
        type: CHAT_TAG_DM_MESSAGE_ERROR,
        payload: payload
    };
};

/** tag : tag dm message : none **/
export const tagChatDMMessageNone = (payload) => {
    return {
        type: CHAT_TAG_DM_MESSAGE_NONE,
        payload: payload
    };
};


/** tag : get all tags **/
export const chatGetAllTags = (payload) => {
    return {
        type: CHAT_TAG_GET_ALL_TAGS,
        payload: payload
    };
};

/** tag : get all tags : success **/
export const chatGetAllTagsSuccess = (payload) => {
    return {
        type: CHAT_TAG_GET_ALL_TAGS_SUCCESS,
        payload: payload
    };
};

/** tag : get all tags : error **/
export const chatGetAllTagsError = (payload) => {
    return {
        type: CHAT_TAG_GET_ALL_TAGS_ERROR,
        payload: payload
    };
};

/** tag : get all tags : none **/
export const chatGetAllTagsNone = (payload) => {
    return {
        type: CHAT_TAG_GET_ALL_TAGS_NONE,
        payload: payload
    };
};


/** tag : get channel message tags **/
export const getChatTagChannelMessages = (payload) => {
    return {
        type: CHAT_TAG_GET_CHANNEL_TAG_MESSAGES,
        payload: payload
    };
};

/** tag : get channel message tags : success **/
export const getChatTagChannelMessagesSuccess = (payload) => {
    return {
        type: CHAT_TAG_GET_CHANNEL_TAG_MESSAGES_SUCCESS,
        payload: payload
    };
};

/** tag : get channel message tags : error **/
export const getChatTagChannelMessagesError = (payload) => {
    return {
        type: CHAT_TAG_GET_CHANNEL_TAG_MESSAGES_ERROR,
        payload: payload
    };
};

/** tag : get channel message tags : none **/
export const getChatTagChannelMessagesNone = (payload) => {
    return {
        type: CHAT_TAG_GET_CHANNEL_TAG_MESSAGES_NONE,
        payload: payload
    };
};


/** tag : get dm message tags **/
export const getChatTagDMMessage = (payload) => {
    return {
        type: CHAT_TAG_GET_DM_TAG_MESSAGES,
        payload: payload
    };
};

/** tag : get dm message tags : success **/
export const getChatTagDMMessageSuccess = (payload) => {
    return {
        type: CHAT_TAG_GET_DM_TAG_MESSAGES_SUCCESS,
        payload: payload
    };
};

/** tag : get dm message tags : error **/
export const getChatTagDMMessageError = (payload) => {
    return {
        type: CHAT_TAG_GET_DM_TAG_MESSAGES_ERROR,
        payload: payload
    };
};

/** tag : get dm message tags : none **/
export const getChatTagDMMessageNone = (payload) => {
    return {
        type: CHAT_TAG_GET_DM_TAG_MESSAGES_NONE,
        payload: payload
    };
};
